import * as React from 'react';
import { theme } from 'twin.macro';

import { v4 as uuidv4 } from 'uuid';

function SvgLoginCover(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');
  const id = uuidv4();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 275 194"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M238 76c0-10.8-10.7-26.5-16-33-29.5-29.5-67-34-101-34C87.0001 9 15.0001 43 14.0001 81s35.5 59.5 67 76C112.5 173.5 179.3 180 205 145c21.7-29.5 33-34.5 33-69z"
      />
      <path
        fill="#fff"
        d="M22 68.9307c0-19.3004 31.4974-23.4842 31.4974-23.4842 15.4295-23.3828 39.9038-6.696 39.9038-6.696s4.3629-15.4114 25.1128-18.4937c20.75-3.0823 33.732 18.4937 33.732 18.4937C182.788 33.8949 188 61.1731 188 68.9307c0 7.7575-4.895 30.2913-35.647 26.0399 0 0-12.982 18.6004-33.732 15.9424-20.7505-2.657-25.1134-15.9424-25.1134-15.9424s-24.4743 14.4544-39.9038-5.8457c-.0023 0-.0175.0017-.0452.0047C52.3009 89.2676 22 88.231 22 68.9307z"
      />
      <rect
        width={32.8713}
        height={32.8713}
        x={94.3167}
        y={54.4704}
        fill="#D6D6D6"
        rx={3.23498}
      />
      <rect
        width={31.8851}
        height={31.8851}
        x={89.8793}
        y={50.0327}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.986139}
        rx={2.79406}
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.986139}
        d="M101.31 54.5074c.04-.2092.225-.3657.444-.3657h10.404c.25 0 .452.2037.452.4519v1.8901c0 .2509-.204.452-.452.452h-4.274v4.676h2.153c3.553 0 6.361 2.8895 6.361 6.3606v1.4956c0 .2508-.204.452-.452.452h-4.429c-.254 0-.452-.1984-.452-.452v-1.4956c0-.3127-.161-.5475-.33-.6989-.165-.1481-.365-.2441-.542-.3031l-.076-.0253H98.4584v2.0627c0 1.4987-1.2762 2.779-2.7738 2.7858-.0074-.0035-.0189-.0109-.0308-.0227-.0118-.0117-.0189-.0227-.0223-.0295V56.7095c.0036-.0074.0109-.019.0227-.0309.0117-.0118.0226-.0189.0295-.0223 1.4979.0063 2.7747 1.2868 2.7747 2.7859v2.1777h7.5766v-4.676h-4.273c-.251 0-.452-.2037-.452-.4519V54.5074zm11.704 19.0615c.408-.6126.848-1.1353 1.046-1.3627.184.1857.639.7155 1.061 1.3594.224.3424.43.7043.579 1.0508.152.3526.23.6562.23.8916 0 1.2916-.912 2.2352-1.874 2.2352-.963 0-1.874-.9436-1.874-2.2352 0-.2319.08-.5333.236-.8864.154-.3467.366-.709.596-1.0527z"
      />
      <rect
        width={32.8713}
        height={32.8713}
        x={46.6533}
        y={47.0743}
        fill="#D6D6D6"
        rx={3.23498}
      />
      <rect
        width={32.8713}
        height={32.8713}
        x={41.7227}
        y={42.1437}
        fill={illustrationPrimaryColor}
        rx={3.28713}
      />
      <path
        fill="#FCDB9D"
        d="M62.048 47.8961L51.5842 61.7879l5.4309-1.4765-2.5293 9.244 10.8361-14.1742-5.739.8731 2.4651-8.3582z"
      />
      <rect
        width={32.3498}
        height={32.3498}
        x={137.871}
        y={38.8565}
        fill="#D6D6D6"
        rx={3.23498}
      />
      <rect
        width={31.3637}
        height={31.3637}
        x={133.434}
        y={34.4189}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.986139}
        rx={2.74192}
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M142.681 40.3958c-1.002 0-1.775.7617-1.775 1.7498v14.6163c0 .9881.773 1.7498 1.775 1.7498 1.003 0 1.776-.7617 1.776-1.7498V42.1456c0-.9881-.773-1.7498-1.776-1.7498zm4.262 0c-1.003 0-1.776.7617-1.776 1.7498v14.6163c0 .9881.773 1.7498 1.776 1.7498 1.003 0 1.775-.7617 1.775-1.7498V42.1456c0-.9881-.772-1.7498-1.775-1.7498zm4.261 0c-1.002 0-1.775.7617-1.775 1.7498v14.6163c0 .9881.773 1.7498 1.775 1.7498.94 0 1.881-.7411 1.776-1.7704V42.1456c0-.9881-.773-1.7498-1.776-1.7498zm4.346 0c-1.003 0-1.776.7617-1.776 1.7498v14.6163c0 .9881.773 1.7498 1.776 1.7498 1.002 0 1.775-.7617 1.775-1.7498V42.1456c0-.9881-.773-1.7498-1.775-1.7498zm2.757 2.3468c-.188 0-.355.1647-.355.35v2.635c0 .1853.167.35.355.35h.439c.94 0 1.692-.7411 1.692-1.6675s-.752-1.6675-1.692-1.6675h-.439zm0 9.6344c-.188 0-.355.1647-.355.35v2.635c0 .1853.167.35.355.35h.439c.94 0 1.692-.7411 1.692-1.6675s-.752-1.6675-1.692-1.6675h-.439zm-20.159-9.6344c-.188 0-.355.1647-.355.35v2.635c0 .1853.167.35.355.35h1.776c.188 0 .355-.1647.355-.35v-2.635c0-.1853-.167-.35-.355-.35h-1.776zm0 9.6344c-.188 0-.355.1647-.355.35v2.635c0 .1853.167.35.355.35h1.776c.188 0 .355-.1647.355-.35v-2.635c0-.1853-.167-.35-.355-.35h-1.776z"
        clipRule="evenodd"
      />
      <path fill="#D6D6D6" d="M165.909 153.638h4.583v9.353h-4.583v-9.353z" />
      <path
        fill="#fff"
        d="M166.097 156.631l3.648 4.864.28.374.374.654.094.281h-2.619l-.094-.281-1.683-2.338-.281-.374v-3.461l.281.281z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M164.6 152.516h1.029v10.849H164.6v-10.849zM171.054 152.422h1.029v10.85h-1.029v-10.85zM250.836 99.3894h4.677v9.3536h-4.677v-9.3536z"
      />
      <path
        fill={illustrationPrimaryColor}
        d="M172.083 70.1139h77.257V175.15h-77.257V70.1139z"
      />
      <path
        fill="#D6D6D6"
        d="M249.34 80.2153h1.029v10.7562h-1.029V80.2153zM255.794 80.2153h1.029v10.7562h-1.029V80.2153zM257.516 168.697c-1.965-1.31-4.116 6.266-4.116 11.036 0 4.771 1.964 6.267 4.116 6.267 2.338 0 4.209-1.496 4.209-6.267.374-2.619-.749-8.698-4.209-11.036zM192.379 77.3158h4.677v9.3532h-4.677v-9.3532zM187.328 77.3158h4.677v9.3532h-4.677v-9.3532zM182.371 77.3158h4.677v9.3532h-4.677v-9.3532zM177.321 77.3158h4.77v9.3532h-4.77v-9.3532z"
      />
      <path
        fill="#E9F9FF"
        d="M182.091 76.8482H197.336v10.1014H176.853V76.8482h5.238zm-4.677 9.8208h4.583v-9.3532h-4.583v9.3532zm4.957 0h4.677v-9.3532h-4.677v9.3532zm4.957 0h4.677v-9.3532h-4.677v9.3532zm5.051 0h4.677v-9.3532h-4.677v9.3532z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M192.847 78.1576l-.281-.3741-.094-.2806-.28-.2806H194.25l.28.2806 1.964 3.3671.281.3742v3.7412l-.281-.3741-3.647-6.4537zM189.105 86.669l-1.122-1.7771-.374-.5612-4.677-6.2666-.281-.3741-.093-.0936-.281-.2806H185.645l.28.2806 1.777 2.4319.281.3741 4.022 6.0796.093.2806M177.694 80.4959l3.648 5.0508.281.2806.467.6547.281.2806H179.752l-.28-.2806-1.778-2.4319-.28-.2806v-3.5542l.28.2806z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#E9F9FF"
        d="M206.596 85.7337h4.677v-8.8855h-4.677v8.8855zm4.957 0h4.583v-8.8855h-4.583v8.8855z"
      />
      <path
        fill="#fff"
        d="M251.117 102.569l3.648 4.864.28.374.374.655.094.281H252.894l-.093-.281-1.684-2.338-.281-.374v-3.461l.281.28z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M250.836 81.4312h4.677v9.3532h-4.677v-9.3532zM249.34 98.08h1.029v10.85h-1.029V98.08zM255.607 98.08h1.029v10.85h-1.029V98.08zM250.649 117.254h4.677v9.353h-4.677v-9.353z"
      />
      <path
        fill="#fff"
        d="M250.743 120.247l3.648 4.864.28.374.374.655.094.28h-2.432l-.093-.28-1.684-2.339-.281-.374v-3.461l.094.281z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M249.34 116.132h1.029v10.849h-1.029v-10.849zM255.607 115.851h1.029v10.943h-1.029v-10.943zM165.909 117.254h4.583v9.353h-4.583v-9.353z"
      />
      <path
        fill="#D6D6D6"
        d="M166.097 120.247l3.647 4.864.281.374.374.655.094.28h-2.432l-.094-.28-1.683-2.339-.281-.374v-3.461l.094.281z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M164.6 115.945h1.029v10.756H164.6v-10.756zM171.054 115.851h1.029v10.85h-1.029v-10.85zM166.003 99.3894h4.677v9.3536h-4.677v-9.3536z"
      />
      <path
        fill="#fff"
        d="M166.284 102.382l3.648 5.145.28.374.374.655.094.28h-2.619l-.094-.28-1.683-2.432-.281-.374v-3.555l.281.187z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M164.694 98.08h1.028v10.85h-1.028V98.08zM171.147 98.08h1.029v10.85h-1.029V98.08zM165.909 81.5248h4.583v9.3532h-4.583v-9.3532z"
      />
      <path
        fill="#fff"
        d="M166.097 84.5178l3.648 5.1442.28.3742.374.6547.094.2806h-2.619l-.094-.2806-1.683-2.4318-.281-.3742v-3.5542l.281.1871z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M164.694 80.2153h1.028v10.7562h-1.028V80.2153zM171.054 80.2153h1.029v10.7562h-1.029V80.2153zM165.909 135.306h4.583v9.353h-4.583v-9.353z"
      />
      <path
        fill="#fff"
        d="M166.097 138.205l3.648 4.864.28.374.374.655.094.28h-2.619l-.094-.28-1.683-2.339-.281-.374v-3.46l.281.28z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M164.6 134.09h1.029v10.849H164.6V134.09zM171.054 133.996h1.029v10.85h-1.029v-10.85zM241.428 166.452c-2.338 0-4.116 6.266-4.116 11.037 0 4.77 1.965 6.266 4.116 6.266 2.338 0 4.209-1.496 4.209-6.266-.094-4.771-1.964-11.037-4.209-11.037zM192.192 95.3675h4.583v9.5405h-4.583v-9.5405zM187.235 95.3675h4.676v9.5405h-4.676v-9.5405zM182.184 95.3675h4.677v9.5405h-4.677v-9.5405zM177.227 95.3675h4.676v9.5405h-4.676v-9.5405z"
      />
      <path
        fill="#E9F9FF"
        d="M181.904 95.1805H197.056v10.0075H176.853V95.1805h5.051zm-4.677 9.6335h4.677v-9.4465h-4.677v9.4465zm4.957 0h4.677v-9.4465h-4.677v9.4465zm5.051 0h4.676v-9.4465h-4.676v9.4465zm4.957 0h4.583v-9.4465h-4.583v9.4465z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M192.847 96.3028l-.281-.3741-.093-.2806-.281-.2806H194.25l.28.2806 1.965 3.3672.28.3741v3.7416l-.28-.374-3.648-6.4542zM188.919 104.627l-1.123-1.777-.374-.561-4.676-6.2668-.281-.3741-.094-.0935-.28-.2806h3.367l.281.2806 1.777 2.4318.28.3741 4.022 6.0795.094.281M177.32 98.6411l3.648 5.0509.281.28.467.655.281.281h-2.806l-.281-.281-1.777-2.432-.28-.28v-3.5545l.467.2806z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M239.987 95.3675h4.676v9.5405h-4.676v-9.5405zM234.936 95.3675h4.677v9.5405h-4.677v-9.5405zM229.885 95.3675h4.677v9.5405h-4.677v-9.5405zM224.928 95.3675h4.677v9.5405h-4.677v-9.5405z"
      />
      <path
        fill="#E9F9FF"
        d="M229.698 95.1805H244.944v10.0075h-20.296V95.1805h5.05zm-4.77 9.6335h4.677v-9.4465h-4.677v9.4465zm4.957 0h4.677v-9.4465h-4.677v9.4465zm5.051 0h4.677v-9.4465h-4.677v9.4465zm5.051 0h4.677v-9.4465h-4.677v9.4465z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M240.361 96.3028l-.281-.3741-.093-.2806-.281-.2806h2.339l.28.2806 2.151 3.3672.281.3741v3.7416l-.281-.374-4.115-6.4542zM236.62 104.627l-1.123-1.777-.374-.561-4.677-6.2668-.28-.3741-.094-.0935-.28-.2806h3.367l.28.2806 1.778 2.4318.28.3741 4.022 6.0795.094.281M225.022 98.6411l3.648 5.0509.28.28.468.655.28.281H227.08l-.281-.281-1.777-2.432-.281-.28v-3.5545l.281.2806z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M240.081 77.3158h4.676v9.3532h-4.676v-9.3532zM235.03 77.3158h4.676v9.3532h-4.676v-9.3532zM230.073 77.3158h4.676v9.3532h-4.676v-9.3532zM225.022 77.3158h4.77v9.3532h-4.77v-9.3532z"
      />
      <path
        fill="#E9F9FF"
        d="M229.792 76.8482h15.246v10.1014h-20.484V76.8482h5.238zm-4.677 9.8208h4.677v-9.3532h-4.677v9.3532zm4.958 0h4.676v-9.3532h-4.676v9.3532zm4.957 0h4.676v-9.3532h-4.676v9.3532zm5.051 0h4.676v-9.3532h-4.676v9.3532z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M240.548 78.1576l-.281-.3741-.093-.2806-.281-.2806H241.951l.281.2806 1.964 3.3671.28.3742v3.7412l-.28-.3741-3.648-6.4537zM236.9 86.669l-1.122-1.7771-.374-.5612-4.677-6.2666-.281-.3741-.093-.0936-.281-.2806h3.367l.281.2806 1.777 2.4319.281.3741 4.022 6.0796.093.2806M225.302 80.4959l3.648 5.0508.281.2806.467.6547.281.2806H227.36l-.281-.2806-1.777-2.4319-.28-.2806v-3.5542l.28.2806z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M239.987 113.419h4.676v9.54h-4.676v-9.54zM234.936 113.419h4.677v9.54h-4.677v-9.54zM229.885 113.419h4.677v9.54h-4.677v-9.54zM224.928 113.419h4.677v9.54h-4.677v-9.54z"
      />
      <path
        fill="#D6D6D6"
        d="M229.698 113.139H244.944v10.008h-20.296v-10.008h5.05zm-4.77 9.633h4.677v-9.353h-4.677v9.353zm4.957 0h4.677v-9.353h-4.677v9.353zm5.051 0h4.677v-9.353h-4.677v9.353zm5.051 0h4.677v-9.353h-4.677v9.353z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M240.361 114.355l-.281-.375-.093-.28-.281-.281h2.339l.28.281 2.151 3.367.281.374v3.741l-.281-.374-4.115-6.453zM236.62 122.772l-1.123-1.777-.374-.561-4.677-6.267-.28-.374-.094-.093-.28-.281h3.367l.28.281 1.778 2.432.28.374 4.022 6.079.094.281M225.022 116.599l3.648 5.051.28.281.468.654.28.281H227.08l-.281-.281-1.777-2.431-.281-.281v-3.554l.281.28z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M211.74 77.1288h4.116v8.5114h-4.116v-8.5114zM206.783 85.6402v-8.5114h4.116v8.5114h-4.116z"
      />
      <path
        fill="#fff"
        d="M212.208 77.877l-.281-.3741-.093-.2806-.281-.2806H213.611l.281.2806 1.964 3.3672.28.3741v3.7413l-.28-.3742-3.648-6.4537zM206.877 79.4671l3.647 5.0507.281.2806.468.6547.28.2806H208.934l-.28-.2806-1.777-2.4318-.281-.2806v-3.5542l.281.2806z"
        opacity={0.63}
      />
      <path
        fill="#E9F9FF"
        d="M206.41 104.16h4.583v-8.886h-4.583v8.886zm4.957 0h4.676v-8.886h-4.676v8.886z"
      />
      <path
        fill="#D6D6D6"
        d="M211.553 95.3675h4.116v8.5115h-4.116v-8.5115zM206.502 103.879v-8.5115h4.116v8.5115h-4.116z"
      />
      <path
        fill="#fff"
        d="M211.927 96.2093l-.28-.3741-.094-.2806-.28-.2806H213.424l.281.2806 1.964 3.3671.374.3742v3.7411l-.281-.374-3.835-6.4537zM206.596 97.7058l3.648 5.0512.28.28.468.655.281.28h-2.619l-.281-.28-1.777-2.432-.281-.281v-3.5538l.281.2806z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M192.098 113.326h4.677v9.54h-4.677v-9.54zM187.048 113.326h4.583v9.54h-4.583v-9.54zM182.091 113.326h4.676v9.54h-4.676v-9.54zM177.04 113.326h4.676v9.54h-4.676v-9.54z"
      />
      <path
        fill="#E9F9FF"
        d="M181.81 113.045h15.246v10.008h-20.297v-10.008h5.051zm-4.77 9.634h4.676v-9.353h-4.676v9.353zm5.051 0h4.676v-9.353h-4.676v9.353zm4.957 0h4.583v-9.353h-4.583v9.353zm5.051 0h4.676v-9.353h-4.676v9.353z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M192.473 114.167l-.281-.374-.093-.28-.281-.281h2.338l.281.281 2.151 3.367.281.374v3.741l-.281-.374-4.115-6.454zM188.731 122.679l-1.122-1.777-.374-.561-4.677-6.267-.28-.374-.094-.094-.28-.28h3.367l.28.28 1.777 2.432.281.374 3.835 6.08.093.28M177.227 116.506l3.648 5.05.28.281.468.655.281.28h-2.713l-.281-.28-1.777-2.432-.28-.281v-3.554l.374.281z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M192.005 131.19h4.676v9.541h-4.676v-9.541zM186.954 131.19h4.677v9.541h-4.677v-9.541zM181.904 131.19h4.583v9.541h-4.583v-9.541zM176.946 131.19h4.677v9.541h-4.677v-9.541z"
      />
      <path
        fill="#E9F9FF"
        d="M181.716 130.91H196.962v10.008h-20.296V130.91h5.05zm-4.77 9.633h4.677v-9.353h-4.677v9.353zm4.957 0h4.583v-9.353h-4.583v9.353zm5.051 0h4.677v-9.353h-4.677v9.353zm5.051 0h4.676v-9.353h-4.676v9.353z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M192.379 132.032l-.281-.374-.093-.281-.281-.28h2.339l.28.28 2.151 3.367.281.375v3.741l-.281-.374-4.115-6.454zM188.638 140.543l-1.123-1.777-.374-.561-4.676-6.266-.281-.375-.094-.093-.28-.281h3.367l.281.281 1.777 2.432.28.374 4.022 6.079.094.281M177.04 134.464l3.648 5.051.28.28.468.655.28.281H179.098l-.281-.281-1.777-2.432-.281-.28v-3.742l.281.468z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M239.987 131.19h4.676v9.541h-4.676v-9.541zM234.936 131.19h4.677v9.541h-4.677v-9.541zM229.885 131.19h4.677v9.541h-4.677v-9.541zM224.928 131.19h4.677v9.541h-4.677v-9.541z"
      />
      <path
        fill="#E9F9FF"
        d="M229.698 130.91H244.944v10.008h-20.296V130.91h5.05zm-4.77 9.633h4.677v-9.353h-4.677v9.353zm4.957 0h4.677v-9.353h-4.677v9.353zm5.051 0h4.677v-9.353h-4.677v9.353zm5.051 0h4.677v-9.353h-4.677v9.353z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M240.361 132.032l-.281-.374-.093-.281-.281-.28h2.339l.28.28 2.151 3.367.281.375v3.741l-.281-.374-4.115-6.454zM236.62 140.543l-1.123-1.777-.374-.561-4.677-6.266-.28-.375-.094-.093-.28-.281h3.367l.28.281 1.778 2.432.28.374 4.022 6.079.094.281M225.022 134.37l3.648 5.051.28.281.468.654.28.281H227.08l-.281-.281-1.777-2.431-.281-.281v-3.648l.281.374z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M191.818 149.429h4.676v9.54h-4.676v-9.54zM186.861 149.429h4.676v9.54h-4.676v-9.54zM181.81 149.429h4.676v9.54h-4.676v-9.54zM176.759 149.429h4.583v9.54h-4.583v-9.54z"
      />
      <path
        fill="#E9F9FF"
        d="M181.529 149.148H196.775v10.008h-20.296v-10.008h5.05zm-4.77 9.634h4.583v-9.353h-4.583v9.353zm5.051 0h4.676v-9.353h-4.676v9.353zm5.051 0h4.676v-9.353h-4.676v9.353zm4.957 0h4.676v-9.353h-4.676v9.353z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M192.192 150.364l-.281-.374-.093-.28-.281-.281h2.339l.28.281 2.151 3.367.281.374v3.741l-.281-.374-4.115-6.454zM188.544 158.782l-1.122-1.777-.374-.561-4.677-6.267-.281-.374-.093-.093-.281-.281h3.367l.281.281 1.777 2.431.281.375 4.022 6.079.093.281M176.946 152.796l3.648 5.051.281.281.467.654.281.281H179.004l-.281-.281-1.777-2.432-.28-.28v-3.648l.28.374z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#D6D6D6"
        d="M240.081 149.429h4.676v9.54h-4.676v-9.54zM235.03 149.429h4.676v9.54h-4.676v-9.54zM230.073 149.429h4.676v9.54h-4.676v-9.54zM225.022 149.429h4.583v9.54h-4.583v-9.54z"
      />
      <path
        fill="#E9F9FF"
        d="M229.792 149.148h15.246v10.008h-20.484v-10.008h5.238zm-4.77 9.634h4.583v-9.353h-4.583v9.353zm5.051 0h4.676v-9.353h-4.676v9.353zm4.957 0h4.676v-9.353h-4.676v9.353zm5.051 0h4.676v-9.353h-4.676v9.353z"
      />
      <g fill="#fff" opacity={0.63}>
        <path
          d="M240.455 150.364l-.281-.374-.094-.28-.28-.281h2.338l.281.281 2.151 3.367.281.374v3.741l-.281-.374-4.115-6.454zM236.713 158.689l-1.122-1.777-.374-.468-4.677-6.267-.28-.374-.094-.093-.281-.281H233.253l.28.281 1.777 2.431.281.375 4.022 6.079.093.281M225.115 152.609l3.648 5.051.281.28.467.655.281.281h-2.713l-.28-.281-1.777-2.432-.094-.28v-3.648l.187.374z"
          opacity={0.63}
        />
      </g>
      <path
        fill="#E9F9FF"
        d="M206.41 122.024h4.583v-8.885h-4.583v8.885zm4.957 0h4.676v-8.885h-4.676v8.885z"
      />
      <path
        fill="#D6D6D6"
        d="M211.647 113.326h4.115v8.511h-4.115v-8.511zM206.596 121.931v-8.512h4.116v8.512h-4.116z"
      />
      <path
        fill="#fff"
        d="M212.021 114.167l-.281-.374-.093-.28-.281-.281H213.424l.281.281 1.964 3.367.28.374v3.741l-.28-.374-3.648-6.454zM206.596 115.57l3.648 5.051.28.281.468.654.281.281h-2.619l-.281-.281-1.777-2.431-.281-.281v-3.648l.281.374z"
        opacity={0.63}
      />
      <path
        fill="#E9F9FF"
        d="M206.128 139.795h4.677v-8.885h-4.677v8.885zm5.051 0h4.676v-8.885h-4.676v8.885z"
      />
      <path
        fill="#D6D6D6"
        d="M211.366 131.19h4.116v8.512h-4.116v-8.512zM206.409 139.608v-8.511h4.115v8.511h-4.115z"
      />
      <path
        fill="#fff"
        d="M211.74 131.939l-.28-.375-.094-.28-.281-.281h2.058l.281.281 1.964 3.367.281.374v3.741l-.281-.374-3.648-6.453zM206.409 133.435l3.648 5.051.28.28.468.655.28.281H208.466l-.28-.281-1.777-2.432-.281-.28v-3.648l.281.374z"
        opacity={0.63}
      />
      <path
        fill="#E9F9FF"
        d="M206.502 158.034h4.677v-8.886h-4.677v8.886zm5.051 0h4.583v-8.886h-4.583v8.886z"
      />
      <path
        fill="#D6D6D6"
        d="M211.74 149.335h4.116v8.512h-4.116v-8.512zM206.783 157.753v-8.511h4.116v8.511h-4.116z"
      />
      <path
        fill="#fff"
        d="M212.208 150.084l-.281-.374-.093-.281-.281-.281H213.611l.281.281 1.964 3.367.28.374v3.742l-.28-.375-3.648-6.453zM206.783 151.58l3.648 5.051.28.281.468.654.281.281h-2.619l-.281-.281-1.777-2.432-.281-.28V151.3l.281.28zM251.117 84.6113l3.648 4.8637.28.3741.374.6547.094.2806H252.894l-.093-.2806-1.684-2.3383-.281-.3741v-3.4607l.281.2806z"
        opacity={0.63}
      />
      <path fill="#D6D6D6" d="M250.743 135.306h4.583v9.353h-4.583v-9.353z" />
      <path
        fill="#fff"
        d="M251.024 138.486l3.648 4.863.28.375.374.654.094.281h-2.619l-.094-.281-1.683-2.245-.281-.374v-3.46l.281.187z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M249.247 133.996h1.028v10.85h-1.028v-10.85zM255.513 133.996h1.029v10.85h-1.029v-10.85zM251.024 153.919h4.676v9.353h-4.676v-9.353z"
      />
      <path
        fill="#fff"
        d="M251.304 157.099l3.648 4.863.281.374.374.655.093.281H253.081l-.093-.281-1.684-2.338-.28-.374v-3.461l.28.281z"
        opacity={0.63}
      />
      <path
        fill="#D6D6D6"
        d="M249.434 152.609h1.028v10.85h-1.028v-10.85zM255.794 152.609h1.029v10.85h-1.029v-10.85z"
      />
      <path
        fill="#fff"
        d="M166.096 120.06l3.648 5.144.281.374.374.655.093.281H167.874l-.094-.281-1.684-2.432-.187-.374v-3.554l.187.187z"
        opacity={0.63}
      />
      <circle cx={164.083} cy={119.083} r={29.5644} fill="#E6E6E6" />
      <g filter="url(#filter0_d)">
        <path
          fill={illustrationPrimaryColor}
          fillRule="evenodd"
          d="M125.325 147.932l-16.311 16.315c-2.689 2.687-2.687 7.07.016 9.733l.005.006c1.339 1.343 3.113 2.014 4.867 2.014s3.524-.671 4.868-2.015l16.311-16.315c1.306-1.306 2.015-3.018 2.015-4.869 0-.73-.114-1.438-.334-2.103l-.161-.487 6.849-6.851.582.469c5.663 4.566 12.654 7.054 20.031 7.054 8.54 0 16.565-3.319 22.6-9.355 12.449-12.452 12.449-32.737 0-45.1889-12.45-12.4521-32.729-12.4521-45.179 0-11.82 11.8239-12.433 30.6989-1.815 43.2339l.495.585-6.608 6.59-.522-.234c-2.541-1.137-5.641-.65-7.709 1.418zm19.393-48.3324l.001-.0012c5.348-5.3278 12.336-8.0034 19.343-8.0034s14.017 2.6747 19.342 8.0046c10.652 10.6554 10.652 28.0174 0 38.6724-5.172 5.173-12.033 8.001-19.342 8.005-7.309 0-14.19-2.849-19.344-8.005-10.653-10.655-10.653-28.018 0-38.6724z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M163.855 101.666c5.863 0 11.031 2.902 14.144 7.338 1.943 2.769 3.079 6.128 3.079 9.76 0 5.066-2.215 9.622-5.763 12.764-1.813 1.606-1.986 4.383-.387 6.203 1.599 1.819 4.365 1.993 6.178.388 5.341-4.731 8.726-11.648 8.726-19.355 0-5.508-1.731-10.624-4.677-14.822-4.695-6.6892-12.491-11.0648-21.3-11.0648-8.81 0-16.606 4.3756-21.301 11.0648-2.945 4.198-4.677 9.314-4.677 14.822 0 7.707 3.385 14.624 8.727 19.355 1.813 1.605 4.579 1.431 6.178-.388 1.599-1.82 1.426-4.597-.387-6.203-3.549-3.142-5.763-7.698-5.763-12.764 0-3.632 1.136-6.991 3.079-9.76 3.113-4.436 8.281-7.338 14.144-7.338z"
        clipRule="evenodd"
      />
      <path
        fill="#333"
        fillRule="evenodd"
        d="M157.213 100.05c-.157.678-.664 1.056-1.421 1.056-.943 0-1.541-.687-1.541-1.7752 0-1.0782.605-1.7732 1.541-1.7732.745 0 1.312.4546 1.421 1.1401h-.432c-.109-.4499-.5-.745-.989-.745-.668 0-1.102.5427-1.102 1.3781 0 .8422.429 1.3802 1.105 1.3802.502 0 .863-.242.986-.661h.432zm-12.09 10.41c.728 0 1.159-.359 1.159-.964 0-.457-.299-.785-.763-.845v-.019c.329-.059.59-.393.59-.75 0-.514-.396-.857-.982-.857h-1.296v3.435h1.292zm-.866-3.056h.773c.415 0 .648.188.648.528 0 .372-.275.574-.78.574h-.641v-1.102zm0 2.678v-1.207h.761c.546 0 .825.202.825.6 0 .397-.27.607-.784.607h-.802zm-.54 12.925h-.448l-.344-.983h-1.361l-.343.983h-.449l1.262-3.434h.422l1.261 3.434zm-1.465-2.913h-.014l-.548 1.566h1.11l-.548-1.566zm6.604 15.461h-.448l-.344-.983h-1.361l-.343.983h-.448l1.261-3.435h.422l1.261 3.435zm-1.465-2.913h-.014l-.548 1.566h1.11l-.548-1.566zm4.066 1.752h-.953v1.004h-.384v-1.004h-.953v-.386h.953v-1.004h.384v1.004h.953v.386zm19.455-36.7796v3.4346h1.15c1.017 0 1.615-.635 1.615-1.7184 0-1.0806-.6-1.7162-1.615-1.7162h-1.15zm.427.3856h.695c.777 0 1.206.4761 1.206 1.3353 0 .8517-.431 1.3277-1.206 1.3277h-.695V98zm11.02 12.075h1.693v.385h-2.12v-3.435h2.12v.386h-1.693v1.102h1.605v.381h-1.605v1.181zm2.919 12.932v-1.487h1.515v-.381h-1.515v-1.181h1.65v-.385h-2.077v3.434h.427zm-4.985 10.801v.326c0 .929-.538 1.479-1.451 1.479-.956 0-1.567-.7-1.567-1.793 0-1.073.609-1.756 1.567-1.756.74 0 1.302.44 1.43 1.123h-.448c-.143-.464-.501-.728-.982-.728-.704 0-1.129.509-1.129 1.359 0 .864.432 1.399 1.129 1.399.607 0 1.024-.395 1.024-.971v-.062h-.965v-.376h1.392z"
        clipRule="evenodd"
        opacity={0.8}
      />
      <path
        fill="#50B849"
        d="M160.895 125.274l.862-2.648h4.19l.861 2.648h2.631l-4.003-11.611h-3.163l-4.009 11.611h2.631zm1.485-4.564l1.429-4.394h.091l1.429 4.394h-2.949z"
      />
      <defs>
        <linearGradient
          id={id}
          x1={190}
          x2={134.5}
          y1={100}
          y2={100}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.0602007} stopColor="#FF1500" />
          <stop offset={0.567708} stopColor="#FFE600" stopOpacity={0.5} />
          <stop offset={0.911662} stopColor="#219653" />
        </linearGradient>
        <filter
          id="filter0_d"
          width={100.755}
          height={100.755}
          x={101.123}
          y={82.8019}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1.67925} />
          <feGaussianBlur stdDeviation={2.93868} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

const MemoSvgLoginCover = React.memo(SvgLoginCover);
export default MemoSvgLoginCover;
