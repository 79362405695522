import { useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../contexts/AppContext';
import { selectedTenantId } from '../../lib/apolloClient';

import paths from '../../lib/paths';
import useClickOutside from '../../hooks/useClickOutside';

import { Box, CompanyIcon, Icon, MenuItem } from '../../ui';
import { TenancySelector } from './TenancySelector';
import { useScrollLock } from '../../hooks/useScrollLock';
import { bp } from '../../lib/twinMacro';
import useAccountHasStatements from '../../hooks/data/useAccountHasStatements';

const Menu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tenantId = useReactiveVar(selectedTenantId);
  const hasStatementsEnabled = useAccountHasStatements();

  const { isMenuActive, closeMenu, toggleMenu, userTenancies } =
    useContext(AppContext);

  const refMenu = useRef(null);
  useScrollLock(refMenu, () => window.innerWidth < bp.xl && isMenuActive, [
    isMenuActive,
  ]);

  useClickOutside(refMenu, () => {
    if (isMenuActive) closeMenu();
  });

  let menuItems = [
    {
      label: t('drawer_menu.consumption'),
      icon: 'consumption',
      path: paths.consumption().route,
      active:
        location.pathname === '/' ||
        location.pathname.startsWith('/consumption') ||
        location.pathname.startsWith('/dashboard'),
    },
    {
      label: t('drawer_menu.meter_list'),
      icon: 'meterList',
      path: paths.meters().route,
      active: location.pathname.startsWith(paths.meters().route),
    },
    {
      label: t('drawer_menu.allocation_statements'),
      icon: 'allocationStatement',
      path: paths.statements().route,
      active: location.pathname.startsWith(paths.statements().route),
    },
    {
      label: t('drawer_menu.alarm_settings'),
      icon: 'alarm',
      path: paths.alarms().route,
      active: location.pathname.startsWith(paths.alarms().route),
    },
    {
      label: t('drawer_menu.tenancy_info'),
      icon: 'tenancyInfo',
      path: paths.settings().route,
      active: location.pathname.startsWith(paths.settings().route),
    },
  ];

  if (!hasStatementsEnabled) {
    menuItems = menuItems.filter((item) => item.icon !== 'allocationStatement');
  }

  return (
    <Box as="section" ref={refMenu}>
      <Box
        as="header"
        className="fixed xl:static top-0 z-80 xl:z-10 flex w-full items-center p-4 overflow-y-hidden bg-secondary-500"
        style={{
          height: 'var(--height-header)',
          maxWidth: 'var(--width-menu)',
        }}
      >
        <button
          onClick={() => toggleMenu()}
          className="xl:hidden absolute icon"
          type="button"
        >
          <Icon name="close" className="text-white" />
        </button>
        <Box className="flex w-full justify-center">
          <Link to="/">
            <CompanyIcon className="text-white h-10" />
          </Link>
        </Box>
      </Box>

      <Box
        as="nav"
        className="px-4 fixed z-80 xl:z-10 w-screen overflow-y-auto bg-secondary-500 text-gray-200"
        style={{
          height: 'calc(100% - var(--height-header))',
          maxWidth: 'var(--width-menu)',
        }}
      >
        {userTenancies && userTenancies.length > 1 && tenantId && (
          <TenancySelector />
        )}

        <Box
          as="ul"
          className="flex flex-col space-y-2 py-4"
          onClick={() => closeMenu()}
        >
          {menuItems.map((menuItem, index) => {
            return (
              <Box as="li" key={index}>
                <Link to={menuItem.path}>
                  <MenuItem
                    icon={menuItem.icon}
                    label={menuItem.label}
                    active={menuItem.active}
                  />
                </Link>
              </Box>
            );
          })}
          <Box as="li" className="absolute inset-x-0 bottom-0">
            <Box className="px-4">
              <Box style={{ paddingTop: '18px', paddingBottom: '14px' }}>
                <Link to={paths.help().route}>
                  <MenuItem
                    icon="helpMenu"
                    label={t('drawer_menu.help')}
                    active={location.pathname.startsWith(paths.help().route)}
                  />
                </Link>
              </Box>
              <Box className="w-full h-px bg-secondary-400" />
              <Box style={{ paddingTop: '18px', paddingBottom: '14px' }}>
                <Link to={paths.profile().route}>
                  <MenuItem
                    icon="user"
                    label={t('drawer_menu.profile_settings')}
                    active={location.pathname.startsWith(paths.profile().route)}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
