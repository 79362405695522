import * as React from 'react';
import { theme } from 'twin.macro';

function SvgAnalyseUsage(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      width={343}
      height={260}
      viewBox="0 0 343 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M322.014 139.457C340.005 101.644 316.309 83.1571 308.646 74.0306C266.418 32.7392 213.552 27.2455 165.722 28.0747C117.891 28.904 32.6414 51.2126 16.9741 133.22C5.13483 195.189 48.3806 233.786 97.7147 232.93C139.873 232.2 142.318 232.132 184.466 221.281C204.832 216.038 304.022 177.27 322.014 139.457Z"
        fill="#F5F5F5"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M142.388 120.145H307.765C308.538 120.145 309.165 120.772 309.165 121.545V224.119C309.165 224.893 308.538 225.519 307.765 225.519H142.388C141.614 225.519 140.988 224.893 140.988 224.119V121.545C140.988 120.772 141.614 120.145 142.388 120.145Z"
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M301.68 113.493H136.303C135.198 113.493 134.303 114.388 134.303 115.493V217.116C134.303 218.22 135.198 219.116 136.303 219.116H301.68C302.784 219.116 303.68 218.22 303.68 217.116V115.493C303.68 114.388 302.784 113.493 301.68 113.493Z"
          fill="white"
        />
        <path
          d="M180.427 169.635H134.301V204.843H180.427C182.277 204.843 183.782 203.455 183.782 201.743V172.735C183.782 171.023 182.277 169.635 180.427 169.635Z"
          fill="#D6D6D6"
        />
        <path
          d="M218.99 132.524V113.601H258.004V132.524C258.004 133.575 257.152 134.427 256.1 134.427H220.893C219.842 134.427 218.99 133.575 218.99 132.524Z"
          fill="#D6D6D6"
        />
        <path
          d="M136.303 114.093H301.68C302.453 114.093 303.08 114.72 303.08 115.493V217.116C303.08 217.889 302.453 218.516 301.68 218.516H136.303C135.53 218.516 134.903 217.889 134.903 217.116V115.493C134.903 114.72 135.53 114.093 136.303 114.093Z"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.646 194.556H296.066"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.85 2.85"
        />
        <path
          d="M237.84 210.352V209.42C237.84 208.925 237.641 208.449 237.298 208.106C236.946 207.754 236.48 207.564 235.985 207.564H232.264C231.769 207.564 231.294 207.764 230.951 208.106C230.599 208.458 230.409 208.925 230.409 209.42V210.352"
          fill="white"
        />
        <path
          d="M237.84 210.352V209.42C237.84 208.925 237.641 208.449 237.298 208.106C236.946 207.754 236.48 207.564 235.985 207.564H232.264C231.769 207.564 231.294 207.764 230.951 208.106C230.599 208.458 230.409 208.925 230.409 209.42V210.352"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M234.12 205.699C235.148 205.699 235.976 204.862 235.976 203.843C235.976 202.825 235.138 201.988 234.12 201.988C233.102 201.988 232.265 202.825 232.265 203.843C232.265 204.862 233.093 205.699 234.12 205.699Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.951 210.352V209.42C274.951 208.925 274.751 208.449 274.409 208.106C274.057 207.754 273.59 207.564 273.096 207.564H269.375C268.88 207.564 268.404 207.764 268.062 208.106C267.71 208.458 267.52 208.925 267.52 209.42V210.352"
          fill="white"
        />
        <path
          d="M274.951 210.352V209.42C274.951 208.925 274.751 208.449 274.409 208.106C274.057 207.754 273.59 207.564 273.096 207.564H269.375C268.88 207.564 268.404 207.764 268.062 208.106C267.71 208.458 267.52 208.925 267.52 209.42V210.352"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M271.231 205.699C272.259 205.699 273.087 204.862 273.087 203.843C273.087 202.825 272.249 201.988 271.231 201.988C270.213 201.988 269.375 202.825 269.375 203.843C269.375 204.862 270.203 205.699 271.231 205.699Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M199.777 210.352V209.42C199.777 208.925 199.578 208.449 199.235 208.106C198.883 207.754 198.417 207.564 197.922 207.564H194.201C193.706 207.564 193.231 207.764 192.888 208.106C192.536 208.458 192.346 208.925 192.346 209.42V210.352"
          fill="white"
        />
        <path
          d="M199.777 210.352V209.42C199.777 208.925 199.578 208.449 199.235 208.106C198.883 207.754 198.417 207.564 197.922 207.564H194.201C193.706 207.564 193.231 207.764 192.888 208.106C192.536 208.458 192.346 208.925 192.346 209.42V210.352"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M196.057 205.699C197.085 205.699 197.913 204.862 197.913 203.843C197.913 202.825 197.075 201.988 196.057 201.988C195.039 201.988 194.202 202.825 194.202 203.843C194.202 204.862 195.03 205.699 196.057 205.699Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M160.764 210.352V209.42C160.764 208.925 160.564 208.449 160.221 208.106C159.869 207.754 159.403 207.564 158.908 207.564H155.188C154.693 207.564 154.217 207.764 153.874 208.106C153.522 208.458 153.332 208.925 153.332 209.42V210.352"
          fill="white"
        />
        <path
          d="M160.764 210.352V209.42C160.764 208.925 160.564 208.449 160.221 208.106C159.869 207.754 159.403 207.564 158.908 207.564H155.188C154.693 207.564 154.217 207.764 153.874 208.106C153.522 208.458 153.332 208.925 153.332 209.42V210.352"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M157.044 205.699C158.071 205.699 158.899 204.862 158.899 203.843C158.899 202.825 158.062 201.988 157.044 201.988C156.025 201.988 155.188 202.825 155.188 203.843C155.188 204.862 156.016 205.699 157.044 205.699Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.855 163.926H195.498C194.285 163.926 193.298 164.813 193.298 165.903V189.618H198.056V165.903C198.056 164.813 197.078 163.926 195.855 163.926Z"
          fill="#F9B83B"
        />
        <path
          d="M233.917 135.379H233.56C232.347 135.379 231.36 136.684 231.36 138.287V189.618H236.118V138.287C236.118 136.684 235.13 135.379 233.917 135.379Z"
          fill="#F9B83B"
        />
        <path
          d="M157.793 151.555H157.436C156.223 151.555 155.235 152.471 155.235 153.596V189.617H159.993V153.596C160.002 152.471 159.015 151.555 157.793 151.555Z"
          fill="#F9B83B"
        />
        <path
          d="M271.98 147.749H271.623C270.41 147.749 269.422 148.937 269.422 150.395V189.618H274.18V150.395C274.18 148.937 273.193 147.749 271.98 147.749Z"
          fill="#F9B83B"
        />
        <path
          d="M291.736 142.772H288.416C287.569 142.772 286.893 142.087 286.893 141.25V137.929C286.893 137.082 287.578 136.406 288.416 136.406H291.736C292.583 136.406 293.259 137.092 293.259 137.929V141.25C293.259 142.087 292.574 142.772 291.736 142.772Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M291.736 157.74H288.416C287.569 157.74 286.893 157.055 286.893 156.218V152.887C286.893 152.041 287.578 151.365 288.416 151.365H291.736C292.583 151.365 293.259 152.05 293.259 152.887V156.208C293.259 157.055 292.574 157.74 291.736 157.74Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M291.736 172.699H288.416C287.569 172.699 286.893 172.014 286.893 171.176V167.855C286.893 167.009 287.578 166.333 288.416 166.333H291.736C292.583 166.333 293.259 167.018 293.259 167.855V171.176C293.259 172.014 292.574 172.699 291.736 172.699Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M291.736 187.667H288.416C287.569 187.667 286.893 186.982 286.893 186.144V182.824C286.893 181.977 287.578 181.301 288.416 181.301H291.736C292.583 181.301 293.259 181.986 293.259 182.824V186.144C293.259 186.982 292.574 187.667 291.736 187.667Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M40.9531 59.8827H185.406C186.179 59.8827 186.806 60.5095 186.806 61.2827V151.43C186.806 152.203 186.179 152.83 185.406 152.83H40.9531C40.1799 152.83 39.5531 152.203 39.5531 151.43V61.2827C39.5531 60.5095 40.1799 59.8827 40.9531 59.8827Z"
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M180.388 53.5449H34.3877C33.2831 53.5449 32.3877 54.4404 32.3877 55.5449V146.545C32.3877 147.649 33.2831 148.545 34.3877 148.545H180.388C181.492 148.545 182.388 147.649 182.388 146.545V55.5449C182.388 54.4404 181.492 53.5449 180.388 53.5449Z"
          fill="white"
        />
        <path
          d="M34.3877 54.1449H180.388C181.161 54.1449 181.788 54.7717 181.788 55.5449V146.545C181.788 147.318 181.161 147.945 180.388 147.945H34.3877C33.6145 147.945 32.9877 147.318 32.9877 146.545V55.5449C32.9877 54.7717 33.6145 54.1449 34.3877 54.1449Z"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.4629 134.932H172.789"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.85 2.85"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8066 103.026C42.8066 87.7854 42.8923 75.4307 42.9979 75.4307H172.979C173.084 75.4307 173.17 87.7854 173.17 103.026C173.17 118.266 173.084 130.621 172.979 130.621H42.9979C42.8923 130.621 42.8066 118.266 42.8066 103.026Z"
          fill="#F5F5F5"
        />
        <path
          d="M87.0541 62.1089H46.1371C44.2977 62.1089 42.8066 63.6 42.8066 65.4393C42.8066 67.2787 44.2977 68.7698 46.1371 68.7698H87.0541C88.8934 68.7698 90.3845 67.2787 90.3845 65.4393C90.3845 63.6 88.8934 62.1089 87.0541 62.1089Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M169.839 62.1089H141.292C139.453 62.1089 137.962 63.6 137.962 65.4393C137.962 67.2787 139.453 68.7698 141.292 68.7698H169.839C171.678 68.7698 173.17 67.2787 173.17 65.4393C173.17 63.6 171.678 62.1089 169.839 62.1089Z"
          fill="#D6D6D6"
        />
        <path
          d="M43.7578 117.01L65.4106 98.8934L72.59 109.61L82.4166 104.286L104.717 120.154L134.958 85.8979L144.766 94.0577L157.63 85.8979L173.17 94.0577"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.8}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={51.9877}
          y={164.145}
          width={124.8}
          height={34.8}
          rx={3.4}
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.5883 180.938C61.5883 177.068 64.7254 173.931 68.5953 173.931C72.4651 173.931 75.6022 177.068 75.6022 180.938C75.6022 183.53 74.1946 185.795 72.0982 187.008C71.726 187.223 71.5988 187.699 71.8141 188.072C72.0294 188.444 72.5056 188.571 72.8778 188.356C75.4356 186.876 77.1593 184.109 77.1593 180.938C77.1593 176.208 73.325 172.374 68.5953 172.374C63.8655 172.374 60.0312 176.208 60.0312 180.938C60.0312 184.109 61.7549 186.876 64.3127 188.356C64.6849 188.571 65.1611 188.444 65.3764 188.072C65.5917 187.699 65.4646 187.223 65.0924 187.008C62.996 185.795 61.5883 183.53 61.5883 180.938ZM71.2219 178.41C71.4915 178.245 71.8066 178.54 71.66 178.82L69.615 182.724C69.5737 182.803 69.5008 182.86 69.4146 182.882L67.4251 183.379C67.2286 183.428 67.0382 183.28 67.0382 183.077V181.139C67.0382 181.03 67.0947 180.929 67.1873 180.873L71.2219 178.41Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M116.975 173.02H85.746C84.0212 173.02 82.623 174.418 82.623 176.143C82.623 177.868 84.0212 179.266 85.746 179.266H116.975C118.7 179.266 120.098 177.868 120.098 176.143C120.098 174.418 118.7 173.02 116.975 173.02Z"
          fill="#D6D6D6"
        />
        <path
          d="M164.23 173.02H150.698C148.973 173.02 147.575 174.418 147.575 176.143C147.575 177.868 148.973 179.266 150.698 179.266H164.23C165.955 179.266 167.353 177.868 167.353 176.143C167.353 174.418 165.955 173.02 164.23 173.02Z"
          fill="#FCDB9D"
        />
        <path
          d="M161.373 188.666H84.2965C83.5082 188.666 82.8691 189.305 82.8691 190.093C82.8691 190.882 83.5082 191.521 84.2965 191.521H161.373C162.161 191.521 162.8 190.882 162.8 190.093C162.8 189.305 162.161 188.666 161.373 188.666Z"
          fill={illustrationPrimaryColor}
        />
        <rect
          x={214.832}
          y={91.2558}
          width={36.8}
          height={36.8}
          rx={3.4}
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M227.563 96.5422C227.608 96.3149 227.809 96.1449 228.048 96.1449H240.094C240.369 96.1449 240.589 96.3677 240.589 96.6392V98.8278C240.589 99.102 240.366 99.3221 240.094 99.3221H235.717H235.117V99.9221V104.195V104.795H235.717H237.639C241.737 104.795 244.975 108.127 244.975 112.131V113.862C244.975 114.137 244.753 114.357 244.481 114.357H239.352C239.075 114.357 238.858 114.14 238.858 113.862V112.131C238.858 111.758 238.667 111.479 238.467 111.3C238.272 111.125 238.037 111.012 237.829 110.943L237.737 110.912H237.639H228.048H224.803H224.203V111.512V113.33C224.203 115.046 222.742 116.514 221.027 116.526C221.026 116.525 221.024 116.524 221.022 116.522C221.016 116.519 221.01 116.514 221.005 116.508C220.999 116.503 220.995 116.497 220.991 116.492C220.99 116.49 220.989 116.489 220.988 116.487V99.0965C220.989 99.0948 220.99 99.0927 220.992 99.0905C220.995 99.0851 221 99.0794 221.006 99.0738C221.011 99.0683 221.017 99.0636 221.022 99.06C221.024 99.0587 221.025 99.0576 221.027 99.0568C222.742 99.0683 224.203 100.536 224.203 102.253V104.204V104.804H224.803H232.434H233.034V104.204V99.9316V99.3316H232.434H228.057C227.783 99.3316 227.563 99.1088 227.563 98.8373V96.6392V96.5422ZM241.11 118.627C241.568 117.94 242.061 117.35 242.299 117.076C242.523 117.307 243.029 117.903 243.501 118.623C243.76 119.019 243.999 119.436 244.17 119.835C244.345 120.242 244.433 120.589 244.433 120.856C244.433 122.34 243.387 123.416 242.293 123.416C241.198 123.416 240.152 122.34 240.152 120.856C240.152 120.594 240.242 120.249 240.423 119.842C240.6 119.442 240.845 119.024 241.11 118.627Z"
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width={278}
            height={173}
            fill="white"
            transform="translate(32.3877 53.5449)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgAnalyseUsage = React.memo(SvgAnalyseUsage);
export default MemoSvgAnalyseUsage;
