import { lazy } from 'react';
import { Redirect, Route, Switch, Router } from 'react-router';
import { history } from '../../helpers/history';
import PrivateRoute from './PrivateRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

const AccountCreated = lazy(() => import('../../routes/auth/AccountCreated'));
const Alarm = lazy(() => import('../../routes/alarm/Alarm'));
const AlarmEdit = lazy(() => import('../../routes/alarm/AlarmEdit'));
const AlarmNew = lazy(() => import('../../routes/alarm/AlarmNew'));
const Alarms = lazy(() => import('../../routes/alarm/Alarms'));
const CookiePolicy = lazy(() => import('../../routes/cookie-policy'));
const ConsumptionPage = lazy(
  () => import('../../routes/consumption/ConsumptionPage')
);
const ConsumptionMeterPage = lazy(
  () => import('../../routes/consumption/ConsumptionMeterPage')
);
const ConsumptionRoomPage = lazy(
  () => import('../../routes/consumption/ConsumptionRoomPage')
);
const Dashboard = lazy(() => import('../../routes/Dashboard'));
const DataPolicy = lazy(() => import('../../routes/data-policy'));
const EnterCode = lazy(() => import('../../routes/auth/EnterCode'));
const ForgotPassword = lazy(() => import('../../routes/auth/ForgotPassword'));
const Help = lazy(() => import('../../routes/Help'));
const IndoorClimatePage = lazy(
  () => import('../../routes/consumption/IndoorClimate')
);
const ImpersonationPortal = lazy(
  () => import('../../routes/ImpersonationPortal')
);
const Login = lazy(() => import('../../routes/auth/Login'));
const Meters = lazy(() => import('../../routes/Meters'));
const Notifications = lazy(() => import('../../routes/Notifications'));
const Profile = lazy(() => import('../../routes/Profile'));
const Report = lazy(() => import('../../routes/Report'));
const SetPassword = lazy(() => import('../../routes/auth/SetPassword'));
const Settings = lazy(() => import('../../routes/Settings'));
const SignInWithEmail = lazy(() => import('../../routes/auth/LoginWithEmail'));
const Signup = lazy(() => import('../../routes/auth/Signup'));
const SignupWithEmail = lazy(() => import('../../routes/auth/SignupWithEmail'));
const Statements = lazy(() => import('../../routes/Statements'));

const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <UnauthenticatedRoute
          exact
          path="/cookie-policy"
          component={CookiePolicy}
        />
        <UnauthenticatedRoute
          exact
          path="/data-policy"
          component={DataPolicy}
        />
        <UnauthenticatedRoute exact path="/signup" component={Signup} />
        <UnauthenticatedRoute
          exact
          path="/signup/email"
          component={SignupWithEmail}
        />
        <UnauthenticatedRoute
          exact
          path="/account-created"
          component={AccountCreated}
        />
        <UnauthenticatedRoute
          exact
          path="/enter-code"
          component={EnterCode}
          /*@ts-ignore*/
          storeOnConfirm={true}
        />

        <UnauthenticatedRoute
          exact
          path="/set-password"
          component={SetPassword}
        />
        <UnauthenticatedRoute exact path="/login" component={Login} />
        <UnauthenticatedRoute
          exact
          path="/login/email"
          component={SignInWithEmail}
        />
        <UnauthenticatedRoute
          exact
          path="/login/email/forgot-password"
          component={ForgotPassword}
        />
        <UnauthenticatedRoute exact path="/report/:code" component={Report} />
        <UnauthenticatedRoute
          exact
          path="/impersonate/:token"
          component={ImpersonationPortal}
        />
        <PrivateRoute exact path="/" component={Dashboard} noBreadcrumbs />
        <PrivateRoute
          exact
          path="/dashboard/indoor-climate"
          component={IndoorClimatePage}
        />
        <PrivateRoute
          exact
          path="/consumption/:consumptionType"
          component={ConsumptionPage}
        />
        <PrivateRoute
          exact
          path="/consumption/:consumptionType/room/:roomName"
          component={ConsumptionRoomPage}
        />
        <PrivateRoute
          exact
          path="/consumption/:consumptionType/meter/:meterId"
          component={ConsumptionMeterPage}
        />
        <PrivateRoute exact path="/alarms" component={Alarms} noBreadcrumbs />
        <PrivateRoute
          exact
          path="/alarms/new-alarm"
          component={AlarmNew}
          noBreadcrumbs
        />
        <PrivateRoute exact path="/alarms/:alarmId" component={Alarm} />
        <PrivateRoute
          exact
          path="/alarms/:alarmId/edit"
          component={AlarmEdit}
          noBreadcrumbs
        />
        <PrivateRoute exact path="/help" component={Help} noBreadcrumbs />
        <PrivateRoute exact path="/meters" component={Meters} noBreadcrumbs />
        <PrivateRoute
          exact
          path="/notifications"
          component={Notifications}
          noBreadcrumbs
        />
        <PrivateRoute exact path="/profile" component={Profile} noBreadcrumbs />
        <PrivateRoute
          exact
          path="/settings"
          component={Settings}
          noBreadcrumbs
        />
        <PrivateRoute
          exact
          path="/statements"
          component={Statements}
          noBreadcrumbs
        />

        {/* Catch all (404 NOT FOUND) route */}
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
