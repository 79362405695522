import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgCarCharging(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3998 1.30005C9.4545 1.30005 7.99976 2.88273 7.99976 4.70005V26.6H7.8C6.76863 26.6 6 27.3686 6 28.4V30.7H26.3V28.4C26.3 27.3686 25.5314 26.6 24.5 26.6H23.8998V4.70005C23.8998 2.7548 22.3171 1.30005 20.4998 1.30005H11.3998Z"
        fill={`url(#${id})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.67748C10 4.30335 10.2878 4.00006 10.6429 4.00006H21.3571C21.7122 4.00006 22 4.30335 22 4.67748V10.3226C22 10.6968 21.7122 11.0001 21.3571 11.0001H10.6429C10.2878 11.0001 10 10.6968 10 10.3226V4.67748ZM15.8 17.9H20.4L14.9 24.2L16.6 19.3H12L18.3 13.1L15.8 17.9Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id={id}
          x1={3.4625}
          y1={1.30005}
          x2={30.0635}
          y2={20.6342}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A9069" />
          <stop offset={1} stopColor="#A3F5BA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgCarCharging = React.memo(SvgCarCharging);
export default MemoSvgCarCharging;
