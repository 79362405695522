import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { DEFAULT_PERIOD_TYPE, GRAPHQL_URL } from '../config';
import {
  fetchNewAccessToken,
  getAccessToken,
  getRefreshToken,
  isTokenExpired,
  processNewAccessTokenResponse,
  setAccessToken,
  startAuthenticateTimer,
  stopAuthenticateTimer,
} from '../helpers/auth';
import { Period, Periodicity } from '../models/Period';
import PeriodUtil from './periodUtil';

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
  includeUnusedVariables: false,
});

export const selectedPeriod = makeVar<Period>(
  PeriodUtil.periods.find((p) => p.key === DEFAULT_PERIOD_TYPE)
);
export const selectedTenantId = makeVar<number>(null);
export const selectedSpanType = makeVar<Periodicity>('day');
export const selectedComparison = makeVar<string>((window.location.pathname.includes('room') || window.location.pathname.includes('meter')) ? 'last_period' :'property');

const authLink = setContext((_, { headers }) => {
  if (getAccessToken()) {
    return {
      headers: {
        ...headers,
        authorization: `JWT ${getAccessToken()}`,
      },
    };
  } else {
    return headers;
  }
});

const TokenLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => {
    return !(
      isTokenExpired(1000 * 60 * 4.5) || typeof getAccessToken() !== 'string'
    );
  },
  fetchAccessToken: async () => {
    // We don't want to auth timer to attempt a refresh simultaneously.
    stopAuthenticateTimer();

    // No need to refresh token if user is unavailable.
    if (!getRefreshToken()) {
      return Promise.reject();
    }

    return fetchNewAccessToken();
  },
  handleResponse: () => async (response: Response) => {
    return await processNewAccessTokenResponse(response);
  },
  handleFetch: (newToken) => {
    //console.log('[AUTH] Processing new access token.');
    setAccessToken(newToken);
    startAuthenticateTimer();
  },
  handleError: (err) => {
    stopAuthenticateTimer();
    //console.log('[AUTH]', err);
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([TokenLink, errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

export default client;
