import * as React from 'react';

function SvgNorwegian(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="currentColor"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={0.5}
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0H0V8H8V0ZM12 0V8H28V0H12ZM28 12H12V20H28V12ZM8 20V12H0V20H8Z"
          fill="#F14247"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.6667H9.33333V20H10.6667V10.6667H28V9.33333H10.6667V0H9.33333V9.33333H0V10.6667Z"
          fill="#0A3A85"
        />
      </g>
    </svg>
  );
}

const MemoSvgNorwegian = React.memo(SvgNorwegian);
export default MemoSvgNorwegian;
