import tw, { styled } from 'twin.macro';
import SvgStepperCurrent from '../assets/stepper-bar/StepperCurrent';
import SvgStepperDone from '../assets/stepper-bar/StepperDone';
import SvgStepperInactive from '../assets/stepper-bar/StepperInactive';

export type StepperBarProps = {
  stepCount: number;
  currentStep: number;
}

const StepperBar: React.FC<StepperBarProps> = ({ stepCount, currentStep }) => {
  const parts = [];
  for (let i = 1; i < stepCount + 1; i++) {
    // Steps
    if (i === currentStep) {
      parts.push(
        <div className="z-10" key={i}>
          <SvgStepperCurrent />
        </div>
      );
    } else if (i > currentStep) {
      parts.push(
        <div className="z-10" key={i}>
          <SvgStepperInactive />
        </div>
      );
    } else if (i < currentStep) {
      parts.push(
        <div className="z-10" key={i}>
          <SvgStepperDone />
        </div>
      );
    }

    // Line
    if (i < stepCount) {
      parts.push(<Line active={currentStep - 1 >= i} key={i + 10} />);
    }
  }

  return <StepperBarWrapper>{parts}</StepperBarWrapper>;
};

const StepperBarWrapper = styled.div(() => [
  tw`flex items-center max-w-4xl px-5 sm:px-0 mt-2 xl:mt-6 mb-8 xl:mb-12 mx-auto`,
]);

const Line = styled.div<{ active?: boolean }>(({ active }) => [
  tw`h-0.5 w-full bg-gray-100 transition-colors duration-200`,
  active && [tw`h-1 bg-sunflower-500`],
]);

export default StepperBar;
