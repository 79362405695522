import * as React from 'react';

function SvgBgRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={413}
      height={154}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 413 154"
      {...props}
    >
      <g clipPath="url(#clip0)" opacity={0.25}>
        <path
          fill="#fff"
          d="M349.422 32.398a6.527 6.527 0 00-3.995 1.37c-.343-3.425-3.182-6.105-6.633-6.105a6.565 6.565 0 00-4.434 1.735c-.976-2.517-3.376-4.305-6.193-4.305-3.51 0-6.38 2.77-6.641 6.293a6.496 6.496 0 00-3.405-.96c-3.682 0-6.663 3.045-6.663 6.805v.224h44.403c-.761-2.905-3.354-5.057-6.439-5.057zM300.055 42.17l-2.951 3.016-2.951-3.016a1.499 1.499 0 00-2.124-.022 1.495 1.495 0 00-.022 2.122l4.024 4.11c.283.29.671.455 1.073.455a1.5 1.5 0 001.073-.455l4.025-4.11a1.503 1.503 0 00-2.147-2.1z"
        />
        <path
          fill="#51B89F"
          d="M183.599 61.275h-.849l-.887 47.456h3.473l-.887-47.456h-.85z"
        />
        <path
          fill="#51B89F"
          d="M185.455 60.464h-3.711c-.477 0-.865.521-.865 1.169s.388 1.169.865 1.169h3.711c.477 0 .865-.521.865-1.17 0-.647-.388-1.168-.865-1.168z"
        />
        <path
          fill="#51B89F"
          d="M179.888 61.387a2.924 2.924 0 00-1.542.484l-10.702 7.015c-.209.134-.045.461.186.372l11.06-4.036c.372-.134.693-.387.916-.707 0 0 1.782-2.294 1.826-2.413l-.126-.76-1.618.045zM186.305 62.43a1.91 1.91 0 00-1.073-.44s-2.877-.394-3.004-.372l-.596.491.85 1.386c.29.469.7.848 1.192 1.094l11.432 5.749c.224.111.418-.186.231-.35l-9.032-7.558zM182.624 45.846c-.015-.245-.373-.268-.418-.022l-2.034 11.587c-.067.387-.015.79.156 1.146 0 0 1.096 2.689 1.178 2.785l.723.269.775-1.423a2.92 2.92 0 00.35-1.578l-.73-12.764z"
        />
        <path
          fill="#51B89F"
          d="M181.744 60.464c-.477 0-.865.521-.865 1.169s.388 1.169.865 1.169.864-.521.864-1.17c0-.647-.387-1.168-.864-1.168z"
        />
        <path
          fill="#51B89F"
          d="M181.744 61.186c-.179 0-.328.201-.328.447s.149.447.328.447c.179 0 .328-.201.328-.447s-.149-.447-.328-.447z"
        />
        <path
          fill="#51B89F"
          d="M181.461 60.717h-.223c-.291 0-1.543.44-1.536.916.008.44 1.282.916 1.536.916h.223c.336 0 .611-.41.611-.916 0-.507-.275-.916-.611-.916z"
        />
        <path
          fill="#A5E0C8"
          d="M321.214 87.206c52.518-35.14 91.727-18.817 91.727-18.817v99.171H-12.806s105.851-67.405 161.992-67.405c79.862 0 99.768 35.401 172.028-12.949z"
        />
        <path fill="#51B89F" d="M325.953 97.715v-42.51h31.156v42.51" />
        <path
          fill="#006169"
          d="M325.491 96.796V91.97h.462v4.826M325.491 75.656h.462v4.825h-.462v-4.825zM325.491 67.728h.462v4.826h-.462v-4.826zM325.491 59.801h.462v4.825h-.462v-4.825zM325.491 83.813h.462v4.825h-.462v-4.825z"
        />
        <path
          fill="#E9F9FF"
          d="M341.416 62.214h2.077v-4.021h-2.077v4.02zm2.192 0h2.077v-4.021h-2.077v4.02z"
        />
        <path
          fill="#BAE8E2"
          d="M343.723 58.422h1.847v3.792h-1.847v-3.792zM341.531 62.214v-3.792h1.846v3.792h-1.846z"
        />
        <path
          fill="#fff"
          d="M343.954 58.767l-.115-.23-.115-.115-.116-.115H344.531l.116.115.923 1.494.115.115v1.723l-.115-.23-1.616-2.757zM341.531 59.456l1.615 2.298.116.115.231.345.115.115h-1.154l-.115-.115-.808-1.034-.115-.115.115-1.609z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M341.3 70.486h2.077v-3.907H341.3v3.907zm2.308 0h2.077v-3.907h-2.077v3.907z"
        />
        <path
          fill="#BAE8E2"
          d="M343.608 66.58h1.846v3.79h-1.846v-3.79zM341.416 70.37v-3.79h1.846v3.79h-1.846z"
        />
        <path
          fill="#fff"
          d="M343.839 66.924l-.116-.23-.115-.115-.115-.114H344.416l.115.114.923 1.494.231.23v1.608l-.115-.23-1.731-2.757zM341.416 67.613l1.615 2.298.115.115.231.345.116.115h-1.27l-.115-.115-.808-1.034-.115-.115v-1.609h.231z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M341.3 78.413h2.077v-4.021H341.3v4.021zm2.308 0h2.077v-4.021h-2.077v4.021z"
        />
        <path
          fill="#BAE8E2"
          d="M343.723 74.507h1.847v3.791h-1.847v-3.791zM341.416 78.413v-3.791h1.846v3.791h-1.846z"
        />
        <path
          fill="#fff"
          d="M343.839 74.966l-.116-.114-.115-.115-.115-.115H344.416l.115.115.923 1.493.116.23v1.723l-.116-.23-1.615-2.987zM341.416 75.54l1.615 2.299.115.115.231.344.116.115h-1.27l-.115-.115-.808-1.149-.115-.115v-1.608l.231.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M341.185 86.34h2.077v-4.02h-2.077v4.02zm2.308 0h2.077v-4.02h-2.077v4.02z"
        />
        <path
          fill="#BAE8E2"
          d="M343.608 82.55h1.846v3.79h-1.846v-3.79zM341.3 86.34v-3.79h1.846v3.79H341.3z"
        />
        <path
          fill="#fff"
          d="M343.723 82.894l-.23-.23-.116-.115H344.3l.116.115.923 1.494.115.23v1.723l-.115-.23-1.616-2.987zM341.3 83.583l1.616 2.298.115.115.231.345.115.115H342.108l-.116-.115-.807-1.15-.116-.114v-1.609l.231.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M341.416 94.498h2.077v-4.021h-2.077v4.02zm2.192 0h2.077v-4.021h-2.077v4.02z"
        />
        <path
          fill="#BAE8E2"
          d="M343.723 90.592h1.847v3.79h-1.847v-3.79zM341.531 94.383v-3.791h1.846v3.79h-1.846z"
        />
        <path
          fill="#fff"
          d="M343.954 90.936l-.115-.23-.116-.114-.115-.115H344.531l.115.115.924 1.493.115.23v1.723l-.115-.23-1.616-2.872zM341.531 91.626l1.615 2.297.116.115.231.345.115.115h-1.154l-.115-.115-.808-1.149-.115-.115v-1.608l.115.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M350.647 62.328h2.077v-3.906h-2.077v3.907zm2.193 0h2.077v-3.906h-2.077v3.907z"
        />
        <path
          fill="#BAE8E2"
          d="M352.955 58.422h1.846v3.792h-1.846v-3.792zM350.763 62.214v-3.792h1.846v3.792h-1.846z"
        />
        <path
          fill="#fff"
          d="M353.186 58.767l-.115-.115-.116-.115-.115-.115H353.763l.115.115.924 1.494.115.23v1.608l-.115-.23-1.616-2.872zM350.763 59.456l1.615 2.183.116.115.23.345.116.115h-1.154l-.116-.115-.807-1.034-.116-.115.116-1.494z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M350.532 70.486h2.077v-4.022h-2.077v4.022zm2.308 0h2.077v-4.022h-2.077v4.022z"
        />
        <path
          fill="#BAE8E2"
          d="M352.84 66.58h1.846v3.79h-1.846v-3.79zM350.647 70.37v-3.79h1.846v3.79h-1.846z"
        />
        <path
          fill="#fff"
          d="M353.07 66.924l-.115-.23-.115-.115-.116-.114H353.647l.116.114.923 1.494.231.23v1.723l-.116-.23-1.731-2.872zM350.647 67.613l1.616 2.183.115.115.231.345.115.115H351.455l-.116-.115-.807-1.15-.116-.114v-1.609l.231.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M350.532 78.528h2.077v-4.021h-2.077v4.021zm2.308 0h2.077v-4.021h-2.077v4.021z"
        />
        <path
          fill="#BAE8E2"
          d="M352.955 74.622h1.846v3.791h-1.846v-3.791zM350.647 78.413v-3.791h1.846v3.791h-1.846z"
        />
        <path
          fill="#fff"
          d="M353.07 74.966l-.115-.114-.115-.115-.116-.115H353.647l.116.115.923 1.493.115.23v1.723l-.115-.23-1.616-2.987zM350.647 75.656l1.616 2.298.115.115.231.344.115.115H351.455l-.116-.115-.807-1.149-.116-.115v-1.608l.231.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M350.416 86.456h2.077v-4.022h-2.077v4.022zm2.308 0h2.077v-4.022h-2.077v4.022z"
        />
        <path
          fill="#BAE8E2"
          d="M352.839 82.55h1.847v3.79h-1.847v-3.79zM350.531 86.34v-3.79h1.847v3.79h-1.847z"
        />
        <path
          fill="#fff"
          d="M352.955 82.894l-.231-.23-.115-.115H353.532l.115.115.808 1.494.115.23v1.723l-.115-.23-1.5-2.987zM350.532 83.583l1.615 2.298.115.115.231.345.116.115h-1.27l-.115-.115-.808-1.15-.115-.114v-1.609l.231.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M350.647 94.613h2.077v-4.021h-2.077v4.02zm2.193 0h2.077v-4.021h-2.077v4.02z"
        />
        <path
          fill="#BAE8E2"
          d="M352.955 90.706h1.846v3.792h-1.846v-3.792zM350.763 94.498v-3.792h1.846v3.792h-1.846z"
        />
        <path
          fill="#fff"
          d="M353.186 91.051l-.116-.23-.115-.115-.115-.114H353.763l.115.114.923 1.494.116.23v1.723l-.116-.23-1.615-2.872zM350.763 91.74l1.615 2.298.116.115.23.345.116.115h-1.154l-.116-.115-.807-1.15-.116-.114.116-1.493z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M331.607 62.328h2.077v-4.02h-2.077v4.02zm2.192 0h2.078v-4.02h-2.078v4.02z"
        />
        <path
          fill="#BAE8E2"
          d="M333.915 58.537h1.846v3.791h-1.846v-3.79zM331.607 62.328v-3.79h1.846v3.79h-1.846z"
        />
        <path
          fill="#fff"
          d="M334.03 58.882l-.115-.23-.116-.115-.115-.115H334.607l.116.115.923 1.494.115.23v1.608l-.115-.23-1.616-2.757zM331.722 59.571l1.616 2.298.115.115.231.344.116.115h-1.27l-.115-.114-.808-1.035-.115-.114V59.57h.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M331.492 70.6h2.077v-4.02h-2.077v4.02zm2.192 0h2.077v-4.02h-2.077v4.02z"
        />
        <path
          fill="#BAE8E2"
          d="M333.8 66.694h1.846v3.792H333.8v-3.792zM331.492 70.486v-3.792h1.846v3.792h-1.846z"
        />
        <path
          fill="#fff"
          d="M333.915 67.039l-.231-.23-.115-.115H334.492l.115.115.923 1.494.231.23v1.608l-.115-.115-1.731-2.987zM331.607 67.728l1.616 2.183.115.115.231.345.115.115H332.415l-.116-.115-.807-1.034-.116-.115v-1.609l.231.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M331.492 78.528h2.077v-4.021h-2.077v4.021zm2.192 0h2.077v-4.021h-2.077v4.021z"
        />
        <path
          fill="#BAE8E2"
          d="M333.8 74.622h1.846v3.791H333.8v-3.791zM331.607 78.528v-3.791h1.846v3.791h-1.846z"
        />
        <path
          fill="#fff"
          d="M334.03 75.081l-.115-.23-.116-.114-.115-.115H334.607l.116.115.923 1.493.115.115v1.723l-.115-.114-1.616-2.873zM331.607 75.656l1.616 2.298.115.115.231.344.115.115H332.415l-.116-.115-.807-1.149-.116-.115v-1.608l.231.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M331.376 86.456h2.077v-4.022h-2.077v4.022zm2.193 0h2.077v-4.022h-2.077v4.022z"
        />
        <path
          fill="#BAE8E2"
          d="M333.684 82.664h1.846v3.791h-1.846v-3.79zM331.491 86.455v-3.79h1.847v3.79h-1.847z"
        />
        <path
          fill="#fff"
          d="M333.915 83.009l-.116-.23-.115-.115-.115-.115H334.492l.115.115.923 1.494.116.23v1.723l-.116-.23-1.615-2.872zM331.492 83.698l1.615 2.298.115.115.231.344.116.115h-1.27l-.115-.114-.808-1.15-.115-.114v-1.609l.231.115z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M331.492 94.613h2.077v-4.021h-2.077v4.02zm2.308 0h2.077v-4.021H333.8v4.02z"
        />
        <path
          fill="#BAE8E2"
          d="M333.915 90.706h1.846v3.792h-1.846v-3.792zM331.607 94.498v-3.792h1.846v3.792h-1.846z"
        />
        <path
          fill="#fff"
          d="M334.03 91.051l-.115-.23-.116-.115-.115-.114H334.607l.116.114.923 1.494.115.23v1.723l-.115-.23-1.616-2.872zM331.607 91.74l1.616 2.298.115.115.231.345.115.115H332.415l-.116-.115-.807-1.149-.116-.115v-1.608l.231.114z"
          opacity={0.63}
        />
        <path
          fill="#51B89F"
          d="M165.172 74.035h-.735l-.768 41.066h3.005l-.767-41.066h-.735z"
        />
        <path
          fill="#51B89F"
          d="M166.778 73.332h-3.212c-.413 0-.748.452-.748 1.012s.335 1.012.748 1.012h3.212c.412 0 .748-.451.748-1.012 0-.56-.336-1.012-.748-1.012z"
        />
        <path
          fill="#51B89F"
          d="M161.96 74.131a2.526 2.526 0 00-1.334.42l-9.262 6.07c-.18.115-.038.399.162.322l9.57-3.493c.323-.116.6-.335.794-.612 0 0 1.541-1.985 1.58-2.088l-.11-.657-1.4.038zM167.513 75.034a1.653 1.653 0 00-.929-.38s-2.489-.342-2.599-.323l-.516.425.735 1.199a2.57 2.57 0 001.032.947l9.893 4.975c.194.097.361-.161.2-.303l-7.816-6.54zM164.327 60.683c-.012-.212-.322-.232-.361-.02l-1.76 10.027a1.65 1.65 0 00.135.993s.948 2.326 1.019 2.41l.626.232.67-1.231a2.51 2.51 0 00.303-1.366l-.632-11.045z"
        />
        <path
          fill="#51B89F"
          d="M163.566 73.332c-.413 0-.748.452-.748 1.012s.335 1.012.748 1.012.748-.451.748-1.012c0-.56-.335-1.012-.748-1.012z"
        />
        <path
          fill="#51B89F"
          d="M163.566 73.957c-.155 0-.284.174-.284.387 0 .213.129.387.284.387.155 0 .284-.174.284-.387 0-.213-.129-.387-.284-.387z"
        />
        <path
          fill="#51B89F"
          d="M163.322 73.552h-.194c-.251 0-1.335.38-1.328.792.006.38 1.109.793 1.328.793h.194c.29 0 .528-.355.528-.793 0-.438-.238-.792-.528-.792zM114.188 106.065c0 9.986-7.188 18.078-7.188 18.078s-7.188-8.092-7.188-18.078c0-9.987 7.188-18.078 7.188-18.078s7.188 8.091 7.188 18.078z"
        />
        <path fill="#006169" d="M106.536 103.86h.929v30.347h-.929V103.86z" />
        <path
          fill="#51B89F"
          d="M100.818 87.085c0 9.986-7.188 18.078-7.188 18.078s-7.188-8.092-7.188-18.078c0-9.987 7.188-18.078 7.188-18.078s7.188 8.091 7.188 18.078z"
        />
        <path fill="#006169" d="M93.165 84.88h.93v30.347h-.93V84.88z" />
        <path
          fill="#51B89F"
          d="M91.213 92.393c0 9.987-7.188 18.078-7.188 18.078s-7.188-8.091-7.188-18.078 7.188-18.078 7.188-18.078 7.188 8.091 7.188 18.078z"
        />
        <path fill="#006169" d="M83.56 90.188h.93v30.347h-.93V90.188z" />
        <path
          fill="#51B89F"
          d="M78.407 96.64c0 9.986-7.188 18.077-7.188 18.077s-7.188-8.091-7.188-18.078c0-9.986 7.188-18.077 7.188-18.077s7.188 8.09 7.188 18.078z"
        />
        <path fill="#006169" d="M70.754 94.435h.93v30.346h-.93V94.435z" />
        <path
          fill="#51B89F"
          d="M64.534 108.318c0 9.986-7.188 18.077-7.188 18.077s-7.188-8.091-7.188-18.077c0-9.987 7.188-18.078 7.188-18.078s7.188 8.09 7.188 18.078z"
        />
        <path fill="#006169" d="M56.88 106.113h.93v30.346h-.93v-30.346z" />
        <path
          fill="#51B89F"
          d="M51.727 109.379c0 9.987-7.188 18.078-7.188 18.078s-7.188-8.091-7.188-18.078c0-9.986 7.188-18.078 7.188-18.078s7.188 8.092 7.188 18.078z"
        />
        <path fill="#006169" d="M44.075 107.174h.929v30.347h-.93v-30.347z" />
        <path fill="#51B89F" d="M261.085 135.009V71.13h35.773v63.879" />
        <path
          fill="#006169"
          d="M260.393 133.63v-7.238h.692v7.238M260.393 101.921h.692v7.238h-.692v-7.238zM260.508 89.972h.693v7.238h-.693v-7.238zM260.393 78.024h.692v7.238h-.692v-7.238zM260.393 114.099h.692v7.238h-.692v-7.238z"
        />
        <path
          fill="#E9F9FF"
          d="M284.395 81.7h3.115v-5.974h-3.115V81.7zm3.346 0h3.116v-5.974h-3.116V81.7z"
        />
        <path
          fill="#BAE8E2"
          d="M287.857 75.955h2.769V81.7h-2.769v-5.745zM284.51 81.7v-5.745h2.77V81.7h-2.77z"
        />
        <path
          fill="#fff"
          d="M288.087 76.415l-.23-.23-.116-.23-.231-.23h1.501l.23.23 1.27 2.298.231.23v2.528l-.231-.23-2.424-4.366zM284.625 77.45l2.424 3.331.231.23.346.46.231.23h-1.847l-.231-.23-1.269-1.61-.231-.229V77.22l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M284.279 94.108h3.116v-5.974h-3.116v5.974zm3.347 0h3.115v-5.974h-3.115v5.974z"
        />
        <path
          fill="#BAE8E2"
          d="M287.741 88.249h2.77v5.744h-2.77V88.25zM284.279 93.878v-5.744h2.77v5.744h-2.77z"
        />
        <path
          fill="#fff"
          d="M287.972 88.708l-.231-.23-.115-.23-.231-.23h1.5l.231.23 1.269 2.298.231.23v2.528l-.231-.23-2.423-4.366zM284.395 89.742l2.423 3.332.231.23.346.46.231.23h-1.846l-.231-.23-1.27-1.61-.23-.229v-2.412l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M284.279 106.057h3.116v-5.975h-3.116v5.975zm3.347 0h3.115v-5.975h-3.115v5.975z"
        />
        <path
          fill="#BAE8E2"
          d="M287.741 100.197h2.77v5.745h-2.77v-5.745zM284.395 105.942v-5.745h2.769v5.745h-2.769z"
        />
        <path
          fill="#fff"
          d="M288.087 100.772l-.23-.23-.116-.23-.231-.23h1.501l.23.23 1.27 2.298.231.23v2.527l-.231-.229-2.424-4.366zM284.395 101.691l2.423 3.332.231.229.346.46.231.23h-1.846l-.231-.23-1.27-1.608-.23-.23v-2.413l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M284.049 118.005h3.115v-5.974h-3.115v5.974zm3.346 0h3.116v-5.974h-3.116v5.974z"
        />
        <path
          fill="#BAE8E2"
          d="M287.626 112.146h2.769v5.744h-2.769v-5.744zM284.279 117.89v-5.744h2.77v5.744h-2.77z"
        />
        <path
          fill="#fff"
          d="M287.857 112.72l-.231-.229-.116-.23-.23-.23h1.5l.23.23 1.27 2.298.231.229v2.528l-.231-.23-2.423-4.366zM284.279 113.754l2.424 3.332.23.23.347.46.23.229h-1.846l-.231-.229-1.269-1.609-.231-.23v-2.412l.346.229z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M284.279 130.184h3.116v-5.975h-3.116v5.975zm3.462 0h3.116v-5.975h-3.116v5.975z"
        />
        <path
          fill="#BAE8E2"
          d="M287.857 124.324h2.769v5.745h-2.769v-5.745zM284.51 130.069v-5.745h2.77v5.745h-2.77z"
        />
        <path
          fill="#fff"
          d="M288.087 124.899l-.23-.23-.116-.23-.231-.23h1.501l.23.23 1.27 2.298.231.23v2.527l-.231-.229-2.424-4.366zM284.51 125.933l2.424 3.331.23.23.346.46.231.23h-1.846l-.231-.23-1.269-1.609-.231-.229v-2.413l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M269.624 81.93h3.116v-5.975h-3.116v5.975zm3.231 0h3.116v-5.975h-3.116v5.975z"
        />
        <path
          fill="#BAE8E2"
          d="M273.086 76.07h2.77v5.745h-2.77V76.07zM269.624 81.815V76.07h2.77v5.745h-2.77z"
        />
        <path
          fill="#fff"
          d="M273.317 76.645l-.231-.23-.115-.23-.231-.23h1.5l.231.23 1.269 2.298.231.23v2.527l-.231-.23-2.423-4.365zM269.74 77.679l2.423 3.332.231.23.346.459.231.23h-1.847l-.23-.23-1.27-1.608-.23-.23v-2.413l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M269.394 94.223h3.115V88.25h-3.115v5.974zm3.346 0h3.116V88.25h-3.116v5.974z"
        />
        <path
          fill="#BAE8E2"
          d="M272.855 88.364h2.77v5.744h-2.77v-5.744zM269.509 94.108v-5.744h2.769v5.744h-2.769z"
        />
        <path
          fill="#fff"
          d="M273.086 88.938l-.231-.23-.115-.23-.231-.23h1.5l.231.23 1.269 2.298.231.23v2.528l-.231-.23-2.423-4.366zM269.624 89.972l2.424 3.332.23.23.347.46.23.229h-1.846l-.231-.23-1.269-1.608-.231-.23v-2.413l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M269.394 106.287h3.115v-5.975h-3.115v5.975zm3.346 0h3.116v-5.975h-3.116v5.975z"
        />
        <path
          fill="#BAE8E2"
          d="M272.971 100.312h2.769v5.745h-2.769v-5.745zM269.624 106.172v-5.745h2.77v5.745h-2.77z"
        />
        <path
          fill="#fff"
          d="M273.201 101.002l-.23-.23-.116-.23-.23-.23h1.5l.23.23 1.27 2.298.231.23v2.527l-.231-.23-2.424-4.365zM269.624 101.921l2.424 3.332.23.229.347.46.23.23h-1.846l-.231-.23-1.269-1.609-.231-.229v-2.413l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M269.278 118.12h3.116v-5.974h-3.116v5.974zm3.347 0h3.115v-5.974h-3.115v5.974z"
        />
        <path
          fill="#BAE8E2"
          d="M272.74 112.376h2.769v5.744h-2.769v-5.744zM269.394 118.005v-5.744h2.769v5.744h-2.769z"
        />
        <path
          fill="#fff"
          d="M273.086 112.835l-.231-.23-.115-.229-.231-.23h1.5l.231.23 1.27 2.297.23.23v2.528l-.23-.23-2.424-4.366zM269.394 113.869l2.423 3.332.231.23.346.459.231.23h-1.847l-.23-.23-1.27-1.608-.231-.23v-2.413l.347.23z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M269.509 130.414h3.115v-5.975h-3.115v5.975zm3.346 0h3.116v-5.975h-3.116v5.975z"
        />
        <path
          fill="#BAE8E2"
          d="M273.086 124.554h2.77v5.745h-2.77v-5.745zM269.624 130.184v-5.745h2.77v5.745h-2.77z"
        />
        <path
          fill="#fff"
          d="M273.317 125.014l-.231-.23-.115-.23-.231-.23h1.5l.231.23 1.269 2.298.231.23v2.527l-.231-.23-2.423-4.365zM269.624 126.048l2.424 3.331.23.23.347.46.23.23h-1.846l-.231-.23-1.269-1.609-.231-.229v-2.413l.346.23z"
          opacity={0.63}
        />
        <path
          fill="#51B89F"
          d="M139.57 131.788c34.685 7.409 25.346 13.575 56.737 17.097 29.139 3.269 42.018-18.035 76.829-10.894 39.737 8.146 41.16-35.914 78.371-30.747 34.215 4.751 39.186-16.106 70.137-7.64 13.295 3.634 40.922 48.283 51.586 54.821v24.126c-131.061.507-368.307 1.02-499.376 1.527 0 0 80.039-43.126 106.185-48.32 26.146-5.194 42.472-3.611 59.531.03z"
        />
        <path
          fill="#005267"
          d="M340.653 124.186c41.196-14.821 72.121-7.928 72.121-7.928v41.935H103.517s57.813-28.492 102.009-28.492c62.659 0 78.353 14.935 135.127-5.515z"
        />
        <path
          fill="#51B89F"
          fillRule="evenodd"
          d="M396.359 48.492c2.176 0 3.95 5.92 3.95 10.448 0 4.534-1.767 5.979-3.95 5.979-2.176 0-3.95-1.445-3.95-5.98 0-4.534 1.766-10.447 3.95-10.447zM380.372 74.398c2.4 0 4.353 6.53 4.353 11.535 0 5.004-1.953 6.597-4.353 6.597-2.399 0-4.352-1.593-4.352-6.597 0-5.004 1.953-11.535 4.352-11.535zM396.009 75.377c2.399 0 4.352 6.53 4.352 11.534 0 5.004-1.953 6.598-4.352 6.598-2.4 0-4.353-1.594-4.353-6.598s1.945-11.534 4.353-11.534z"
          clipRule="evenodd"
        />
        <path
          fill="#007F88"
          fillRule="evenodd"
          d="M233.496 137.516c-.116.46-.577.46-.924.345-.346-.115-.692-.345-.461-.804.231-.46.692-.919 1.038-.805.462.115.577.805.347 1.264zM230.38 142.457c-.462 0-.692-.345-.692-.69 0-.459.115-.804.576-.804.462 0 1.154.23 1.154.689 0 .345-.577.69-1.038.805zM235.572 133.84c-.461 0-1.154-.345-1.154-.69 0-.344.577-.804 1.154-.804"
          clipRule="evenodd"
        />
        <path
          fill="#007F88"
          fillRule="evenodd"
          d="M232.457 141.537c-.115.46-.577.46-.923.345-.346-.115-.693-.345-.462-.804.116-.46.693-.919 1.039-.804.346.114.461.804.346 1.263z"
          clipRule="evenodd"
        />
        <path
          fill="#00B23C"
          d="M244.573 118.904h-9.116v37.454h9.116v-37.454z"
        />
        <path
          fill="#007F88"
          d="M248.381 118.904h-3.808v37.454h3.808v-37.454z"
        />
        <path
          fill="#E9F9FF"
          d="M241.688 126.142h-3.231c-.808 0-1.384-.689-1.384-1.608 0-.919.576-1.609 1.384-1.609h3.231c.808 0 1.385.69 1.385 1.609s-.692 1.608-1.385 1.608z"
        />
        <path
          fill="#00B23C"
          d="M228.418 141.882l-2.193 1.723.693 2.528 3-2.413-1.5-1.838z"
        />
        <path
          fill="#007F88"
          d="M179.029 139.584h-7.039a2.31 2.31 0 01-2.308-2.298 2.31 2.31 0 012.308-2.297h4.731a2.31 2.31 0 012.308 2.297v2.298zM219.417 139.584h7.039a2.31 2.31 0 002.308-2.298 2.31 2.31 0 00-2.308-2.297h-4.731a2.31 2.31 0 00-2.308 2.297v2.298z"
        />
        <path
          fill="#BAE8E2"
          d="M179.491 126.717l-4.616 17.578h48.697l-4.616-17.578c-.693-2.643-3.116-4.481-5.885-4.481h-27.695c-2.77 0-5.193 1.838-5.885 4.481z"
        />
        <path
          fill="#9CDD6E"
          d="M228.072 155.554h-57.813v-6.434c0-5.17 4.27-9.421 9.462-9.421h38.888c5.193 0 9.463 4.251 9.463 9.421v6.434zM212.955 122.236h-27.579c-2.77 0-5.193 1.838-5.885 4.481h39.349c-.692-2.643-3.115-4.481-5.885-4.481z"
        />
        <path
          fill="#51B89F"
          d="M179.721 139.584l3.116-2.068c.231-.115.577-.23.808-.23h6.347c.461 0 .807.115 1.154.46l.577.689c.346.345.692.46 1.153.46h24.464c.462 0 .923.23 1.154.574l.115.23-38.888-.115z"
        />
        <path
          fill="#007F88"
          fillRule="evenodd"
          d="M228.649 142.112c.346-.345 1.038-.575 1.269-.345.346.23.116.919-.231 1.379"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          d="M243.535 134.184l-2.885.69 1.385-5.745c.23-.459-.577-.689-.577-.459l-1.385 2.068-4.501 7.353c-.23.459 0 .689.462.689l2.654-.689-1.385 5.744c0 .46.462.69.808.46l2.193-3.217 3.808-6.204c0-.23-.231-.69-.577-.69z"
        />
        <path
          fill="#99CCCF"
          fillRule="evenodd"
          d="M232.803 140.274c-.115.459-.577.459-.923.344-.346-.115-.693-.344-.462-.804.231-.46.693-.919 1.039-.804.346.115.461.804.346 1.264z"
          clipRule="evenodd"
        />
        <path
          fill="#99CCCF"
          fillRule="evenodd"
          d="M233.149 138.895c-.115.459-.577.459-.923.345-.346-.115-.692-.345-.462-.805.116-.459.693-.919 1.039-.804.346.115.462.804.346 1.264zM233.842 136.138c-.116.459-.577.459-.924.344-.346-.115-.692-.344-.461-.804.115-.46.692-.919 1.038-.804.462 0 .577.804.347 1.264z"
          clipRule="evenodd"
        />
        <path
          fill="#99CCCF"
          fillRule="evenodd"
          d="M234.303 134.759c-.115.459-.577.459-.923.345-.346-.115-.692-.345-.462-.805.231-.459.693-.919 1.039-.804.462.115.462.804.346 1.264z"
          clipRule="evenodd"
        />
        <path
          fill="#99CCCF"
          fillRule="evenodd"
          d="M234.419 134.184c-.347.345-.693.23-1.039 0-.346-.229-.461-.574-.115-.919.346-.344 1.038-.689 1.269-.344.346.114.231.804-.115 1.263z"
          clipRule="evenodd"
        />
        <path
          fill="#E9F9FF"
          d="M181.568 154.175h-5.424c-1.269 0-2.654-.919-2.654-1.838s1.27-1.493 2.654-1.493h5.424c1.269 0 2.077.574 2.077 1.493 0 .919-.808 1.838-2.077 1.838zM223.687 154.175h-5.424c-1.269 0-2.654-.919-2.654-1.838s1.27-1.493 2.654-1.493h5.424c1.269 0 2.077.574 2.077 1.493 0 .919-.808 1.838-2.077 1.838z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h413v155H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgBgRight = React.memo(SvgBgRight);
export default MemoSvgBgRight;
