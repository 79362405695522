import * as React from 'react';
import { theme } from 'twin.macro';

function SvgMonitor(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 343 260"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M300.1 225.2c34.98-23.02 24.28-51.1 22.45-62.88C307.81 105.12 267.35 50.06 226 26 184.65 1.94 94.87-13.05 39 48.99-3.22 95.87 17.17 164.19 59.82 189c36.44 21.21 38.57 22.42 80.25 34.97 20.13 6.06 125.06 24.26 160.04 1.23z"
      />
      <path
        fill="#fff"
        d="M42 75.6c0-23.49 38.33-28.58 38.33-28.58 18.77-28.45 48.56-8.15 48.56-8.15s5.3-18.75 30.56-22.5c25.25-3.75 41.04 22.5 41.04 22.5C237.66 32.97 244 66.16 244 75.6c0 9.44-5.96 36.86-43.38 31.68 0 0-15.8 22.64-41.05 19.4-25.25-3.23-30.56-19.4-30.56-19.4s-29.78 17.6-48.55-7.11h-.06c-1.53.17-38.4-1.09-38.4-24.57z"
      />
      <rect width={40} height={40} x={130} y={58} fill="#D6D6D6" rx={3.94} />
      <rect
        width={38.8}
        height={38.8}
        x={124.6}
        y={52.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.4}
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        d="M138.51 58.05a.55.55 0 01.54-.45h12.66c.3 0 .55.25.55.55v2.3c0 .3-.25.55-.55.55H146.51V66.69h2.62a7.74 7.74 0 017.74 7.74v1.82c0 .3-.25.55-.55.55h-5.39a.54.54 0 01-.55-.55v-1.82c0-.38-.2-.67-.4-.85-.2-.18-.44-.3-.66-.37l-.1-.03h-14.18v2.51a3.45 3.45 0 01-3.38 3.39.16.16 0 01-.06-.06v-18.3l.03-.03a.13.13 0 01.03-.03 3.45 3.45 0 013.38 3.39v2.65H144.26V61.01H139.06a.55.55 0 01-.55-.55v-2.41zm14.24 23.19c.5-.74 1.03-1.38 1.28-1.66a12.23 12.23 0 012 2.94c.18.42.27.8.27 1.08 0 1.57-1.1 2.72-2.28 2.72-1.17 0-2.28-1.15-2.28-2.72 0-.28.1-.65.29-1.08.18-.42.44-.86.72-1.28z"
      />
      <rect width={40} height={40} x={72} y={49} fill="#D6D6D6" rx={3.94} />
      <rect
        width={40}
        height={40}
        x={66}
        y={43}
        fill={illustrationPrimaryColor}
        rx={4}
      />
      <path
        fill="#FCDB9D"
        d="M90.73 50L78 66.9l6.6-1.8-3.07 11.26L94.72 59.1l-6.99 1.06 3-10.17z"
      />
      <rect
        width={39.37}
        height={39.37}
        x={183}
        y={39}
        fill="#D6D6D6"
        rx={3.94}
      />
      <rect
        width={38.17}
        height={38.17}
        x={177.6}
        y={33.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.34}
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M188.85 40.87c-1.22 0-2.16.93-2.16 2.13V60.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V43c0-1.2-.94-2.13-2.16-2.13zm5.19 0c-1.22 0-2.16.93-2.16 2.13V60.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V43c0-1.2-.94-2.13-2.16-2.13zm5.18 0c-1.22 0-2.16.93-2.16 2.13V60.8c0 1.2.94 2.13 2.16 2.13 1.15 0 2.3-.9 2.16-2.16V43c0-1.2-.94-2.13-2.16-2.13zm5.3 0c-1.23 0-2.17.93-2.17 2.13V60.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V43c0-1.2-.94-2.13-2.16-2.13zm3.35 2.86c-.23 0-.44.2-.44.42v3.21c0 .23.2.43.44.43h.53c1.14 0 2.06-.9 2.06-2.03s-.92-2.03-2.06-2.03h-.53zm0 11.72c-.23 0-.44.2-.44.43v3.2c0 .23.2.43.44.43h.53c1.14 0 2.06-.9 2.06-2.03s-.92-2.03-2.06-2.03h-.53zm-24.53-11.72c-.23 0-.44.2-.44.42v3.21c0 .23.2.43.44.43h2.16c.23 0 .43-.2.43-.43v-3.2c0-.23-.2-.43-.43-.43h-2.16zm0 11.72c-.23 0-.44.2-.44.43v3.2c0 .23.2.43.44.43h2.16c.23 0 .43-.2.43-.42v-3.21c0-.23-.2-.43-.43-.43h-2.16z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M282.33 131.87H129.17c-1.23 0-2.23 1-2.23 2.23v91.25c0 1.24 1 2.23 2.23 2.23h153.16c1.23 0 2.23-1 2.23-2.23V134.1c0-1.23-1-2.23-2.23-2.23z"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M276.76 137.73H134.73c-1.23 0-2.23 1-2.23 2.23v79.55c0 1.23 1 2.22 2.23 2.22h142.03c1.24 0 2.23-1 2.23-2.22v-79.55c0-1.23-1-2.23-2.23-2.23zM287.95 237.7H121.94a5.06 5.06 0 010-10.12h166a5.06 5.06 0 010 10.11z"
      />
      <path
        fill="#F5F5F5"
        d="M227.29 170.17h-45.67c-.62 0-1.11.5-1.11 1.11v34.53c0 .62.5 1.12 1.11 1.12h45.67c.61 0 1.11-.5 1.11-1.12v-34.53c0-.61-.5-1.11-1.11-1.11z"
      />
      <path
        fill="#D6D6D6"
        d="M227.29 159.03h-45.67c-.62 0-1.11.5-1.11 1.12v4.45c0 .62.5 1.12 1.11 1.12h45.67c.61 0 1.11-.5 1.11-1.12v-4.45c0-.62-.5-1.12-1.11-1.12z"
      />
      <path
        fill="#F9B83B"
        d="M183 191h3v14h-3zM188 183h3v22h-3zM193 194h3v11h-3zM198 175h3v30h-3zM203 189h3v16h-3zM208 192h3v13h-3zM213 183h3v22h-3zM218 175h3v30h-3zM223 188h3v17h-3z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeDasharray="1 3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M169.5 195.5L185 188l9 3.5 13-10 11 8.5 16-15"
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M139.14 153.93H89.17c-1.23 0-2.23 1-2.23 2.23v93.81c0 1.23 1 2.23 2.23 2.23h49.97c1.23 0 2.23-1 2.23-2.23v-93.81c0-1.23-1-2.23-2.23-2.23z"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M136.23 159.35H92.07c-1.23 0-2.23 1-2.23 2.23v77.7c0 1.24 1 2.23 2.23 2.23h44.16c1.23 0 2.23-1 2.23-2.22v-77.71c0-1.23-1-2.23-2.23-2.23zM114.15 250.37a3.34 3.34 0 100-6.67 3.34 3.34 0 000 6.67z"
      />
      <path
        fill="#F5F5F5"
        d="M127.04 190.93h-26.73c-.62 0-1.11.5-1.11 1.11v24.5c0 .62.5 1.12 1.11 1.12h26.73c.62 0 1.12-.5 1.12-1.12v-24.5c0-.61-.5-1.11-1.12-1.11z"
      />
      <rect width={2} height={14} x={100.44} y={202} fill="#F9B83B" rx={1} />
      <rect width={2} height={22} x={104.44} y={194} fill="#F9B83B" rx={1} />
      <rect width={2} height={11} x={108.44} y={205} fill="#F9B83B" rx={1} />
      <rect width={2} height={7} x={112.44} y={209} fill="#F9B83B" rx={1} />
      <rect width={2} height={16} x={116.44} y={200} fill="#F9B83B" rx={1} />
      <rect width={2} height={13} x={120.44} y={203} fill="#F9B83B" rx={1} />
      <rect width={2} height={22} x={124.44} y={194} fill="#F9B83B" rx={1} />
      <path
        fill="#D6D6D6"
        d="M127.04 180.9h-26.73c-.62 0-1.11.5-1.11 1.12v3.34c0 .61.5 1.11 1.11 1.11h26.73c.62 0 1.12-.5 1.12-1.11v-3.34c0-.62-.5-1.12-1.12-1.12z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeDasharray="1 3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M93.5 193.5l10 5 13.5-5 17.5 10.5"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M296.5 204.5c18.8 0 34-15.2 34-34s-15.2-34-34-34-34 15.2-34 34 15.3 34 34 34z"
      />
      <path
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M288.6 181.8c7.7 0 14-6.3 14-14s-6.3-14-14-14-14 6.3-14 14 6.3 14 14 14z"
      />
      <path
        fill="#F9B83B"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M284.4 182.4c0 2.9 6.2 5.3 14 5.3 7.7 0 14-2.4 14-5.3v-3.9c0-2.9-6.2-5.3-14-5.3-7.7 0-14 2.4-14 5.3v3.9z"
      />
      <path
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M298.3 183.1c7.7 0 14-2.4 14-5.3s-6.3-5.3-14-5.3-14 2.4-14 5.3 6.3 5.3 14 5.3z"
      />
      <path
        fill="#F9B83B"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M289.8 174.2c0 2.9 6.2 5.3 14 5.3 7.7 0 14-2.4 14-5.3v-3.9c0-2.9-6.2-5.3-14-5.3-7.7 0-14 2.4-14 5.3v3.9z"
      />
      <path
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M303.8 174.9c7.7 0 14-2.4 14-5.3s-6.3-5.3-14-5.3-14 2.4-14 5.3 6.3 5.3 14 5.3z"
      />
    </svg>
  );
}

const MemoSvgMonitor = React.memo(SvgMonitor);
export default MemoSvgMonitor;
