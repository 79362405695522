import tw, { styled, css } from 'twin.macro';

export type ContainerProps = {
  spacingY?: '6' | '8';
  variant?: '704' | 'auto';
};

const Container = styled.section<ContainerProps>(({ spacingY, variant }) => [
  tw`w-full mx-auto space-y-4`,

  spacingY === '6' && [tw`space-y-6`],
  spacingY === '8' && [tw`space-y-8`],

  variant === '704' &&
    css`
      max-width: 704px;
    `,
]);

export default Container;
