import * as React from 'react';

function SvgEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2914 6.17328C20.4761 5.00872 18.317 4.33337 16.0002 4.33337C9.55684 4.33337 4.3335 9.55672 4.3335 16C4.3335 18.3169 5.00884 20.476 6.17341 22.2913L7.6284 20.8363C6.80487 19.4138 6.3335 17.762 6.3335 16C6.3335 10.6613 10.6614 6.33337 16.0002 6.33337C17.7621 6.33337 19.4139 6.80475 20.8364 7.62828L22.2914 6.17328ZM25.8269 9.70882L24.3719 11.1638C25.1954 12.5863 25.6668 14.2381 25.6668 16C25.6668 21.3388 21.3389 25.6667 16.0002 25.6667C14.2382 25.6667 12.5864 25.1953 11.1639 24.3718L9.70894 25.8268C11.5242 26.9914 13.6833 27.6667 16.0002 27.6667C22.4435 27.6667 27.6668 22.4434 27.6668 16C27.6668 13.6832 26.9915 11.5241 25.8269 9.70882Z"
        fill="#ADADAD"
      />
      <path
        d="M26.6668 5.33337L5.3335 26.6667"
        stroke="#ADADAD"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoSvgEmpty = React.memo(SvgEmpty);
export default MemoSvgEmpty;
