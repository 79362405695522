import tw, { styled, css } from 'twin.macro';

interface IPageComposition {
  Header: React.FC;
  Container: React.FC;
  Menu: React.FC<menuProps>;
  Main: React.FC<menuProps>;
  Wrapper: React.FC;
}

type menuProps = {
  active?: boolean;
};

const Page: IPageComposition & React.FC = ({ children }) => {
  return <>{children}</>;
};

const Header = styled.header(() => [
  css`
    height: var(--height-header);
  `,

  tw`xl:hidden fixed top-0 z-50 flex items-center w-full px-4 bg-secondary-500`,
]);

const Container = styled.section(() => [
  css`
    margin-top: var(--height-header);
  `,

  tw`flex xl:mt-0`,
]);

const Main = styled.main<menuProps>(({ active }) => [
  css`
    position: relative;
    height: calc(100% - var(--height-header));

    @media (min-width: 1280px) {
      width: calc(100vw - var(--width-menu));
    }
  `,

  active &&
    css`
      width: calc(100vw - var(--width-menu));
    `,

  tw`overflow-y-auto w-full px-4 xl:px-16 py-6 xl:py-8 xl:h-screen`,
]);

const Menu = styled.aside<menuProps>(({ active }) => [
  css`
    position: relative;
    height: calc(100vh - var(--height-header));
    max-width: var(--width-menu);

    @media (min-width: 1280px) {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
      width: var(--width-menu);
    }
  `,

  tw`transition overflow-y-auto hidden xl:block xl:h-full`,

  active && tw`z-60 block`,
]);

const Wrapper = styled.section(() => [
  css`
    max-width: 1100px;
  `,
  tw`m-auto mb-40 xl:mb-0`,
]);

Page.Header = Header;
Page.Container = Container;
Page.Main = Main;
Page.Menu = Menu;
Page.Wrapper = Wrapper;

export default Page;
