import tw, { styled } from 'twin.macro';

import { Box, Icon } from '../ui'

const Help: React.FC = ({ children }) => (
  <Container>
    {children}

    <Box className="absolute top-5 right-4 flex items-center justify-center w-6 h-6 border border-gray-200 rounded-full">
      <Icon name="help" textColor="text-gray-500" />
    </Box>
  </Container>
);

const Container = styled.ul(() => [
  tw`relative px-4 py-6 pr-14 cursor-pointer`,
]);

export default Help;
