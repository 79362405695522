import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgAppStore(props: React.SVGProps<SVGSVGElement>) {
  const id = uuidv4();

  return (
    <svg
      width={200}
      height={60}
      viewBox="0 0 200 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M12.8425 59.2854C12.3257 59.2854 11.8215 59.2794 11.3088 59.2689C10.2466 59.2562 9.18699 59.1717 8.13852 59.0161C7.16087 58.8622 6.2138 58.576 5.32853 58.1668C4.45138 57.7611 3.65135 57.2291 2.95909 56.5912C2.25681 55.9608 1.67212 55.2296 1.22821 54.4267C0.779404 53.6185 0.468805 52.7523 0.30723 51.8581C0.132744 50.8973 0.0383392 49.9258 0.0248309 48.9519C0.0140776 48.6251 0 47.5367 0 47.5367V11.7321C0 11.7321 0.0149935 10.6604 0.0249157 10.3456C0.0378498 9.37327 0.131705 8.40327 0.30567 7.44397C0.467543 6.54736 0.778383 5.67861 1.22743 4.86781C1.66971 4.06542 2.25115 3.33372 2.9492 2.70106C3.64647 2.06239 4.44906 1.52763 5.3277 1.11629C6.21093 0.70847 7.15622 0.424271 8.13188 0.273209C9.1838 0.115992 10.2471 0.0309929 11.3129 0.0189249L12.8433 0H187.148L188.697 0.0196843C189.753 0.0311527 190.807 0.115393 191.849 0.27169C192.835 0.424647 193.79 0.710829 194.683 1.12083C196.443 1.94981 197.876 3.26134 198.779 4.87159C199.221 5.6768 199.527 6.53846 199.687 7.42733C199.863 8.39449 199.962 9.37212 199.982 10.3524C199.987 10.7913 199.987 11.2628 199.987 11.7321C200 12.3133 200 12.8665 200 13.4243V45.8626C200 46.4257 200 46.9751 199.987 47.5291C199.987 48.0331 199.987 48.4949 199.98 48.9701C199.96 49.9329 199.863 50.893 199.69 51.8429C199.532 52.7435 199.223 53.6165 198.774 54.4313C198.327 55.2255 197.746 55.9504 197.052 56.5791C196.359 57.2204 195.557 57.7556 194.678 58.1639C193.787 58.5762 192.834 58.8634 191.849 59.0161C190.801 59.1725 189.741 59.257 188.679 59.2689C188.182 59.2794 187.662 59.2854 187.157 59.2854L185.318 59.2885L12.8425 59.2854Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.4118 12.9253C34.5557 14.7991 33.9614 16.6551 32.7552 18.0991C32.1776 18.8155 31.4445 19.3919 30.6112 19.7848C29.7778 20.1777 28.8659 20.3769 27.9442 20.3675C27.8854 19.4663 28.0052 18.5625 28.2969 17.7076C28.5886 16.8527 29.0464 16.0635 29.6441 15.3852C30.8667 13.9966 32.569 13.1183 34.4118 12.9253ZM37.7736 26.5636C37.1612 27.6397 36.8316 28.8527 36.8156 30.0898C36.8171 31.4814 37.2294 32.8418 38.001 34.0013C38.7726 35.1609 39.8694 36.0682 41.1547 36.6103C40.6494 38.2473 39.8877 39.7944 38.8978 41.1941C37.5684 43.1771 36.1745 45.1145 33.9623 45.1503C32.9101 45.1745 32.1999 44.8728 31.4598 44.5585C30.6879 44.2305 29.8835 43.8888 28.6247 43.8888C27.2897 43.8888 26.4493 44.2415 25.6387 44.5817C24.9383 44.8757 24.2601 45.1603 23.3044 45.1998C21.1975 45.2776 19.5873 43.0833 18.2096 41.1188C15.4559 37.1071 13.3117 29.813 16.1864 24.8499C16.85 23.6608 17.8112 22.6633 18.9763 21.9547C20.1415 21.2461 21.4708 20.8506 22.8348 20.8066C24.0297 20.7821 25.1763 21.2414 26.1816 21.6441C26.9504 21.952 27.6365 22.2268 28.1984 22.2268C28.6923 22.2268 29.3593 21.9628 30.1366 21.6552C31.361 21.1706 32.8593 20.5776 34.386 20.7374C35.5561 20.774 36.7015 21.082 37.7313 21.6372C38.7611 22.1924 39.6469 22.9793 40.3184 23.9356C39.2614 24.5836 38.386 25.4876 37.7736 26.5636Z"
          fill="white"
        />
        <path
          d="M62.8771 40.2268H55.8415L54.1519 45.2017H51.1719L57.8359 26.7956H60.932L67.596 45.2017H64.5652L62.8771 40.2268ZM56.5701 37.9311H62.147L59.3978 29.8571H59.3209L56.5701 37.9311Z"
          fill="white"
        />
        <path
          d="M81.9878 38.4928C81.9878 42.663 79.7496 45.3423 76.3718 45.3423C75.5162 45.3869 74.6653 45.1903 73.9164 44.7751C73.1676 44.3599 72.5512 43.7428 72.1377 42.9944H72.0738V49.6412H69.3115V31.7823H71.9853V34.0143H72.0361C72.4686 33.2696 73.0954 32.6556 73.8499 32.2379C74.6045 31.8201 75.4585 31.6142 76.321 31.6419C79.7364 31.6419 81.9878 34.3342 81.9878 38.4928ZM79.1486 38.4928C79.1486 35.7759 77.7406 33.9897 75.5924 33.9897C73.4818 33.9897 72.0622 35.8135 72.0622 38.4928C72.0622 41.1967 73.4818 43.0075 75.5924 43.0075C77.7406 43.0075 79.1486 41.2343 79.1486 38.4928Z"
          fill="white"
        />
        <path
          d="M96.7999 38.4928C96.7999 42.663 94.5616 45.3422 91.1839 45.3422C90.3282 45.3869 89.4773 45.1903 88.7284 44.7751C87.9796 44.3599 87.3632 43.7428 86.9497 42.9944H86.8858V49.6412H84.1235V31.7823H86.7973V34.0143H86.8481C87.2805 33.2696 87.9074 32.6556 88.6619 32.2379C89.4164 31.8201 90.2704 31.6142 91.133 31.6419C94.5485 31.6419 96.7999 34.3342 96.7999 38.4928ZM93.9606 38.4928C93.9606 35.7759 92.5526 33.9897 90.4044 33.9897C88.2938 33.9897 86.8742 35.8135 86.8742 38.4928C86.8742 41.1967 88.2938 43.0075 90.4044 43.0075C92.5527 43.0075 93.9607 41.2343 93.9607 38.4928H93.9606Z"
          fill="white"
        />
        <path
          d="M106.588 40.0734C106.793 41.8987 108.571 43.0971 111.001 43.0971C113.329 43.0971 115.004 41.8986 115.004 40.2528C115.004 38.8242 113.994 37.9687 111.602 37.3825L109.21 36.8078C105.821 35.9914 104.247 34.4108 104.247 31.8459C104.247 28.6701 107.022 26.4888 110.963 26.4888C114.864 26.4888 117.537 28.6701 117.627 31.8459H114.839C114.672 30.009 113.149 28.9003 110.924 28.9003C108.699 28.9003 107.176 30.0221 107.176 31.6548C107.176 32.9561 108.149 33.7218 110.528 34.308L112.561 34.8059C116.348 35.699 117.922 37.216 117.922 39.9083C117.922 43.3518 115.171 45.5085 110.796 45.5085C106.703 45.5085 103.939 43.4025 103.761 40.0733L106.588 40.0734Z"
          fill="white"
        />
        <path
          d="M123.884 28.6064V31.7822H126.443V33.9635H123.884V41.3616C123.884 42.5108 124.396 43.0464 125.521 43.0464C125.825 43.0411 126.128 43.0199 126.43 42.9827V45.151C125.924 45.2452 125.41 45.2879 124.896 45.2784C122.171 45.2784 121.109 44.2579 121.109 41.6553V33.9635H119.152V31.7822H121.108V28.6064H123.884Z"
          fill="white"
        />
        <path
          d="M127.925 38.4927C127.925 34.2704 130.419 31.6172 134.307 31.6172C138.209 31.6172 140.691 34.2703 140.691 38.4927C140.691 42.7265 138.222 45.3682 134.307 45.3682C130.394 45.3682 127.925 42.7265 127.925 38.4927ZM137.877 38.4927C137.877 35.5963 136.545 33.8868 134.307 33.8868C132.069 33.8868 130.739 35.6093 130.739 38.4927C130.739 41.4006 132.069 43.0971 134.307 43.0971C136.545 43.0971 137.876 41.4006 137.876 38.4927H137.877Z"
          fill="white"
        />
        <path
          d="M142.969 31.7822H145.603V34.0663H145.667C145.845 33.3529 146.265 32.7225 146.854 32.281C147.444 31.8394 148.167 31.6137 148.904 31.6418C149.222 31.6407 149.54 31.6751 149.851 31.7445V34.321C149.449 34.1986 149.029 34.1424 148.609 34.1546C148.208 34.1384 147.808 34.2089 147.437 34.3614C147.065 34.5139 146.731 34.7447 146.458 35.0381C146.185 35.3314 145.978 35.6802 145.853 36.0607C145.727 36.4411 145.686 36.8441 145.731 37.242V45.2017H142.969L142.969 31.7822Z"
          fill="white"
        />
        <path
          d="M162.586 41.2602C162.214 43.6963 159.835 45.3682 156.791 45.3682C152.877 45.3682 150.447 42.7526 150.447 38.5563C150.447 34.3471 152.89 31.6172 156.675 31.6172C160.399 31.6172 162.74 34.1676 162.74 38.2365V39.1802H153.235V39.3467C153.191 39.8405 153.253 40.3381 153.417 40.8062C153.581 41.2744 153.843 41.7024 154.185 42.0619C154.527 42.4214 154.943 42.7042 155.403 42.8914C155.863 43.0786 156.358 43.166 156.855 43.1477C157.508 43.2087 158.163 43.058 158.723 42.7179C159.283 42.3778 159.718 41.8666 159.963 41.2602L162.586 41.2602ZM153.248 37.2551H159.976C160.001 36.811 159.933 36.3666 159.778 35.9498C159.622 35.533 159.382 35.1527 159.072 34.8329C158.762 34.5131 158.389 34.2607 157.977 34.0914C157.564 33.9221 157.121 33.8396 156.675 33.8492C156.226 33.8465 155.78 33.9327 155.363 34.1027C154.947 34.2728 154.569 34.5234 154.25 34.8401C153.932 35.1567 153.679 35.5331 153.507 35.9476C153.335 36.3622 153.247 36.8065 153.248 37.2551V37.2551Z"
          fill="white"
        />
        <path
          d="M56.2236 12.9412C56.8027 12.8997 57.3838 12.987 57.925 13.1966C58.4662 13.4063 58.954 13.7331 59.3532 14.1534C59.7524 14.5738 60.0531 15.0773 60.2336 15.6277C60.414 16.178 60.4697 16.7613 60.3967 17.3357C60.3967 20.1612 58.8654 21.7853 56.2236 21.7853H53.02V12.9412H56.2236ZM54.3975 20.5346H56.0697C56.4835 20.5592 56.8976 20.4916 57.2819 20.3365C57.6662 20.1815 58.011 19.9429 58.2911 19.6382C58.5713 19.3335 58.7798 18.9704 58.9015 18.5752C59.0232 18.18 59.055 17.7627 58.9945 17.3537C59.0506 16.9464 59.0156 16.5317 58.892 16.1394C58.7685 15.7471 58.5595 15.3869 58.2799 15.0847C58.0004 14.7824 57.6573 14.5455 57.2752 14.391C56.893 14.2365 56.4814 14.1682 56.0697 14.191H54.3975V20.5346Z"
          fill="white"
        />
        <path
          d="M61.9529 18.4452C61.9108 18.0066 61.9612 17.5641 62.1006 17.146C62.2401 16.7279 62.4657 16.3435 62.7629 16.0174C63.0601 15.6914 63.4223 15.4308 63.8264 15.2526C64.2305 15.0743 64.6674 14.9822 65.1093 14.9822C65.5511 14.9822 65.9881 15.0743 66.3922 15.2526C66.7963 15.4308 67.1585 15.6914 67.4557 16.0174C67.7529 16.3435 67.9785 16.7279 68.1179 17.146C68.2574 17.5641 68.3077 18.0066 68.2657 18.4452C68.3085 18.8843 68.2588 19.3274 68.1197 19.7462C67.9806 20.165 67.7552 20.5501 67.4579 20.8768C67.1606 21.2035 66.7981 21.4645 66.3936 21.6432C65.9892 21.8219 65.5517 21.9142 65.1093 21.9142C64.6669 21.9142 64.2294 21.8219 63.8249 21.6432C63.4205 21.4645 63.0579 21.2035 62.7607 20.8768C62.4634 20.5501 62.238 20.165 62.0989 19.7462C61.9598 19.3274 61.91 18.8843 61.9529 18.4452ZM66.907 18.4452C66.907 16.9985 66.2553 16.1524 65.1115 16.1524C63.9633 16.1524 63.3174 16.9985 63.3174 18.4452C63.3174 19.9036 63.9633 20.7431 65.1115 20.7431C66.2553 20.7431 66.907 19.8978 66.907 18.4452H66.907Z"
          fill="white"
        />
        <path
          d="M76.6569 21.7851H75.2867L73.9034 16.8695H73.7989L72.4213 21.7851H71.0641L69.2192 15.1108H70.559L71.758 20.2037H71.8567L73.2328 15.1108H74.4999L75.876 20.2037H75.9805L77.1737 15.1108H78.4946L76.6569 21.7851Z"
          fill="white"
        />
        <path
          d="M80.0464 15.1109H81.3179V16.1712H81.4166C81.5841 15.7904 81.8665 15.4712 82.2245 15.258C82.5826 15.0449 82.9983 14.9484 83.414 14.9821C83.7396 14.9577 84.0666 15.0066 84.3707 15.1254C84.6748 15.2441 84.9483 15.4295 85.1707 15.6679C85.3932 15.9063 85.5591 16.1916 85.656 16.5026C85.7529 16.8136 85.7784 17.1423 85.7306 17.4645V21.7852H84.4097V17.7953C84.4097 16.7227 83.9423 16.1893 82.9654 16.1893C82.7443 16.179 82.5236 16.2166 82.3184 16.2993C82.1132 16.3821 81.9284 16.5081 81.7766 16.6687C81.6248 16.8294 81.5096 17.0209 81.439 17.23C81.3683 17.4392 81.3439 17.6612 81.3673 17.8807V21.7852H80.0464L80.0464 15.1109Z"
          fill="white"
        />
        <path
          d="M87.8354 12.5054H89.1563V21.7852H87.8354V12.5054Z"
          fill="white"
        />
        <path
          d="M90.9929 18.4452C90.9509 18.0065 91.0012 17.564 91.1407 17.1458C91.2803 16.7277 91.5059 16.3433 91.8031 16.0173C92.1003 15.6912 92.4626 15.4307 92.8667 15.2524C93.2708 15.0741 93.7078 14.982 94.1497 14.982C94.5915 14.982 95.0285 15.0741 95.4326 15.2524C95.8367 15.4307 96.199 15.6912 96.4962 16.0173C96.7934 16.3433 97.019 16.7277 97.1586 17.1458C97.2981 17.564 97.3484 18.0065 97.3064 18.4452C97.3492 18.8842 97.2994 19.3274 97.1603 19.7462C97.0211 20.1649 96.7957 20.55 96.4984 20.8767C96.2011 21.2034 95.8385 21.4645 95.434 21.6431C95.0295 21.8218 94.592 21.9141 94.1497 21.9141C93.7073 21.9141 93.2698 21.8218 92.8653 21.6431C92.4608 21.4645 92.0982 21.2034 91.8009 20.8767C91.5036 20.55 91.2782 20.1649 91.139 19.7462C90.9999 19.3274 90.9501 18.8842 90.9929 18.4452ZM95.947 18.4452C95.947 16.9984 95.2953 16.1524 94.1515 16.1524C93.0033 16.1524 92.3574 16.9984 92.3574 18.4452C92.3574 19.9035 93.0033 20.743 94.1515 20.743C95.2953 20.743 95.947 19.8977 95.947 18.4452H95.947Z"
          fill="white"
        />
        <path
          d="M98.6963 19.8976C98.6963 18.6962 99.5933 18.0036 101.186 17.9052L102.999 17.801V17.2249C102.999 16.52 102.531 16.1219 101.628 16.1219C100.891 16.1219 100.38 16.3919 100.233 16.8637H98.9547C99.0897 15.7173 100.171 14.982 101.689 14.982C103.367 14.982 104.314 15.815 104.314 17.2249V21.7851H103.042V20.8472H102.938C102.726 21.1836 102.428 21.4579 102.074 21.642C101.721 21.8261 101.325 21.9135 100.927 21.8951C100.646 21.9243 100.362 21.8944 100.094 21.8074C99.825 21.7204 99.5776 21.5782 99.3674 21.39C99.1572 21.2018 98.9889 20.9718 98.8733 20.7147C98.7577 20.4577 98.6974 20.1793 98.6963 19.8976ZM102.999 19.3273V18.7693L101.364 18.8735C100.442 18.9351 100.024 19.2477 100.024 19.8361C100.024 20.4368 100.547 20.7864 101.266 20.7864C101.476 20.8076 101.689 20.7864 101.891 20.724C102.093 20.6617 102.281 20.5594 102.442 20.4232C102.604 20.2871 102.737 20.1199 102.832 19.9317C102.928 19.7434 102.984 19.5379 102.999 19.3273Z"
          fill="white"
        />
        <path
          d="M106.05 18.4451C106.05 16.3362 107.137 15.0001 108.828 15.0001C109.246 14.9809 109.661 15.0808 110.025 15.2882C110.388 15.4955 110.685 15.8018 110.881 16.1711H110.979V12.5054H112.3V21.7852H111.034V20.7307H110.93C110.719 21.0975 110.412 21.3999 110.042 21.6052C109.671 21.8104 109.252 21.9107 108.828 21.8951C107.125 21.8952 106.05 20.5592 106.05 18.4451ZM107.414 18.4451C107.414 19.8608 108.083 20.7126 109.203 20.7126C110.316 20.7126 111.004 19.8485 111.004 18.4509C111.004 17.0599 110.309 16.1835 109.203 16.1835C108.091 16.1835 107.414 17.0411 107.414 18.4451H107.414Z"
          fill="white"
        />
        <path
          d="M117.765 18.4452C117.723 18.0066 117.774 17.5641 117.913 17.146C118.053 16.7279 118.278 16.3435 118.575 16.0174C118.873 15.6914 119.235 15.4308 119.639 15.2526C120.043 15.0743 120.48 14.9822 120.922 14.9822C121.364 14.9822 121.801 15.0743 122.205 15.2526C122.609 15.4308 122.971 15.6914 123.268 16.0174C123.565 16.3435 123.791 16.7279 123.93 17.146C124.07 17.5641 124.12 18.0066 124.078 18.4452C124.121 18.8843 124.071 19.3274 123.932 19.7462C123.793 20.165 123.568 20.5501 123.27 20.8768C122.973 21.2035 122.611 21.4645 122.206 21.6432C121.802 21.8219 121.364 21.9142 120.922 21.9142C120.479 21.9142 120.042 21.8219 119.637 21.6432C119.233 21.4645 118.87 21.2035 118.573 20.8768C118.276 20.5501 118.05 20.165 117.911 19.7462C117.772 19.3274 117.723 18.8843 117.765 18.4452ZM122.72 18.4452C122.72 16.9985 122.068 16.1524 120.924 16.1524C119.776 16.1524 119.13 16.9985 119.13 18.4452C119.13 19.9036 119.776 20.7431 120.924 20.7431C122.068 20.7431 122.72 19.8978 122.72 18.4452Z"
          fill="white"
        />
        <path
          d="M125.851 15.1109H127.122V16.1712H127.221C127.388 15.7904 127.671 15.4712 128.029 15.258C128.387 15.0449 128.803 14.9484 129.218 14.9821C129.544 14.9577 129.871 15.0066 130.175 15.1254C130.479 15.2441 130.752 15.4295 130.975 15.6679C131.197 15.9063 131.363 16.1916 131.46 16.5026C131.557 16.8136 131.583 17.1423 131.535 17.4645V21.7852H130.214V17.7953C130.214 16.7227 129.747 16.1893 128.77 16.1893C128.549 16.179 128.328 16.2166 128.123 16.2993C127.917 16.3821 127.733 16.5081 127.581 16.6687C127.429 16.8294 127.314 17.0209 127.243 17.23C127.173 17.4392 127.148 17.6612 127.171 17.8807V21.7852H125.851V15.1109Z"
          fill="white"
        />
        <path
          d="M138.999 13.4492V15.1413H140.449V16.2507H138.999V19.6827C138.999 20.3818 139.288 20.688 139.945 20.688C140.113 20.6874 140.282 20.6773 140.449 20.6576V21.7548C140.212 21.7971 139.971 21.8196 139.73 21.8221C138.261 21.8221 137.676 21.3068 137.676 20.02V16.2507H136.614V15.1412H137.676V13.4492H138.999Z"
          fill="white"
        />
        <path
          d="M142.252 12.5054H143.562V16.1834H143.666C143.842 15.7991 144.132 15.478 144.497 15.2641C144.862 15.0501 145.285 14.9537 145.707 14.9878C146.031 14.9702 146.355 15.0242 146.656 15.1458C146.956 15.2674 147.226 15.4537 147.446 15.6913C147.667 15.929 147.831 16.2122 147.929 16.5207C148.027 16.8292 148.055 17.1554 148.012 17.476V21.7852H146.69V17.801C146.69 16.7349 146.192 16.195 145.259 16.195C145.032 16.1764 144.803 16.2076 144.59 16.2862C144.376 16.3648 144.182 16.4891 144.021 16.6503C143.861 16.8114 143.738 17.0056 143.66 17.2193C143.583 17.4329 143.553 17.6608 143.573 17.8871V21.7851H142.252L142.252 12.5054Z"
          fill="white"
        />
        <path
          d="M155.714 19.983C155.535 20.593 155.146 21.1206 154.616 21.4736C154.086 21.8266 153.448 21.9824 152.814 21.9139C152.373 21.9255 151.935 21.8413 151.53 21.6671C151.125 21.4929 150.763 21.2329 150.469 20.9051C150.174 20.5773 149.955 20.1896 149.826 19.769C149.698 19.3483 149.662 18.9047 149.722 18.4689C149.664 18.0318 149.7 17.5873 149.828 17.1653C149.957 16.7434 150.175 16.3539 150.467 16.0233C150.76 15.6926 151.121 15.4285 151.524 15.2489C151.928 15.0692 152.366 14.9782 152.808 14.982C154.671 14.982 155.794 16.2507 155.794 18.3466V18.8062H151.068V18.88C151.047 19.1249 151.078 19.3715 151.159 19.6038C151.239 19.8362 151.367 20.0492 151.535 20.2292C151.703 20.4092 151.907 20.5523 152.133 20.6492C152.36 20.7461 152.604 20.7948 152.85 20.7921C153.166 20.8299 153.487 20.7732 153.77 20.6291C154.054 20.485 154.288 20.2601 154.443 19.9829L155.714 19.983ZM151.068 17.832H154.449C154.465 17.608 154.435 17.383 154.359 17.1715C154.283 16.9599 154.164 16.7665 154.009 16.6037C153.854 16.4409 153.666 16.3122 153.458 16.226C153.25 16.1398 153.026 16.0979 152.801 16.103C152.573 16.1002 152.346 16.1429 152.134 16.2288C151.923 16.3147 151.73 16.442 151.569 16.6031C151.407 16.7642 151.28 16.956 151.194 17.1671C151.108 17.3781 151.065 17.6042 151.068 17.832H151.068Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width={200} height={59.2885} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgAppStore = React.memo(SvgAppStore);
export default MemoSvgAppStore;
