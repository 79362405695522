import * as React from 'react';
import { SVGProps } from 'react';

const SvgNeutral = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3.667C9.19 3.667 3.667 9.188 3.667 16c0 6.811 5.522 12.333 12.333 12.333 6.812 0 12.333-5.521 12.333-12.333S22.812 3.667 16 3.667ZM1.667 16C1.667 8.084 8.084 1.667 16 1.667S30.333 8.084 30.333 16 23.916 30.333 16 30.333 1.667 23.916 1.667 16Z"
      fill="#828282"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 12a1.5 1.5 0 0 1 1.5-1.5h.013a1.5 1.5 0 0 1 0 3H12a1.5 1.5 0 0 1-1.5-1.5ZM18.5 12a1.5 1.5 0 0 1 1.5-1.5h.013a1.5 1.5 0 0 1 0 3H20a1.5 1.5 0 0 1-1.5-1.5ZM22.333 21a1 1 0 0 1-1 1v-2a1 1 0 0 1 1 1Zm-11.666 0v-1h10.666v2H10.667v-1Zm0 0v-1a1 1 0 1 0 0 2v-1Z"
      fill="#828282"
    />
  </svg>
);

const MemoSvgNeutral = React.memo(SvgNeutral);
export default MemoSvgNeutral;
