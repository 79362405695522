import { createContext, ReactElement, useContext } from 'react';
import tw, { styled } from 'twin.macro';
import Icon from './Icon';

interface ICardSelectCompositon {
  Option: typeof Option;
}

export interface CardSelectProps {
  value?: any;
  children: Array<ReactElement<typeof Option>>;
  onValueChange: any;
}

const SelectContext = createContext<{
  onOptionChange: Function;
  selectedOption: any;
}>({
  onOptionChange: () => { },
  selectedOption: null,
});

const CardSelect: ICardSelectCompositon & React.FC<CardSelectProps> = ({
  children,
  onValueChange,
  value,
}) => {
  return (
    <SelectContext.Provider
      value={{ onOptionChange: onValueChange, selectedOption: value }}
    >
      <Container>{children}</Container>
    </SelectContext.Provider>
  );
};

const Option: React.FC<{ selected?: boolean; value: any }> = ({
  children,
  selected,
  value,
}) => {
  const { onOptionChange, selectedOption } = useContext(SelectContext);

  return (
    <Button
      selected={selected || value === selectedOption}
      onClick={() => onOptionChange(value)}
    >
      {children}

      {selected || (value === selectedOption && <Done />)}
    </Button>
  );
};

const Button = styled.div<{ selected: boolean }>(({ selected }) => [
  tw`relative`,
  tw`flex items-center space-x-2 h-16 px-8 py-10`,
  tw`rounded-sm border border-gray-200 font-bold text-base leading-none`,
  tw`transition focus:outline-none hover:border-gray-300 hover:border-gray-300 cursor-pointer`,

  selected && tw`border-action-400 hover:border-action-400`,
]);

const Container = styled.div(() => [tw`grid grid-cols-1 xl:grid-cols-2 gap-2`]);

const Done = () => {
  return (
    <DoneWrapper>
      <Icon name="check" size="4" className="text-white" />
    </DoneWrapper>
  );
};

const DoneWrapper = styled.div(() => [
  tw`absolute top-2 right-2 flex items-center justify-center`,
  tw`w-5 h-5 bg-action-400 rounded-full`,
]);

CardSelect.Option = Option;

export default CardSelect;
