import SvgDaily from '../assets/alarm-period/Daily';
import SvgMonthly from '../assets/alarm-period/Monthly';
import SvgWeekly from '../assets/alarm-period/Weekly';
import SvgYearly from '../assets/alarm-period/Yearly';
import SvgIncrease from '../assets/alarm-trigger/Increase';
import MemoSvgMax from '../assets/alarm-trigger/Max';
import MemoSvgMin from '../assets/alarm-trigger/Min';
import SvgTotal from '../assets/alarm-trigger/Total';
import MemoSvgZero from '../assets/alarm-trigger/Zero';
import MemoSvgArrow from '../assets/Arrow';
import SvgBack from '../assets/Back';
import SvgCarCharging from '../assets/CarCharging';
import SvgCheck from '../assets/Check';
import MemoSvgChevron from '../assets/Chevron';
import SvgChevronLeft from '../assets/ChevronLeft';
import SvgChevronRight from '../assets/ChevronRight';
import SvgClose from '../assets/Close';
import SvgColdWater from '../assets/ColdWater';
import MemoSvgCooling from '../assets/Cooling';
import SvgDownload from '../assets/Download';
import SvgDropdownActive from '../assets/dropdown/DropdownActive';
import SvgDropdownInactive from '../assets/dropdown/DropdownInactive';
import SvgEdit from '../assets/Edit';
import SvgElectricity from '../assets/Electricity';
import SvgEmail from '../assets/Email';
import SvgEnergy from '../assets/Energy';
import SvgDanish from '../assets/flags/Danish';
import SvgEnglish from '../assets/flags/English';
import SvgNorwegian from '../assets/flags/Norwegian';
import SvgEmpty from '../assets/granularity/Empty';
import SvgMedium from '../assets/granularity/Medium';
import SvgNeutral from '../assets/granularity/Neutral';
import SvgOk from '../assets/granularity/Ok';
import SvgSad from '../assets/granularity/Sad';
import SvgSmile from '../assets/granularity/Smile';
import SvgHeating from '../assets/Heating';
import SvgHelp from '../assets/Help';
import SvgHumidity from '../assets/Humidity';
import SvgBgLeft from '../assets/illustrations/rating/BgLeft';
import SvgBgRight from '../assets/illustrations/rating/BgRight';
import SVGApple from '../assets/login/Apple';
import SvgFacebook from '../assets/login/Facebook';
import SvgGoogle from '../assets/login/Google';
import SvgAlarm from '../assets/menu/Alarm';
import SvgAllocationStatement from '../assets/menu/AllocationStatement';
import SvgConsumption from '../assets/menu/Consumption';
import SvgHelpMenu from '../assets/menu/HelpMenu';
import SvgMenu from '../assets/menu/Menu';
import SvgMeterList from '../assets/menu/MeterList';
import SvgSettings from '../assets/menu/Settings';
import MemoSvgTenancyInfo from '../assets/menu/TenancyInfo';
import SvgUser from '../assets/menu/User';
import SvgNotificationsNone from '../assets/NotificationsNone';
import SvgPeriodDropdown from '../assets/PeriodDropdown';
import SvgTemperature from '../assets/Temperature';
import SvgTime from '../assets/Time';
import SvgWarmWater from '../assets/WarmWater';

export const IconMapping = {
  apple: SVGApple,
  google: SvgGoogle,
  facebook: SvgFacebook,

  alarm: SvgAlarm,
  allocationStatement: SvgAllocationStatement,
  arrow: MemoSvgArrow,
  back: SvgBack,
  carcharging: SvgCarCharging,
  check: SvgCheck,
  chevron: MemoSvgChevron,
  chevronLeft: SvgChevronLeft,
  chevronRight: SvgChevronRight,
  close: SvgClose,
  coldwater: SvgColdWater,
  consumption: SvgConsumption,
  cooling: MemoSvgCooling,
  daily: SvgDaily,
  download: SvgDownload,
  dropdownActive: SvgDropdownActive,
  dropdownInactive: SvgDropdownInactive,
  edit: SvgEdit,
  electricity: SvgElectricity,
  email: SvgEmail,
  empty: SvgEmpty,
  energy: SvgEnergy,
  flagDanish: SvgDanish,
  flagEnglish: SvgEnglish,
  flagNorwegian: SvgNorwegian,
  heating: SvgHeating, // Only here for compatibility
  help: SvgHelp,
  helpMenu: SvgHelpMenu,
  hotwater: SvgWarmWater,
  humidity: SvgHumidity,
  increase: SvgIncrease,
  max: MemoSvgMax,
  medium: SvgMedium,
  menu: SvgMenu,
  meterList: SvgMeterList,
  min: MemoSvgMin,
  monthly: SvgMonthly,
  neutral: SvgNeutral,
  notificationsNone: SvgNotificationsNone,
  ok: SvgOk,
  periodDropdown: SvgPeriodDropdown,
  radiator: SvgHeating,
  ratingBgLeft: SvgBgLeft,
  ratingBgRight: SvgBgRight,
  sad: SvgSad,
  settings: SvgSettings,
  smile: SvgSmile,
  temperature: SvgTemperature,
  tenancyInfo: MemoSvgTenancyInfo,
  time: SvgTime,
  total: SvgTotal,
  user: SvgUser,
  warmwater: SvgWarmWater, // Only here for compatibility
  weekly: SvgWeekly,
  yearly: SvgYearly,
  zero: MemoSvgZero,
};
