import { Link } from 'react-router-dom';
import paths from '../../lib/paths';

import { Badge, Box, Icon, Text } from '../../ui';
import { usePageHeaderData } from '../../hooks/data/usePageHeaderData';

const HeaderMain = () => {
  const { notificationCount, pageName, isRoot } = usePageHeaderData();

  return (
    <Box className="hidden xl:block">
      <Box
        className={`flex align-middle justify-between py-6 -mt-8 mb-6 border-gray-100 ${
          isRoot ? 'border-b-0' : 'border-b'
        }`}
      >
        <Text variant="h2">{pageName}</Text>
        <Link
          to={paths.notifications().route}
          className="flex relative xl:mr-1.5"
        >
          <Icon name="notificationsNone" textColor="text-white xl:text-black" />
          {notificationCount > 0 && <Badge>{notificationCount}</Badge>}
        </Link>
      </Box>
    </Box>
  );
};

export default HeaderMain;
