import * as React from 'react';

function SvgZero(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="url(#paint0_linear)"
        fillRule="evenodd"
        d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14zm2 0c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zm-20.353-4.803a1.4 1.4 0 00-2.294 0l-3.5 5a1.4 1.4 0 002.294 1.606L10.5 14.44l2.353 3.362A1.4 1.4 0 0014 18.4h8.415l-1.469 1.678a1.4 1.4 0 102.108 1.844l3.5-4a1.4 1.4 0 000-1.844l-3.5-4a1.4 1.4 0 10-2.108 1.844l1.469 1.678h-7.686l-3.082-4.403z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-4}
          x2={26.4}
          y1={0}
          y2={32}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgZero = React.memo(SvgZero);
export default MemoSvgZero;
