import tw, { styled } from 'twin.macro';

interface IDetailComposition {
  Item: React.FC;
  Col: React.FC;
  Footer: React.FC;
}

const Detail: IDetailComposition & React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div(() => [
  tw`py-1 px-4 rounded border border-gray-100`,
]);

const Item = styled.div(() => [
  tw`py-4 flex justify-between border-b border-gray-100 last:border-none`,
]);

const Col = styled.div(() => [tw`space-y-2`]);

const Footer = styled.div(() => [
  tw`py-4 flex items-center space-x-2 border-b border-gray-100 last:border-none`,
]);

Detail.Item = Item;
Detail.Col = Col;
Detail.Footer = Footer;

export default Detail;
