import { ButtonHTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';

export type BoxProps = {
  display?: 'inline' | 'block'
}

const Box = styled.div<BoxProps & ButtonHTMLAttributes<HTMLButtonElement>>(({ display }) => [
  display === 'block' && [tw`block`],
  display === 'inline' && [tw`inline`],
]);

export default Box;
