export const bp = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const mq = (n) => {
  return `@media (min-width: ${bp[n]}px)`;
};
