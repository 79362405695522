import { useReactiveVar } from '@apollo/client';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../contexts/AppContext';
import { SELECTED_TENANCY_IDENTIFIER } from '../../helpers/auth';
import useClickOutside from '../../hooks/useClickOutside';
import { selectedTenantId } from '../../lib/apolloClient';
import { Icon } from '../../ui';

export const TenancySelector = () => {
  const tenantId = useReactiveVar(selectedTenantId);
  const history = useHistory();
  const [showSelect, setShowSelect] = useState<boolean>(false);

  const { userTenancies } = useContext(AppContext);

  const setCurrentTenancyId = (tenantId: number) => {
    if (tenantId) {
      history.push('/');
      selectedTenantId(tenantId);
      localStorage.setItem(
        SELECTED_TENANCY_IDENTIFIER,
        JSON.stringify(tenantId)
      );
    }
  };

  const toggleSelect = () => {
    setShowSelect((curr) => !curr);
  };

  const refMenu = useRef();
  useClickOutside(refMenu, () => {
    if (showSelect) {
      setShowSelect(false);
    }
  });

  return (
    <div className="relative w-full">
      <button
        className="transition-colors w-full flex items-center justify-between h-12 p-3 text-white border border-secondary-100 rounded bg-transparent cursor-pointer focus:outline-none focus:ring-1 focus:border-white hover:bg-secondary-600"
        onClick={toggleSelect}
        aria-haspopup="listbox"
      >
        <span className="break-all">
          {userTenancies.find((t) => t.id === tenantId)?.apartment.address}
        </span>
        <Icon
          name="chevron"
          size="8"
          className={`text-white transition-transform transform ${
            !showSelect && 'rotate-180'
          }`}
        />
      </button>
      {showSelect && (
        <ul
          className="mt-1 absolute bg-white w-full border-secondary-100 rounded space-y-2 shadow-lg"
          ref={refMenu}
          tabIndex={-1}
          role="listbox"
        >
          {userTenancies.map((t, i) => {
            const active = t.id === tenantId;
            return (
              <li
                key={t.id}
                id={String(t.id)}
                className={`transition-colors break-all flex items-center justify-between p-4 text-gray-400 bg-transparent rounded cursor-pointer hover:bg-secondary-100 hover:text-white ${
                  active && 'font-bold'
                }`}
                onClick={() => {
                  setCurrentTenancyId(t.id);
                  setShowSelect(false);
                }}
                role="option"
                aria-selected={active}
              >
                {t.apartment.address}
                {active && <Icon name="check" className="text-green-600" />}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
