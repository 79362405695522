import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import LanguageSelect from './LanguageSelect';
import paths from '../lib/paths';
import { Box, Button, Illustration, Login as Layout, Text } from '../ui';
import LoginHeader from '../routes/auth/LoginHeader';
import { useState } from 'react';
import AddTenancyModal from './AddTenancyModal';
import DeleteUserModal from './profile/DeleteUserModal';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useAuth } from '../hooks/useAuth';
import { loader } from 'graphql.macro';

const DeleteUserMutation = loader('../graphql/mutations/deleteUser.graphql');

const NoTenancies = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [showAddNewTenancyModal, setShowAddNewtenancyModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);

  const handleUserAccountDelete = (doDelete: boolean) => {
    setShowDeleteAccountModal(false);
    if (doDelete) {
      deleteUser({
        optimisticResponse: false,
      });
    }
  };

  const [deleteUser, { loading: deletingUser }] = useMutation(
    DeleteUserMutation,
    {
      onCompleted: () => {
        const message = t('profile.account_deleted');
        toast.success(message);
        setUserDeleted(true);
      },
    }
  );

  if (userDeleted && !deletingUser) {
    logout();
    return <Redirect to={paths.login().route} />;
  }

  return (
    <>
      <Layout>
        <Layout.Main>
          <LoginHeader prevLink={paths.login().route} />

          <Layout.Form>
            <Box className="mb-3">
              <Text as="h1" variant="h1">
                {t('no_tenancies.no_linked_tenancies')}{' '}
              </Text>
            </Box>

            <Box className="mb-3">
              <Text>{t('no_tenancies.you_do_not_have_any_tenancies')}</Text>
            </Box>

            <Box className="mb-3">
              <Text>{t('no_tenancies.if_you_have_invitation')}</Text>
            </Box>

            <Box className="mb-6">
              <Button
                variant="primary"
                fullWidth
                onClick={() => setShowAddNewtenancyModal(true)}
              >
                {t('settings.add_new_tenancy')}
              </Button>
            </Box>

            <Box className="mb-3">
              <Text>{t('no_tenancies.if_you_do_not_have_invitation')}</Text>
            </Box>

            <Box className="mb-3">
              <Button
                variant="danger"
                fullWidth
                onClick={() => setShowDeleteAccountModal(true)}
              >
                {t('profile.delete_account')}
              </Button>
            </Box>
          </Layout.Form>
        </Layout.Main>

        <Layout.Aside>
          <Layout.Lang>
            <LanguageSelect />
          </Layout.Lang>

          <Layout.Image>
            <Illustration illustration="loginCover" />
          </Layout.Image>
        </Layout.Aside>
      </Layout>

      <AddTenancyModal
        show={showAddNewTenancyModal}
        onClose={() => setShowAddNewtenancyModal(false)}
      />

      <DeleteUserModal
        show={showDeleteAccountModal}
        onClose={(doDelete: boolean) => handleUserAccountDelete(doDelete)}
      />
    </>
  );
};

export default NoTenancies;
