import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgGoogle(props: React.SVGProps<SVGSVGElement>) {
  const clipId = uuidv4();
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M16 8.18368C16 7.63986 15.955 7.0931 15.859 6.55811H8.1604V9.63876H12.569C12.3861 10.6323 11.7983 11.5113 10.9375 12.0698V14.0687H13.5677C15.1122 12.6753 16 10.6176 16 8.18368Z"
          fill="#4285F4"
        />
        <path
          d="M8.16042 16.0002C10.3617 16.0002 12.2182 15.2918 13.5707 14.0689L10.9405 12.07C10.2088 12.558 9.26407 12.8343 8.16342 12.8343C6.03408 12.8343 4.22865 11.4263 3.58085 9.5332H0.866699V11.5938C2.25227 14.2953 5.07438 16.0002 8.16042 16.0002Z"
          fill="#34A853"
        />
        <path
          d="M3.57792 9.53282C3.23602 8.53926 3.23602 7.46338 3.57792 6.46981V4.40918H0.866764C-0.290874 6.6697 -0.290874 9.33294 0.866764 11.5935L3.57792 9.53282Z"
          fill="#FBBC04"
        />
        <path
          d="M8.16042 3.16595C9.32406 3.14831 10.4487 3.57749 11.2914 4.36529L13.6217 2.08126C12.1462 0.723182 10.1878 -0.0234656 8.16042 5.08294e-05C5.07439 5.08294e-05 2.25227 1.70499 0.866699 4.40938L3.57785 6.47001C4.22265 4.574 6.03109 3.16595 8.16042 3.16595Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgGoogle = React.memo(SvgGoogle);
export default MemoSvgGoogle;
