import tw, { styled } from 'twin.macro';

interface IFormControlGroupComposition {
  Prefix: React.FC;
  Suffix: React.FC;
  Text: React.FC;
}

const FormControlGroup: IFormControlGroupComposition & React.FC<{ className?: string }> = ({
  children,
  ...props
}) => (<Container {...props}>{children}</Container>);


const Container = styled.div(() => [
  `
    input {
      width: 100%;
    }

    input[type=number] {
      padding-right: 40px;
    }

    div + input { 
      padding-left: 28px;
    }
  `,

  tw`relative`,
]);

const Prefix = styled.div(() => [
  tw`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none`,
]);

const Suffix = styled.div(() => [
  tw`absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none`,
]);

const Text = styled.span(() => [tw`text-sm text-gray-300`]);

FormControlGroup.Prefix = Prefix;
FormControlGroup.Suffix = Suffix;
FormControlGroup.Text = Text;

export default FormControlGroup;
