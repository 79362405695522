import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { usePageHeaderData } from '../../hooks/data/usePageHeaderData';
import paths from '../../lib/paths';

import { Box, Badge, CompanyIcon, Icon } from '../../ui';

const Header = () => {
  const { toggleMenu } = useContext(AppContext);
  const { notificationCount } = usePageHeaderData();

  return (
    <>
      <button onClick={() => toggleMenu()} type="button">
        <Icon name="menu" />
      </button>
      <Box className="flex items-center justify-center w-full">
        <Link to={paths.consumption().route}>
          <CompanyIcon className="text-white h-10" />
        </Link>
      </Box>

      {/* Note: Link resizes SVG incorrctly... w-6, h-6 fix this */}
      <Link to={paths.notifications().route} className="relative w-6 h-6">
        <Icon name="notificationsNone" />
        {notificationCount > 0 && <Badge>{notificationCount}</Badge>}
      </Link>
    </>
  );
};

export default Header;
