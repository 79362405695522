import * as React from 'react';

function SvgMin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="url(#paint0_linear)"
        fillRule="evenodd"
        d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14zm0 2c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm4.107-10.614a1.322 1.322 0 00-1.902 0l-.875.898V6.382c0-.763-.603-1.382-1.346-1.382-.743 0-1.346.619-1.346 1.382v15.902l-.875-.898a1.32 1.32 0 00-1.869.034 1.408 1.408 0 00-.033 1.919l3.172 3.258c.062.065.13.123.204.173.018.013.037.023.056.034l.025.014.047.026a.692.692 0 00.213.087l.054.016c.028.012.057.02.087.026a1.275 1.275 0 00.62-.026l.051-.016a.778.778 0 00.213-.09l.054-.027a1.129 1.129 0 00.285-.219l3.165-3.256a1.409 1.409 0 000-1.953zM4.06 15a.9.9 0 110-1.8h1.763a.9.9 0 110 1.8H4.06zm6.171 0a.9.9 0 010-1.8h1.764a.9.9 0 110 1.8H10.23zm9.772 0a.9.9 0 110-1.8h1.763a.9.9 0 110 1.8h-1.763zm6.171 0a.9.9 0 110-1.8h1.764a.9.9 0 010 1.8h-1.764z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-4}
          x2={26.4}
          y1={0}
          y2={32}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgMin = React.memo(SvgMin);
export default MemoSvgMin;
