export const OperatingSystems = {
  ANDROID: 'Android',
  IOS: 'iOS',
  OTHER: 'other',
};

export const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return OperatingSystems.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OperatingSystems.IOS;
  }

  return OperatingSystems.OTHER;
};

export const isMobileWebView = () => {
  const userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  // iOS WebView
  if (ios && !safari) {
    return true;
  }

  // Android WebView
  if (window.hasOwnProperty('Android ') || window.Android) {
    return true;
  }

  return false;
};
