import * as React from 'react';

function SvgStepperDone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={8} cy={8} r={8} fill="#F7A60A" />
      <path
        d="M12 5L6.5 10.5L4 8"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoSvgStepperDone = React.memo(SvgStepperDone);
export default MemoSvgStepperDone;
