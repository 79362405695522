import { ChangeEventHandler } from 'react';
import tw, { styled } from 'twin.macro';

type SwitchProps = {
  checked: boolean;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

type SwitchStateProps = {
  checked?: boolean;
};

const Switch: React.FC<SwitchProps> = ({ checked, name, onChange }) => {
  return (
    <Container>
      <Toggle className="group">
        <input
          checked={checked}
          name={name}
          type="checkbox"
          onChange={onChange}
          className="hidden"
        />

        <ToggleBar checked={checked} />
        <ToggleCircle checked={checked} />
      </Toggle>
    </Container>
  );
};

const Container = styled.label(() => [tw`flex items-center cursor-pointer`]);

const Toggle = styled.div(() => [
  tw`flex-shrink-0 relative rounded-full inline-flex items-center justify-center h-5 w-10 -ml-1`,
  tw`cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`,
]);

const ToggleBar = styled.span<SwitchStateProps>(({ checked }) => [
  tw`absolute h-4 w-8 mx-auto rounded-full transition-colors ease-in-out duration-200`,

  tw`bg-gray-200`,
  checked && tw`bg-action-200`,
]);

const ToggleCircle = styled.span<SwitchStateProps>(({ checked }) => [
  tw`absolute left-0 inline-block h-5 w-5`,
  tw`rounded-full shadow-md ring-0`,
  tw`transform transition-transform ease-in-out duration-200`,

  tw`translate-x-1 bg-white`,
  checked && tw`translate-x-4 bg-white bg-action-400`,
]);

export default Switch;
