import * as React from 'react';

function SvgTenancyInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={23}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7251 1.30035L11.7252 1.30052L20.7 10.2749L20.7004 10.2753L20.7013 10.2763C21.0997 10.6755 21.0995 11.3272 20.7009 11.7262C20.5054 11.9217 20.2511 12.0269 19.9756 12.0269H19.6439H18.6439V13.0269V19.6317C18.6439 20.3865 18.0283 21.0022 17.2732 21.0022H13.5172L13.5172 16.1794C13.5172 15.0307 12.5844 14.0978 11.4357 14.0978H10.5646C9.41607 14.0978 8.48286 15.0305 8.48286 16.1794L8.48286 21.0022H4.72689C3.97193 21.0022 3.35616 20.3864 3.35616 19.6317L3.35616 13.0269L3.35616 12.0269H2.35616H2.01549L2.0058 12.026L1.9792 12.0248C1.72012 12.0136 1.48191 11.9089 1.29935 11.7262L1.29918 11.7261C0.901037 11.3279 0.900119 10.6775 1.29735 10.278L1.30639 10.2689L1.30827 10.2669L10.2748 1.30051L10.2749 1.30051C10.4701 1.10528 10.7244 1 11.0001 1C11.2759 1 11.5301 1.1053 11.7251 1.30035Z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgTenancyInfo = React.memo(SvgTenancyInfo);
export default MemoSvgTenancyInfo;
