import { ButtonHTMLAttributes, ClassAttributes } from 'react';
import tw, { styled } from 'twin.macro';

import { Box, Icon } from '../ui';

export type DateOrPeriodSwitcherProps = {
  rounded?: boolean;
  onOpen?: Function;
  onPrev?: Function;
  onNext?: Function;
  disablePrev?: boolean;
  disableNext?: boolean;
};

const DateOrPeriodSwitcher: React.FC<
  DateOrPeriodSwitcherProps &
    ClassAttributes<HTMLButtonElement> &
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  onOpen,
  onPrev,
  onNext,
  disablePrev = false,
  disableNext = false,
  children,
  ...props
}) => (
  <Container {...props}>
    <Box
      onClick={() => onOpen()}
      className="flex items-center space-x-4 flex-grow cursor-pointer"
    >
      <Box className="flex items-center">
        <Icon name="periodDropdown" />
      </Box>

      {children}
    </Box>

    <Box className="space-x-2 flex no-wrap">
      <button type="button" onClick={() => onPrev()} disabled={disablePrev}>
        <Icon name="chevronLeft" />
      </button>
      <button type="button" onClick={() => onNext()} disabled={disableNext}>
        <Icon name="chevronRight" />
      </button>
    </Box>
  </Container>
);

const Container = styled.section<DateOrPeriodSwitcherProps>(({ rounded }) => [
  tw`relative flex items-center justify-between h-16 px-4 py-3.5 text-left bg-white shadow`,

  rounded && tw`rounded xl:rounded-none`,
]);

export default DateOrPeriodSwitcher;
