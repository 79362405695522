import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgWarmWater(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4a1 1 0 011-1h11a1 1 0 011 1v2a1 1 0 01-1 1h-4v3.905h1.752c4.047 0 7.248 3.295 7.248 7.248v1.578a1 1 0 01-1 1h-4.683a1 1 0 01-1-1v-1.578c0-.282-.283-.47-.565-.564H5.03v1.659c0 1.875-1.594 3.468-3.469 3.468-.28 0-.562-.281-.562-.562V6.218c0-.281.281-.562.562-.562 1.875 0 3.469 1.593 3.469 3.468v1.781H12V7H8a1 1 0 01-1-1V4zm11.5 22.116c0-1.395 1.855-3.535 2.177-3.907.162-.279.484-.279.646 0 .403.372 2.177 2.512 2.177 3.907C23.5 27.698 22.371 29 21 29s-2.5-1.302-2.5-2.884zm5.795-15.702l.566 1.375 1.85-.762-.572-1.387a1.503 1.503 0 010-1.265l.566-1.374a3.761 3.761 0 00-.006-2.934L26.223 3l-1.827.815.477 1.067a1.768 1.768 0 01-.012 1.345L24.295 7.6a3.503 3.503 0 000 2.813zm4.566 1.375l-.566-1.375a3.503 3.503 0 010-2.813l.566-1.374a1.768 1.768 0 00.011-1.345l-.476-1.067L30.223 3l.476 1.067c.4.894.401 2.037.006 2.934l-.566 1.374a1.503 1.503 0 000 1.265l.571 1.387-1.849.762z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1={-1.885}
          y1={4.202}
          x2={22.581}
          y2={27.27}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8087" />
          <stop offset={1} stopColor="#FFD8DA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgWarmWater = React.memo(SvgWarmWater);
export default MemoSvgWarmWater;
