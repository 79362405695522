import tw, { styled, css } from 'twin.macro';
import { mq } from '../lib/twinMacro';

import CheckSVG from '../../src/svgs/check.svg'

interface ILanguageListComposition {
  Button: typeof Button;
  List: typeof List;
  Item: typeof Item;
}

type ListProps = {
  visible?: boolean
}

const LanguageList: ILanguageListComposition & React.FC<{ className?: string }> = ({
  children,
  ...props
}) => (
  <Container {...props}>
    {children}
  </Container>
);

const Container = styled.div(() => [
  tw`relative`,
]);

const Button = styled.button(() => [
  tw`hidden xl:flex items-center h-9 px-2 py-2.5 space-x-1 text-sm rounded-sm border border-gray-200 cursor-pointer`,
]);

const List = styled.ul<ListProps>(({ visible }) => [
  tw`bg-white`,

  css`min-width: 360px;`,

  visible && tw`xl:block`
]);

const Item = styled.li<{ active?: boolean }>(({ active }) => [
  tw`relative flex items-center justify-between py-5 pl-6 xl:pl-8 pr-4 h-14 sm:h-16`,
  tw`cursor-pointer hover:bg-gray-25 transition`,

  css`line-height: 24px;`,

  `&:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      border-top: 1px solid #F5F5F5;
      width: calc(100% - 48px);
    }`,


  active && [
    tw`bg-gray-25`,

    css`
      background-image: url(${CheckSVG});
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: right 20px center;
      `,

    css`
      ${mq('xl')} {
        background-position: right 26px center;
      }
    `,

    `&:after {
      border-top: 1px solid transparent;
    }`,
  ],
]);


LanguageList.Button = Button;
LanguageList.List = List;
LanguageList.Item = Item;

export default LanguageList;
