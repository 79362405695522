import tw, { styled, css } from 'twin.macro';

interface IRowCardsComposition {
  List: React.FC;
  Item: typeof Item;

  Card: typeof Card;
  CardHeader: typeof CardHeader;
  CardContent: typeof CardContent;
  CardDefinition: typeof CardDefinition;

  Header: typeof Header;
  HeaderCell: typeof HeaderCell;

  Row: typeof Row;
  Cell: typeof Cell;
}

export type RowProps = {
  columns:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | 'alarms';
};

export type CellProps = {
  variant?: 'icon';
};

const RowCards: IRowCardsComposition & React.FC = ({ children }) => (
  <section>{children}</section>
);

const List = styled.ul(() => [tw`space-y-4 sm:space-y-2 xl:space-y-4`]);

const Item = styled.li<{ showCursor?: boolean }>(({ showCursor = true }) => [
  tw`bg-white rounded shadow-md hover:shadow-lg transition-shadow`,
  tw`xl:transition xl:shadow-none xl:hover:shadow-none xl:border xl:border-gray-100 xl:hover:border-gray-200`,
  showCursor && tw`cursor-pointer`,
]);

const Card = styled.section(() => [tw`p-4 sm:hidden`]);

const CardHeader = styled.div(() => [tw`flex items-center space-x-4 mb-2`]);

const CardContent = styled.div(() => [
  css`
    width: calc(100% - 48px);
  `,
]);

const CardDefinition = styled.div(() => [
  tw`flex justify-between py-3 last:pb-1 border-b border-gray-100 last:border-none sm:border-none`,
]);

const Header = styled.ul<RowProps>(({ columns }) => [
  css`
    font-size: 11px;
    line-height: 13px;
  `,

  tw`hidden sm:grid grid-cols-none px-3 py-4 py-0 mb-3 text-gray-300 uppercase font-bold`,

  columns === '1' && tw`grid-cols-1`,
  columns === '2' && tw`grid-cols-2`,
  columns === '3' && tw`grid-cols-3`,
  columns === '4' && tw`grid-cols-4`,
  columns === '5' && tw`grid-cols-5`,
  columns === '6' && tw`grid-cols-6`,
  columns === '7' && tw`grid-cols-7`,
  columns === '8' && tw`grid-cols-8`,
  columns === '9' && tw`grid-cols-9`,
  columns === '10' && tw`grid-cols-10`,
  columns === '11' && tw`grid-cols-11`,
  columns === '12' && tw`grid-cols-12`,

  columns === 'alarms' && tw`grid-cols-alarms`,
]);

const HeaderCell = styled.div<CellProps>(({ variant }) => [
  tw`flex items-center px-3`,

  variant === 'icon' && tw`pl-11 space-x-3`,
]);

const Row = styled.ul<RowProps>(({ columns }) => [
  tw`hidden sm:grid grid-cols-none px-3 py-4 text-gray-500`,

  columns === '1' && tw`grid-cols-1`,
  columns === '2' && tw`grid-cols-2`,
  columns === '3' && tw`grid-cols-3`,
  columns === '4' && tw`grid-cols-4`,
  columns === '5' && tw`grid-cols-5`,
  columns === '6' && tw`grid-cols-6`,
  columns === '7' && tw`grid-cols-7`,
  columns === '8' && tw`grid-cols-8`,
  columns === '9' && tw`grid-cols-9`,
  columns === '10' && tw`grid-cols-10`,
  columns === '11' && tw`grid-cols-11`,
  columns === '12' && tw`grid-cols-12`,

  columns === 'alarms' && tw`grid-cols-alarms`,
]);

const Cell = styled.div<CellProps>(({ variant }) => [
  tw`flex items-center px-3`,

  variant === 'icon' && tw`pl-0 space-x-3`,
]);

RowCards.List = List;
RowCards.Item = Item;

RowCards.Card = Card;
RowCards.CardHeader = CardHeader;
RowCards.CardContent = CardContent;
RowCards.CardDefinition = CardDefinition;

RowCards.Header = Header;
RowCards.HeaderCell = HeaderCell;
RowCards.Row = Row;
RowCards.Cell = Cell;

export default RowCards;
