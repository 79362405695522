import * as React from 'react';

function SvgBgLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="479"
      height="156"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 479 156"
      {...props}
    >
      <g clipPath="url(#clip0)" opacity={0.25}>
        <path fill="#BAE8E2" d="M188.32 107.409h4.952v10.052h-4.952v-10.052z" />
        <path
          fill="#fff"
          d="M188.595 110.576l3.852 5.233.275.413.412.688.138.276h-2.751l-.138-.276-1.788-2.478-.275-.413v-3.718l.275.275z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M186.944 106.17h1.1v11.566h-1.1V106.17zM193.823 106.032h1.1v11.567h-1.1v-11.567z"
        />
        <path fill="#BAE8E2" d="M279.251 49.3h4.953v10.053h-4.953V49.3z" />
        <path
          fill="#007F88"
          d="M194.923 17.906H277.6v112.498h-82.677V17.906z"
          opacity={0.75}
        />
        <path
          fill="#006169"
          d="M277.6 28.784h1.1V40.35h-1.1V28.784zM284.479 28.784h1.1V40.35h-1.1V28.784z"
        />
        <path
          fill="#BAE8E2"
          d="M216.658 25.617h4.953V35.67h-4.953V25.617zM211.292 25.617h4.953V35.67h-4.953V25.617zM205.928 25.617h4.952V35.67h-4.952V25.617zM200.563 25.617h5.09V35.67h-5.09V25.617z"
        />
        <path
          fill="#E9F9FF"
          d="M205.652 25.204h16.096v10.74h-21.461v-10.74h5.365zM200.7 35.669h4.952V25.617H200.7v10.052zm5.228 0h4.952V25.617h-4.952v10.052zm5.365 0h4.952V25.617h-4.952v10.052zm5.365 0h4.952V25.617h-4.952v10.052zM231.928 34.705h4.952v-9.501h-4.952v9.501zm5.227 0h4.953v-9.501h-4.953v9.501z"
        />
        <path
          fill="#fff"
          d="M279.526 52.743l3.852 5.233.275.413.413.688.138.276h-2.752l-.137-.276-1.789-2.478-.275-.413v-3.718l.275.275z"
          opacity={0.63}
        />
        <path fill="#BAE8E2" d="M279.251 30.023h4.953v10.052h-4.953V30.023z" />
        <path
          fill="#006169"
          d="M277.6 47.924h1.1V59.49h-1.1V47.924zM284.341 47.924h1.1V59.49h-1.1V47.924z"
        />
        <path
          fill="#005267"
          d="M228.764 13.913h-1.376v3.718h1.239l.137-3.718zM243.759 13.913h-1.238v3.305h1.238v-3.305z"
        />
        <path
          fill="#005267"
          d="M229.314 17.218h-2.476c-.55 0-.963.275-.963.688v.138h4.265v-.138c.137-.413-.276-.688-.826-.688zM244.446 17.218h-2.476c-.55 0-.963.275-.963.688v.138h4.265v-.138c0-.413-.413-.688-.826-.688z"
        />
        <path
          fill="#fff"
          d="M223.399 14.877h22.973l4.402-10.603h-22.973l-4.402 10.603z"
        />
        <path
          fill="#BAE8E2"
          d="M227.113 9.093h5.915l1.651-3.855h-5.915l-1.651 3.855zM225.187 13.913h5.916l1.65-3.856h-5.915l-1.651 3.856zM234.404 9.093h5.916l1.65-3.855h-5.915l-1.651 3.855zM232.341 13.913h5.915l1.651-3.856h-5.915l-1.651 3.856zM241.557 9.093h5.916l1.651-3.855h-5.916l-1.651 3.855zM239.632 13.913h5.915l1.651-3.856h-5.916l-1.65 3.856z"
        />
        <path
          fill="#00B23C"
          d="M223.399 14.877l.688.963h22.973l-.688-.963h-22.973z"
        />
        <path
          fill="#007F88"
          d="M246.647 14.601l.413.826 2.889-9.363-.413-.826-2.889 9.363z"
        />
        <path fill="#BAE8E2" d="M278.976 68.44h4.952v10.053h-4.952V68.44z" />
        <path
          fill="#fff"
          d="M279.113 71.608l3.852 5.232.275.413.413.689.137.275h-2.613l-.138-.275-1.788-2.479-.275-.413v-3.718l.137.276z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M277.6 67.201h1.1v11.567h-1.1V67.201zM284.341 66.926h1.1V78.63h-1.1V66.926z"
        />
        <path fill="#BAE8E2" d="M188.32 68.44h4.952v10.053h-4.952V68.44z" />
        <path
          fill="#fff"
          d="M188.595 71.608l3.851 5.232.276.413.412.689.138.275h-2.614l-.137-.275-1.789-2.479-.275-.413v-3.718l.138.276z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M186.944 67.064h1.1V78.63h-1.1V67.064zM193.823 66.926h1.1v11.567h-1.1V66.926z"
        />
        <path fill="#BAE8E2" d="M188.457 49.3h4.952v10.053h-4.952V49.3z" />
        <path
          fill="#fff"
          d="M188.732 52.468l3.852 5.508.275.413.413.688.137.276H190.658l-.137-.276-1.789-2.616-.275-.413v-3.855l.275.275z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M187.081 47.924h1.101V59.49h-1.101V47.924zM193.96 47.924h1.101V59.49h-1.101V47.924z"
        />
        <path fill="#BAE8E2" d="M188.32 30.16h4.952v10.053h-4.952V30.16z" />
        <path
          fill="#fff"
          d="M188.595 33.328l3.852 5.508.275.413.412.688.138.276h-2.751l-.138-.276-1.788-2.616-.275-.413v-3.855l.275.275z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M187.081 28.784h1.101V40.35h-1.101V28.784zM193.823 28.784h1.1V40.35h-1.1V28.784z"
        />
        <path fill="#BAE8E2" d="M188.32 87.718h4.952V97.77h-4.952V87.718z" />
        <path
          fill="#fff"
          d="M188.595 90.885l3.852 5.233.275.413.412.688.138.276h-2.751l-.138-.276-1.788-2.478-.275-.413V90.61l.275.275z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M186.944 86.479h1.1v11.567h-1.1V86.479zM193.823 86.341h1.1v11.567h-1.1V86.34z"
        />
        <path
          fill="#BAE8E2"
          d="M216.521 45.032h4.952v10.19h-4.952v-10.19zM211.156 45.032h4.952v10.19h-4.952v-10.19zM205.79 45.032h4.952v10.19h-4.952v-10.19zM200.426 45.032h4.952v10.19h-4.952v-10.19z"
        />
        <path
          fill="#E9F9FF"
          d="M205.515 44.757H221.611v10.74H200.15v-10.74h5.365zm-5.09 10.327h4.953V45.032h-4.953v10.052zm5.365 0h4.953V45.032h-4.953v10.052zm5.365 0h4.953V45.032h-4.953v10.052zm5.366 0h4.952V45.032h-4.952v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M217.208 45.996l-.275-.413-.137-.275-.275-.276h2.201l.275.276 2.063 3.58.275.413v3.993l-.275-.413-3.852-6.885zM212.944 54.946l-1.238-1.927-.413-.551-4.952-6.747-.275-.413-.138-.138-.275-.276h3.577l.275.276 1.926 2.616.275.413 4.264 6.472.138.275M200.563 48.475l3.852 5.37.275.275.55.689.275.275H202.626l-.275-.275-1.926-2.616-.275-.276v-3.855l.413.413z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M267.558 45.032h4.952v10.19h-4.952v-10.19zM262.193 45.032h4.952v10.19h-4.952v-10.19zM256.828 45.032h4.953v10.19h-4.953v-10.19zM251.463 45.032h4.952v10.19h-4.952v-10.19z"
        />
        <path
          fill="#E9F9FF"
          d="M256.552 44.757H272.648v10.74h-21.461v-10.74h5.365zm-5.09 10.327h4.953V45.032h-4.953v10.052zm5.365 0h4.953V45.032h-4.953v10.052zm5.366 0h4.952V45.032h-4.952v10.052zm5.365 0h4.952V45.032h-4.952v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M267.971 45.996l-.276-.413-.137-.275-.275-.276h2.476l.275.276 2.339 3.58.275.413v3.993l-.275-.413-4.402-6.885zM263.981 54.946l-1.238-1.927-.412-.551-4.953-6.747-.275-.413-.137-.138-.276-.276h3.577l.275.276 1.926 2.616.275.413 4.265 6.472.137.275M251.6 48.475l3.852 5.37.275.275.55.689.275.275h-2.751l-.275-.275-1.926-2.617-.275-.275v-3.855l.275.413z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M267.695 25.617h4.952V35.67h-4.952V25.617zM262.33 25.617h4.952V35.67h-4.952V25.617zM256.965 25.617h4.952V35.67h-4.952V25.617zM251.6 25.617h5.09V35.67h-5.09V25.617z"
        />
        <path
          fill="#E9F9FF"
          d="M256.69 25.204H272.785v10.74h-21.46v-10.74h5.365zm-4.953 10.465h4.953V25.617h-4.953v10.052zm5.228 0h4.952V25.617h-4.952v10.052zm5.365 0h4.952V25.617h-4.952v10.052zm5.365 0h4.953V25.617h-4.953v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M268.245 26.58l-.275-.412-.138-.276-.275-.275h2.201l.275.275 2.064 3.58.275.414v3.993l-.275-.413-3.852-6.885zM264.255 35.669l-1.238-1.928-.412-.55-4.953-6.748-.275-.413-.138-.138-.275-.275h3.577l.275.275 1.926 2.617.275.413 4.265 6.472.137.275M251.875 29.06l3.852 5.37.275.275.55.689.275.275h-2.751l-.275-.275-1.926-2.617-.275-.275v-3.856l.275.413z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M267.558 64.31h4.952V74.5h-4.952V64.31zM262.193 64.31h4.952V74.5h-4.952V64.31zM256.828 64.31h4.953V74.5h-4.953V64.31zM251.463 64.31h4.952V74.5h-4.952V64.31z"
        />
        <path
          fill="#E9F9FF"
          d="M256.552 64.034H272.648v10.74h-21.461v-10.74h5.365zm-5.09 10.328h4.953V64.31h-4.953v10.052zm5.365 0h4.953V64.31h-4.953v10.052zm5.366 0h4.952V64.31h-4.952v10.052zm5.365 0h4.952V64.31h-4.952v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M267.971 65.274l-.276-.413-.137-.276-.275-.275h2.476l.275.275 2.339 3.58.275.413v3.994l-.275-.413-4.402-6.885zM263.981 74.362l-1.238-1.928-.412-.55-4.953-6.748-.275-.413-.137-.138-.276-.275h3.577l.275.275 1.926 2.617.275.413 4.265 6.471.137.276M251.6 67.752l3.852 5.37.275.276.55.688.275.276h-2.751l-.275-.276-1.926-2.616-.275-.275v-3.856l.275.413z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M237.431 25.48h4.402v9.087h-4.402V25.48zM232.065 34.567V25.48h4.402v9.088h-4.402z"
        />
        <path
          fill="#fff"
          d="M237.843 26.305l-.275-.413-.138-.275-.275-.275h2.201l.275.275 2.064 3.58.275.413v3.993l-.275-.413-3.852-6.885zM232.203 27.958l3.852 5.37.275.275.55.689.275.275h-2.751l-.275-.275-1.926-2.616-.275-.276v-3.855l.275.413z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M231.653 54.396h4.952v-9.502h-4.952v9.502zm5.365 0h4.953v-9.502h-4.953v9.502z"
        />
        <path
          fill="#BAE8E2"
          d="M237.156 45.032h4.402v9.088h-4.402v-9.088zM231.791 54.12v-9.088h4.402v9.088h-4.402z"
        />
        <path
          fill="#fff"
          d="M237.568 45.858l-.275-.413-.137-.275-.276-.276h2.201l.276.276 2.063 3.58.413.413v3.993l-.275-.413-3.99-6.885zM231.928 47.51l3.852 5.371.275.276.55.688.276.275h-2.752l-.275-.275-1.926-2.616-.275-.276v-3.855l.275.413z"
          opacity={0.63}
        />
        <path
          fill="#BAE8E2"
          d="M216.383 64.172h4.952v10.19h-4.952v-10.19zM211.018 64.172h4.953v10.19h-4.953v-10.19zM205.653 64.172h4.952v10.19h-4.952v-10.19zM200.288 64.172h4.953v10.19h-4.953v-10.19z"
        />
        <path
          fill="#E9F9FF"
          d="M205.378 63.897H221.473v10.74h-21.46v-10.74h5.365zm-5.09 10.327h4.952V64.172h-4.952v10.052zm5.365 0h4.952V64.172h-4.952v10.052zm5.365 0h4.952V64.172h-4.952v10.052zm5.365 0h4.952V64.172h-4.952v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M216.796 65.136l-.275-.413-.138-.276-.275-.275h2.476l.275.275 2.339 3.58.275.414v3.993l-.275-.413-4.402-6.885zM212.806 74.224l-1.238-1.928-.413-.55-4.953-6.748-.275-.413-.137-.138-.275-.275H209.091l.276.275 1.925 2.617.276.413 4.127 6.471.137.276M200.425 67.615l3.852 5.37.275.275.551.689.275.275h-2.752l-.275-.275-1.926-2.617-.275-.275V67.2l.275.414z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M216.246 83.312h4.952v10.19h-4.952v-10.19zM210.88 83.312h4.952v10.19h-4.952v-10.19zM205.515 83.312h4.953v10.19h-4.953v-10.19zM200.15 83.312h4.953v10.19h-4.953v-10.19z"
        />
        <path
          fill="#E9F9FF"
          d="M205.24 83.037H221.336v10.74h-21.461v-10.74h5.365zm-5.09 10.327h4.953V83.312h-4.953v10.052zm5.365 0h4.953V83.312h-4.953v10.052zm5.366 0h4.952V83.312h-4.952v10.052zm5.365 0h4.952V83.312h-4.952v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M216.659 84.276l-.276-.413-.137-.275-.275-.276h2.476l.275.276 2.339 3.58.275.413v3.993l-.275-.413-4.402-6.885zM212.668 93.364l-1.238-1.928-.412-.55-4.953-6.748-.275-.413-.137-.137-.276-.276h3.577l.275.276 1.926 2.616.275.413 4.265 6.472.137.275M200.288 86.892l3.852 5.37.275.276.55.688.275.276h-2.751l-.275-.276-1.926-2.616-.275-.275V86.34l.275.551z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M267.558 83.312h4.952v10.19h-4.952v-10.19zM262.193 83.312h4.952v10.19h-4.952v-10.19zM256.828 83.312h4.953v10.19h-4.953v-10.19zM251.463 83.312h4.952v10.19h-4.952v-10.19z"
        />
        <path
          fill="#E9F9FF"
          d="M256.552 83.037H272.648v10.74h-21.461v-10.74h5.365zm-5.09 10.327h4.953V83.312h-4.953v10.052zm5.365 0h4.953V83.312h-4.953v10.052zm5.366 0h4.952V83.312h-4.952v10.052zm5.365 0h4.952V83.312h-4.952v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M267.971 84.276l-.276-.413-.137-.276-.275-.275h2.476l.275.275 2.339 3.58.275.414v3.993l-.275-.413-4.402-6.885zM263.981 93.364l-1.238-1.928-.412-.55-4.953-6.748-.275-.413-.137-.138-.276-.275h3.577l.275.275 1.926 2.617.275.413 4.265 6.471.137.276M251.6 86.754l3.852 5.37.275.276.55.688.275.276h-2.751l-.275-.276-1.926-2.616-.275-.275V86.34l.275.413z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M216.107 102.865h4.952v10.19h-4.952v-10.19zM210.742 102.865h4.953v10.19h-4.953v-10.19zM205.377 102.865h4.953v10.19h-4.953v-10.19zM200.012 102.865h4.953v10.19h-4.953v-10.19z"
        />
        <path
          fill="#E9F9FF"
          d="M205.102 102.59h16.096v10.74h-21.461v-10.74h5.365zm-5.09 10.327h4.953v-10.052h-4.953v10.052zm5.366 0h4.952v-10.052h-4.952v10.052zm5.365 0h4.952v-10.052h-4.952v10.052zm5.365 0h4.952v-10.052h-4.952v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M216.52 103.829l-.275-.413-.137-.275-.275-.276h2.476l.275.276 2.339 3.58.275.413v3.993l-.275-.413-4.403-6.885zM212.53 112.917l-1.238-1.928-.412-.551-4.953-6.747-.275-.413-.138-.137-.275-.276h3.577l.275.276 1.926 2.616.275.413 4.265 6.472.137.275M200.15 106.445l3.852 5.371.275.275.55.688.275.276h-2.751l-.275-.276-1.926-2.616-.275-.275v-3.856l.275.413z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#BAE8E2"
          d="M267.695 102.865h4.952v10.19h-4.952v-10.19zM262.33 102.865h4.952v10.19h-4.952v-10.19zM256.965 102.865h4.952v10.19h-4.952v-10.19zM251.6 102.865h4.952v10.19H251.6v-10.19z"
        />
        <path
          fill="#E9F9FF"
          d="M256.69 102.59H272.785v10.74h-21.46v-10.74h5.365zm-5.09 10.327h4.952v-10.052H251.6v10.052zm5.365 0h4.952v-10.052h-4.952v10.052zm5.365 0h4.952v-10.052h-4.952v10.052zm5.365 0h4.953v-10.052h-4.953v10.052z"
        />
        <g fill="#fff" opacity={0.63}>
          <path
            d="M268.108 103.829l-.275-.413-.137-.275-.275-.276h2.476l.275.276 2.339 3.58.275.413v3.993l-.275-.413-4.403-6.885zM264.119 112.779l-1.238-1.928-.413-.55-4.953-6.748-.275-.413-.137-.137-.275-.276H260.404l.276.276 1.925 2.616.276.413 4.264 6.472.138.275M251.738 106.308l3.852 5.37.275.275.55.689.275.275h-2.888l-.276-.275-1.926-2.617-.137-.275v-3.855l.275.413z"
            opacity={0.63}
          />
        </g>
        <path
          fill="#E9F9FF"
          d="M231.653 73.535h4.952v-9.5h-4.952v9.5zm5.365 0h4.953v-9.5h-4.953v9.5z"
        />
        <path
          fill="#BAE8E2"
          d="M237.294 64.172h4.402v9.088h-4.402v-9.088zM231.929 73.398V64.31h4.402v9.088h-4.402z"
        />
        <path
          fill="#fff"
          d="M237.706 65.136l-.275-.413-.138-.275-.275-.276h2.201l.275.276 2.064 3.58.275.413v3.993l-.275-.413-3.852-6.885zM231.928 66.65l3.852 5.37.275.276.55.689.276.275h-2.752l-.275-.275-1.926-2.617-.275-.275v-3.856l.275.414z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M231.378 92.538h4.952v-9.501h-4.952v9.5zm5.365 0h4.952v-9.501h-4.952v9.5z"
        />
        <path
          fill="#BAE8E2"
          d="M237.018 83.312h4.402V92.4h-4.402v-9.088zM231.653 92.4v-9.088h4.402V92.4h-4.402z"
        />
        <path
          fill="#fff"
          d="M237.43 84.138l-.275-.413-.137-.275-.276-.276h2.201l.276.276 2.063 3.58.275.413v3.993l-.275-.413-3.852-6.885zM231.653 85.79l3.851 5.37.276.276.55.689.275.275h-2.751l-.275-.275-1.926-2.617-.276-.275v-3.856l.276.413z"
          opacity={0.63}
        />
        <path
          fill="#E9F9FF"
          d="M231.791 112.091h4.952v-9.501h-4.952v9.501zm5.365 0h4.952v-9.501h-4.952v9.501z"
        />
        <path
          fill="#BAE8E2"
          d="M237.431 102.727h4.402v9.088h-4.402v-9.088zM232.066 111.815v-9.088h4.402v9.088h-4.402z"
        />
        <path
          fill="#fff"
          d="M237.844 103.553l-.275-.413-.138-.275-.275-.275h2.201l.275.275 2.064 3.58.275.413v3.993l-.275-.413-3.852-6.885zM232.066 105.206l3.852 5.37.275.275.55.689.275.275h-2.751l-.275-.275-1.926-2.616-.275-.276v-3.855l.275.413zM279.526 33.466l3.852 5.232.275.413.413.689.138.275h-2.752l-.137-.275-1.789-2.479-.275-.413V33.19l.275.276z"
          opacity={0.63}
        />
        <path fill="#BAE8E2" d="M279.113 87.718h4.952V97.77h-4.952V87.718z" />
        <path
          fill="#fff"
          d="M279.388 91.16l3.852 5.233.275.413.413.689.137.275H281.314l-.137-.275-1.789-2.479-.275-.413v-3.718l.275.276z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M277.462 86.341h1.101v11.567h-1.101V86.34zM284.203 86.341h1.1v11.567h-1.1V86.34z"
        />
        <path
          fill="#BAE8E2"
          d="M279.388 107.684h4.953v10.052h-4.953v-10.052z"
        />
        <path
          fill="#fff"
          d="M279.664 111.127l3.851 5.232.276.414.412.688.138.275h-2.752l-.137-.275-1.788-2.479-.276-.413v-3.717l.276.275z"
          opacity={0.63}
        />
        <path
          fill="#006169"
          d="M277.738 106.307h1.101v11.567h-1.101v-11.567zM284.479 106.307h1.1v11.567h-1.1v-11.567z"
        />
        <path
          fill="#51B89F"
          d="M131.182 10.47c1.934 0 3.868.691 5.249 1.797.552-4.424 4.143-7.88 8.701-7.88 2.21 0 4.144.83 5.663 2.212 1.244-3.318 4.282-5.53 8.011-5.53 4.558 0 8.288 3.594 8.702 8.156 1.243-.83 2.762-1.244 4.282-1.244 4.696 0 8.701 4.01 8.701 8.848v.415h-57.596c.829-4.01 4.282-6.774 8.287-6.774zM131.182 24.018c1.934 0 3.868-.691 5.249-1.797.552 4.424 4.143 7.88 8.701 7.88 2.21 0 4.144-.83 5.663-2.212 1.244 3.318 4.282 5.53 8.011 5.53 4.558 0 8.288-3.594 8.702-8.156 1.243.83 2.762 1.244 4.282 1.244 4.696 0 8.701-4.01 8.701-8.848v-.415h-57.596c.829 4.01 4.282 6.774 8.287 6.774z"
        />
        <path
          fill="#fff"
          d="M110.976 42.442l2.952 3.036 2.952-3.036a1.493 1.493 0 012.125-.022c.596.584.603 1.544.022 2.136l-4.026 4.137a1.496 1.496 0 01-1.073.457c-.403 0-.79-.165-1.074-.457l-4.025-4.137a1.518 1.518 0 01.022-2.136 1.493 1.493 0 012.125.022z"
        />
        <path
          fill="#51B89F"
          d="M28.513 18.25h.85l.887 47.762h-3.474l.887-47.762h.85z"
        />
        <path
          fill="#51B89F"
          d="M26.656 17.433h3.712c.478 0 .865.525.865 1.177s-.387 1.177-.865 1.177h-3.712c-.477 0-.865-.525-.865-1.177s.388-1.177.865-1.177z"
        />
        <path
          fill="#51B89F"
          d="M32.224 18.363c.552.015 1.081.18 1.544.487l10.704 7.06c.21.134.045.464-.186.374l-11.063-4.062a1.922 1.922 0 01-.917-.712s-1.781-2.308-1.826-2.428l.127-.764 1.617.045zM25.806 19.412a1.902 1.902 0 011.074-.442s2.877-.398 3.004-.375l.596.495-.85 1.393c-.29.473-.7.855-1.192 1.102l-11.436 5.786c-.223.112-.417-.187-.23-.352l9.034-7.607z"
        />
        <path
          fill="#51B89F"
          d="M29.488 2.722c.015-.248.373-.27.418-.023l2.035 11.662c.067.39.015.794-.157 1.154 0 0-1.095 2.705-1.177 2.803l-.723.27-.776-1.432a2.945 2.945 0 01-.35-1.589l.73-12.845z"
        />
        <path
          fill="#51B89F"
          d="M30.369 17.433c.477 0 .864.525.864 1.177s-.387 1.177-.864 1.177c-.478 0-.865-.525-.865-1.177s.387-1.177.864-1.177z"
        />
        <path
          fill="#51B89F"
          d="M30.368 18.16c.18 0 .328.203.328.45s-.149.45-.328.45c-.179 0-.328-.203-.328-.45s.15-.45.328-.45z"
        />
        <path
          fill="#51B89F"
          d="M30.651 17.688h.224c.29 0 1.543.442 1.535.922-.007.442-1.282.922-1.535.922h-.224c-.335 0-.611-.412-.611-.922s.276-.922.611-.922z"
        />
        <path
          fill="#A5E0C8"
          d="M89.811 87.769C37.278 52.402-1.94 68.83-1.94 68.83v99.811h393.443s-73.458-44.696-129.614-44.696c-79.885 0-99.796 12.485-172.076-36.176z"
        />
        <path
          fill="#51B89F"
          d="M270.528 133.241c-1.602-1.069-3.416 5.236-3.416 9.189 0 3.953 1.601 5.236 3.416 5.236 1.921 0 3.522-1.283 3.522-5.236.32-2.244-.64-7.266-3.522-9.189zM242.666 123.945c-1.921 0-3.416 5.236-3.416 9.189 0 3.954 1.601 5.236 3.416 5.236 1.922 0 3.523-1.282 3.523-5.236-.107-3.953-1.708-9.189-3.523-9.189zM256.864 125.762c-1.922 0-3.416 5.235-3.416 9.189 0 3.953 1.601 5.235 3.416 5.235 1.921 0 3.522-1.282 3.522-5.235-.106-3.954-1.708-9.189-3.522-9.189z"
        />
        <path
          fill="#51B89F"
          d="M331.753 132.638c-34.694 7.457-25.354 13.662-56.753 17.207-29.148 3.29-42.029-18.151-76.85-10.964-39.748 8.199-41.172-36.146-78.393-30.945-34.225 4.782-39.197-16.21-70.156-7.689C36.3 103.904 8.667 148.841-2 155.421v24.282c131.097.51 411.708-.172 542.813.338 0 0-103.012-42.74-144.11-47.403-41.097-4.663-47.887-3.665-64.95 0z"
        />
        <path
          fill="#005267"
          d="M71.016 124.367C50.52 110.59 15.079 156.94 15.079 156.94l20.07-.961h153.823s-28.715-26.486-50.705-26.486c-31.277 0-39.07 13.883-67.251-5.126z"
        />
        <path
          fill="#51B89F"
          fillRule="evenodd"
          d="M14.646 48.805c-2.177 0-3.951 5.958-3.951 10.515 0 4.564 1.766 6.018 3.95 6.018 2.178 0 3.952-1.454 3.952-6.018s-1.767-10.515-3.951-10.515zM30.637 74.878c-2.401 0-4.354 6.573-4.354 11.61 0 5.035 1.953 6.64 4.354 6.64 2.4 0 4.353-1.605 4.353-6.64 0-5.037-1.953-11.61-4.354-11.61zM10.479 101.461c-2.4 0-4.353 6.573-4.353 11.609s1.953 6.64 4.353 6.64c2.4 0 4.354-1.604 4.354-6.64s-1.946-11.609-4.354-11.609z"
          clipRule="evenodd"
        />
        <path
          fill="#00B23C"
          d="M447.803 108.311l16.661 68.649-45.652 4.992 17.682-73.626c1.462-6.055 9.841-6.063 11.309-.015z"
        />
        <path
          fill="#008982"
          d="M442.13 120.587c.544 0 .984.442.984.989v43.393c0 .547-.44.989-.984.989a.986.986 0 01-.984-.989v-43.393c0-.547.44-.989.984-.989z"
        />
        <path
          fill="#008982"
          d="M434.101 140.845l7.045 7.239v-7.412c0-.187.052-.367.149-.524l6.195-9.833a.983.983 0 011.357-.307.995.995 0 01.305 1.364l-6.046 9.592v9.548a.978.978 0 01-.611.915.967.967 0 01-1.073-.225l-8.73-8.971a.988.988 0 01.015-1.394.977.977 0 011.394.008z"
        />
        <path
          fill="#00B23C"
          d="M401.677 85.456l16.661 68.649-45.652 4.992 17.682-73.626c1.461-6.055 9.84-6.07 11.309-.015z"
        />
        <path
          fill="#008982"
          d="M395.996 97.732c.544 0 .984.442.984.99v56.238c0 .547-.44.989-.984.989a.986.986 0 01-.984-.989V98.721c0-.547.44-.989.984-.989z"
        />
        <path
          fill="#008982"
          d="M387.975 117.99l7.044 7.239v-7.412c0-.187.053-.367.15-.524l6.194-9.833a.983.983 0 011.357-.307.995.995 0 01.306 1.364l-6.046 9.593v9.547a.98.98 0 01-.611.915.969.969 0 01-1.074-.225l-8.729-8.971a.987.987 0 01.015-1.394.977.977 0 011.394.008z"
        />
        <path
          fill="#51B89F"
          d="M132.282 65.32h-.85l-.887 47.761h3.474l-.887-47.762h-.85z"
        />
        <path
          fill="#51B89F"
          d="M134.139 64.502h-3.713c-.477 0-.864.525-.864 1.177s.387 1.177.864 1.177h3.713c.477 0 .864-.525.864-1.177s-.387-1.177-.864-1.177z"
        />
        <path
          fill="#51B89F"
          d="M128.57 65.431a2.914 2.914 0 00-1.543.488l-10.705 7.06c-.209.134-.045.464.187.374l11.062-4.062c.373-.135.694-.39.917-.712 0 0 1.782-2.308 1.827-2.428l-.127-.764-1.618.044zM134.989 66.48a1.905 1.905 0 00-1.074-.442s-2.877-.397-3.004-.374l-.597.494.85 1.394c.291.472.701.855 1.193 1.102l11.435 5.786c.224.112.418-.188.232-.352l-9.035-7.607zM131.306 49.79c-.015-.247-.372-.27-.417-.022l-2.035 11.661a1.93 1.93 0 00.156 1.155s1.096 2.705 1.178 2.803l.723.27.776-1.432c.26-.487.387-1.034.35-1.589l-.731-12.845z"
        />
        <path
          fill="#51B89F"
          d="M130.426 64.502c-.477 0-.864.525-.864 1.177s.387 1.177.864 1.177.865-.525.865-1.177-.388-1.177-.865-1.177z"
        />
        <path
          fill="#51B89F"
          d="M130.426 65.23c-.179 0-.328.201-.328.449 0 .247.149.45.328.45.179 0 .328-.203.328-.45 0-.248-.149-.45-.328-.45z"
        />
        <path
          fill="#51B89F"
          d="M130.144 64.757h-.224c-.291 0-1.543.442-1.536.922.008.442 1.283.922 1.536.922h.224c.335 0 .611-.413.611-.922 0-.51-.276-.922-.611-.922zM100.127 50.526h-.85l-.887 47.762h3.473l-.887-47.762h-.849z"
        />
        <path
          fill="#51B89F"
          d="M101.057 54.008l-.142-3.723c-.019-.478-.556-.847-1.206-.821-.65.025-1.157.434-1.139.912l.143 3.723c.018.479.555.847 1.205.822.649-.025 1.157-.434 1.139-.913z"
        />
        <path
          fill="#51B89F"
          d="M99.918 48.457a2.932 2.932 0 00-.544-1.528L91.933 36.47c-.143-.204-.465-.027-.367.201l4.468 10.934c.148.369.414.68.744.892 0 0 2.368 1.696 2.489 1.736l.757-.157-.106-1.62zM99.124 54.938c.242-.317.385-.697.4-1.094 0 0 .286-2.9.259-3.027l-.516-.579-1.356.907c-.46.31-.825.736-1.053 1.239l-5.33 11.692c-.104.228.203.411.36.218l7.236-9.356zM115.594 50.597c.246-.024.255-.383.007-.418l-11.688-1.584a1.911 1.911 0 00-1.143.202s-2.652 1.202-2.746 1.288l-.24.734 1.454.72a2.917 2.917 0 001.595.289l12.761-1.23z"
        />
        <path
          fill="#51B89F"
          d="M100.914 50.285c-.018-.478-.555-.847-1.205-.822-.649.026-1.156.435-1.138.913.018.478.555.847 1.205.822.649-.026 1.156-.435 1.138-.913z"
        />
        <path
          fill="#51B89F"
          d="M100.19 50.313c-.007-.179-.214-.32-.46-.311-.246.01-.442.167-.435.346.007.18.214.321.46.312.246-.01.442-.167.435-.346z"
        />
        <path
          fill="#51B89F"
          d="M100.649 50.011l-.008-.224c-.011-.291-.5-1.53-.977-1.504-.44.024-.869 1.321-.86 1.575l.01.225c.012.336.433.597.94.577.508-.02.908-.312.895-.649z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0h480.366v156H0z"
            transform="matrix(-1 0 0 1 478.366 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgBgLeft = React.memo(SvgBgLeft);
export default MemoSvgBgLeft;
