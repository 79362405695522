import * as React from 'react';
import { theme } from 'twin.macro';

function SvgSignUp(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      width={275}
      height={194}
      viewBox="0 0 275 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M249.02 87C249.02 76.2 238.353 60.5 233.02 54C203.52 24.5 166.02 20 132.02 20C98.0201 20 26.0201 54 25.0201 92C24.0201 130 60.52 151.5 92.02 168C123.52 184.5 143.353 161 169.02 126C190.653 96.5 249.02 121.5 249.02 87Z"
        fill="#F5F5F5"
      />
      <path
        d="M78.9343 110.562L96.2879 108.679L98.9138 123.722L89.4788 125.473L92.6543 131.478L93.4991 137.656C93.4991 137.656 91.5449 144.862 87.321 148.007L85.204 166.8H46.6901L48.797 140.109L45.9267 144.119L29.5604 140.404L36.3084 124.659L41.6418 116.638L44.9509 113.691L49.0107 112.251L57.9674 110.521L58.8631 111.661C58.8631 111.661 60.6137 117.88 64.7867 119.132C68.9597 120.384 73.0208 120.577 73.0208 120.577C73.0208 120.577 77.0717 118.816 77.6925 117.615C78.3134 116.414 79.4228 114.124 78.9343 110.562Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M58.7814 110.47C54.1097 110.653 44.1657 111.946 39.4125 119.346C33.0716 129.208 29.5602 140.394 29.5602 140.394L45.9265 144.109L48.8171 139.407C48.8171 139.407 48.5728 149.92 46.5983 166.45"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={121.12}
        y={59.8399}
        width={88.48}
        height={31.76}
        rx={1.6}
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x={117} y={55} width={88.536} height={32.56} rx={2} fill="white" />
      <path
        d="M134.856 67.5792V55H117V70.54H131.895C133.53 70.54 134.856 69.2144 134.856 67.5792Z"
        fill="#D6D6D6"
      />
      <path
        d="M172.8 55H204.28C205.385 55 206.28 55.8954 206.28 57V85.56C206.28 86.6646 205.385 87.56 204.28 87.56H172.8V55Z"
        fill="#FCDB9D"
      />
      <rect
        x={117.4}
        y={55.4}
        width={88.48}
        height={31.76}
        rx={1.6}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={136.344}
        y={62.3999}
        width={29.76}
        height={4.44}
        rx={2.22}
        fill="#D6D6D6"
      />
      <rect
        x={136.344}
        y={74.24}
        width={23.808}
        height={5.92}
        rx={2.96}
        fill={illustrationPrimaryColor}
      />
      <rect
        x={184.704}
        y={74.24}
        width={12.648}
        height={5.92}
        rx={2.96}
        fill="white"
      />
      <path
        d="M182.773 75.1276L180.47 72.8368C180.196 72.5648 179.744 72.5648 179.471 72.8368L177.167 75.1276C176.894 75.3996 176.894 75.8494 177.167 76.1214C177.441 76.3933 177.893 76.3933 178.166 76.1214L179.26 75.0335V80.7867C179.26 81.1737 179.576 81.4875 179.965 81.4875C180.354 81.4875 180.669 81.1737 180.669 80.7867V75.0335L181.763 76.1214C181.9 76.2573 182.079 76.3306 182.268 76.3306C182.457 76.3306 182.626 76.2573 182.773 76.1214C183.046 75.8494 183.046 75.4101 182.773 75.1276Z"
        fill="white"
      />
      <rect
        x={160.52}
        y={105.12}
        width={74.0801}
        height={26.48}
        rx={1.6}
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={157}
        y={101}
        width={74.2561}
        height={27.28}
        rx={2}
        fill="white"
      />
      <path
        d="M203.8 101H229.88C230.985 101 231.88 101.895 231.88 103V126.28C231.88 127.385 230.985 128.28 229.88 128.28H203.8V101Z"
        fill="#FCDB9D"
      />
      <rect
        x={157.4}
        y={101.4}
        width={74.0801}
        height={26.48}
        rx={1.6}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={173.224}
        y={107.2}
        width={24.96}
        height={3.72}
        rx={1.86}
        fill="#D6D6D6"
      />
      <rect
        x={173.224}
        y={117.12}
        width={19.968}
        height={4.96}
        rx={2.48}
        fill={illustrationPrimaryColor}
      />
      <rect
        x={213.784}
        y={117.12}
        width={10.608}
        height={4.96}
        rx={2.48}
        fill="white"
      />
      <path
        d="M212.164 117.864L210.233 115.944C210.003 115.716 209.624 115.716 209.395 115.944L207.463 117.864C207.234 118.092 207.234 118.468 207.463 118.696C207.692 118.924 208.072 118.924 208.301 118.696L209.218 117.785V122.605C209.218 122.929 209.483 123.192 209.809 123.192C210.136 123.192 210.4 122.929 210.4 122.605V117.785L211.318 118.696C211.432 118.81 211.582 118.872 211.741 118.872C211.9 118.872 212.041 118.81 212.164 118.696C212.394 118.468 212.394 118.1 212.164 117.864Z"
        fill="white"
      />
      <rect
        x={108.76}
        y={141.214}
        width={55.8401}
        height={19.8349}
        rx={1.6}
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={106}
        y={138}
        width={56.1681}
        height={20.6349}
        rx={2}
        fill="white"
      />
      <path
        d="M141.4 138H160.64C161.745 138 162.64 138.895 162.64 140V156.635C162.64 157.739 161.745 158.635 160.64 158.635H141.4V138Z"
        fill="#FCDB9D"
      />
      <rect
        x={106.4}
        y={138.4}
        width={55.8401}
        height={19.8349}
        rx={1.6}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={118.272}
        y={150.193}
        width={15.104}
        height={3.7518}
        rx={1.8759}
        fill={illustrationPrimaryColor}
      />
      <rect
        x={148.952}
        y={150.193}
        width={8.02401}
        height={3.7518}
        rx={1.8759}
        fill="white"
      />
      <path
        d="M147.727 150.756L146.266 149.304C146.092 149.132 145.805 149.132 145.632 149.304L144.171 150.756C143.997 150.928 143.997 151.213 144.171 151.386C144.344 151.558 144.631 151.558 144.805 151.386L145.498 150.696V154.342C145.498 154.588 145.699 154.786 145.945 154.786C146.192 154.786 146.393 154.588 146.393 154.342V150.696L147.086 151.386C147.173 151.472 147.287 151.518 147.407 151.518C147.527 151.518 147.634 151.472 147.727 151.386C147.9 151.213 147.9 150.935 147.727 150.756Z"
        fill="white"
      />
      <path
        d="M200 145C206.075 145 211 140.075 211 134C211 127.925 206.075 123 200 123C193.925 123 189 127.925 189 134C189 140.075 193.925 145 200 145Z"
        fill="white"
      />
      <path
        d="M207.794 133.745C207.794 138.034 204.316 141.512 200.027 141.512C195.737 141.512 192.26 138.034 192.26 133.745C192.26 129.455 195.737 125.978 200.027 125.978C204.316 125.978 207.794 129.455 207.794 133.745Z"
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.851613}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.709 137.881C197.676 137.21 198.845 136.807 200.107 136.807C201.343 136.807 202.485 137.183 203.425 137.827"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.851613}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.032 133.234C197.655 133.234 198.16 132.729 198.16 132.106C198.16 131.483 197.655 130.978 197.032 130.978C196.408 130.978 195.903 131.483 195.903 132.106C195.903 132.729 196.408 133.234 197.032 133.234Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M202.7 133.234C203.323 133.234 203.828 132.729 203.828 132.106C203.828 131.483 203.323 130.978 202.7 130.978C202.077 130.978 201.572 131.483 201.572 132.106C201.572 132.729 202.077 133.234 202.7 133.234Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M136.919 92.2114C136.919 92.2114 147.606 84.0079 148.634 85.0257C150.211 86.5829 143.921 91.7737 143.921 91.7737C143.921 91.7737 146.506 94.5931 144.898 97.8297C144.023 99.5803 139.015 102.664 134.649 102.501C116.786 118.746 112.013 119.397 107.789 120.445C105.336 121.056 98.7407 121.962 98.7407 121.962L96.1962 109.911L103.626 108.832L131.708 95.1529C131.708 95.1529 133.428 89.616 137.244 90.1249C141.061 90.644 142.506 90.8068 142.059 91.8246C141.611 92.8424 137.295 92.9544 137.295 92.9544C137.295 92.9544 137.936 95.906 137.316 97.1274"
        fill="white"
      />
      <path
        d="M131.708 95.1523C131.708 95.1523 133.428 89.6155 137.244 90.1244C141.061 90.6434 142.506 90.8063 142.059 91.8241C141.611 92.8419 137.295 92.9539 137.295 92.9539C137.295 92.9539 137.937 95.9055 137.316 97.1269"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.708 95.1521L103.626 108.831L96.1962 109.91"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.659 102.501C116.797 118.745 112.023 119.396 107.799 120.445C105.346 121.055 98.751 121.961 98.751 121.961"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.687 90.1141C139.687 90.1141 147.606 83.9971 148.644 85.0149C150.221 86.5721 143.931 91.763 143.931 91.763C143.931 91.763 146.517 94.5823 144.908 97.8189C144.033 99.5696 139.026 102.654 134.659 102.491"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.9342 110.562L96.2879 108.679L98.9138 123.722L89.4787 125.473"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.823 70C212.107 70 218.823 63.2843 218.823 55C218.823 46.7157 212.107 40 203.823 40C195.538 40 188.823 46.7157 188.823 55C188.823 63.2843 195.538 70 203.823 70Z"
        fill="white"
      />
      <path
        d="M214.639 54.6447C214.639 60.5951 209.815 65.4189 203.864 65.4189C197.914 65.4189 193.09 60.5951 193.09 54.6447C193.09 48.6942 197.914 43.8705 203.864 43.8705C209.815 43.8705 214.639 48.6942 214.639 54.6447Z"
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.505 59.5654C207.188 60.4853 205.589 61.0288 203.864 61.0288C202.192 61.0288 200.624 60.5166 199.338 59.6491"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.777 53.9549C200.631 53.9549 201.324 53.2623 201.324 52.4079C201.324 51.5535 200.631 50.8608 199.777 50.8608C198.923 50.8608 198.23 51.5535 198.23 52.4079C198.23 53.2623 198.923 53.9549 199.777 53.9549Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M207.492 53.9549C208.346 53.9549 209.039 53.2623 209.039 52.4079C209.039 51.5535 208.346 50.8608 207.492 50.8608C206.637 50.8608 205.944 51.5535 205.944 52.4079C205.944 53.2623 206.637 53.9549 207.492 53.9549Z"
        fill={illustrationPrimaryColor}
      />
      <rect
        x={110.4}
        y={45.4}
        width={20.2}
        height={20.2}
        rx={1.6}
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.703 48.1826C117.724 48.0921 117.805 48.025 117.901 48.025H124.548C124.661 48.025 124.752 48.1166 124.752 48.2288V49.4362C124.752 49.5491 124.66 49.64 124.548 49.64H122.133H121.733V50.04V52.3973V52.7973H122.133H123.193C125.415 52.7973 127.172 54.6045 127.172 56.7758V57.7313C127.172 57.8441 127.08 57.935 126.968 57.935H124.138C124.023 57.935 123.935 57.8463 123.935 57.7313V56.7758C123.935 56.5448 123.816 56.3735 123.696 56.266C123.579 56.1613 123.44 56.0951 123.32 56.055L123.258 56.0345H123.193H117.901H116.111H115.711V56.4345V57.4373C115.711 58.3307 114.963 59.0983 114.075 59.1309V49.6326C114.963 49.6652 115.711 50.4328 115.711 51.3263V52.4025V52.8025H116.111H120.322H120.722V52.4025V50.0453V49.6453H120.322H117.907C117.794 49.6453 117.703 49.5536 117.703 49.4415V48.2288V48.1826ZM125.165 60.3983C125.387 60.0654 125.625 59.7741 125.766 59.6076C125.902 59.7574 126.143 60.0497 126.37 60.3956C126.511 60.6119 126.64 60.8386 126.733 61.054C126.828 61.2743 126.873 61.4561 126.873 61.59C126.873 62.38 126.318 62.933 125.761 62.933C125.204 62.933 124.649 62.38 124.649 61.59C124.649 61.4589 124.694 61.2789 124.792 61.0582C124.888 60.8426 125.021 60.6156 125.165 60.3983Z"
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
      />
      <path
        d="M62.8935 99.0706L61.3159 110.47C61.3159 110.47 60.3388 110.409 58.7816 110.47"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1913 140.771C30.1913 140.771 28.3287 146.999 30.517 151.529C33.9165 158.552 52.0335 166.297 61.6721 166.46H80.6339C80.6339 166.46 82.9239 165.066 79.4023 162.368C78.3234 161.534 73.6721 157.432 68.7764 157.259C63.8706 157.086 61.153 159.865 61.153 159.865C61.153 159.865 47.7383 149.575 42.5068 146.938L44.3286 143.743L30.1913 140.771Z"
        fill="white"
      />
      <path
        d="M30.1913 140.771C30.1913 140.771 28.3287 146.999 30.517 151.529C33.9165 158.552 52.0335 166.297 61.6721 166.46H80.6339C80.6339 166.46 82.9239 165.066 79.4023 162.368C78.3234 161.534 73.6721 157.432 68.7764 157.259C63.8706 157.086 61.153 159.865 61.153 159.865C61.153 159.865 47.7383 149.575 42.5068 146.938L44.3286 143.743L30.1913 140.771Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.237 106.47L75.84 110.47H78.9341C78.9341 110.47 80.6848 120.882 71.0665 120.567C61.4482 120.251 59.5347 113.768 58.5067 110.491"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4787 125.473C89.4787 125.473 94.0995 131.284 93.499 137.646C93.0003 142.938 87.3209 147.997 87.3209 147.997L85.2039 166.45"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.8528 144.933C46.8528 144.933 48.9292 135.579 51.7485 132.149"
        stroke="white"
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M93.5498 130.155C93.5498 130.155 89.9162 124.109 86.2928 121.554"
        stroke="white"
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M79.4126 98.6535C79.4126 98.6535 76.919 99.3049 75.5042 97.4626"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.4455 82.5721C82.4455 82.5721 83.7585 81.6052 86.0893 82.4602"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.0257 82.9384C75.0257 82.9384 73.1428 82.1954 71.2089 83.6814"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.2674 88.73C84.7789 88.73 85.1936 88.1285 85.1936 87.3865C85.1936 86.6445 84.7789 86.043 84.2674 86.043C83.7559 86.043 83.3412 86.6445 83.3412 87.3865C83.3412 88.1285 83.7559 88.73 84.2674 88.73Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M75.4227 88.9741C75.9342 88.9741 76.3489 88.3726 76.3489 87.6306C76.3489 86.8886 75.9342 86.2871 75.4227 86.2871C74.9111 86.2871 74.4965 86.8886 74.4965 87.6306C74.4965 88.3726 74.9111 88.9741 75.4227 88.9741Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M86.7001 84.9741C86.7611 85.6561 86.8019 86.3482 86.812 87.0606C87.0563 97.2591 84.2573 105.625 76.3286 106.002C66.466 106.47 59.6976 97.9105 59.4533 87.7222C59.4432 87.1319 59.4025 86.0021 59.433 85.422"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.924 81.7275C78.924 81.7275 81.0613 91.1117 81.7026 92.1194C82.3438 93.127 78.9647 93.1168 78.9647 93.1168"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4228 85.422C59.4228 85.422 54.9139 84.3432 53.4585 87.4068C52.003 90.4704 54.6798 96.6994 61.0412 95.4882L62.6391 99.0098L61.9775 103.956L43.7384 105.534C40.8885 105.737 38.5272 103.346 38.7919 100.506C39.3211 94.6841 40.4305 85.6358 42.4153 81.0556C45.4992 73.9615 51.0768 62.2771 66.5679 62.7249C82.0589 63.1727 86.6085 71.763 87.2599 72.73C87.9113 73.6969 90.8426 79.1829 86.6899 84.964C86.6899 84.964 85.9367 75.4475 81.0004 71.763C81.0004 71.7732 75.6263 81.9208 59.4228 85.422Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4228 85.422C59.4228 85.422 54.9139 84.3432 53.4585 87.4068C52.003 90.4704 54.6798 96.6994 61.0412 95.4882L62.6391 99.0098L61.9775 103.956L43.7384 105.534C40.8885 105.737 38.5272 103.346 38.7919 100.506C39.3211 94.6841 40.4305 85.6358 42.4153 81.0556C45.4992 73.9615 51.0768 62.2771 66.5679 62.7249C82.0589 63.1727 86.6085 71.763 87.2599 72.73C87.9113 73.6969 90.8426 79.1829 86.6899 84.964C86.6899 84.964 85.9367 75.4475 81.0004 71.763C81.0004 71.7732 75.6263 81.9208 59.4228 85.422Z"
        fill={illustrationPrimaryColor}
      />
      <rect
        x={117.4}
        y={126.4}
        width={16.7574}
        height={16.7574}
        rx={1.6}
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.287 129.511C121.742 129.511 121.323 129.925 121.323 130.461V138.394C121.323 138.93 121.742 139.344 122.287 139.344C122.831 139.344 123.25 138.93 123.25 138.394V130.461C123.25 129.925 122.831 129.511 122.287 129.511ZM124.599 129.511C124.055 129.511 123.636 129.925 123.636 130.461V138.394C123.636 138.93 124.055 139.344 124.599 139.344C125.144 139.344 125.563 138.93 125.563 138.394V130.461C125.563 129.925 125.144 129.511 124.599 129.511ZM126.912 129.511C126.368 129.511 125.949 129.925 125.949 130.461V138.394C125.949 138.93 126.368 139.344 126.912 139.344C127.423 139.344 127.933 138.941 127.876 138.383V130.461C127.876 129.925 127.457 129.511 126.912 129.511ZM129.271 129.511C128.726 129.511 128.307 129.925 128.307 130.461V138.394C128.307 138.93 128.726 139.344 129.271 139.344C129.815 139.344 130.234 138.93 130.234 138.394V130.461C130.234 129.925 129.815 129.511 129.271 129.511ZM130.767 130.785C130.665 130.785 130.574 130.875 130.574 130.975V132.405C130.574 132.506 130.665 132.595 130.767 132.595H131.005C131.515 132.595 131.924 132.193 131.924 131.69C131.924 131.187 131.515 130.785 131.005 130.785H130.767ZM130.767 136.014C130.665 136.014 130.574 136.103 130.574 136.204V137.634C130.574 137.735 130.665 137.824 130.767 137.824H131.005C131.515 137.824 131.924 137.422 131.924 136.919C131.924 136.416 131.515 136.014 131.005 136.014H130.767ZM119.826 130.785C119.724 130.785 119.633 130.875 119.633 130.975V132.405C119.633 132.506 119.724 132.595 119.826 132.595H120.79C120.892 132.595 120.983 132.506 120.983 132.405V130.975C120.983 130.875 120.892 130.785 120.79 130.785H119.826ZM119.826 136.014C119.724 136.014 119.633 136.103 119.633 136.204V137.634C119.633 137.735 119.724 137.824 119.826 137.824H120.79C120.892 137.824 120.983 137.735 120.983 137.634V136.204C120.983 136.103 120.892 136.014 120.79 136.014H119.826Z"
        fill={illustrationPrimaryColor}
      />
    </svg>
  );
}

const MemoSvgSignUp = React.memo(SvgSignUp);
export default MemoSvgSignUp;
