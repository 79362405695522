import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgCooling(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m9.316 18.565-4.811-1.797a1.145 1.145 0 0 0-1.438.632c-.198.535.05 1.165.645 1.408l3.174 1.215-3.027 1.7a1.042 1.042 0 0 0-.397 1.457c.198.34.595.535.942.535.198 0 .397-.049.546-.146l3.027-1.7-.496 3.304c-.099.582.347 1.117.942 1.214h.15c.546 0 .991-.389 1.09-.922l.744-5.053 4.414-2.526v5.004l-4.019 3.158c-.496.389-.546 1.068-.149 1.506a1.073 1.073 0 0 0 1.539.147l2.678-2.09v3.4c0 .583.496 1.069 1.09 1.069.596 0 1.092-.486 1.092-1.068v-3.4L19.73 27.7c.197.146.445.242.694.242.298 0 .645-.146.842-.389.398-.485.299-1.117-.149-1.506l-4.019-3.158.006-5.004 4.414 2.526.744 5.004c.1.535.547.922 1.091.922h.15c.595-.097.992-.632.941-1.214l-.496-3.304 3.027 1.7c.15.097.347.146.547.146.397 0 .744-.193.941-.535.299-.535.15-1.165-.397-1.457l-3.027-1.7 3.174-1.215c.547-.193.843-.825.646-1.408-.198-.535-.843-.825-1.438-.632l-4.812 1.797-4.513-2.476 4.414-2.526 4.812 1.797c.15.05.248.05.397.05.446 0 .843-.243 1.043-.68a1.106 1.106 0 0 0-.646-1.408l-3.174-1.214 3.027-1.7c.547-.293.694-.972.397-1.458-.298-.535-.992-.679-1.488-.389l-3.027 1.748.496-3.304c.1-.582-.346-1.117-.941-1.214-.595-.097-1.142.34-1.24.922l-.745 5.004-4.414 2.526-.002-5.004 4.02-3.158c.495-.389.546-1.068.148-1.506a1.073 1.073 0 0 0-1.538-.146l-2.678 2.089v-3.4c0-.583-.496-1.069-1.091-1.069s-1.091.486-1.091 1.068v3.4L12.097 4.38c-.496-.39-1.141-.293-1.539.146-.397.485-.298 1.117.15 1.506l4.018 3.158v5.004l-4.319-2.526-.744-5.004c-.099-.582-.645-.971-1.24-.922a1.126 1.126 0 0 0-.942 1.214l.496 3.304-2.976-1.7c-.547-.292-1.19-.146-1.489.389-.298.535-.149 1.165.398 1.457l3.027 1.7-3.175 1.215c-.546.193-.842.825-.645 1.408.15.436.595.68 1.043.68.149 0 .248 0 .397-.05l4.811-1.797 4.415 2.525-4.467 2.478Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1={-0.605}
          y1={2}
          x2={26.839}
          y2={30.842}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51BCD5" />
          <stop offset={1} stopColor="#AEE5EF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgCooling = React.memo(SvgCooling);
export default MemoSvgCooling;
