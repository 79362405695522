import * as React from 'react';

function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66671 2.6665H13.3334C14.0667 2.6665 14.6667 3.2665 14.6667 3.99984V11.9998C14.6667 12.7332 14.0667 13.3332 13.3334 13.3332H2.66671C1.93337 13.3332 1.33337 12.7332 1.33337 11.9998V3.99984C1.33337 3.2665 1.93337 2.6665 2.66671 2.6665Z"
        stroke="#99AEB8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 4L8.00004 8.66667L1.33337 4"
        stroke="#99AEB8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoSvgEmail = React.memo(SvgEmail);
export default MemoSvgEmail;
