import * as React from 'react';

function SvgEnglish(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={28} height={20} rx={2} fill="currentColor" />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <rect width={28} height={20} rx={2} fill="currentColor" />
      </mask>
      <g mask="url(#mask0)">
        <rect width={28} height={20} fill="#0A17A7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.2825 -1.9165L10.6666 6.14329V-1.33339H17.3333V6.14329L29.2824 -1.9165L30.7736 0.294263L21.3262 6.66661H28V13.3333H21.3262L30.7736 19.7056L29.2824 21.9164L17.3333 13.8566V21.3333H10.6666V13.8566L-1.2825 21.9164L-2.77368 19.7056L6.67371 13.3333H-3.14713e-05V6.66661H6.67371L-2.77368 0.294263L-1.2825 -1.9165Z"
          fill="currentColor"
        />
        <path
          d="M18.668 6.33219L31.3333 -2"
          stroke="#DB1F35"
          strokeWidth={0.666667}
          strokeLinecap="round"
        />
        <path
          d="M20.0127 13.6975L31.3665 21.3503"
          stroke="#DB1F35"
          strokeWidth={0.666667}
          strokeLinecap="round"
        />
        <path
          d="M8.00562 6.31055L-3.8374 -1.6709"
          stroke="#DB1F35"
          strokeWidth={0.666667}
          strokeLinecap="round"
        />
        <path
          d="M9.29012 13.605L-3.8374 22.3105"
          stroke="#DB1F35"
          strokeWidth={0.666667}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12H12V20H16V12H28V8H16V0H12V8H0V12Z"
          fill="#E6273E"
        />
      </g>
    </svg>
  );
}

const MemoSvgEnglish = React.memo(SvgEnglish);
export default MemoSvgEnglish;
