import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgTemperature(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.454 30a5.963 5.963 0 01-5.954-5.954c0-1.58.607-2.916 1.701-4.13V6.252C12.201 3.944 14.145 2 16.454 2c2.308 0 4.252 1.944 4.252 4.253v13.662a5.786 5.786 0 011.701 4.131A5.963 5.963 0 0116.454 30z"
        fill={`url(#${id})`}
      />
      <path
        d="M17.17 21.373V6.355c0-.365-.365-.729-.73-.729-.364 0-.728.364-.728.729v15.018c-1.094.365-1.823 1.337-1.823 2.43 0 1.458 1.215 2.552 2.552 2.552 1.336 0 2.551-1.215 2.551-2.552 0-1.093-.729-2.065-1.822-2.43z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id={id}
          x1={9.012}
          y1={2}
          x2={28.877}
          y2={10.893}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8087" />
          <stop offset={1} stopColor="#FFD8DA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgTemperature = React.memo(SvgTemperature);
export default MemoSvgTemperature;
