import * as React from 'react';

function SvgNoNotifications(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="214"
      height="97"
      viewBox="0 0 214 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.898 34.1689C18.9136 34.1689 15.6835 37.399 15.6835 41.3834C15.6835 45.3678 18.9136 48.5978 22.898 48.5978H23.8022C23.7866 48.5979 23.7709 48.598 23.7553 48.5982H35.7585C39.7429 48.5982 42.9729 51.8282 42.9729 55.8126C42.9729 59.7665 39.7921 62.9776 35.8498 63.0265H7.21443C3.23001 63.0265 0 66.2565 0 70.2409C0 74.2253 3.23001 77.4553 7.21443 77.4553H45.4463C41.4784 77.4747 38.2678 80.6972 38.2678 84.6697C38.2678 88.6541 41.4978 91.8841 45.4823 91.8841H179.106C183.09 91.8841 186.32 88.6541 186.32 84.6697C186.32 80.6972 183.11 77.4747 179.142 77.4553H182.243L182.296 77.4551L182.324 77.4549H170.323C166.339 77.4549 163.109 74.2249 163.109 70.2404C163.109 66.2858 166.291 63.0744 170.234 63.0266H198.867C202.852 63.0266 206.082 59.7966 206.082 55.8121C206.082 51.8277 202.852 48.5977 198.867 48.5977H168.478C172.445 48.5779 175.656 45.3555 175.656 41.3834C175.656 37.399 172.426 34.1689 168.441 34.1689H22.898ZM192.594 84.6697C192.594 80.6852 195.824 77.4552 199.808 77.4552H206.709C210.693 77.4552 213.923 80.6852 213.923 84.6697C213.923 88.6541 210.693 91.8841 206.709 91.8841H199.808C195.824 91.8841 192.594 88.6541 192.594 84.6697Z"
        fill="#F5F5F5"
      />
      <path
        d="M120.27 29.9706C124.73 34.4339 127.235 40.4876 127.235 46.8C127.235 63.4922 133.213 73.4999 138.457 79.0502C138.626 79.229 138.766 79.537 138.805 79.99C138.844 80.4375 138.776 80.9547 138.609 81.448C138.442 81.9438 138.193 82.3601 137.92 82.6361C137.651 82.9085 137.408 83 137.207 83H69.7043C69.5034 83 69.2603 82.9085 68.9909 82.6361C68.718 82.3601 68.4695 81.9438 68.3019 81.448C68.1352 80.9547 68.0675 80.4375 68.1061 79.99C68.1452 79.537 68.2847 79.229 68.4537 79.0502C73.6982 73.4999 79.6759 63.4922 79.6759 46.8C79.6759 40.4876 82.1815 34.4339 86.6411 29.9706C91.1007 25.5073 97.1491 23 103.456 23C109.762 23 115.81 25.5073 120.27 29.9706Z"
        fill="white"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M122.473 46.2C122.456 45 122.456 45 121.956 43M116.903 34.6235C118.357 36.0916 118.956 37 119.956 38.5M103.456 29C98.4121 29 93.575 31.0229 90.0087 34.6235C86.4423 38.2242 84.4387 43.1078 84.4387 48.2"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.5733 86C94.4958 86.4886 94.4556 86.9896 94.4556 87.5C94.4556 92.7467 98.7089 97 103.956 97C109.202 97 113.456 92.7467 113.456 87.5C113.456 86.9896 113.415 86.4886 113.338 86H94.5733Z"
        fill="#D6D6D6"
      />
      <circle
        cx="102.956"
        cy="18.5"
        r="4.5"
        fill="white"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M142.36 30.5267C144.409 32.5755 146.034 35.0078 147.143 37.6848C148.251 40.3617 148.822 43.2309 148.822 46.1284C148.822 49.0259 148.251 51.895 147.143 54.572C146.034 57.2489 144.409 59.6812 142.36 61.7301"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M60.6393 30.5267C58.5904 32.5755 56.9652 35.0078 55.8564 37.6848C54.7475 40.3617 54.1768 43.2309 54.1768 46.1284C54.1768 49.0259 54.7475 51.895 55.8564 54.572C56.9652 57.2489 58.5904 59.6812 60.6393 61.7301"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M149.52 23.4791C152.418 26.3767 154.716 29.8166 156.284 33.6025C157.852 37.3884 158.66 41.4461 158.66 45.544C158.66 49.6418 157.852 53.6995 156.284 57.4854C154.716 61.2713 152.418 64.7113 149.52 67.6089"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M53.4791 23.4791C50.5815 26.3767 48.283 29.8166 46.7148 33.6025C45.1466 37.3884 44.3395 41.4461 44.3395 45.544C44.3395 49.6418 45.1466 53.6995 46.7148 57.4854C48.283 61.2713 50.5815 64.7113 53.4791 67.6089"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

const MemoSvgNoNotifications = React.memo(SvgNoNotifications);
export default MemoSvgNoNotifications;
