import { Suspense } from 'react';
import { Route, RouteProps } from 'react-router';
import { Spinner } from '../../ui';

const UnauthenticatedRoute: React.FC<RouteProps & { component: React.FC }> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense
          fallback={
            <div className="flex justify-center w-full">
              <Spinner />
            </div>
          }
        >
          <Component {...props} {...rest} />
        </Suspense>
      )}
    />
  );
};

export default UnauthenticatedRoute;
