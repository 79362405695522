import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CookieConsentModal } from './components/CookieConsentModal';
import { AppStoreLink, GooglePlayLink } from './components/NativeAppLink';
import AppRouter from './components/routing/AppRouter';
import useDisableIOSInputZoom from './hooks/useDisableIOSInputZoom';
import { getMobileOS, isMobileWebView, OperatingSystems } from './lib/checkOS';
import { Box, Modal, Text } from './ui';

const App = () => {
  useDisableIOSInputZoom();
  const [showNewAppModal, setShowNewAppModal] = useState<boolean>(() =>
    isMobileWebView()
  );

  return (
    <>
      <AppRouter />
      <CookieConsentModal />
      {isMobileWebView() && (
        <NewAppNoticeModal
          show={showNewAppModal}
          onClose={() => setShowNewAppModal(false)}
        />
      )}
    </>
  );
};

const NewAppNoticeModal = ({ show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('new_app_notice.title')}
      titleVariant="h1"
      show={show}
      onHide={() => onClose()}
      maxWidth="484"
      variant="stretch"
    >
      <Modal.Body>
        <Box className="mb-4">
          <Text variant="p">{t('new_app_notice.body')}</Text>
        </Box>
        <Box>
          {OperatingSystems.ANDROID === getMobileOS() && <GooglePlayLink />}

          {OperatingSystems.IOS === getMobileOS() && <AppStoreLink />}

          {OperatingSystems.OTHER === getMobileOS() && (
            <Box className="flex flex-row flex-wrap justify-center gap-4">
              <GooglePlayLink />
              <AppStoreLink />
            </Box>
          )}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default App;
