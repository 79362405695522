import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';

const AccountHasStatementsQuery = loader(
  '../../graphql/queries/AccountHasStatements.graphql'
);
const useAccountHasStatements = () => {
  const { userTenancies } = useContext(AppContext);
  const [statementsEnabled, setStatementsEnabled] = useState<boolean>(false);
  const [executeQuery] = useLazyQuery(AccountHasStatementsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => setStatementsEnabled(res?.me?.accountHasStatements),
  });

  useEffect(() => {
    executeQuery();
  }, [userTenancies.length, executeQuery]);

  return statementsEnabled;
};

export default useAccountHasStatements;
