import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Period } from '../models/Period';

dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);

class PeriodUtil {
  private static readonly format = 'YYYY/MM/DD';

  public static readonly periods: Period[] = [
    {
      key: 'thisWeek',
      startDate: dayjs().startOf('isoWeek').toDate(),
      endDate: dayjs().endOf('isoWeek').toDate(),
      label: {
        prefixKey: 'period_selector.this_week',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'week',
    },
    {
      key: 'thisMonth',
      startDate: dayjs().startOf('month').toDate(),
      endDate: dayjs().endOf('month').toDate(),
      label: {
        prefixKey: 'period_selector.this_month',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'month',
    },
    {
      key: 'thisYear',
      startDate: dayjs().startOf('year').toDate(),
      endDate: new Date(),
      label: {
        prefixKey: 'period_selector.this_year',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'year',
    },
    {
      key: 'prevWeek',
      startDate: dayjs().subtract(1, 'week').startOf('isoWeek').toDate(),
      endDate: dayjs().subtract(1, 'week').endOf('isoWeek').toDate(),
      label: {
        prefixKey: 'period_selector.last_week',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'week',
    },
    {
      key: 'prevMonth',
      startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
      endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
      label: {
        prefixKey: 'period_selector.last_month',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'month',
    },
    {
      key: 'prevYear',
      startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
      endDate: dayjs().subtract(1, 'year').endOf('year').toDate(),
      label: {
        prefixKey: 'period_selector.last_year',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'year',
    },
    {
      key: 'last30Days',
      startDate: dayjs().subtract(30, 'day').toDate(),
      endDate: new Date(),
      label: {
        prefixKey: 'period_selector.last_30_days',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'day',
    },
    {
      key: 'last365Days',
      startDate: dayjs().subtract(365, 'day').toDate(),
      endDate: new Date(),
      label: {
        prefixKey: 'period_selector.last_365_days',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'day',
    },
    {
      key: 'sinceMovingIn',
      startDate: null,
      endDate: null,
      label: {
        prefixKey: 'period_selector.since_moving_in',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'day',
    },
    {
      key: 'custom',
      startDate: null,
      endDate: null,
      label: {
        prefixKey: 'period_selector.custom_period',
        prefix: null,
        dateRange: null,
      },
      incrementation: 'day',
    },
  ];
}

export default PeriodUtil;
