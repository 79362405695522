import { AppBrand, BRAND } from '../config';

const APP_NAME: Record<AppBrand, { en: string; da: string; nb: string }> = {
  TECHEM_DENMARK: {
    en: 'Techem Tenancy App',
    da: 'Techem Beboer App',
    nb: 'Techem Beboer App',
  },
  TECHEM_NORWAY: {
    en: 'Techem Tenancy App',
    da: 'Techem Beboer App',
    nb: 'Techem Beboer App',
  },
  PALLE_MORCH: {
    en: 'Palle Mørch Tenancy App',
    da: 'Palle Mørch Beboer App',
    nb: 'Palle Mørch Beboer App',
  },
};

const APP_STORE_LINKS: Record<
  AppBrand,
  { appStore: string; playStore: string }
> = {
  TECHEM_DENMARK: {
    appStore: 'https://apps.apple.com/dk/app/forbrugsm%C3%A5l/id997730273?',
    playStore: 'https://play.google.com/store/apps/details?id=dk.techem.app',
  },
  TECHEM_NORWAY: {
    appStore: 'https://apps.apple.com/us/app/beboer-app/id1542811424',
    playStore: 'https://play.google.com/store/apps/details?id=no.techem.tenant',
  },
  PALLE_MORCH: {
    appStore: 'https://apps.apple.com/dk/app/metrius/id1006953000',
    playStore: 'https://play.google.com/store/apps/details?id=dk.metrius.app',
  },
};

export const getAppName = (lang: string): string => {
  const appName = APP_NAME[BRAND][lang];

  return appName ? appName : 'Beboer App';
};

export const getNativeAppLinks = () => {
  return APP_STORE_LINKS[BRAND];
};
