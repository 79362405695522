import { useTranslation } from 'react-i18next';

import { Box, Button, Modal, Switch, Text } from '../../ui';
import { useState } from 'react';

const DeleteUserModal = ({ show, onClose }) => {
  const { t } = useTranslation();
  const [doDelete, setDoDelete] = useState(false);

  return (
    <Modal
      title={t('profile.delete_account')}
      titleVariant="h1"
      show={show}
      onHide={onClose}
      maxWidth="484"
      variant="stretch"
    >
      <Modal.FormWrapper>
        <Modal.Body>
          <Text>{t('profile.delete_account_message')}</Text>
          <Box className="flex justify-between mt-2">
            <Text variant="bold">{t('profile.delete_account_confirm')}</Text>
            <Switch
              name="Email notifications"
              checked={doDelete}
              onChange={(event) => setDoDelete(event.target.checked)}
            />
          </Box>

          <Box className="xl:hidden mt-4">
            <Button
              disabled={!doDelete}
              onClick={() => onClose(doDelete)}
              fullWidth
              variant="danger"
            >
              {t('profile.delete_account')}
            </Button>
          </Box>
        </Modal.Body>

        <Box className="hidden xl:block">
          <Modal.Footer>
            <Button
              disabled={!doDelete}
              onClick={() => onClose(doDelete)}
              variant="danger"
            >
              {t('profile.delete_account')}
            </Button>
          </Modal.Footer>
        </Box>
      </Modal.FormWrapper>
    </Modal>
  );
};

export default DeleteUserModal;
