import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { APP_LANGUAGES, DEFAULT_LANG } from '../config';
import { LANG_IDENTIFIER } from '../helpers/auth';
import daTranslation from './da/translation.json';
import enTranslation from './en/translation.json';
import nbTranslation from './nb/translation.json';

i18n.use(initReactI18next).init({
  lng: localStorage.getItem(LANG_IDENTIFIER) || DEFAULT_LANG || 'en',
  resources: {
    en: {
      translation: enTranslation,
    },
    da: {
      translation: daTranslation,
    },
    nb: {
      translation: nbTranslation,
    },
  },
  preload: ['en', 'da', 'nb'],
  fallbackLng: 'en',
  supportedLngs: ['en', 'da', 'nb'],

  react: {
    bindI18n: 'languageChanged',
    useSuspense: true,
  },
});

export default i18n;

export const enabledLanguages = APP_LANGUAGES || ['en', 'da'];
