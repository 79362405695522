import React, { Suspense } from 'react';
import { BRAND } from '../config';

const TechemIcon = React.lazy(
  () => import('../assets/company-logos/TechemWhite')
);
const PalleMorchIcon = React.lazy(
  () => import('../assets/company-logos/PallemorchWhite')
);

export type CompanyIconProps = {
  className: string;
};

const CompanyIcon: React.FC<CompanyIconProps> = ({ className }) => {
  if (BRAND === 'TECHEM_DENMARK' || BRAND === 'TECHEM_NORWAY') {
    return (
      <Suspense fallback={<div></div>}>
        <div>
          <TechemIcon className={className} />
        </div>
      </Suspense>
    );
  } else if (BRAND === 'PALLE_MORCH') {
    return (
      <Suspense fallback={<div></div>}>
        <div>
          <PalleMorchIcon className={className} />
        </div>
      </Suspense>
    );
  } else {
    return <></>;
  }
};

export default CompanyIcon;
