import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import tw, { css, styled } from 'twin.macro';
import { IconMapping } from '../lib/icons';
import { mq } from '../lib/twinMacro';
import { Backdrop, Box, Icon, Text } from '../ui';

interface IModalComposition {
  Header: typeof Header;
  Body: typeof Body;
  FormWrapper: typeof FormWrapper;
  Footer: typeof Footer;
}

export type ModalProps = {
  title?: string;
  titleIcon?: keyof typeof IconMapping;
  subtitle?: string;
  titleVariant?: 'h1' | 'h2';
  maxWidth?: '484' | '706';
  onHide?: Function;
  hiddenOnPrint?: boolean;
  show?: boolean;
  zIndex?: '90' | '100';
  variant?: 'panel' | 'stretch';
};

type ModalVariantProps = {
  variant?: 'panel' | 'stretch';
};

type ModalBodyProps = {
  breakout?: boolean;
};

type ModalFooterProps = {
  bottom?: boolean;
  cols?: '1' | '2';
  pb?: boolean;
};

const Modal: IModalComposition & React.FC<ModalProps & ModalVariantProps> = ({
  title,
  titleIcon,
  subtitle,
  titleVariant = 'h2',
  hiddenOnPrint = false,
  onHide,
  show,
  variant,
  children,
  ...props
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    enableBodyScroll(document.getElementById('modal'));

    return () => clearAllBodyScrollLocks();
  }, []);

  useEffect(() => {
    if (show) {
      disableBodyScroll(document.getElementById('modal'));
    } else {
      enableBodyScroll(document.getElementById('modal'));
    }
  });

  return show
    ? createPortal(
        <Box
          as="section"
          ref={modalRef}
          className={hiddenOnPrint && 'print:hidden'}
        >
          <Container variant={variant} {...props}>
            <Inner variant={variant}>
              {(title || subtitle || onHide) && (
                <Header variant={variant}>
                  <Box className="space-y-3">
                    {(title || onHide) && (
                      <Box className="flex items-center justify-between || -mb-1">
                        {titleIcon && (
                          <Box className="mr-2">
                            <Icon name={titleIcon} size="8" />
                          </Box>
                        )}
                        <Text
                          as="h2"
                          variant={titleVariant}
                          className="w-full xl:relative"
                        >
                          {title}

                          {onHide && (
                            <Box
                              onClick={() => onHide()}
                              className="absolute top-4 right-4 xl:inset-y-0 xl:-right-2 cursor-pointer"
                            >
                              <Icon name="close" textColor="text-gray-900" />
                            </Box>
                          )}
                        </Text>
                      </Box>
                    )}

                    {subtitle && <Text color="300">{subtitle}</Text>}
                  </Box>
                </Header>
              )}

              {children}
            </Inner>
          </Container>

          <Box
            className={`${
              variant === 'stretch' ? 'hidden xl:block' : 'block'
            } ${hiddenOnPrint && 'print:hidden'}`}
          >
            <Backdrop active onClick={() => onHide()} />
          </Box>
        </Box>,
        document.getElementById('modal')
      )
    : null;
};

const Container = styled.section<ModalVariantProps & { maxWidth?: string }>(
  ({ maxWidth, variant }) => [
    tw`fixed z-90 inset-x-4 m-auto`,
    tw`bottom-auto top-1/2 transform -translate-y-1/2`,

    css`
      max-width: 414px;
    `,
    maxWidth === '484' &&
      css`
        max-width: 484px;
      `,
    maxWidth === '706' &&
      css`
        max-width: 706px;
      `,

    variant === 'panel' && [
      tw`inset-x-0 -bottom-4 top-auto max-h-full transform-none`,
      tw`sm:bottom-auto sm:top-1/2 sm:transform sm:-translate-y-1/2`,
    ],

    variant === 'stretch' && [
      tw`inset-x-0 top-0 transform-none h-full`,
      tw`xl:h-auto`,
      tw`xl:bottom-auto xl:top-1/2 xl:transform xl:-translate-y-1/2`,

      css`
        max-width: none;
        ${mq('xl')} {
          max-width: 706px;
        }
      `,

      maxWidth === '484' &&
        css`
          ${mq('xl')} {
            max-width: 484px;
          }
        `,

      maxWidth === '706' &&
        css`
          ${mq('xl')} {
            max-width: 706px;
          }
        `,
    ],
  ]
);

const Inner = styled.section<ModalVariantProps>(({ variant }) => [
  tw`py-6 xl:py-8 xl:pb-6 shadow-md mb-4 sm:mb-0 rounded sm:rounded-b bg-white`,

  variant === 'panel' && [tw`rounded-b-none pb-0`],

  variant === 'stretch' && [
    tw`w-full h-full xl:w-auto xl:h-auto rounded-none xl:rounded`,
  ],
]);

const Header = styled.header<ModalVariantProps>(({ variant }) => [
  tw`pb-6 px-6 xl:px-8`,

  variant === 'stretch' && [
    tw`pt-48 sm:pt-20 xl:pt-0 text-center xl:text-left`,
  ],
]);

const Body = styled.section<ModalBodyProps>(({ breakout }) => [
  tw`pb-2 px-6 xl:px-8 overflow-y-auto`,

  css`
    max-height: 80vh;
    ${mq('sm')} {
      max-height: 96vh;
    }
  `,

  breakout && tw`-mx-6 xl:-mx-8`,
]);

const Footer = styled.footer<ModalFooterProps>(({ bottom, cols, pb }) => [
  tw`grid grid-cols-1 gap-3`,
  tw`px-6 xl:px-8`,
  tw`mt-4 xl:mt-6`,
  tw`xl:flex xl:justify-end xl:gap-2`,
  tw`xl:pt-6 xl:px-8 xl:border-t border-gray-100`,

  bottom && tw`absolute inset-x-0 bottom-0 xl:static`,

  cols === '1' && tw`grid-cols-1`,
  cols === '2' && tw`grid-cols-2`,

  pb && tw`pb-6 xl:pb-0`,
]);

const FormWrapper = styled.div(() => [tw`max-w-xs xl:max-w-full mx-auto`]);

Modal.Header = Header;
Modal.Body = Body;
Modal.FormWrapper = FormWrapper;
Modal.Footer = Footer;

export default Modal;
