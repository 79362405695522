import { getNativeAppLinks } from '../helpers/brandUtil';
import { Illustration } from '../ui';

export const GooglePlayLink = () => {
  const { playStore } = getNativeAppLinks();

  return (
    <a href={playStore}>
      <Illustration illustration="googlePlay" />
    </a>
  );
};

export const AppStoreLink = () => {
  const { appStore } = getNativeAppLinks();

  return (
    <a href={appStore}>
      <Illustration illustration="appStore" />
    </a>
  );
};
