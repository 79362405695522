import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LANG_IDENTIFIER } from '../helpers/auth';
import { enabledLanguages } from '../i18n/config';
import { IconMapping } from '../lib/icons';

import { UserLanguage } from '../models/User';

import { Box, Icon, Modal, LanguageList, Text } from '../ui';

const options = [
  { i18nKey: 'profile.english', icon: 'flagEnglish', value: 'en' },
  { i18nKey: 'profile.danish', icon: 'flagDanish', value: 'da' },
  { i18nKey: 'profile.norwegian', icon: 'flagNorwegian', value: 'nb' },
].filter((o) => enabledLanguages.includes(o.value));

const LanguageSelect = ({ toggleColor = 'text-gray-500' }) => {
  const { t, i18n } = useTranslation();

  const [langVisible, setLangVisible] = useState(false);

  const onLanguageChange = (language: UserLanguage) => {
    setLangVisible(false);
    if (language) {
      i18n.changeLanguage(language);
      localStorage.setItem(LANG_IDENTIFIER, language);
    }
  };

  const currLang = options.find((o) => o.value === i18n.language);

  return (
    <Box>
      <Box
        as="button"
        onClick={() => setLangVisible(true)}
        className="h-8 xl:h-9 border border-gray-200 rounded"
      >
        <Box className="flex items-center pl-1.5 pr-1 space-x-1">
          <Icon name={currLang?.icon as keyof typeof IconMapping} />

          <Box className={`hidden xl:block pl-1 leading-16px  ${toggleColor}`}>
            {t(currLang?.i18nKey)}
          </Box>

          <Icon name="dropdownInactive" />
        </Box>
      </Box>

      <Modal
        title={t('global.select_a_language')}
        show={langVisible}
        onHide={onLanguageChange}
        variant="panel"
      >
        <Modal.Body breakout>
          <LanguageList>
            <LanguageList.List visible={true}>
              {options.map((o) => {
                return (
                  <LanguageList.Item
                    key={o.value}
                    active={i18n.language === o.value}
                    onClick={() => onLanguageChange(o.value as UserLanguage)}
                  >
                    <Box className="flex items-center space-x-3">
                      <Icon name={o.icon as keyof typeof IconMapping} />
                      <Text variant="bold">{t(o.i18nKey)}</Text>
                    </Box>
                  </LanguageList.Item>
                );
              })}
            </LanguageList.List>
          </LanguageList>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default LanguageSelect;
