import tw, { styled, css } from 'twin.macro';
import { mq } from '../lib/twinMacro';

const PageFooter: React.FC = ({ children }) => {
  return (
    <Container>
      <ButtonGroup>{children}</ButtonGroup>
    </Container>
  );
};

const Container = styled.section(() => [
  tw`fixed inset-x-0 bottom-0 flex p-4 xl:border-t border-gray-100 bg-white w-full`,
  css`
    ${mq('xl')} {
      margin-left: var(--width-menu);
    }
  `,
]);

const ButtonGroup = styled.div(() => [
  tw`flex w-full sm:justify-end space-x-2`,
  css`
    ${mq('xl')} {
      width: calc(100% - var(--width-menu));
    }
  `,
]);

export default PageFooter;
