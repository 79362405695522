import * as React from 'react';

function SvgNoAllocations(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 235 178"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M197.64 117.13c3.38-7.57.8-21.93-.89-28.15-11.45-29.92-36.33-44.81-60.17-55.46-23.84-10.64-84.97-9.35-97.57 16.98-12.6 26.34 6.27 52.84 23.19 74.27 16.92 21.43 38.16 11.15 67.14-5.34 24.45-13.9 57.5 21.89 68.3-2.3z"
      />
      <circle
        cx={109.5}
        cy={85.5}
        r={44.5}
        fill="#fff"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={1.2}
      />
      <path
        fill="#fff"
        d="M93.1 115.68l31.41-3.5a2.87 2.87 0 002.54-3.17L123.3 75.3a2.87 2.87 0 00-2.15-2.46l-21.87-5.54-11.42 1.27a2.87 2.87 0 00-2.54 3.17l4.6 41.41a2.87 2.87 0 003.18 2.54z"
      />
      <path
        fill="#D6D6D6"
        d="M93.1 115.68l31.41-3.5a2.87 2.87 0 002.54-3.17L123.3 75.3a2.87 2.87 0 00-2.15-2.46l-21.87-5.54-5.68.65-3.57.4-2.16.23a2.87 2.87 0 00-2.55 3.18l4.6 41.4a2.87 2.87 0 003.18 2.53z"
      />
      <path
        fill="#fff"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.2}
        d="M96.38 61.87a2.27 2.27 0 012.27-2.27h24.67c.6 0 1.18.24 1.6.67l6.95 6.93c.42.43.66 1 .66 1.61v34.73a2.27 2.27 0 01-2.27 2.27H98.65a2.27 2.27 0 01-2.27-2.27V61.87z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.2}
        d="M123.8 59.72v5.74a2.87 2.87 0 002.87 2.88h3.23M102.24 74.8h24.43M102.24 81.99h24.43M102.24 89.17h24.43M102.24 96.36h12.93"
      />
      <circle
        cx={187.5}
        cy={98.5}
        r={16.5}
        fill="#fff"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={1.2}
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M188.2 89.6a.6.6 0 00-1.2 0v10.55l-3.98-3.97a.6.6 0 10-.84.84l5 5a.6.6 0 00.84 0l5-5a.6.6 0 10-.84-.84l-3.98 3.97V89.6zm-9.6 11.4c.33 0 .6.27.6.6v4c0 .34.13.69.42.98.3.29.64.42.98.42h14c.34 0 .69-.13.98-.42.29-.3.42-.64.42-.98v-4a.6.6 0 011.2 0v4c0 .66-.27 1.32-.78 1.82-.5.51-1.16.78-1.82.78h-14c-.66 0-1.31-.27-1.82-.78-.51-.5-.78-1.16-.78-1.82v-4c0-.33.27-.6.6-.6z"
        clipRule="evenodd"
      />
      <path
        fill="#D6D6D6"
        d="M150.8 72.6c10.95 0 19.8-8.85 19.8-19.8 0-10.95-8.85-19.8-19.8-19.8A19.78 19.78 0 00131 52.8c0 10.85 8.85 19.8 19.8 19.8z"
      />
      <path
        fill="#fff"
        d="M152.6 46.04h-2.56l-3.24 2v2.48l2.95-1.9h.1v10.37h2.76V46.04z"
      />
    </svg>
  );
}

const MemoSvgNoAllocations = React.memo(SvgNoAllocations);
export default MemoSvgNoAllocations;
