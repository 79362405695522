import tw, { styled, css } from 'twin.macro';

const Badge = styled.span(() => [
  css`
    position: absolute;
    top: -5px;
    left: 13px;
    padding: 1px 4px;
    line-height: 12px;
    max-height: 14px;
    min-width: 16px;
  `,
  tw`bg-red-500 text-white text-xss text-center font-bold rounded ring-2 ring-secondary-500 xl:ring-white`,
]);

export default Badge;
