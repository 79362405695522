import { useQuery, useReactiveVar } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { selectedTenantId } from '../../lib/apolloClient';
const HeaderMainQuery = loader('../../graphql/queries/HeaderMain.graphql');

export const usePageHeaderData = () => {
  const tenancyId = useReactiveVar(selectedTenantId);
  const [pageName, setPageName] = useState();
  const location = useLocation();
  const [notificationCount, setNotificationCount] = useState(0);
  useQuery(HeaderMainQuery, {
    variables: { tenancyId },
    pollInterval: 1000 * 60,
    displayName: 'notifications',
    onCompleted: (data) => {
      if (data) {
        setPageName(data.tenancy.apartment.address);
        setNotificationCount(data.notifications.length);
      }
    },
  });

  return { notificationCount, pageName, isRoot: location.pathname === '/' };
};
