import * as React from 'react';

function SvgNoAlarms(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 235 178"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M197.639 117.129c3.381-7.572.794-21.93-.887-28.147-11.448-29.92-36.333-44.816-60.172-55.46-23.839-10.645-84.967-9.348-97.565 16.983-12.598 26.33 6.263 52.833 23.183 74.264 16.92 21.431 38.161 11.153 67.138-5.341 24.451-13.89 57.501 21.891 68.303-2.299z"
      />
      <circle
        cx={117.5}
        cy={85.5}
        r={44.5}
        fill="#fff"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={1.2}
      />
      <path
        fill="#D6D6D6"
        d="M135.03 76.06a16.184 16.184 0 00-16.182-16.181 16.183 16.183 0 00-16.182 16.182c0 10.408-4.098 16.718-7.777 20.284-1.066 1.034-.19 3.988 1.295 3.988h45.329c1.485 0 2.36-2.954 1.294-3.988-3.678-3.566-7.777-9.876-7.777-20.284z"
      />
      <path
        stroke="#D6D6D6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5.394}
        d="M123.514 105.727a5.397 5.397 0 01-7.358 1.967 5.394 5.394 0 01-1.973-1.967"
      />
      <path
        fill="#D6D6D6"
        d="M158.8 72.6c10.947 0 19.8-8.853 19.8-19.8 0-10.947-8.853-19.8-19.8-19.8-10.947 0-19.8 8.853-19.8 19.8 0 10.852 8.853 19.8 19.8 19.8z"
      />
      <path
        fill="#fff"
        d="M160.608 46.042h-2.57l-3.236 1.999v2.475l2.95-1.904h.096v10.376h2.76V46.042z"
      />
    </svg>
  );
}

const MemoSvgNoAlarms = React.memo(SvgNoAlarms);
export default MemoSvgNoAlarms;
