import * as React from 'react';

function SvgStepperInactive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={8}
        cy={8}
        r={7}
        fill="white"
        stroke="#D6D6D6"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgStepperInactive = React.memo(SvgStepperInactive);
export default MemoSvgStepperInactive;
