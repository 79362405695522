import * as React from 'react';

function SvgDanish(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="currentColor"
        stroke="#F5F5F5"
        strokeWidth={0.5}
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={28}
        height={20}
      >
        <rect
          x={0.25}
          y={0.25}
          width={27.5}
          height={19.5}
          rx={1.75}
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={0.5}
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V8H8V0H2ZM12 0V8H28V2C28 0.895431 27.1046 0 26 0H12ZM28 12H12V20H26C27.1046 20 28 19.1046 28 18V12ZM8 20V12H0V18C0 19.1046 0.895431 20 2 20H8Z"
          fill="#EF264D"
        />
      </g>
    </svg>
  );
}

const MemoSvgDanish = React.memo(SvgDanish);
export default MemoSvgDanish;
