import * as React from 'react';

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3335 1.99998C11.5086 1.82488 11.7165 1.68599 11.9452 1.59123C12.174 1.49647 12.4192 1.44769 12.6668 1.44769C12.9145 1.44769 13.1596 1.49647 13.3884 1.59123C13.6172 1.68599 13.8251 1.82488 14.0002 1.99998C14.1753 2.17507 14.3142 2.38294 14.4089 2.61172C14.5037 2.84049 14.5524 3.08569 14.5524 3.33331C14.5524 3.58093 14.5037 3.82613 14.4089 4.05491C14.3142 4.28368 14.1753 4.49155 14.0002 4.66665L5.00016 13.6666L1.3335 14.6666L2.3335 11L11.3335 1.99998Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoSvgEdit = React.memo(SvgEdit);
export default MemoSvgEdit;
