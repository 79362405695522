import * as React from 'react';

function SvgWeekly(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C8.17157 2 7.5 2.67157 7.5 3.5V6.5C7.5 7.32843 8.17157 8 9 8C9.82843 8 10.5 7.32843 10.5 6.5V3.5C10.5 2.67157 9.82843 2 9 2ZM24 2C23.1716 2 22.5 2.67157 22.5 3.5V6.5C22.5 7.32843 23.1716 8 24 8C24.8284 8 25.5 7.32843 25.5 6.5V3.5C25.5 2.67157 24.8284 2 24 2ZM6.5 4H4.5C3.39543 4 2.5 4.89543 2.5 6V27C2.5 28.6569 3.84315 30 5.5 30H27.5C29.1569 30 30.5 28.6569 30.5 27V6C30.5 4.89543 29.6046 4 28.5 4H26.5V6.5C26.5 7.88071 25.3807 9 24 9C22.6193 9 21.5 7.88071 21.5 6.5V4H11.5V6.5C11.5 7.88071 10.3807 9 9 9C7.61929 9 6.5 7.88071 6.5 6.5V4ZM29 11.5H4V26.5C4 27.6046 4.89543 28.5 6 28.5H27C28.1046 28.5 29 27.6046 29 26.5V11.5ZM9 16.5C9.55228 16.5 10 16.9477 10 17.5V18.5C10 19.0523 9.55228 19.5 9 19.5H6C5.44772 19.5 5 19.0523 5 18.5V17.5C5 16.9477 5.44772 16.5 6 16.5H9ZM15 16.5C15.5523 16.5 16 16.9477 16 17.5V18.5C16 19.0523 15.5523 19.5 15 19.5H12C11.4477 19.5 11 19.0523 11 18.5V17.5C11 16.9477 11.4477 16.5 12 16.5H15ZM18 16.5C17.4477 16.5 17 16.9477 17 17.5V18.5C17 19.0523 17.4477 19.5 18 19.5H21C21.5523 19.5 22 19.0523 22 18.5V17.5C22 16.9477 21.5523 16.5 21 16.5H18ZM27 16.5C27.5523 16.5 28 16.9477 28 17.5V18.5C28 19.0523 27.5523 19.5 27 19.5H24C23.4477 19.5 23 19.0523 23 18.5V17.5C23 16.9477 23.4477 16.5 24 16.5H27Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-1}
          y1={2}
          x2={25.6}
          y2={30}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgWeekly = React.memo(SvgWeekly);
export default MemoSvgWeekly;
