import { useLocalStorage } from '@rehooks/local-storage';
import { Suspense, useContext, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AppContext } from '../../contexts/AppContext';
import { UNUSED_ACTIVATION_CODE_IDENTIFIER } from '../../helpers/auth';
import { useAuth } from '../../hooks/useAuth';
import paths from '../../lib/paths';
import { Spinner } from '../../ui';
import AddTenancyModal from '../AddTenancyModal';
import PageComponent from '../layout/Page';
import NoTenancies from '../NoTenancies';

const PrivateRoute: React.FC<
  RouteProps & { component: React.FC; noBreadcrumbs?: boolean }
> = ({ component: Component, noBreadcrumbs, ...rest }) => {
  const { tokenExpired, logout, isLoggingIn, unvalidatedActivationCode } =
    useAuth();
  const { setBreadcrumbs, userTenancies } = useContext(AppContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unusedActivationCode, _, deleteActivationCode] = useLocalStorage(
    UNUSED_ACTIVATION_CODE_IDENTIFIER
  );
  const [showAddTenancyModal, setShowAddNewtenancyModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (noBreadcrumbs) {
      setBreadcrumbs([]);
    }
  }, [setBreadcrumbs, noBreadcrumbs]);

  useEffect(
    () => setShowAddNewtenancyModal(!!unusedActivationCode),
    [unusedActivationCode]
  );

  if (tokenExpired && !isLoggingIn) {
    //console.log('Private route. Token expired. Redirecting.');
    logout();
    if (unvalidatedActivationCode || unusedActivationCode) {
      return <Redirect to={paths.sign_up().route} />;
    } else {
      return <Redirect to={paths.login().route} />;
    }
  }

  if (!userTenancies || userTenancies.length === 0) {
    return <NoTenancies />;
  }

  const handleUnusedCodeRejection = () => {
    deleteActivationCode();
    setShowAddNewtenancyModal(false);
  };

  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <PageComponent>
            <Suspense
              fallback={
                <div className="flex justify-center w-full">
                  <Spinner />
                </div>
              }
            >
              <Component {...props} />
            </Suspense>
          </PageComponent>
        )}
      />
      {showAddTenancyModal && (
        <AddTenancyModal
          show
          onClose={() => handleUnusedCodeRejection()}
          code={unusedActivationCode}
        />
      )}
    </>
  );
};

export default PrivateRoute;
