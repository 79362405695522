import React, { useMemo } from 'react';
import { IconMapping } from '../lib/icons';
import SvgWrapper from './SvgWrapper';

export type IconProps = {
  name: keyof typeof IconMapping;
  size?: string;
  textColor?: string;
  className?: string;
};

const Icon: React.FC<IconProps> = ({
  name,
  size,
  textColor = 'text-gray-25',
  className,
  ...props
}) => {
  return useMemo(() => {
    const IconComponent = IconMapping[name];

    if (IconComponent) {
      const styles = `${textColor} ${className}`;

      return (
        //@ts-ignore
        <SvgWrapper size={size}>
          <IconComponent className={styles} {...props} />
        </SvgWrapper>
      );
    } else {
      return null;
    }
  }, [name, size, textColor, className, props]);
};

export default Icon;
