import tw, { styled, css } from 'twin.macro';

interface ILoginComposition {
  Aside: React.FC;
  Form: React.FC;
  Image: React.FC;
  Lang: React.FC;
  Main: React.FC;
}

const Login: ILoginComposition & React.FC = ({ children }) => (
  <Container>{children}</Container>
);

const Container = styled.section(() => [
  tw`xl:grid xl:grid-cols-2 h-full overflow-auto`,
]);

const Aside = styled.aside(() => [
  tw`relative hidden xl:flex items-center justify-center bg-marine-50`,
]);

const Form = styled.section(() => [
  tw`mx-auto`,

  css`
    width: 343px;
  `,
]);

const Image = styled.section(() => [tw`p-4 w-full max-w-xl`]);

const Lang = styled.section(() => [tw`absolute top-8 right-8`]);

const Main = styled.section(() => [
  tw`px-4 overflow-x-hidden overflow-y-auto flex flex-col justify-center`,
]);

Login.Aside = Aside;
Login.Form = Form;
Login.Image = Image;
Login.Lang = Lang;
Login.Main = Main;

export default Login;
