import * as React from 'react';
import { theme } from 'twin.macro';

function SvgAlarms(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 343 260"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M300.105 211.209c34.973-23.031 24.276-51.117 22.446-62.893-14.741-57.192-57.12-89.269-98.467-113.33-41.347-24.06-125.827-49.123-181.699 12.917C.165 94.784 17.173 150.198 59.82 175.014c36.444 21.208 38.57 22.416 80.245 34.961 20.137 6.061 125.068 24.264 160.041 1.234z"
      />
      <path
        fill="#fff"
        d="M34 83.596C34 60.11 72.328 55.02 72.328 55.02c18.776-28.454 48.558-8.148 48.558-8.148s5.309-18.754 30.559-22.504c25.25-3.751 41.047 22.504 41.047 22.504C229.657 40.962 236 74.156 236 83.596c0 9.44-5.956 36.861-43.378 31.688 0 0-15.798 22.633-41.048 19.4-25.25-3.234-30.559-19.4-30.559-19.4s-29.782 17.589-48.557-7.114l-.054.006h-.001c-1.53.168-38.403-1.094-38.403-24.58z"
      />
      <rect width={40} height={40} x={122} y={66} fill="#D6D6D6" rx={3.937} />
      <rect
        width={38.8}
        height={38.8}
        x={116.6}
        y={60.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.4}
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        d="M130.51 66.045a.55.55 0 01.54-.445h12.66a.55.55 0 01.55.55v2.3a.55.55 0 01-.55.55H138.51V74.69h2.62c4.323 0 7.74 3.516 7.74 7.74v1.82a.55.55 0 01-.55.55h-5.39a.544.544 0 01-.55-.55v-1.82c0-.38-.195-.666-.401-.85a1.89 1.89 0 00-.659-.37l-.093-.03H127.04v2.51c0 1.824-1.553 3.382-3.375 3.39a.155.155 0 01-.065-.063V68.725a.128.128 0 01.028-.038.128.128 0 01.036-.027c1.822.008 3.376 1.566 3.376 3.39v2.65H136.26V69.01H131.06a.55.55 0 01-.55-.55v-2.415zm14.242 23.195a17.244 17.244 0 011.273-1.658c.224.226.777.871 1.291 1.654.273.417.524.858.705 1.28.185.428.279.798.279 1.084 0 1.572-1.109 2.72-2.28 2.72-1.171 0-2.28-1.148-2.28-2.72 0-.282.097-.649.287-1.079.187-.421.446-.862.725-1.28z"
      />
      <rect width={40} height={40} x={64} y={57} fill="#D6D6D6" rx={3.937} />
      <rect
        width={40}
        height={40}
        x={58}
        y={51}
        fill={illustrationPrimaryColor}
        rx={4}
      />
      <path
        fill="#FCDB9D"
        d="M82.733 58L70 74.904l6.609-1.796-3.078 11.249 13.186-17.248-6.984 1.062 3-10.17z"
      />
      <rect
        width={39.365}
        height={39.365}
        x={175}
        y={47}
        fill="#D6D6D6"
        rx={3.937}
      />
      <rect
        width={38.166}
        height={38.166}
        x={169.6}
        y={41.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.337}
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M180.853 48.873c-1.22 0-2.161.927-2.161 2.13v17.785c0 1.203.941 2.13 2.161 2.13 1.22 0 2.161-.927 2.161-2.13V51.004c0-1.203-.941-2.13-2.161-2.13zm5.186 0c-1.22 0-2.161.927-2.161 2.13v17.785c0 1.203.941 2.13 2.161 2.13 1.22 0 2.16-.927 2.16-2.13V51.004c0-1.203-.94-2.13-2.16-2.13zm5.186 0c-1.221 0-2.161.927-2.161 2.13v17.785c0 1.203.94 2.13 2.161 2.13 1.143 0 2.287-.902 2.16-2.155v-17.76c0-1.203-.94-2.13-2.16-2.13zm5.287 0c-1.22 0-2.161.927-2.161 2.13v17.785c0 1.203.941 2.13 2.161 2.13 1.22 0 2.161-.927 2.161-2.13V51.004c0-1.203-.941-2.13-2.161-2.13zm3.355 2.856c-.228 0-.432.2-.432.426v3.206c0 .226.204.426.432.426h.534c1.144 0 2.059-.902 2.059-2.029 0-1.127-.915-2.029-2.059-2.029h-.534zm0 11.724c-.228 0-.432.2-.432.426v3.206c0 .226.204.426.432.426h.534c1.144 0 2.059-.902 2.059-2.03 0-1.126-.915-2.028-2.059-2.028h-.534zm-24.53-11.724c-.229 0-.432.2-.432.426v3.206c0 .226.203.426.432.426h2.16c.229 0 .433-.2.433-.426v-3.206a.443.443 0 00-.433-.426h-2.16zm0 11.724c-.229 0-.432.2-.432.426v3.206c0 .226.203.426.432.426h2.16c.229 0 .433-.2.433-.426V63.88a.443.443 0 00-.433-.426h-2.16z"
        clipRule="evenodd"
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.09}
        d="M77.49 142.775H243.61a3.572 3.572 0 013.572 3.572v101.471a3.572 3.572 0 01-3.572 3.572H77.49a3.572 3.572 0 01-3.572-3.572V146.347c0-1.973 1.6-3.572 3.572-3.572z"
      />
      <path
        fill="#fff"
        d="M237.345 136H71.227a4.117 4.117 0 00-4.118 4.118v100.491a4.117 4.117 0 004.118 4.117h166.118a4.118 4.118 0 004.118-4.117V140.118a4.118 4.118 0 00-4.118-4.118z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.09}
        d="M237.345 136H71.227a4.117 4.117 0 00-4.118 4.118v100.491a4.117 4.117 0 004.118 4.117h166.118a4.118 4.118 0 004.118-4.117V140.118a4.118 4.118 0 00-4.118-4.118z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeDasharray="2.94 2.94"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.09}
        d="M75.698 219.445h157.927"
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M86.307 232.452a4.592 4.592 0 116.888 3.978.51.51 0 10.51.883 5.612 5.612 0 10-5.613 0 .51.51 0 10.512-.883 4.59 4.59 0 01-2.297-3.978zm6.314-1.657c.176-.108.383.086.287.269l-1.34 2.559a.205.205 0 01-.132.103l-1.304.326a.204.204 0 01-.253-.198v-1.27c0-.072.037-.138.097-.175l2.645-1.614zM125.692 232.452a4.592 4.592 0 116.888 3.978.51.51 0 10.51.883 5.611 5.611 0 00-2.806-10.473 5.611 5.611 0 00-2.807 10.473.511.511 0 00.511-.883 4.592 4.592 0 01-2.296-3.978zm6.313-1.657c.177-.108.383.086.287.269l-1.34 2.559a.206.206 0 01-.131.103l-1.304.326a.204.204 0 01-.253-.198v-1.27c0-.072.037-.138.097-.175l2.644-1.614zM164.753 232.452a4.592 4.592 0 116.888 3.978.51.51 0 10.51.883 5.611 5.611 0 00-2.806-10.473 5.611 5.611 0 00-2.806 10.473.51.51 0 10.51-.883 4.592 4.592 0 01-2.296-3.978zm6.313-1.657c.177-.108.383.086.287.269l-1.34 2.559a.206.206 0 01-.131.103l-1.304.326a.204.204 0 01-.253-.198v-1.27c0-.072.037-.138.097-.175l2.644-1.614zM203.814 232.452a4.592 4.592 0 116.888 3.978.51.51 0 10.511.883 5.611 5.611 0 00-2.807-10.473 5.612 5.612 0 00-2.806 10.473.51.51 0 10.511-.883 4.59 4.59 0 01-2.297-3.978zm6.313-1.657c.177-.108.384.086.288.269l-1.341 2.559a.203.203 0 01-.131.103l-1.304.326a.204.204 0 01-.253-.198v-1.27c0-.072.037-.138.098-.175l2.643-1.614z"
        clipRule="evenodd"
      />
      <rect
        width={6.359}
        height={49.962}
        x={87.427}
        y={164.16}
        fill={illustrationPrimaryColor}
        rx={3.179}
      />
      <rect
        width={6.359}
        height={38.153}
        x={126.791}
        y={175.969}
        fill={illustrationPrimaryColor}
        rx={3.179}
      />
      <rect
        width={6.359}
        height={60.863}
        x={166.458}
        y={153.26}
        fill={illustrationPrimaryColor}
        rx={3.179}
      />
      <rect
        width={6.359}
        height={44.511}
        x={205.519}
        y={169.611}
        fill={illustrationPrimaryColor}
        rx={3.179}
      />
      <path
        stroke="#F7A60A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.09}
        d="M52 191.412h238"
      />
      <circle
        cx={269.771}
        cy={191.057}
        r={14.989}
        fill={illustrationPrimaryColor}
      />
      <path
        fill="#FCDB9D"
        d="M275.675 187.878a5.45 5.45 0 10-10.9 0c0 3.506-1.381 5.631-2.62 6.832-.359.348-.064 1.344.436 1.344h15.268c.5 0 .795-.996.436-1.344-1.239-1.201-2.62-3.326-2.62-6.832z"
      />
      <path
        stroke="#FCDB9D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.817}
        d="M271.797 197.87a1.815 1.815 0 01-3.143 0"
      />
      <circle cx={217} cy={54} r={12} fill="#FCDB9D" />
      <path
        fill={illustrationPrimaryColor}
        d="M221.727 51.455a4.36 4.36 0 00-4.363-4.364A4.363 4.363 0 00213 51.455c0 3.026-1.285 4.767-2.328 5.682-.25.22-.068.863.265.863h12.853c.334 0 .516-.643.265-.863-1.042-.915-2.328-2.656-2.328-5.682z"
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M215.741 58.826a.727.727 0 01.994.264.722.722 0 00.629.362.73.73 0 00.629-.362.727.727 0 111.258.73 2.185 2.185 0 01-1.887 1.087 2.183 2.183 0 01-1.888-1.087.727.727 0 01.265-.994z"
        clipRule="evenodd"
      />
      <circle cx={118} cy={109} r={12} fill="#FCDB9D" />
      <path
        fill={illustrationPrimaryColor}
        d="M122.727 106.455a4.36 4.36 0 00-4.363-4.364 4.364 4.364 0 00-4.364 4.364c0 3.026-1.285 4.768-2.328 5.682-.25.22-.068.863.265.863h12.853c.334 0 .516-.643.265-.863-1.042-.914-2.328-2.656-2.328-5.682z"
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M116.741 113.826a.727.727 0 01.994.264.727.727 0 001.258 0 .726.726 0 111.258.73 2.18 2.18 0 01-2.976.795 2.184 2.184 0 01-.799-.795.727.727 0 01.265-.994z"
        clipRule="evenodd"
      />
      <circle cx={53} cy={94} r={12} fill="#FCDB9D" />
      <path
        fill={illustrationPrimaryColor}
        d="M57.727 91.454a4.364 4.364 0 00-8.727 0c0 3.027-1.285 4.769-2.328 5.683-.25.22-.069.863.265.863H59.79c.334 0 .516-.643.265-.863-1.042-.915-2.328-2.656-2.328-5.683z"
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M51.74 98.826a.727.727 0 01.995.264.726.726 0 001.258 0 .727.727 0 011.258.73 2.177 2.177 0 01-1.887 1.087 2.18 2.18 0 01-1.887-1.087.727.727 0 01.264-.994z"
        clipRule="evenodd"
      />
    </svg>
  );
}

const MemoSvgAlarms = React.memo(SvgAlarms);
export default MemoSvgAlarms;
