import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgElectricity(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.964 14.44H25L10.993 30.882l5.763-13.573H6.9l14.44-15.44-6.376 12.573zM4 16C4 9.373 9.373 4 16 4a1 1 0 100-2C8.268 2 2 8.268 2 16a13.99 13.99 0 006.46 11.799 1 1 0 101.08-1.685A11.99 11.99 0 014 16zM23.787 4.364a1 1 0 10-1.113 1.661A11.987 11.987 0 0128 16c0 6.627-5.373 12-12 12a1 1 0 100 2c7.732 0 14-6.268 14-14 0-4.851-2.468-9.125-6.213-11.636z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1={6.23}
          y1={3.867}
          x2={26.669}
          y2={20.113}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgElectricity = React.memo(SvgElectricity);
export default MemoSvgElectricity;
