import tw, { styled, css } from 'twin.macro';

import { IconMapping } from '../lib/icons';
import { Box, Text } from './';

export type PageHeaderProps = {
  title: string;
  subtitle?: string;
  icon?: keyof typeof IconMapping;
};

interface IPageHeaderComposition {
  ButtonGroup: React.FC;
}

const PageHeader: IPageHeaderComposition & React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <ContainerStyle className={`${children ? 'xl:-mt-2' : 'mt-0'} mb-4`}>
      <Box className="flex flex-col justify-center">
        {title && (
          <Text
            as="h2"
            variant="h2"
            className={`${subtitle ? 'mb-0.5' : 'mb-0'}`}
          >
            {title}
          </Text>
        )}

        {subtitle && (
          <Box className="xl:hidden">
            <Text variant="p2" display="inline" color="300">
              {subtitle}
            </Text>
          </Box>
        )}
      </Box>

      {children}
    </ContainerStyle>
  );
};

const ButtonGroup = ({ children }) => (
  <ButtonGroupContainerStyle>
    <ButtonGroupWrapperStyle>{children}</ButtonGroupWrapperStyle>
  </ButtonGroupContainerStyle>
);

export const ContainerStyle = styled.header(() => [
  tw`flex justify-between align-middle`,
]);

export const ButtonGroupContainerStyle = styled.div(() => [
  `button { min-width: 180px; }`,

  css`
    background-color: rgba(255, 255, 255, 0.7);
  `,

  tw`fixed bottom-0 left-4 right-4 xl:static flex justify-center space-y-4`,
  tw`py-4 sm:pt-6 xl:pt-0 xl:pb-0`,
]);

export const ButtonGroupWrapperStyle = styled.div(() => [
  tw`grid gap-2 xl:flex xl:items-center w-full max-w-sm`,
]);

PageHeader.ButtonGroup = ButtonGroup;

export default PageHeader;
