import { Box, Text } from './index';

export type HeaderProps = {
  title?: string;
  subtitle?: string;
  mb?: 'mb-0' | 'mb-4'
}

const Header: React.FC<HeaderProps> = ({ title, subtitle, mb, children }) => (
  <Box className={`flex items-center justify-between ${mb ? mb : 'mb-4'}`}>
    <Box className="space-y-1">
      {title && (
        <Text as="h2" variant="h2">
          {title}
        </Text>
      )}

      {subtitle && (
        <Text variant="p2" display="inline" color="300">
          {subtitle}
        </Text>
      )}
    </Box>

    {children && (
      <Box>
        {children}
      </Box>
    )}
  </Box>
);

export default Header;
