import tw, { styled } from 'twin.macro';
import { useRef } from 'react';
import { useScrollLock } from '../hooks/useScrollLock';
interface IMobileOnlyPanelComposition {
  Footer: typeof Footer;
}

const MobileOnlyPanel: IMobileOnlyPanelComposition & React.FC = ({
  children,
}) => {
  const ref = useRef(null);
  useScrollLock(ref);

  return <Container ref={ref}>{children}</Container>;
};

const Container = styled.section(() => [
  tw`fixed z-60 top-0 inset-x-0 w-full h-full py-6 px-4 bg-white`,
]);

const Footer = styled.section(() => [tw`fixed inset-x-0 bottom-14 px-4`]);

MobileOnlyPanel.Footer = Footer;

export default MobileOnlyPanel;
