import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgHeating(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id1 = uuidv4();
  const id2 = uuidv4();
  const id3 = uuidv4();
  const id4 = uuidv4();
  const id5 = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.84289 7.22222C5.84289 5.97676 6.80816 5 8.03895 5C9.26975 5 10.235 5.97676 10.235 7.22222H11.1135C11.1135 5.97676 12.0788 5 13.3096 5C14.5404 5 15.5056 5.97676 15.5056 7.22222H16.3841C16.3841 5.97676 17.3494 5 18.5802 5C19.811 5 20.7762 5.97676 20.7762 7.22222H21.7643C21.7643 5.97676 22.7296 5 23.9604 5C25.1912 5 26.1565 5.97676 26.1565 7.22222V25.7778C26.1565 27.0232 25.1912 28 23.9604 28C22.7296 28 21.7643 27.0232 21.7643 25.7778L20.7762 25.7546C20.9043 27.0695 19.753 28 18.5802 28C17.3494 28 16.3841 27.0232 16.3841 25.7778H15.5056C15.5056 27.0232 14.5404 28 13.3096 28C12.0788 28 11.1135 27.0232 11.1135 25.7778H10.235C10.235 27.0232 9.26975 28 8.03895 28C6.80816 28 5.84289 27.0232 5.84289 25.7778V7.22222Z"
        fill={`url(#${id1})`}
      />
      <path
        d="M26.9255 8.44444C26.9255 8.19898 27.1222 8 27.3647 8H27.9137C29.0659 8 30 8.94518 30 10.1111C30 11.277 29.0659 12.2222 27.9137 12.2222H27.3647C27.1222 12.2222 26.9255 12.0232 26.9255 11.7778V8.44444Z"
        fill={`url(#${id2})`}
      />
      <path
        d="M26.9255 20.6667C26.9255 20.4212 27.1222 20.2222 27.3647 20.2222H27.9137C29.0659 20.2222 30 21.1674 30 22.3333C30 23.4993 29.0659 24.4444 27.9137 24.4444H27.3647C27.1222 24.4444 26.9255 24.2455 26.9255 24V20.6667Z"
        fill={`url(#${id3})`}
      />
      <path
        d="M2 8.44444C2 8.19898 2.19664 8 2.43921 8H4.63527C4.87784 8 5.07449 8.19898 5.07449 8.44444V11.7778C5.07449 12.0232 4.87784 12.2222 4.63527 12.2222H2.43921C2.19664 12.2222 2 12.0232 2 11.7778V8.44444Z"
        fill={`url(#${id4})`}
      />
      <path
        d="M2.00027 20.6667C2.00027 20.4212 2.19691 20.2222 2.43948 20.2222H4.63554C4.87811 20.2222 5.07475 20.4212 5.07475 20.6667V24C5.07475 24.2455 4.87811 24.4444 4.63554 24.4444H2.43948C2.19691 24.4444 2.00027 24.2455 2.00027 24V20.6667Z"
        fill={`url(#${id5})`}
      />
      <defs>
        <linearGradient
          id={id1}
          x1={-1.5}
          y1={5}
          x2={19.7228}
          y2={32.1962}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
        <linearGradient
          id={id2}
          x1={-1.5}
          y1={5}
          x2={19.7228}
          y2={32.1962}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
        <linearGradient
          id={id3}
          x1={-1.5}
          y1={5}
          x2={19.7228}
          y2={32.1962}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
        <linearGradient
          id={id4}
          x1={-1.5}
          y1={5}
          x2={19.7228}
          y2={32.1962}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
        <linearGradient
          id={id5}
          x1={-1.5}
          y1={5}
          x2={19.7228}
          y2={32.1962}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgHeating = React.memo(SvgHeating);
export default MemoSvgHeating;
