import tw, { styled, css } from 'twin.macro';

export type TextProps = {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'p'
    | 'p2'
    | 'bold'
    | 'link'
    | 'reading'
    | 'headline'
    | 'caption1'
    | 'caption2'
    | 'label';
  display?: 'inline';
  color?:
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | 'black'
    | 'white'
    | 'inherit';
  uppercase?: boolean;
};

const Text = styled.p<TextProps>(({ variant, display, color, uppercase }) => [
  [
    tw`font-body text-gray-500`,
    css`
      line-height: 17px;
    `,
  ],

  variant === 'h1' && [
    tw`text-2xl font-display font-bold`,
    css`
      line-height: 29px;
    `,
  ],

  variant === 'h2' && [
    tw`text-xl font-display font-bold`,
    css`
      line-height: 22px;
    `,
  ],

  variant === 'h3' && [tw`text-base font-display font-bold leading-none`],

  variant === 'p' && [
    tw`text-base`,
    css`
      line-height: 24px;
    `,
  ],

  variant === 'p2' && [
    tw`text-sm`,
    css`
      line-height: 15px;
    `,
  ],

  variant === 'bold' && [
    tw`text-base font-bold`,
    css`
      line-height: 17px;
    `,
  ],

  variant === 'link' && [
    tw`text-base text-action-400 font-bold cursor-pointer`,
    css`
      line-height: 17px;
    `,
  ],

  variant === 'reading' && [
    tw`text-3xl font-bold`,
    css`
      line-height: 43px;
    `,
  ],

  variant === 'headline' && [
    tw`text-lg`,
    css`
      line-height: 15px;
    `,
  ],

  variant === 'caption1' && [
    tw`text-xs text-gray-400 font-bold inline`,
    css`
      line-height: 15px;
    `,
  ],

  variant === 'caption2' && [
    tw`text-xss text-gray-400 inline`,
    css`
      line-height: 11px;
    `,
  ],

  variant === 'label' && [
    tw`text-gray-400`,
    css`
      line-height: 11px;
    `,
  ],

  display === 'inline' && tw`inline`,

  color === '200' && tw`text-gray-200`,
  color === '300' && tw`text-gray-300`,
  color === '400' && tw`text-gray-400`,
  color === '500' && tw`text-gray-500`,
  color === '600' && tw`text-gray-600`,
  color === '700' && tw`text-gray-700`,
  color === '800' && tw`text-gray-800`,
  color === '900' && tw`text-gray-900`,
  color === 'black' && tw`text-black`,
  color === 'white' && tw`text-white`,
  color === 'inherit' && tw`text-current`,

  uppercase && [tw`uppercase`],
]);

export default Text;
