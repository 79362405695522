import * as React from 'react';
import { theme } from 'twin.macro';

function SvgAnalyse(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 343 260"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M297.1 215.2c34.98-23.02 24.28-51.1 22.45-62.88-14.74-57.2-57.12-89.27-98.47-113.33C179.74 14.93 95.26-10.14 39.38 51.9c-42.21 46.88-25.2 102.3 17.44 127.11 36.44 21.21 38.57 22.42 80.24 34.97 20.14 6.06 125.07 24.26 160.05 1.23z"
      />
      <path
        fill="#fff"
        d="M40 84.6c0-23.49 38.33-28.58 38.33-28.58 18.77-28.45 48.56-8.15 48.56-8.15s5.3-18.75 30.55-22.5 41.05 22.5 41.05 22.5C235.66 41.97 242 75.16 242 84.6c0 9.44-5.96 36.86-43.38 31.68 0 0-15.8 22.64-41.05 19.4-25.25-3.23-30.55-19.4-30.55-19.4s-29.79 17.6-48.56-7.11h-.06c-1.53.17-38.4-1.09-38.4-24.57z"
      />
      <rect width={40} height={40} x={128} y={67} fill="#D6D6D6" rx={3.94} />
      <rect
        width={38.8}
        height={38.8}
        x={122.6}
        y={61.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.4}
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        d="M136.51 67.05a.55.55 0 01.54-.45h12.66c.3 0 .55.25.55.55v2.3c0 .3-.25.55-.55.55H144.51V75.69h2.62a7.74 7.74 0 017.74 7.74v1.82c0 .3-.25.55-.55.55h-5.39a.54.54 0 01-.55-.55v-1.82c0-.38-.2-.67-.4-.85-.2-.18-.44-.3-.66-.37l-.1-.03h-14.18v2.51a3.45 3.45 0 01-3.38 3.39.16.16 0 01-.06-.06v-18.3l.03-.03a.13.13 0 01.03-.03 3.45 3.45 0 013.38 3.39v2.65H142.26V70.01H137.06a.55.55 0 01-.55-.55v-2.41zm14.24 23.19c.5-.74 1.03-1.38 1.28-1.66a12.23 12.23 0 012 2.94c.18.42.27.8.27 1.08 0 1.57-1.1 2.72-2.28 2.72-1.17 0-2.28-1.15-2.28-2.72 0-.28.1-.65.29-1.08.18-.42.44-.86.72-1.28z"
      />
      <rect width={40} height={40} x={70} y={58} fill="#D6D6D6" rx={3.94} />
      <rect
        width={40}
        height={40}
        x={64}
        y={52}
        fill={illustrationPrimaryColor}
        rx={4}
      />
      <path
        fill="#FCDB9D"
        d="M88.73 59L76 75.9l6.6-1.8-3.07 11.26L92.72 68.1l-6.99 1.06 3-10.17z"
      />
      <rect
        width={39.37}
        height={39.37}
        x={181}
        y={48}
        fill="#D6D6D6"
        rx={3.94}
      />
      <rect
        width={38.17}
        height={38.17}
        x={175.6}
        y={42.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.34}
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M186.85 49.87c-1.22 0-2.16.93-2.16 2.13V69.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V52c0-1.2-.94-2.13-2.16-2.13zm5.19 0c-1.22 0-2.16.93-2.16 2.13V69.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V52c0-1.2-.94-2.13-2.16-2.13zm5.18 0c-1.22 0-2.16.93-2.16 2.13V69.8c0 1.2.94 2.13 2.16 2.13 1.15 0 2.3-.9 2.16-2.16V52c0-1.2-.94-2.13-2.16-2.13zm5.3 0c-1.23 0-2.17.93-2.17 2.13V69.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V52c0-1.2-.94-2.13-2.16-2.13zm3.35 2.86c-.23 0-.44.2-.44.42v3.21c0 .23.2.43.44.43h.53c1.14 0 2.06-.9 2.06-2.03s-.92-2.03-2.06-2.03h-.53zm0 11.72c-.23 0-.44.2-.44.43v3.2c0 .23.2.43.44.43h.53c1.14 0 2.06-.9 2.06-2.03s-.92-2.03-2.06-2.03h-.53zm-24.53-11.72c-.23 0-.44.2-.44.42v3.21c0 .23.2.43.44.43h2.16c.23 0 .43-.2.43-.43v-3.2c0-.23-.2-.43-.43-.43h-2.16zm0 11.72c-.23 0-.44.2-.44.43v3.2c0 .23.2.43.44.43h2.16c.23 0 .43-.2.43-.42v-3.21c0-.23-.2-.43-.43-.43h-2.16z"
        clipRule="evenodd"
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.06}
        d="M128.05 147.8h161.6a3.48 3.48 0 013.48 3.48v98.71a3.48 3.48 0 01-3.47 3.48H128.05a3.48 3.48 0 01-3.48-3.48v-98.71a3.48 3.48 0 013.48-3.48z"
      />
      <path
        fill="#fff"
        d="M283.56 141.21h-161.6a4 4 0 00-4.01 4v97.77a4 4 0 004 4h161.62a4 4 0 004-4v-97.76a4 4 0 00-4-4.01z"
      />
      <path
        fill="#D6D6D6"
        d="M287.57 158.88H240.7a4 4 0 01-4-4V141.2h46.87a4 4 0 014 4v13.67z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M283.56 141.21h-161.6a4 4 0 00-4.01 4v97.77a4 4 0 004 4h161.62a4 4 0 004-4v-97.76a4 4 0 00-4-4.01z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeDasharray="2.86 2.86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M126.3 222.4h153.64"
      />
      <path
        fill="#fff"
        d="M221.64 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.73a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        stroke="#F7A60A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M221.64 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.73a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        fill="#fff"
        stroke="#F7A60A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M217.91 233.55a1.86 1.86 0 100-3.72 1.86 1.86 0 000 3.72z"
      />
      <path
        fill="#fff"
        d="M258.8 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.72a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M258.8 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.72a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M255.07 233.55a1.86 1.86 0 100-3.72 1.86 1.86 0 000 3.72z"
      />
      <path
        fill="#fff"
        d="M183.52 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.73a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M183.52 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.73a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M179.8 233.55a1.86 1.86 0 10-.01-3.72 1.86 1.86 0 000 3.72z"
      />
      <path
        fill="#fff"
        d="M144.45 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.73a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M144.45 238.21v-.93a1.87 1.87 0 00-1.86-1.86h-3.73a1.87 1.87 0 00-1.86 1.86v.93"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        d="M140.72 233.55a1.86 1.86 0 100-3.72 1.86 1.86 0 000 3.72z"
      />
      <rect
        width={6.19}
        height={48.61}
        x={137.72}
        y={168.61}
        fill={illustrationPrimaryColor}
        rx={3.09}
      />
      <rect
        width={6.19}
        height={37.12}
        x={176.01}
        y={180.09}
        fill={illustrationPrimaryColor}
        rx={3.09}
      />
      <rect
        width={6.19}
        height={59.21}
        x={214.6}
        y={158}
        fill={illustrationPrimaryColor}
        rx={3.09}
      />
      <circle cx={217.69} cy={161.09} r={2.21} fill="#F9B83B" />
      <circle cx={217.69} cy={166.4} r={2.21} fill="#F9B83B" />
      <circle cx={217.69} cy={171.7} r={2.21} fill="#F9B83B" />
      <circle cx={217.69} cy={177} r={2.21} fill="#F9B83B" />
      <circle cx={217.69} cy={182.31} r={2.21} fill="#F9B83B" />
      <rect
        width={6.19}
        height={43.3}
        x={252.6}
        y={173.91}
        fill={illustrationPrimaryColor}
        rx={3.09}
      />
      <path
        fill={illustrationPrimaryColor}
        d="M169.61 236.88h2.7c.27 0 .48.06.62.17.14.11.21.26.21.45a.6.6 0 01-.17.43c-.1.12-.28.18-.5.18h-3.82a.83.83 0 01-.6-.22.7.7 0 01-.22-.5c0-.13.05-.3.14-.5.1-.2.2-.36.3-.48.46-.48.88-.88 1.25-1.22.37-.34.63-.57.79-.68.28-.2.52-.4.7-.6.2-.2.34-.4.43-.62.1-.21.15-.42.15-.63 0-.22-.05-.41-.16-.59-.1-.17-.24-.3-.43-.4-.18-.1-.37-.15-.59-.15-.45 0-.8.2-1.06.6-.04.05-.1.19-.18.42-.08.23-.17.4-.27.53s-.25.19-.45.19a.58.58 0 01-.43-.17.63.63 0 01-.17-.47 2.16 2.16 0 011.44-1.96c.33-.13.72-.2 1.15-.2.53 0 .98.1 1.36.26a2.04 2.04 0 011.06 1.13 2.2 2.2 0 01-.17 1.96c-.2.34-.43.62-.65.82-.22.2-.59.5-1.1.93-.53.42-.88.75-1.08.99l-.25.33zM132.15 233.52c.32 0 .59-.09.82-.28a.97.97 0 00.34-.8c0-.26-.09-.5-.27-.68a.97.97 0 00-.74-.3c-.21 0-.39.04-.53.1a.82.82 0 00-.32.23c-.07.1-.15.22-.22.37l-.2.43a.36.36 0 01-.18.17.74.74 0 01-.31.06.55.55 0 01-.39-.16.6.6 0 01-.17-.45c0-.18.06-.37.16-.56.11-.2.27-.4.48-.58.21-.17.47-.32.78-.42a3.1 3.1 0 011.96-.03c.28.09.52.22.72.4a1.7 1.7 0 01.62 1.33c0 .34-.08.64-.23.89-.15.25-.37.48-.64.72.26.14.5.31.67.5a1.9 1.9 0 01.56 1.35 2.32 2.32 0 01-.74 1.7c-.25.22-.54.4-.88.53a3.09 3.09 0 01-2.22-.03c-.33-.14-.6-.33-.82-.55a2.67 2.67 0 01-.48-.7c-.1-.23-.16-.43-.16-.58 0-.2.06-.37.2-.49.12-.12.29-.18.48-.18.1 0 .19.03.28.09a.4.4 0 01.18.2c.17.48.37.83.57 1.07.2.23.5.35.87.35a1.3 1.3 0 001.3-1.35c0-.4-.1-.72-.33-.95a1.22 1.22 0 00-.92-.34c-.07 0-.17 0-.32.02a3.9 3.9 0 01-.28.02.64.64 0 01-.44-.14.52.52 0 01-.16-.4c0-.17.07-.3.2-.4.12-.1.3-.16.55-.16h.2zM246.24 237.4v-4.72c-.88.68-1.47 1.01-1.77 1.01a.53.53 0 01-.39-.17.57.57 0 01-.17-.4c0-.18.06-.3.17-.39.11-.08.3-.19.59-.32a4.17 4.17 0 001.67-1.35c.19-.27.31-.44.37-.5s.17-.1.33-.1c.19 0 .33.08.44.22.11.14.17.34.17.59v5.93c0 .69-.24 1.04-.71 1.04a.65.65 0 01-.5-.22c-.14-.14-.2-.35-.2-.62z"
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M217.36 152.33a10.4 10.4 0 00-10.1 10.7 10.2 10.2 0 005.35 8.8c.6.3.79 1 .5 1.6-.3.58-1 .78-1.6.49a12.48 12.48 0 01-6.63-10.8 12.72 12.72 0 1122.34 8.02l8.66 7.73c.4.4.4 1.09 0 1.58l-.1.1c-.4.5-1.1.6-1.49.2l-8.91-7.87c-.35.26-.71.51-1.09.74-.5.3-1.28.1-1.58-.4-.3-.5-.1-1.28.4-1.58a10.58 10.58 0 004.94-9.21 10.4 10.4 0 00-10.69-10.1z"
        clipRule="evenodd"
      />
      <rect
        width={88.38}
        height={31.82}
        x={229.63}
        y={120}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.06}
        rx={4.01}
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M238.64 135.37a6.2 6.2 0 119.29 5.37.69.69 0 00.69 1.19 7.57 7.57 0 10-7.57 0c.33.19.75.07.94-.25a.69.69 0 00-.25-.94 6.19 6.19 0 01-3.1-5.37zm8.51-2.23c.24-.15.52.11.4.36l-1.82 3.45a.28.28 0 01-.17.14l-1.76.44a.28.28 0 01-.34-.27v-1.71c0-.1.05-.19.13-.24l3.56-2.17z"
        clipRule="evenodd"
      />
      <path
        fill="#D6D6D6"
        d="M287.59 128.37h-27.6a2.76 2.76 0 100 5.52h27.6a2.76 2.76 0 100-5.52z"
      />
      <path
        fill={illustrationPrimaryColor}
        d="M310.49 142.1h-52.14a1.33 1.33 0 100 2.65h52.14a1.32 1.32 0 100-2.66z"
      />
    </svg>
  );
}

const MemoSvgAnalyse = React.memo(SvgAnalyse);
export default MemoSvgAnalyse;
