import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import {
  clearUserStorage,
  getAccessToken,
  getAuthType,
  getRefreshToken,
  HAS_ONBOARDED_IDENTIFIER,
  isTokenExpired,
  LANG_IDENTIFIER,
  reauthenticate,
  SELECTED_TENANCY_IDENTIFIER,
  setAccessToken,
  setAuthType,
  setRefreshToken,
  startAuthenticateTimer,
  stopAuthenticateTimer,
  UNUSED_ACTIVATION_CODE_IDENTIFIER,
  validateActivationCode,
} from '../helpers/auth';
import client, { selectedTenantId } from '../lib/apolloClient';
import paths from '../lib/paths';

const RevokeTokenMutation = loader('../graphql/mutations/revokeToken.graphql');

const SocialAuthMutation = loader('../graphql/mutations/socialAuth.graphql');

const EmailSignInMutation = loader('../graphql/mutations/tokenAuth.graphql');

const ImpersonateMutation = loader('../graphql/mutations/impersonate.graphql');

const AddTenancyMutation = loader('../graphql/mutations/addTenancy.graphql');

const CreateUserMutation = loader(
  '../graphql/mutations/createEmailPasswordUser.graphql'
);

const UserQuery = loader('../graphql/queries/User.graphql');

export const useAuth = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const intervalIdentifier = useRef(null);
  const { setCurrentUser, setUserTenancies } = useContext(AppContext);
  const [tokenExpired, setTokenExpired] = useState<boolean>(isTokenExpired());
  const unvalidatedActivationCode = useRef(
    new URLSearchParams(location.search).get('activationCode')
  );
  const [loginError, setLoginError] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  // Check every X milliseconds whether the token has expired or not.
  // If the token has expired, it means that we were not able to refresh it through apollo or timer mechanism.
  useEffect(() => {
    if (unvalidatedActivationCode.current) {
      validateActivationCode(unvalidatedActivationCode.current);
    }

    if (getAccessToken()) {
      startAuthenticateTimer();
    }

    intervalIdentifier.current = setInterval(async () => {
      if (isTokenExpired()) {
        // Token is expired. All other refresh mechanisms failed.
        // We try one last time to refresh the token.
        if (getAccessToken()) {
          await reauthenticate();
        }
        setTokenExpired(isTokenExpired());
      } else {
        setTokenExpired(false);
      }
    }, 2000);
    return () => clearInterval(intervalIdentifier.current);
  }, []);

  // Fetches basic user info. Called by emailLogin, socialLogin or impersonation
  // after receiving tokens.
  const [getUserInfoMutation] = useLazyQuery(UserQuery, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onError: () => toast.error('An error has occurred.'),
    onCompleted: (res) => {
      const { me: user, allTenanciesForCurrentUser: tenancies } = res;
      localStorage.setItem(LANG_IDENTIFIER, user.language);
      setUserTenancies(tenancies);
      setCurrentUser(user);
      selectedTenantId(tenancies[0]?.id);
      localStorage.setItem(
        SELECTED_TENANCY_IDENTIFIER,
        JSON.stringify(tenancies[0]?.id)
      );

      setSignUpSuccess(true);
      setIsSigningUp(false);
      setIsLoggingIn(false);

      startAuthenticateTimer();
    },
  });

  const [revokeTokenMutation] = useMutation(RevokeTokenMutation, {
    variables: { refreshToken: getRefreshToken() },
    onCompleted: () => clearUserStorage(),
  });

  const [addTenancyMutation] = useMutation(AddTenancyMutation, {
    variables: {
      activationCode: localStorage.getItem(UNUSED_ACTIVATION_CODE_IDENTIFIER),
    },
    fetchPolicy: 'no-cache',
    onCompleted: () =>
      localStorage.removeItem(UNUSED_ACTIVATION_CODE_IDENTIFIER),
  });

  const [socialSignInMutation] = useMutation(SocialAuthMutation, {
    onError: (e) => {
      if (e.message === 'Please enter valid credentials') {
        setLoginError(t('login.invalid_credentials'));
      }
      if (e.message === 'User already exists and has above default rights.') {
        setLoginError(t('errors.user_exists_with_above_default_rights'));
      }
      logout({ doRedirect: false });
    },
    onCompleted: async (res) => {
      if (res.socialAuth.token) {
        const { token } = res.socialAuth;
        await handleLogin(token);
      }
    },
  });

  const [emailSignInMutation] = useMutation(EmailSignInMutation, {
    onError: (e) => {
      if (e.message === 'Please enter valid credentials') {
        setLoginError(t('login.invalid_credentials'));
      }
      if (e.message === 'User already exists and has above default rights.') {
        setLoginError(t('errors.user_exists_with_above_default_rights'));
      }
      logout({ doRedirect: false });
      setIsLoggingIn(false);
    },
    onCompleted: async (data) => {
      if (data && data.tokenAuth) {
        const { token, refreshToken } = data.tokenAuth;
        await handleLogin(token, refreshToken);
        setIsLoggingIn(false);
      }
    },
  });

  const [impersonateMutation] = useMutation(ImpersonateMutation, {
    onError: (e) => {
      toast.error('An error has occurred.');
      logout({ doRedirect: true });
    },
    onCompleted: async (res) => {
      if (res.hasOwnProperty('impersonate')) {
        localStorage.setItem(HAS_ONBOARDED_IDENTIFIER, 'true');
        const { token, refreshToken } = res.impersonate;
        await handleLogin(token, refreshToken);
      }
    },
  });

  const [signUpNewUserMutation] = useMutation(CreateUserMutation, {
    onCompleted: async (res) => {
      if (res.hasOwnProperty('createEmailPasswordUser')) {
        const { token, refreshToken } = res.createEmailPasswordUser;
        await handleLogin(token, refreshToken);
      }
    },
    onError: (res) => {
      if (
        res.message ===
        'A user already exists with that email, but the password is not correct.'
      ) {
        toast.error(t('errors.email_in_use') as string, { duration: 6000 });
      }
    },
  });

  // Perform a login using email and password.
  const emailLogin = useCallback(
    (email, password) => {
      clearUserStorage();
      setIsLoggingIn(true);
      setAuthType('email');
      emailSignInMutation({ variables: { email, password } });
    },
    [emailSignInMutation]
  );

  // Perform login using a social provider (Apple, Facebook or Google).
  const socialLogin = useCallback(
    (provider: 'facebook' | 'apple-id', accessToken: string) => {
      clearUserStorage();
      setIsLoggingIn(true);
      setAuthType(provider);
      setRefreshToken(accessToken);
      socialSignInMutation({
        variables: { socialProvider: provider, accessToken },
      });
    },
    [socialSignInMutation]
  );

  // Perform login by impersonation.
  const loginByImpersonation = useCallback(
    (accessToken) => {
      clearUserStorage();
      setIsLoggingIn(true);
      setCurrentUser(null);
      setUserTenancies([]);
      setAuthType('email'); // The behaviour is the same as for sign in email.
      impersonateMutation({ variables: { token: accessToken } });
    },
    [impersonateMutation, setCurrentUser, setUserTenancies]
  );

  const emailPasswordSignup = useCallback(
    (email, password) => {
      setIsSigningUp(true);
      signUpNewUserMutation({ variables: { email, password } });
    },
    [signUpNewUserMutation]
  );

  // Log the user out
  const logout = useCallback(
    async ({ doRedirect } = { doRedirect: true }) => {
      clearInterval(intervalIdentifier.current);

      if (getRefreshToken() && getAuthType() === 'email') {
        await revokeTokenMutation();
      }

      setCurrentUser(null);
      setUserTenancies(null);
      clearUserStorage();
      client.clearStore();
      stopAuthenticateTimer();

      if (
        doRedirect &&
        history &&
        !location.pathname.includes(paths.login().route)
      ) {
        history.push(paths.login().route);
      }
    },
    [
      history,
      location.pathname,
      revokeTokenMutation,
      setCurrentUser,
      setUserTenancies,
    ]
  );

  const handleLogin = async (token: string, refreshToken?: string) => {
    setAccessToken(token);

    if (refreshToken) {
      setRefreshToken(refreshToken);
    }

    if (localStorage.getItem(UNUSED_ACTIVATION_CODE_IDENTIFIER)) {
      await addTenancyMutation();
    }

    await getUserInfoMutation();
  };

  return {
    token: getAccessToken(),
    logout,
    tokenExpired,
    socialLogin,
    emailLogin,
    emailPasswordSignup,
    isSigningUp,
    signUpSuccess,
    loginByImpersonation,
    isLoggingIn,
    loginError,
    unvalidatedActivationCode: unvalidatedActivationCode.current,
  };
};
