import * as React from 'react';
import { SVGProps } from 'react';

const SvgSad = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3.667C9.19 3.667 3.667 9.188 3.667 16c0 6.811 5.522 12.333 12.333 12.333 6.812 0 12.333-5.521 12.333-12.333S22.812 3.667 16 3.667ZM1.667 16C1.667 8.084 8.084 1.667 16 1.667S30.333 8.084 30.333 16 23.916 30.333 16 30.333 1.667 23.916 1.667 16Z"
      fill="#E20613"
    />
    <path
      d="M15.998 17.6c1.69 0 3.2.575 4.505 1.525.777.565 1.323 1.134 1.629 1.542a1 1 0 0 1-1.6 1.2l-.075-.092-.128-.144a6.878 6.878 0 0 0-1.002-.889c-.987-.717-2.101-1.142-3.329-1.142-1.227 0-2.342.425-3.328 1.142-.35.255-.658.528-.918.8l-.084.089-.128.144-.075.092a1 1 0 0 1-1.6-1.2c.306-.408.851-.977 1.629-1.542 1.305-.95 2.815-1.525 4.504-1.525Z"
      fill="#E20613"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 12a1.5 1.5 0 0 1 1.5-1.5h.013a1.5 1.5 0 0 1 0 3H12a1.5 1.5 0 0 1-1.5-1.5ZM18.5 12a1.5 1.5 0 0 1 1.5-1.5h.013a1.5 1.5 0 0 1 0 3H20a1.5 1.5 0 0 1-1.5-1.5Z"
      fill="#E20613"
    />
  </svg>
);

const MemoSvgSad = React.memo(SvgSad);
export default MemoSvgSad;
