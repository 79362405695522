import { InputHTMLAttributes } from 'react';

import tw, { styled, css } from 'twin.macro';
import { Text } from './';

export type FormProps = {
  title?: string;
  subtitle?: string;
};

interface IFormComposition {
  Group: React.FC;
  Label: React.FC;
  Control: React.FC<InputHTMLAttributes<HTMLInputElement>>;
  Caption: React.FC;
  Error: React.FC;
}

const Form: IFormComposition &
  React.FC<FormProps & InputHTMLAttributes<HTMLFormElement>> = ({
  title,
  subtitle,
  onSubmit = (e) => {
    e.preventDefault();
  },
  children,
  ...rest
}) => (
  <Container onSubmit={onSubmit} {...rest}>
    {(title || subtitle) && (
      <Header>
        {title && (
          <Text as="h1" variant="h1">
            {title}
          </Text>
        )}

        {subtitle && <Text color="300">{subtitle}</Text>}
      </Header>
    )}

    {children}
  </Container>
);

const Container = styled.form(() => [
  tw`block`,

  css`
    &:last-child {
      ${tw`-mb-4`}
    }
  `,
]);

const Header = styled.header(() => [tw`text-center space-y-1.5 mb-7`]);

const Group = styled.section(() => [tw`flex flex-col mb-4`]);

const Label = styled.label(() => [tw`mb-2`]);

const Control = styled.input(() => [
  css`
    max-height: 40px;
    line-height: 16px;
  `,

  tw`p-3 text-sm border border-gray-200 rounded-sm`,
  tw`placeholder-gray-300 focus:placeholder-gray-500`,
  tw`hover:border-gray-300`,
  tw`focus:outline-none focus:ring-0 focus:border-gray-400`,
]);

const Caption = styled.p(() => [tw`pt-1 text-xs text-gray-300`]);

const Error = styled.p(() => [tw`pt-1 text-xs text-red-500`]);

Form.Group = Group;
Form.Label = Label;
Form.Control = Control;
Form.Caption = Caption;
Form.Error = Error;

export default Form;
