import tw, { styled, css } from 'twin.macro';

interface INotificationsComposition {
  List: React.FC;
  Item: typeof Item;
  Header: React.FC;
  Content: React.FC;
  Body: React.FC;
  Footer: React.FC;
}

export type ItemProps = {
  variant?: 'new';
};

const Notifications: INotificationsComposition & React.FC = ({ children }) => {
  return <section>{children}</section>;
};

const List = styled.div(() => [tw`flex flex-col space-y-4`]);

const Item = styled.div<ItemProps>(({ variant }) => [
  tw`p-4 bg-white rounded shadow-md hover:shadow-lg transition-shadow`,
  tw`xl:shadow-none xl:hover:shadow-none xl:border xl:border-gray-100`,

  variant === 'new' && tw`bg-sunflower-50`,
]);

const Header = styled.div(() => [
  tw`flex items-center lg:items-start space-x-4 mb-2 lg:mb-0`,
]);

const Content = styled.div(() => [
  css`
    width: calc(100% - 48px);
  `,

  tw`w-full lg:flex lg:justify-between`,
]);

const Body = styled.div(() => [tw`flex justify-between py-3 lg:hidden`]);

const Footer = styled.div(() => [
  tw`flex justify-between pt-3 pb-1 border-t border-gray-100 lg:hidden`,
]);

Notifications.List = List;
Notifications.Item = Item;
Notifications.Header = Header;
Notifications.Content = Content;
Notifications.Body = Body;
Notifications.Footer = Footer;

export default Notifications;
