import dayjs from 'dayjs';

/**
 * We use a custom translation file for Danish because the original includes "." on shortened words.
 *
 * */

const locale = {
  name: 'da',
  weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
  weekdaysShort: 'søn_man_tirs_ons_tors_fre_lør'.split('_'),
  weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
  months: 'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split(
    '_'
  ),
  monthsShort: 'jan_feb_mar_apr_maj_juni_juli_aug_sept_okt_nov_dec'.split('_'),
  weekStart: 1,
  ordinal: (n) => `${n}.`,
  formats: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY HH:mm',
    LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
  },
  relativeTime: {
    future: 'om %s',
    past: '%s siden',
    s: 'få sekunder',
    m: 'et minut',
    mm: '%d minutter',
    h: 'en time',
    hh: '%d timer',
    d: 'en dag',
    dd: '%d dage',
    M: 'en måned',
    MM: '%d måneder',
    y: 'et år',
    yy: '%d år',
  },
};

dayjs.locale(locale, null, true);

export default locale;
