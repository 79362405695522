import { Link } from 'react-router-dom';
import LanguageSelect from '../../components/LanguageSelect';

import { Box, CompanyIcon, Icon } from '../../ui';

const LoginHeader: React.FC<{ prevLink?: string }> = ({ prevLink }) => {
  return (
    <Box className="w-full xl:absolute top-4 xl:w-1/2 xl:-ml-4">
      <Box className="flex items-center justify-between xl:justify-center h-header mb-8 sm:mb-20">
        <Box className="w-24 xl:hidden || flex justify-start">
          {prevLink && (
            <Link to={prevLink}>
              <Box
                as="button"
                type="button"
                className="flex items-center -mt-1"
              >
                <Icon name="back" />
              </Box>
            </Link>
          )}
        </Box>

        <CompanyIcon className="text-black h-10" />

        <Box className="w-24 xl:hidden || flex justify-end">
          <LanguageSelect />
        </Box>
      </Box>
    </Box>
  );
};

export default LoginHeader;
