import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  ReactElement,
} from 'react';
import tw, { styled } from 'twin.macro';
import { Icon } from '.';
import useClickOutside from '../hooks/useClickOutside';

interface IDropdownCompositon {
  Option: typeof Option;
}

export interface DropdownType {
  onValueChange: any;
  value?: any;
  disabled?: boolean;
}

interface OptionType {
  selected?: boolean;
  value: any;
  isToggle?: boolean;
  disabled?: boolean;
}

type DropdownContextType = {
  onValueChange: Dispatch<SetStateAction<any>>;
  selectedOption: any;
  focused: boolean;
  setFocused: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
};

const DropdownContext = createContext<DropdownContextType>({
  onValueChange: () => {},
  selectedOption: null,
  focused: false,
  setFocused: () => {},
  disabled: false,
});

const Dropdown: IDropdownCompositon & React.FC<DropdownType> = ({
  children,
  onValueChange,
  value,
  disabled,
}) => {
  const [focused, setFocused] = useState(false);

  const providerValues = {
    onValueChange,
    selectedOption: value,
    focused,
    setFocused,
    disabled,
  };

  return (
    <DropdownContext.Provider value={providerValues}>
      <DropdownWrapper children={children}></DropdownWrapper>
    </DropdownContext.Provider>
  );
};

const DropdownWrapper = ({ children }) => {
  const { selectedOption, setFocused, focused, disabled } = useContext(
    DropdownContext
  );
  const ref = useRef();
  useClickOutside(ref, () => setFocused(false));

  // Copy the internal setup of an Option to use for the toggle,
  // which for example carries over icons.
  const ActiveChildForToggle = React.Children.toArray(children).find(
    (child: ReactElement) => child.props.value === selectedOption
  ) as ReactElement;

  return (
    <div className="" ref={ref}>
      <DropdownToggle
        onClick={() => !disabled && setFocused((focused) => !focused)}
        disabled={disabled}
      >
        {ActiveChildForToggle &&
          React.cloneElement(ActiveChildForToggle, {
            isToggle: true,
          })}
      </DropdownToggle>
      {focused && (
        <DropdownContainerWrapper>{children}</DropdownContainerWrapper>
      )}
    </div>
  );
};

const DropdownToggle = styled.div<{ disabled }>(({ disabled }) => [
  tw`border border-gray-300 bg-white rounded-sm overflow-hidden`,
  tw`focus:outline-none focus:border-gray-400`,
  disabled && tw`opacity-50`,
]);

const DropdownContainerWrapper = styled.div(() => [
  tw`mt-1 py-1 border border-gray-400 bg-white shadow rounded overflow-hidden absolute`,
]);

const Option: React.FC<OptionType> = ({
  children,
  selected,
  value,
  isToggle,
  disabled,
}) => {
  const { onValueChange, selectedOption, focused, setFocused } = useContext(
    DropdownContext
  );

  const handleOptionChange = () => {
    setFocused(false);
    onValueChange(value);
  };

  if (!isToggle) {
    return (
      <OptionWrapper
        selected={selected || value === selectedOption}
        disabled={disabled}
        onClick={() => handleOptionChange()}
      >
        {children}
        {(selected || value === selectedOption) && (
          <span className="pointer-events-none flex items-center">
            <Icon name="check" size="5" className="text-green-600" />
          </span>
        )}
      </OptionWrapper>
    );
  } else if (isToggle) {
    return (
      <OptionWrapper>
        {children}
        <span className="pointer-events-none flex items-center">
          <Icon name={focused ? 'dropdownActive' : 'dropdownInactive'} />
        </span>
      </OptionWrapper>
    );
  } else {
    return null;
  }
};

const OptionWrapper = styled.button<{ selected?: boolean }>(({ selected }) => [
  tw`px-2 h-8 w-full flex items-center space-x-2 cursor-pointer text-sm`,
  tw`focus:outline-none`,
  tw`hover:bg-gray-25`,
  tw`disabled:opacity-50`,
  selected && tw`bg-gray-25`,
]);

Dropdown.Option = Option;

export default Dropdown;
