import tw, { styled } from 'twin.macro';

import { Box, Icon } from './';

const MenuItem = ({ active, label, icon }) => {
  return (
    <Container active={active}>
      <Icon
        name={icon}
        textColor={active ? 'text-white' : 'text-secondary-100'}
      />

      <Box className="leading-24px">{label}</Box>
    </Container>
  );
};

const Container = styled.div<{ active: boolean }>(({ active }) => [
  tw`flex items-center space-x-3 h-12 p-3 rounded text-secondary-100`,
  active && tw`font-bold bg-secondary-400 text-white`,
  tw`hover:bg-secondary-300 hover:text-white`,
]);

export default MenuItem;
