import { writeStorage } from '@rehooks/local-storage';
import jwtDecode from 'jwt-decode';
import { GRAPHQL_URL } from '../config';
import paths from '../lib/paths';
import { history } from './history';

let authenticateTimeout: ReturnType<typeof setTimeout>;
export const TOKEN_IDENTIFIER = 'connect.token';
export const REFRESH_TOKEN_IDENTIFIER = 'refreshToken';
export const SELECTED_TENANCY_IDENTIFIER = 'selectedTenancy';
export const LANG_IDENTIFIER = 'userLang';
export const AUTH_TYPE_IDENTIFIER = 'authType';
export const UNUSED_ACTIVATION_CODE_IDENTIFIER = 'unusedActivationCode';
export const HAS_ONBOARDED_IDENTIFIER = 'hasOnboarded';
export const DATA_POLICY_ACKNOWLEDGE_IDENTIFIER = 'dataPolicyAcknowledged';

export const isTokenExpired = (offset: number = 0): boolean => {
  try {
    return (
      new Date(
        jwtDecode<{ exp: number }>(getAccessToken()).exp * 1000 - offset
      ) < new Date()
    );
  } catch (e) {
    return true;
  }
};

export const getAccessToken = (): string => {
  return localStorage.getItem(TOKEN_IDENTIFIER);
};

export const setAccessToken = (token): void => {
  if (token) {
    localStorage.setItem(TOKEN_IDENTIFIER, token);
  }
};

export const getRefreshToken = (): string => {
  return localStorage.getItem(REFRESH_TOKEN_IDENTIFIER);
};

export const setRefreshToken = (refreshToken): void => {
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_IDENTIFIER, refreshToken);
  }
};

export const getAuthType = (): string => {
  return localStorage.getItem(AUTH_TYPE_IDENTIFIER);
};

export const setAuthType = (authType: string) => {
  if (authType) {
    localStorage.setItem(AUTH_TYPE_IDENTIFIER, authType);
  }
};

export const clearUserStorage = () => {
  localStorage.removeItem(TOKEN_IDENTIFIER);
  localStorage.removeItem(REFRESH_TOKEN_IDENTIFIER);
  localStorage.removeItem(SELECTED_TENANCY_IDENTIFIER);
  localStorage.removeItem(AUTH_TYPE_IDENTIFIER);
};

/** Solely responsible for returning the correctly configured fetch request  for the current auth type. */
export const fetchNewAccessToken = async (): Promise<Response> => {
  //console.log('[TEMP AUTH LOG] Fetching refresh token. Type:', getAuthType());
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
  };

  if (getAuthType() === 'email') {
    fetchOptions.body = JSON.stringify({
      query: `
        mutation refreshToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
              payload
              token
              refreshExpiresIn
          }
        }
      `,
      variables: { refreshToken: getRefreshToken() },
    });
  } else {
    fetchOptions.body = JSON.stringify({
      query: `
        mutation login($accessToken: String!, $socialProvider: String!) {
          socialAuth(accessToken: $accessToken, provider: $socialProvider) {
            social {
              provider
            }
            token
          }
        }
      `,
      variables: {
        accessToken: getRefreshToken(),
        socialProvider: getAuthType(),
      },
    });
  }

  return fetch(GRAPHQL_URL, fetchOptions);
};

export const processNewAccessTokenResponse = async (
  res: Response
): Promise<{ access_token: string }> => {
  ////console.log('[TEMP AUTH LOG] Handling response');

  if (!res) {
    return { access_token: null };
  }

  const { data } = await res.json();

  if (!data) {
    return { access_token: null };
  }

  if (getAuthType() === 'email') {
    return { access_token: data?.refreshToken?.token };
  } else {
    return { access_token: data?.socialAuth?.token };
  }
};

/** Performs the entire token refresh process. */
export const reauthenticate = async () => {
  //console.log('[TEMP AUTH LOG] Reauthenticating...');
  if (getAccessToken() && getAuthType()) {
    const res = await fetchNewAccessToken();
    const token = await processNewAccessTokenResponse(res);
    setAccessToken(token.access_token);
  }
};

/** Start a timer that will re-authenticate one minute before the token expires. */
export const startAuthenticateTimer = () => {
  if (authenticateTimeout) {
    stopAuthenticateTimer();
  }

  //console.log('[TEMP AUTH LOG] => Auth timer started.');
  try {
    const token = jwtDecode<{ exp: number }>(getAccessToken());
    const expires = new Date(token.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    //console.log('[TEMP AUTH LOG] Access exp => ', expires);
    authenticateTimeout = setTimeout(() => {
      //console.log('[TEMP AUTH LOG] => Auth refresh required.');
      reauthenticate();
    }, timeout);
  } catch (e) {
    stopAuthenticateTimer();
  }
};

export const stopAuthenticateTimer = () => {
  //console.log('[TEMP AUTH LOG] => Auth timer stopped.');
  clearTimeout(authenticateTimeout);
};

/** Validates an activation code (for a tenancy), usually received via search params.
 *  If successful, the activation code is stored in local storage as an "unused activation code".
 */
export const validateActivationCode = async (
  unvalidatedActivationCode: string
) => {
  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      query: `
            query validateActivationCode($activationCode: String!) {
              validateActivationCode(activationCode: $activationCode) {
                ok
                text
              }
            }
          `,
      variables: {
        activationCode: unvalidatedActivationCode,
      },
    }),
  });

  const {
    data: { validateActivationCode },
  } = await res.json();

  // Remove the activationCode param.
  history.replace({
    search: null,
  });

  if (validateActivationCode.ok === true) {
    // We use the writeStorage method here because we are outside of React. This fires an event
    // that will update listening components.
    writeStorage(
      UNUSED_ACTIVATION_CODE_IDENTIFIER,
      unvalidatedActivationCode.replaceAll('-', '')
    );

    // Reroute to signup page if the user is not authenticated.
    if (isTokenExpired()) {
      history.push(paths.sign_up().route);
    }
  }
};
