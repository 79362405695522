import { Fragment, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

import Header from './Header';
import Menu from './Menu';
import HeaderMain from './HeaderMain';

import { Page, Backdrop } from '../../ui';

const PageComponent = ({ children }) => {
  const { isMenuActive, isBackdropActive } = useContext(AppContext);

  return (
    <Page>
      <Page.Header>
        <Header />
      </Page.Header>

      <Page.Container>
        <Page.Menu active={isMenuActive}>
          <Menu />
        </Page.Menu>

        <Page.Main active={isMenuActive}>
          <Page.Wrapper>
            <HeaderMain />

            <Fragment>{children}</Fragment>
          </Page.Wrapper>
        </Page.Main>
      </Page.Container>

      <Backdrop active={isBackdropActive} />
    </Page>
  );
};

export default PageComponent;
