import MemoSvgAccountCreated from '../assets/illustrations/login/AccountCreated';
import MemoSvgAlarmsNotifications from '../assets/illustrations/login/AlarmsNotifications';
import MemoSvgAnalyseUsage from '../assets/illustrations/login/AnalyseUsage';
import MemoSvgAppStore from '../assets/illustrations/login/AppStore';
import MemoSvgCheckEmail from '../assets/illustrations/login/CheckEmail';
import MemoSvgCompareConsumption from '../assets/illustrations/login/CompareConsumption';
import MemoSvgGooglePlay from '../assets/illustrations/login/GooglePlay';
import MemoSvgLoginCover from '../assets/illustrations/login/LoginCover';
import MemoSvgMonitorConsumption from '../assets/illustrations/login/MonitorConsumption';
import MemoSvgSignUp from '../assets/illustrations/login/SignUp';
import SvgNoAlarms from '../assets/illustrations/NoAlarms';
import SvgNoAllocations from '../assets/illustrations/NoAllocations';
import SvgNoNotifications from '../assets/illustrations/NoNotifications';
import MemoSvgAlarms from '../assets/illustrations/onboarding/Alarms';
import MemoSvgAnalyse from '../assets/illustrations/onboarding/Analyse';
import MemoSvgCompare from '../assets/illustrations/onboarding/Compare';
import MemoSvgMonitor from '../assets/illustrations/onboarding/Monitor';

export const IllustrationMapping = {
  accountCreatedCover: MemoSvgAccountCreated,
  alarmsNotificationCover: MemoSvgAlarmsNotifications,
  analyseUsageCover: MemoSvgAnalyseUsage,
  checkEmailCover: MemoSvgCheckEmail,
  compareConsumptionCover: MemoSvgCompareConsumption,
  loginCover: MemoSvgLoginCover,
  monitorConsumptionCover: MemoSvgMonitorConsumption,
  signupCover: MemoSvgSignUp,

  appStore: MemoSvgAppStore,
  googlePlay: MemoSvgGooglePlay,

  onboardingMonitor: MemoSvgMonitor,
  onboardingCompare: MemoSvgCompare,
  onboardingAnalyse: MemoSvgAnalyse,
  onboardingAlarms: MemoSvgAlarms,

  noAlarms: SvgNoAlarms,
  noAllocations: SvgNoAllocations,
  noNotifications: SvgNoNotifications,
};
