import * as React from 'react';
import { theme } from 'twin.macro';

function SvgCompareConsumption(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      width={343}
      height={260}
      viewBox="0 0 343 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M293.923 233.265C330.476 212.834 321.846 184.045 320.876 172.167C310.325 114.056 270.386 78.9875 230.896 51.9891C191.405 24.9907 108.966 -6.13974 48.7387 51.6808C3.22685 95.3736 16.1664 151.876 56.8987 179.723C91.7067 203.52 93.7392 204.879 134.394 220.416C154.038 227.924 257.371 253.695 293.923 233.265Z"
        fill="#F5F5F5"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M68.0342 120.01H215.45C216.223 120.01 216.85 120.637 216.85 121.41V203.182C216.85 203.956 216.223 204.582 215.45 204.582H68.0342C67.261 204.582 66.6342 203.956 66.6342 203.182V121.41C66.6342 120.637 67.261 120.01 68.0342 120.01Z"
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M209.9 114.182H60.3374C59.2328 114.182 58.3374 115.078 58.3374 116.182V197.495C58.3374 198.599 59.2328 199.495 60.3374 199.495H209.9C211.004 199.495 211.9 198.599 211.9 197.495V116.182C211.9 115.078 211.004 114.182 209.9 114.182Z"
          fill="white"
        />
        <path
          d="M69.7043 143.916C85.9231 143.916 99.0711 130.768 99.0711 114.549H59.3374V142.034C62.561 143.251 66.0549 143.916 69.7043 143.916Z"
          fill="#D6D6D6"
        />
        <path
          d="M60.3374 114.782H209.9C210.673 114.782 211.3 115.409 211.3 116.182V197.495C211.3 198.268 210.673 198.895 209.9 198.895H60.3374C59.5642 198.895 58.9374 198.268 58.9374 197.495V116.182C58.9374 115.409 59.5642 114.782 60.3374 114.782Z"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.6645 186.368L83.6632 186.368C82.1107 186.522 80.7551 185.424 80.6002 183.962L80.6002 183.962L80.5996 183.956L80.564 183.647C80.4115 182.194 81.5098 180.864 83.0637 180.709C83.0639 180.709 83.0641 180.709 83.0643 180.709L187.71 170.534L187.711 170.534C189.264 170.38 190.619 171.478 190.774 172.941L190.775 172.946L190.81 173.253C190.81 173.254 190.81 173.255 190.81 173.256C190.963 174.709 189.865 176.038 188.311 176.193C188.31 176.193 188.31 176.193 188.31 176.193L83.6645 186.368Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.999 183.174C135.573 182.426 136.699 182.423 137.278 183.167L141.606 188.734C142.342 189.68 141.668 191.058 140.47 191.058H131.864C130.67 191.058 129.995 189.688 130.723 188.74L134.999 183.174Z"
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={167.937}
          y={84.7824}
          width={118.8}
          height={42.8}
          rx={3.4}
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={162.337}
          y={78.1824}
          width={119}
          height={44}
          rx={4}
          fill="white"
        />
        <path
          d="M186.337 95.1824V78.1824H162.337V99.1824H182.337C184.547 99.1824 186.337 97.3915 186.337 95.1824Z"
          fill="#D6D6D6"
        />
        <path
          d="M237.337 78.1824H278.337C280.547 78.1824 282.337 79.9732 282.337 82.1824V118.182C282.337 120.392 280.547 122.182 278.337 122.182H237.337V78.1824Z"
          fill="#FCDB9D"
        />
        <rect
          x={162.937}
          y={78.7824}
          width={118.8}
          height={42.8}
          rx={3.4}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={188.337}
          y={88.1824}
          width={40}
          height={6}
          rx={3}
          fill="#D6D6D6"
        />
        <rect
          x={188.337}
          y={104.182}
          width={32}
          height={8}
          rx={4}
          fill={illustrationPrimaryColor}
        />
        <rect
          x={253.337}
          y={104.182}
          width={17}
          height={8}
          rx={4}
          fill="white"
        />
        <path
          d="M250.742 105.382L247.646 102.286C247.278 101.918 246.671 101.918 246.303 102.286L243.207 105.382C242.84 105.749 242.84 106.357 243.207 106.725C243.575 107.092 244.183 107.092 244.55 106.725L246.02 105.254V113.029C246.02 113.552 246.444 113.976 246.967 113.976C247.49 113.976 247.914 113.552 247.914 113.029V105.254L249.385 106.725C249.568 106.908 249.809 107.007 250.063 107.007C250.318 107.007 250.544 106.908 250.742 106.725C251.109 106.357 251.109 105.763 250.742 105.382Z"
          fill="white"
        />
        <rect
          x={206.137}
          y={142.822}
          width={99.6}
          height={35.76}
          rx={3.4}
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={201.337}
          y={137.182}
          width={99.96}
          height={36.96}
          rx={4}
          fill="white"
        />
        <path
          d="M264.337 137.182H298.137C300.347 137.182 302.137 138.973 302.137 141.182V170.142C302.137 172.352 300.347 174.142 298.137 174.142H264.337V137.182Z"
          fill="#FCDB9D"
        />
        <rect
          x={201.937}
          y={137.782}
          width={99.6}
          height={35.76}
          rx={3.4}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={223.177}
          y={145.582}
          width={33.6}
          height={5.04}
          rx={2.52}
          fill="#D6D6D6"
        />
        <rect
          x={223.177}
          y={159.022}
          width={26.88}
          height={6.72}
          rx={3.36}
          fill={illustrationPrimaryColor}
        />
        <rect
          x={277.777}
          y={159.022}
          width={14.28}
          height={6.72}
          rx={3.36}
          fill="white"
        />
        <path
          d="M275.597 160.03L272.997 157.429C272.688 157.121 272.177 157.121 271.869 157.429L269.268 160.03C268.959 160.338 268.959 160.849 269.268 161.158C269.577 161.466 270.087 161.466 270.396 161.158L271.631 159.923V166.454C271.631 166.893 271.987 167.249 272.427 167.249C272.866 167.249 273.222 166.893 273.222 166.454V159.923L274.457 161.158C274.611 161.312 274.813 161.395 275.027 161.395C275.241 161.395 275.431 161.312 275.597 161.158C275.906 160.849 275.906 160.35 275.597 160.03Z"
          fill="white"
        />
        <path
          d="M260.837 199.182C269.398 199.182 276.337 192.243 276.337 183.682C276.337 175.122 269.398 168.182 260.837 168.182C252.277 168.182 245.337 175.122 245.337 183.682C245.337 192.243 252.277 199.182 260.837 199.182Z"
          fill="white"
        />
        <path
          d="M271.82 183.323C271.82 189.367 266.92 194.267 260.875 194.267C254.831 194.267 249.931 189.367 249.931 183.323C249.931 177.278 254.831 172.378 260.875 172.378C266.92 172.378 271.82 177.278 271.82 183.323Z"
          fill="#FCDB9D"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M256.201 189.151C257.563 188.205 259.21 187.637 260.989 187.637C262.73 187.637 264.339 188.167 265.663 189.076"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M256.655 182.604C257.533 182.604 258.244 181.892 258.244 181.014C258.244 180.136 257.533 179.424 256.655 179.424C255.777 179.424 255.065 180.136 255.065 181.014C255.065 181.892 255.777 182.604 256.655 182.604Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M264.642 182.604C265.52 182.604 266.232 181.892 266.232 181.014C266.232 180.136 265.52 179.424 264.642 179.424C263.764 179.424 263.052 180.136 263.052 181.014C263.052 181.892 263.764 182.604 264.642 182.604Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M281.445 97.7517C292.648 97.7517 301.73 88.67 301.73 77.4671C301.73 66.2641 292.648 57.1824 281.445 57.1824C270.242 57.1824 261.16 66.2641 261.16 77.4671C261.16 88.67 270.242 97.7517 281.445 97.7517Z"
          fill="white"
        />
        <path
          d="M296.012 76.9865C296.012 85.0007 289.515 91.4975 281.501 91.4975C273.487 91.4975 266.99 85.0007 266.99 76.9865C266.99 68.9723 273.487 62.4755 281.501 62.4755C289.515 62.4755 296.012 68.9723 296.012 76.9865Z"
          fill="#FCDB9D"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M287.777 83.6409C285.996 84.8848 283.834 85.6199 281.501 85.6199C279.239 85.6199 277.119 84.9272 275.38 83.754"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M275.973 76.0538C277.129 76.0538 278.066 75.1171 278.066 73.9617C278.066 72.8063 277.129 71.8696 275.973 71.8696C274.818 71.8696 273.881 72.8063 273.881 73.9617C273.881 75.1171 274.818 76.0538 275.973 76.0538Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M286.406 76.0538C287.561 76.0538 288.498 75.1171 288.498 73.9617C288.498 72.8063 287.561 71.8696 286.406 71.8696C285.251 71.8696 284.314 72.8063 284.314 73.9617C284.314 75.1171 285.251 76.0538 286.406 76.0538Z"
          fill={illustrationPrimaryColor}
        />
        <rect
          x={140.937}
          y={53.7824}
          width={38.8}
          height={38.8}
          rx={3.4}
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.847 59.2274C154.897 58.9729 155.121 58.7824 155.387 58.7824H168.047C168.353 58.7824 168.597 59.0303 168.597 59.3324V61.6324C168.597 61.9376 168.349 62.1824 168.047 62.1824H163.447H162.847V62.7824V67.2724V67.8724H163.447H165.467C169.791 67.8724 173.207 71.3886 173.207 75.6124V77.4324C173.207 77.7376 172.959 77.9824 172.657 77.9824H167.267C166.959 77.9824 166.717 77.741 166.717 77.4324V75.6124C166.717 75.232 166.522 74.9462 166.317 74.762C166.116 74.5818 165.872 74.4649 165.657 74.3932L165.565 74.3624H165.467H155.387H151.977H151.377V74.9624V76.8724C151.377 78.6961 149.824 80.254 148.002 80.2623C147.993 80.258 147.979 80.249 147.964 80.2347C147.95 80.2205 147.941 80.2071 147.937 80.1988V61.9071C147.942 61.8981 147.951 61.884 147.965 61.8695C147.979 61.8552 147.993 61.8465 148.001 61.8424C149.824 61.8501 151.377 63.4082 151.377 65.2324V67.2824V67.8824H151.977H159.997H160.597V67.2824V62.7924V62.1924H159.997H155.397C155.092 62.1924 154.847 61.9444 154.847 61.6424V59.3324V59.2274ZM169.089 82.4227C169.586 81.6773 170.122 81.0412 170.363 80.7645C170.587 80.9905 171.14 81.6352 171.653 82.4187C171.926 82.8354 172.177 83.2758 172.359 83.6975C172.543 84.1265 172.637 84.4959 172.637 84.7824C172.637 86.3541 171.529 87.5024 170.357 87.5024C169.186 87.5024 168.077 86.3541 168.077 84.7824C168.077 84.5002 168.174 84.1334 168.365 83.7037C168.552 83.2819 168.81 82.841 169.089 82.4227Z"
          fill={illustrationPrimaryColor}
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
        />
        <rect
          x={81.9374}
          y={153.022}
          width={22.56}
          height={22.56}
          rx={3.4}
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.4204 167.602V166.474C94.4204 165.875 94.1785 165.299 93.764 164.885C93.338 164.459 92.7737 164.228 92.1749 164.228H87.6727C87.0739 164.228 86.4981 164.47 86.0836 164.885C85.6576 165.311 85.4272 165.875 85.4272 166.474V167.602"
          fill="white"
        />
        <path
          d="M94.4204 167.602V166.474C94.4204 165.875 94.1785 165.299 93.764 164.885C93.338 164.459 92.7737 164.228 92.1749 164.228H87.6727C87.0739 164.228 86.4981 164.47 86.0836 164.885C85.6576 165.311 85.4272 165.875 85.4272 166.474V167.602"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.9182 161.972C91.1618 161.972 92.1636 160.958 92.1636 159.726C92.1636 158.494 91.1503 157.481 89.9182 157.481C88.6862 157.481 87.6729 158.494 87.6729 159.726C87.6729 160.958 88.6746 161.972 89.9182 161.972Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.404 170.242V168.967C101.404 168.29 101.13 167.639 100.662 167.17C100.18 166.689 99.5424 166.428 98.8655 166.428H93.776C93.0992 166.428 92.4483 166.702 91.9797 167.17C91.4981 167.652 91.2378 168.29 91.2378 168.967V170.242"
          fill="white"
        />
        <path
          d="M101.404 170.242V168.967C101.404 168.29 101.13 167.639 100.662 167.17C100.18 166.689 99.5424 166.428 98.8655 166.428H93.776C93.0992 166.428 92.4483 166.702 91.9797 167.17C91.4981 167.652 91.2378 168.29 91.2378 168.967V170.242"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.3146 163.877C97.7204 163.877 98.8528 162.732 98.8528 161.339C98.8528 159.946 97.7074 158.801 96.3146 158.801C94.9218 158.801 93.7764 159.946 93.7764 161.339C93.7764 162.732 94.9088 163.877 96.3146 163.877Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx={105.757}
          cy={151.102}
          r={7.92}
          fill={illustrationPrimaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.071 148.462H110.661C111.051 148.462 111.368 148.762 111.368 149.132V151.752C111.368 152.122 111.051 152.422 110.661 152.422C110.271 152.422 109.955 152.122 109.955 151.752V150.75L107.628 152.957C107.353 153.218 106.905 153.218 106.629 152.957L104.539 150.973L101.684 153.711C101.408 153.973 100.96 153.973 100.684 153.711C100.409 153.449 100.409 153.025 100.684 152.764L104.039 149.552C104.315 149.29 104.762 149.29 105.038 149.552L107.129 151.535L108.956 149.803H108.071C107.681 149.803 107.364 149.503 107.364 149.132C107.364 148.762 107.681 148.462 108.071 148.462Z"
          fill="white"
        />
        <rect
          x={167.937}
          y={148.328}
          width={19.2545}
          height={19.2545}
          rx={3.4}
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M182.678 162.898V161.629C182.678 160.956 182.406 160.308 181.94 159.842C181.461 159.363 180.826 159.104 180.153 159.104H175.089C174.416 159.104 173.768 159.376 173.302 159.842C172.823 160.321 172.563 160.956 172.563 161.629V162.898"
          fill="white"
        />
        <path
          d="M182.678 162.898V161.629C182.678 160.956 182.406 160.308 181.94 159.842C181.461 159.363 180.826 159.104 180.153 159.104H175.089C174.416 159.104 173.768 159.376 173.302 159.842C172.823 160.321 172.563 160.956 172.563 161.629V162.898"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M177.615 156.565C179.014 156.565 180.14 155.426 180.14 154.04C180.14 152.654 179.001 151.514 177.615 151.514C176.229 151.514 175.089 152.654 175.089 154.04C175.089 155.426 176.216 156.565 177.615 156.565Z"
          fill="white"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx={188.474}
          cy={146.364}
          r={8.18182}
          fill={illustrationPrimaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.863 143.637H193.539C193.942 143.637 194.269 143.947 194.269 144.329V147.036C194.269 147.418 193.942 147.728 193.539 147.728C193.136 147.728 192.81 147.418 192.81 147.036V146L190.406 148.28C190.121 148.55 189.659 148.55 189.374 148.28L187.214 146.231L184.265 149.059C183.98 149.33 183.518 149.33 183.233 149.059C182.948 148.789 182.948 148.351 183.233 148.08L186.698 144.763C186.983 144.492 187.445 144.492 187.73 144.763L189.89 146.811L191.777 145.021H190.863C190.46 145.021 190.134 144.711 190.134 144.329C190.134 143.947 190.46 143.637 190.863 143.637Z"
          fill="white"
        />
        <path
          d="M62.7043 136.916C78.9231 136.916 92.0711 123.768 92.0711 107.549C92.0711 91.3304 78.9231 78.1824 62.7043 78.1824C46.4854 78.1824 33.3374 91.3304 33.3374 107.549C33.3374 123.768 46.4854 136.916 62.7043 136.916Z"
          fill="white"
        />
        <path
          d="M84.068 106.876C84.068 118.633 74.5373 128.164 62.7806 128.164C51.0238 128.164 41.4931 118.633 41.4931 106.876C41.4931 95.1195 51.0238 85.5888 62.7806 85.5888C74.5373 85.5888 84.068 95.1195 84.068 106.876Z"
          fill="#FCDB9D"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.2304 110.471C73.6703 115.87 68.684 119.831 62.7801 119.831C56.9067 119.831 51.9511 115.931 50.3604 110.578"
          stroke={illustrationPrimaryColor}
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.7809 105.53C56.4535 105.53 57.8094 104.174 57.8094 102.502C57.8094 100.829 56.4535 99.4734 54.7809 99.4734C53.1083 99.4734 51.7524 100.829 51.7524 102.502C51.7524 104.174 53.1083 105.53 54.7809 105.53Z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M69.8932 105.53C71.5658 105.53 72.9217 104.174 72.9217 102.502C72.9217 100.829 71.5658 99.4734 69.8932 99.4734C68.2206 99.4734 66.8647 100.829 66.8647 102.502C66.8647 104.174 68.2206 105.53 69.8932 105.53Z"
          fill={illustrationPrimaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width={273}
            height={152}
            fill="white"
            transform="translate(33.3374 53.1824)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgCompareConsumption = React.memo(SvgCompareConsumption);
export default MemoSvgCompareConsumption;
