import { useNumberFormat } from '../hooks/useNumberFormat';
import { MeterKind } from '../models/Consumption';
import { Card, Icon } from './';

export type ConsumptionLinkProps = {
  label: string;
  value: number;
  percentage: number;
  unit: string;
  kind: MeterKind;
}

const ConsumptionLink: React.FC<ConsumptionLinkProps> = ({ label, value, percentage, unit, kind }) => {
  const { formatByMeterKind } = useNumberFormat();
  return (
    <Card>
      <div className="flex p-4">
        <div className="flex-grow">
          <div className="mb-4 flex justify-between">
            <span>{label}</span>
            <span>
              <span className="font-bold">
                {formatByMeterKind(value, kind)}
              </span>{' '}
              {unit}
            </span>
          </div>
          <div
            className="bg-sunflower-400 rounded"
            style={{ width: percentage + '%', height: '.3rem' }}
          ></div>
        </div>
        <div className="flex items-center border-l border-gray-100 ml-4 pl-2">
          <Icon name="chevronRight" />
        </div>
      </div>
    </Card>
  );
};

export default ConsumptionLink;
