import { ApolloProvider } from '@apollo/client';
import da from 'date-fns/locale/da';
import nb from 'date-fns/locale/nb';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/nb';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Toaster } from 'react-hot-toast';
import 'swiper/swiper.scss';
import { theme } from 'twin.macro';
import App from './App';
import { APPLE_CLIENT_ID, APP_REDIRECT_URI, GTM_ID } from './config';
import { AppContextProvider } from './contexts/AppContext';
import './i18n/config';
import './i18n/da/da-daysjs-locale';
import client from './lib/apolloClient';
import './styles/tailwind.css';
import './styles/vendor.css';

registerLocale('da', da);
registerLocale('nb', nb);

const tagManagerArgs = {
  gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

//@ts-ignore
window.AppleID.auth.init({
  clientId: APPLE_CLIENT_ID,
  scope: 'email',
  redirectURI: APP_REDIRECT_URI,
  usePopup: true,
});

const toastOptions = {
  duration: 3000,
  style: {
    height: '64px',
    padding: '16px',
  },
  success: {
    style: {
      backgroundColor: theme('colors.green.50'),
      color: theme('colors.green.600'),
      fontWeight: 600,
    },
    iconTheme: {
      primary: theme('colors.green.600'),
      secondary: theme('colors.green.50'),
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <App />
        <Toaster position="bottom-right" toastOptions={toastOptions} />
      </AppContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
