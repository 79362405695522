import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgEnergy(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id1 = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill={`url(#${id1})`}
        fillRule="evenodd"
        d="M12.85 22.95l.88 2.06c.4.95 1.27 1.43 2.22 1.43.3 0 .62-.08.92-.15l.03-.01a2.42 2.42 0 001.27-3.17l-.87-2.07c-.64-1.5-.4-3.25.63-4.52a9.19 9.19 0 00.64-10.62l-1.03-1.75a2.43 2.43 0 00-3.33-.8 2.43 2.43 0 00-.8 3.34l1.03 1.74a4.42 4.42 0 01-.31 5.08 9.25 9.25 0 00-1.28 9.44zm10.04 2l.88 2.06c.4.95 1.27 1.43 2.22 1.43.3 0 .62-.08.92-.15l.03-.01a2.42 2.42 0 001.27-3.17l-.87-2.07c-.64-1.5-.4-3.25.63-4.52a9.19 9.19 0 00.64-10.62l-1.04-1.75a2.43 2.43 0 00-3.33-.8 2.43 2.43 0 00-.79 3.34l1.03 1.74a4.42 4.42 0 01-.31 5.08 9.63 9.63 0 00-1.28 9.44zM3.6 27l-.87-2.06a9.12 9.12 0 011.35-9.36 4.42 4.42 0 00.32-5.08L3.29 8.7a2.43 2.43 0 01.8-3.33 2.45 2.45 0 013.32.8L8.44 7.9a9.19 9.19 0 01-.63 10.62 4.44 4.44 0 00-.64 4.52l.88 2.07a2.42 2.42 0 01-1.27 3.17h-.03c-.3.08-.62.16-.92.16-.96 0-1.83-.48-2.23-1.43z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id={id1}
          x1={-3.47}
          x2={18.43}
          y1={6.08}
          y2={31.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8087" />
          <stop offset={1} stopColor="#FFD8DA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgEnergy = React.memo(SvgEnergy);
export default MemoSvgEnergy;
