import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

export type TooltipProps = {
  children: any;
  content: any;
  placement?: Placement;
  disabled?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, placement, disabled = false }) => {
  return (
    <>
      <Tippy
        content={content}
        placement={placement}
        disabled={disabled}
        delay={100}
      >
        <span>{children}</span>
      </Tippy>
    </>
  );
};

export default Tooltip;
