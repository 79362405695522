import * as React from 'react';

function SvgIncrease(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5 16C30.5 23.732 24.232 30 16.5 30C8.76801 30 2.5 23.732 2.5 16C2.5 8.26801 8.76801 2 16.5 2C24.232 2 30.5 8.26801 30.5 16ZM32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16ZM26.0729 11H20.84C20.0518 11 19.4128 11.606 19.4128 12.3536C19.4128 13.1012 20.0518 13.7073 20.84 13.7073H22.6274L18.9371 17.2075L14.7134 13.2013C14.1561 12.6727 13.2524 12.6727 12.6951 13.2013L5.9179 19.6892C5.36057 20.2178 5.36057 21.0749 5.9179 21.6035C6.47524 22.1322 7.37886 22.1322 7.93619 21.6035L13.7042 16.0728L17.928 20.079C18.4853 20.6077 19.3889 20.6077 19.9463 20.079L24.6457 15.6216V17.6464C24.6457 18.394 25.2847 19 26.0729 19C26.861 19 27.5 18.394 27.5 17.6464V12.3536C27.5 11.606 26.861 11 26.0729 11Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-3.5}
          y1={0}
          x2={26.9}
          y2={32}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
        <clipPath id="clip0">
          <path
            d="M0.5 3C0.5 1.34315 1.84315 0 3.5 0H29.5C31.1569 0 32.5 1.34315 32.5 3V29C32.5 30.6569 31.1569 32 29.5 32H3.5C1.84315 32 0.5 30.6569 0.5 29V3Z"
            fill="currentColor"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgIncrease = React.memo(SvgIncrease);
export default MemoSvgIncrease;
