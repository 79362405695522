import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { MutableRefObject, useEffect } from 'react';

export const useScrollLock = (
  ref: MutableRefObject<any>,
  cb?: () => boolean,
  deps = []
) => {
  useEffect(() => {
    if (cb) {
      if (cb()) {
        disableBodyScroll(ref.current);
      }
    } else {
      disableBodyScroll(ref.current);
    }
    return () => clearAllBodyScrollLocks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
