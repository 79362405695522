import * as React from 'react';
import { theme } from 'twin.macro';

function SvgMonitorConsumption(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      width={343}
      height={260}
      viewBox="0 0 343 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M276 248.568C314.901 233.068 310.091 203.401 310.675 191.498C307.777 132.508 272.741 92.5401 237.1 60.6321C201.458 28.724 123.772 -12.8701 56.5319 36.6208C5.72096 74.0192 11.1974 131.725 47.9593 164.636C79.3744 192.76 81.2128 194.372 119.5 215.068C138 225.068 237.1 264.068 276 248.568Z"
        fill="#F5F5F5"
      />
      <path
        d="M312.325 92.1345C312.325 92.1345 291.386 71.2515 252.567 71.9716C213.748 72.6917 177.388 101.186 164.393 105.429C151.398 109.672 122.117 104.454 98.9296 118.723C75.7422 132.992 61.2514 150.109 133.484 154.474C205.738 158.838 396.333 156.202 312.325 92.1345Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.522 110.221C124.746 111.151 148.019 113.405 151.719 114.358C223.729 135.961 317.598 151.633 333.274 121.347C330.856 156.93 192.583 158.044 133.484 154.473C61.2514 150.108 75.7422 132.992 98.9295 118.723C106.099 114.311 113.85 111.762 121.522 110.221Z"
        fill="#D6D6D6"
      />
      <path
        d="M133.484 154.474C61.2514 150.109 75.7422 132.992 98.9296 118.723C122.117 104.454 151.398 109.672 164.393 105.429C170.736 103.358 182.648 95.5074 197.695 87.9302M329.197 108.819C337.73 121.612 332.369 131.111 318.686 138.086M308.238 142.423C309.971 141.83 311.631 141.212 313.213 140.569M313.213 140.569C315.159 139.78 316.986 138.952 318.686 138.086M313.213 140.569L318.686 138.086"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9731 120.418C23.9731 120.418 38.3641 106.071 65.0412 106.559C91.7184 107.046 116.712 126.644 125.652 129.558C134.581 132.471 154.711 128.893 170.642 138.698C186.573 148.502 196.544 160.268 146.89 163.27C97.2355 166.272 -33.7683 164.455 23.9731 120.418Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6682 131.458C-10.5127 164.747 101.771 165.998 146.89 163.27C196.544 160.268 186.573 148.502 170.642 138.698C165.986 135.832 160.971 134.11 155.983 133.034C154.699 133.367 153.367 133.701 151.985 134.036C136.174 137.876 103.716 150.989 73.8043 136.515C43.8922 122.041 9.71997 150.163 12.6682 131.458Z"
        fill="#D6D6D6"
      />
      <path
        d="M23.9731 120.418C22.7042 121.386 21.5265 122.333 20.437 123.261M146.89 163.27C196.544 160.268 186.573 148.502 170.642 138.698C154.711 128.893 134.581 132.472 125.652 129.558C118.793 127.323 102.487 115.268 83.2211 109.556M16.7498 150.384C7.9753 144.789 6.36457 137.103 16.7498 126.655"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.124 18.9164L201.596 25.1425H200.41L198.881 21.974L200.034 4.60287C200.266 3.27344 201.274 3.21805 201.64 4.52532L203.124 18.9164Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
      <path
        d="M207.489 32.4433L202.858 28.0008L203.446 26.9705L206.957 27.2363L221.426 36.9301C222.467 37.7942 222.002 38.7026 220.695 38.3592L207.489 32.4433Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.423 28.7652L198.582 26.9705L199.169 28.0008L197.186 30.9144L181.566 38.6029C180.292 39.0682 179.749 38.2152 180.69 37.2513L192.423 28.7652Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M200.997 29.6071C202.331 29.6071 203.412 28.5258 203.412 27.192C203.412 25.8581 202.331 24.7769 200.997 24.7769C199.663 24.7769 198.582 25.8581 198.582 27.192C198.582 28.5258 199.663 29.6071 200.997 29.6071Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
      <path
        d="M197.807 101.075H204.188L201.418 29.5962H200.488L197.807 101.075Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.639 32.8089L170.11 39.0351H168.925L167.396 35.8666L168.548 18.4954C168.781 17.166 169.789 17.1106 170.155 18.4179L171.639 32.8089Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.004 46.3358L171.374 41.8933L171.961 40.863L175.473 41.1289L189.941 50.8227C190.983 51.6868 190.517 52.5952 189.21 52.2518L176.004 46.3358Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.938 42.6578L167.097 40.863L167.685 41.8933L165.701 44.807L150.081 52.4955C148.807 52.9608 148.264 52.1078 149.205 51.1439L160.938 42.6578Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.512 43.4887C170.846 43.4887 171.927 42.4074 171.927 41.0736C171.927 39.7397 170.846 38.6584 169.512 38.6584C168.178 38.6584 167.097 39.7397 167.097 41.0736C167.097 42.4074 168.178 43.4887 169.512 43.4887Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.322 114.956H172.703L169.933 43.4888H169.003L166.322 114.956Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.993 50.8333C224.271 49.8884 225.138 49.2399 226.123 49.2416L286.993 49.3446C288.47 49.3471 289.531 50.7666 289.115 52.1839L278.106 89.7204C277.829 90.6654 276.961 91.3141 275.977 91.3126L215.097 91.2199C213.62 91.2176 212.558 89.7977 212.974 88.3802L223.993 50.8333Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M224.569 51.0023C224.771 50.3133 225.404 49.8404 226.122 49.8416L286.992 49.9446C288.069 49.9464 288.843 50.9815 288.54 52.015L277.53 89.5516C277.328 90.2407 276.696 90.7137 275.978 90.7126L215.098 90.6199C214.02 90.6182 213.247 89.5828 213.55 88.5492L224.569 51.0023Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.999 47.2216C222.277 46.2768 223.144 45.6283 224.129 45.63L284.999 45.733C286.476 45.7355 287.537 47.155 287.121 48.5723L276.112 86.1086C275.835 87.0537 274.967 87.7024 273.982 87.7007L213.102 87.5977C211.625 87.5952 210.564 86.1753 210.98 84.7579L221.999 47.2216Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M224.682 51.1871C224.958 50.2425 225.825 49.5933 226.809 49.5933H238.965C240.443 49.5933 241.506 51.0124 241.091 52.4308L237.956 63.1548C237.68 64.0995 236.813 64.7487 235.829 64.7487H223.673C222.195 64.7487 221.132 63.3295 221.546 61.9112L224.682 51.1871Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M245.255 51.1871C245.531 50.2425 246.397 49.5933 247.382 49.5933H259.538C261.015 49.5933 262.079 51.0124 261.664 52.4308L258.529 63.1548C258.252 64.0995 257.386 64.7487 256.402 64.7487H244.246C242.768 64.7487 241.705 63.3295 242.119 61.9112L245.255 51.1871Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M265.783 51.1871C266.059 50.2425 266.926 49.5933 267.91 49.5933H280.055C281.533 49.5933 282.596 51.0124 282.181 52.4308L279.046 63.1548C278.77 64.0995 277.903 64.7487 276.919 64.7487H264.774C263.296 64.7487 262.233 63.3295 262.648 61.9112L265.783 51.1871Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M219.043 70.1869C219.319 69.2422 220.185 68.593 221.17 68.593H233.315C234.792 68.593 235.856 70.0122 235.441 71.4306L232.306 82.1546C232.029 83.0992 231.163 83.7485 230.179 83.7485H218.034C216.556 83.7485 215.493 82.3293 215.907 80.9109L219.043 70.1869Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M239.616 70.1869C239.892 69.2422 240.758 68.593 241.742 68.593H253.887C255.365 68.593 256.429 70.0122 256.014 71.4306L252.878 82.1546C252.602 83.0992 251.736 83.7485 250.752 83.7485H238.607C237.129 83.7485 236.065 82.3293 236.48 80.9109L239.616 70.1869Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M260.133 70.1869C260.41 69.2422 261.276 68.593 262.26 68.593H274.416C275.894 68.593 276.957 70.0122 276.543 71.4306L273.407 82.1546C273.131 83.0992 272.264 83.7485 271.28 83.7485H259.124C257.647 83.7485 256.583 82.3293 256.998 80.9109L260.133 70.1869Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M268 87.9915H264.277V109.827H268V87.9915Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.436 87.9915H221.714V109.827H225.436V87.9915Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.9346 115.735C86.0956 104.257 86.1388 92.7083 88.667 83.9618C89.9524 79.5145 91.8825 75.7903 93.4933 73.1758C94.2981 71.8694 95.0217 70.8426 95.5425 70.1438C95.6815 69.9574 95.8059 69.7944 95.9137 69.6557C106.006 82.6123 105.941 94.1632 103.362 102.504C102.049 106.753 100.077 110.185 98.4314 112.555C97.6094 113.739 96.8706 114.656 96.3393 115.275C96.1861 115.453 96.0502 115.607 95.9346 115.735Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.0283 84.4792V123.022"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.3311 99.3579L95.9176 104.775"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.3311 91.8354L95.7292 96.2226"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.0283 99.3577L99.3186 96.0674"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.0932 115.735C62.2488 104.257 62.292 92.7084 64.8215 83.9619C66.1077 79.5146 68.0387 75.7904 69.6504 73.1759C70.4556 71.8695 71.1796 70.8427 71.7007 70.1439C71.8398 69.9574 71.9644 69.7943 72.0723 69.6555C82.1647 82.6122 82.1001 94.1632 79.5212 102.504C78.2074 106.753 76.2353 110.185 74.5902 112.555C73.7681 113.739 73.0293 114.656 72.498 115.275C72.3448 115.453 72.2088 115.607 72.0932 115.735Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.187 84.4792V123.022"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.4897 99.3579L72.0652 104.775"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.4897 91.8354L71.8768 96.2226"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.187 99.3577L75.4663 96.0674"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.9727 115.735C39.1337 104.257 39.1769 92.7083 41.705 83.9618C42.9905 79.5145 44.9206 75.7903 46.5313 73.1758C47.3362 71.8694 48.0598 70.8426 48.5806 70.1438C48.7196 69.9574 48.844 69.7944 48.9518 69.6557C59.044 82.6123 58.9795 94.1632 56.4005 102.504C55.0868 106.753 53.1147 110.185 51.4695 112.555C50.6475 113.739 49.9087 114.656 49.3774 115.275C49.2242 115.453 49.0883 115.607 48.9727 115.735Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.0664 84.4792V123.022"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3687 99.3579L48.9552 104.775"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3687 91.8354L48.7668 96.2226"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.0664 99.3577L52.3567 96.0674"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7534 44.3847H103.506C103.506 44.3847 101.379 28.2433 87.8855 30.4922C87.8855 30.4922 82.18 20.5548 73.0845 21.995C63.9891 23.4352 62.0946 30.4922 62.0946 30.4922C62.0946 30.4922 51.3484 22.8148 44.5905 33.5942C44.5794 33.6053 32.2823 31.6444 30.7534 44.3847Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={30}
        y={21}
        width={74}
        height={24}
      >
        <path
          d="M61.7457 30.9804L61.7458 30.9804C61.7459 30.9805 61.746 30.9805 61.7461 30.9806C61.7461 30.9806 61.7461 30.9806 61.7462 30.9807C61.9062 31.0948 62.112 31.1234 62.297 31.0571C62.482 30.9908 62.6228 30.8381 62.674 30.6484L62.6741 30.6479L62.6741 30.6478L62.6769 30.6381C62.6797 30.6284 62.6845 30.6122 62.6913 30.5899C62.7051 30.5454 62.7273 30.4768 62.7592 30.3871C62.8229 30.2076 62.9253 29.9442 63.0763 29.6211C63.3785 28.9745 63.8737 28.0925 64.6408 27.1664C66.168 25.3228 68.7892 23.2826 73.1784 22.5876C77.4731 21.9076 80.9975 23.9048 83.4947 26.1497C84.7393 27.2685 85.7104 28.4337 86.3707 29.3204C86.7003 29.763 86.9512 30.1345 87.1186 30.3937C87.2023 30.5232 87.2651 30.6246 87.3064 30.6925C87.3271 30.7265 87.3423 30.7521 87.3521 30.7688L87.3628 30.7869L87.365 30.7907L87.3652 30.791L87.3653 30.7912C87.4902 31.0085 87.7369 31.1253 87.9841 31.0841C91.2304 30.543 93.7522 31.1112 95.7214 32.2352C97.7013 33.3654 99.1652 35.0824 100.239 36.9004C101.313 38.7179 101.983 40.612 102.385 42.0582C102.581 42.7658 102.712 43.3623 102.795 43.7847H31.4421C31.8715 41.0556 32.8297 39.1085 34.0159 37.7142C35.2992 36.2059 36.8796 35.3093 38.4332 34.7844C39.9901 34.2584 41.5101 34.1094 42.6473 34.0932C43.2144 34.0852 43.6823 34.1101 44.0069 34.1365C44.169 34.1497 44.2953 34.1632 44.3804 34.1732C44.423 34.1782 44.4557 34.1823 44.4787 34.1852L44.51 34.1891C44.5136 34.1896 44.5226 34.1906 44.5326 34.1916L44.5327 34.1916C44.5364 34.1919 44.55 34.1932 44.567 34.1938C44.5773 34.1941 44.6064 34.194 44.6252 34.1932C44.6686 34.1891 44.8752 34.1224 45.0148 34.0185C45.0467 33.9866 45.0749 33.9512 45.0989 33.9129C46.7304 31.3104 48.5762 29.8604 50.4025 29.1178C52.2343 28.3729 54.0949 28.3202 55.7809 28.5932C57.4693 28.8667 58.9651 29.4645 60.0436 29.9987C60.5814 30.2651 61.0115 30.5137 61.3053 30.6944C61.4521 30.7848 61.5647 30.858 61.6394 30.9078C61.6767 30.9327 61.7045 30.9517 61.7224 30.9641L61.7418 30.9776L61.7457 30.9804Z"
          fill="white"
          stroke="#335D71"
          strokeWidth={1.2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M69.6167 45.0495H142.37C142.37 45.0495 140.242 28.9081 126.749 31.157C126.749 31.157 121.043 21.2196 111.948 22.6598C102.852 24.1 100.947 31.1681 100.947 31.1681C100.947 31.1681 90.2006 23.4907 83.4427 34.2701C83.4427 34.2701 71.1455 32.3203 69.6167 45.0495Z"
          fill="#D6D6D6"
        />
      </g>
      <path
        d="M30.7534 44.3847H103.506C103.506 44.3847 101.379 28.2433 87.8855 30.4922C87.8855 30.4922 82.18 20.5548 73.0845 21.995C63.9891 23.4352 62.0946 30.4922 62.0946 30.4922C62.0946 30.4922 51.3484 22.8148 44.5905 33.5942C44.5794 33.6053 32.2823 31.6444 30.7534 44.3847Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.646 55.0202H132.399C132.399 55.0202 130.272 38.8788 116.778 41.1277C116.778 41.1277 111.073 31.1903 101.977 32.6305C92.8816 34.0707 90.9761 41.1388 90.9761 41.1388C90.9761 41.1388 80.2299 33.4614 73.472 44.2408C73.472 44.2408 61.1748 42.291 59.646 55.0202Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.788 125.636H122.625C121.395 125.636 120.397 126.633 120.397 127.863V219.122C120.397 220.352 121.395 221.349 122.625 221.349H275.788C277.018 221.349 278.015 220.352 278.015 219.122V127.863C278.015 126.633 277.018 125.636 275.788 125.636Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M270.22 131.496H128.181C126.95 131.496 125.953 132.493 125.953 133.724V213.274C125.953 214.504 126.95 215.501 128.181 215.501H270.22C271.45 215.501 272.447 214.504 272.447 213.274V133.724C272.447 132.493 271.45 131.496 270.22 131.496Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M281.402 231.462H115.39C112.6 231.462 110.334 229.196 110.334 226.406C110.334 223.615 112.6 221.349 115.39 221.349H281.402C284.192 221.349 286.459 223.615 286.459 226.406C286.459 229.196 284.192 231.462 281.402 231.462Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M220.742 157.937H175.075C174.46 157.937 173.961 158.436 173.961 159.051V193.58C173.961 194.195 174.46 194.694 175.075 194.694H220.742C221.357 194.694 221.856 194.195 221.856 193.58V159.051C221.856 158.436 221.357 157.937 220.742 157.937Z"
        fill="#D6D6D6"
      />
      <path
        d="M220.742 146.799H175.075C174.46 146.799 173.961 147.297 173.961 147.912V152.368C173.961 152.983 174.46 153.482 175.075 153.482H220.742C221.357 153.482 221.856 152.983 221.856 152.368V147.912C221.856 147.297 221.357 146.799 220.742 146.799Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M132.595 157.695H82.6271C81.3968 157.695 80.3994 158.692 80.3994 159.923V253.739C80.3994 254.97 81.3968 255.967 82.6271 255.967H132.595C133.826 255.967 134.823 254.97 134.823 253.739V159.923C134.823 158.692 133.826 157.695 132.595 157.695Z"
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.683 163.117H85.5265C84.2962 163.117 83.2988 164.114 83.2988 165.344V243.055C83.2988 244.285 84.2962 245.282 85.5265 245.282H129.683C130.914 245.282 131.911 244.285 131.911 243.055V165.344C131.911 164.114 130.914 163.117 129.683 163.117Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.605 254.14C109.449 254.14 110.944 252.645 110.944 250.801C110.944 248.958 109.449 247.463 107.605 247.463C105.762 247.463 104.267 248.958 104.267 250.801C104.267 252.645 105.762 254.14 107.605 254.14Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.497 194.694H93.7652C93.15 194.694 92.6514 195.192 92.6514 195.807V220.312C92.6514 220.927 93.15 221.426 93.7652 221.426H120.497C121.112 221.426 121.611 220.927 121.611 220.312V195.807C121.611 195.192 121.112 194.694 120.497 194.694Z"
        fill="#D6D6D6"
      />
      <path
        d="M120.497 184.669H93.7652C93.15 184.669 92.6514 185.168 92.6514 185.783V189.124C92.6514 189.739 93.15 190.238 93.7652 190.238H120.497C121.112 190.238 121.611 189.739 121.611 189.124V185.783C121.611 185.168 121.112 184.669 120.497 184.669Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M276.434 190.238C288.738 190.238 298.711 180.265 298.711 167.962C298.711 155.658 288.738 145.685 276.434 145.685C264.131 145.685 254.158 155.658 254.158 167.962C254.158 180.265 264.131 190.238 276.434 190.238Z"
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M280.353 155.014L267.422 172.182L274.133 170.357L271.008 181.781L284.399 164.265L277.307 165.344L280.353 155.014Z"
        fill="#F9B83B"
      />
      <path
        d="M64.2488 195.807C83.0111 195.807 98.2208 180.597 98.2208 161.835C98.2208 143.073 83.0111 127.863 64.2488 127.863C45.4866 127.863 30.2769 143.073 30.2769 161.835C30.2769 180.597 45.4866 195.807 64.2488 195.807Z"
        fill="white"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.3136 173.091C64.0238 173.091 70.2742 166.84 70.2742 159.13C70.2742 151.42 64.0238 145.17 56.3136 145.17C48.6034 145.17 42.353 151.42 42.353 159.13C42.353 166.84 48.6034 173.091 56.3136 173.091Z"
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.0835 173.693C52.0835 176.629 58.3266 179.013 66.0441 179.013C73.7486 179.013 80.0046 176.629 80.0046 173.693V169.77C80.0046 166.835 73.7615 164.45 66.0441 164.45C58.3395 164.45 52.0835 166.835 52.0835 169.77V173.693Z"
        fill="#F9B83B"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.0309 174.398C73.7411 174.398 79.9914 172.016 79.9914 169.078C79.9914 166.14 73.7411 163.758 66.0309 163.758C58.3207 163.758 52.0703 166.14 52.0703 169.078C52.0703 172.016 58.3207 174.398 66.0309 174.398Z"
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5571 165.527C57.5571 168.463 63.8003 170.847 71.5177 170.847C79.2223 170.847 85.4782 168.463 85.4782 165.527V161.604C85.4782 158.669 79.2351 156.284 71.5177 156.284C63.8131 156.284 57.5571 158.669 57.5571 161.604V165.527Z"
        fill="#F9B83B"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.5045 166.232C79.2147 166.232 85.4651 163.85 85.4651 160.912C85.4651 157.974 79.2147 155.592 71.5045 155.592C63.7943 155.592 57.5439 157.974 57.5439 160.912C57.5439 163.85 63.7943 166.232 71.5045 166.232Z"
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoSvgMonitorConsumption = React.memo(SvgMonitorConsumption);
export default MemoSvgMonitorConsumption;
