import * as React from 'react';
import { theme } from 'twin.macro';
import { v4 as uuidv4 } from 'uuid';

function AccountCreated(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  const id = uuidv4();

  return (
    <svg
      fill="none"
      viewBox="0 0 275 194"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M237.678 84.5c1.623 10.677 0 31.595-9.5 44.5-24.732 33.598-40.081 34.157-73.695 39.266-33.614 5.109-109.905-17.686-116.603-55.104-6.7-37.419 26.156-64.159 54.819-85.205 28.663-21.046 51.802-.793 82.437 29.953 25.82 25.915 57.358-7.518 62.542 26.59z"
        fill="#F5F5F5"
      />
      <path
        d="M42.867 126.733c17.047 0 30.867-13.819 30.867-30.866C73.734 78.819 59.914 65 42.867 65S12 78.82 12 95.867s13.82 30.866 30.867 30.866z"
        fill="#fff"
      />
      <path
        d="M65.322 95.159c0 12.357-10.018 22.375-22.375 22.375s-22.375-10.018-22.375-22.375S30.59 72.784 42.947 72.784 65.322 82.802 65.322 95.16z"
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.261}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.033 98.937c-1.64 5.675-6.88 9.839-13.086 9.839-6.173 0-11.382-4.1-13.054-9.726"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.261}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.54 93.745a3.183 3.183 0 10-.001-6.367 3.183 3.183 0 000 6.367zM50.423 93.745a3.183 3.183 0 100-6.367 3.183 3.183 0 000 6.367z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M179.603 26.555h-76.206A3.397 3.397 0 00100 29.951v143.077a3.397 3.397 0 003.397 3.397h76.206a3.397 3.397 0 003.397-3.397V29.952a3.397 3.397 0 00-3.397-3.398z"
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={1.83}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.166 34.823h-67.342a3.397 3.397 0 00-3.397 3.397v118.514a3.397 3.397 0 003.397 3.397h67.342a3.397 3.397 0 003.398-3.397V38.22a3.397 3.397 0 00-3.398-3.397z"
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.83}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M136.415 78.926h53.077v69.664h-53.077V78.926z"
          fill="#fff"
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
        />
        <path fill="#D6D6D6" d="M141.529 84.04h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M141.944 84.455h13.684v6.082h-13.684zM146.367 84.385v6.22M151.204 84.385v6.22"
        />
        <path fill="#D6D6D6" d="M169.864 84.04h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M170.279 84.454h13.684v6.082h-13.684zM174.702 84.385v6.22"
        />
        <path fill="#D6D6D6" d="M160.189 84.04h5.529v6.911h-5.529z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M160.604 84.454h4.7v6.082h-4.7z"
        />
        <path fill="#D6D6D6" d="M141.529 96.48h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M141.944 96.895h13.684v6.082h-13.684zM146.367 96.825v6.22M151.204 96.825v6.22"
        />
        <path fill="#D6D6D6" d="M169.864 96.48h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M170.279 96.894h13.684v6.082h-13.684zM174.702 96.824v6.22"
        />
        <path fill="#D6D6D6" d="M160.189 96.48h5.529v6.911h-5.529z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M160.604 96.894h4.7v6.082h-4.7z"
        />
        <path fill="#D6D6D6" d="M141.529 108.921h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M141.944 109.335h13.684v6.082h-13.684zM146.367 109.266v6.22M151.204 109.266v6.22"
        />
        <path fill="#D6D6D6" d="M169.864 108.921h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M170.279 109.335h13.684v6.082h-13.684zM174.702 109.267v6.22"
        />
        <path fill="#D6D6D6" d="M160.189 108.921h5.529v6.911h-5.529z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M160.604 109.335h4.7v6.082h-4.7z"
        />
        <path fill="#D6D6D6" d="M141.529 121.361h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M141.944 121.776h13.684v6.082h-13.684zM146.367 121.706v6.22M151.204 121.706v6.22"
        />
        <path fill="#D6D6D6" d="M169.864 121.36h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M170.279 121.774h13.684v6.082h-13.684zM174.702 121.705v6.22"
        />
        <path fill="#D6D6D6" d="M160.189 121.36h5.529v6.911h-5.529z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M160.604 121.774h4.7v6.082h-4.7z"
        />
        <path fill="#D6D6D6" d="M141.529 133.801h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M141.944 134.216h13.684v6.082h-13.684zM146.367 134.145v6.22M151.204 134.145v6.22"
        />
        <path fill="#D6D6D6" d="M169.864 133.799h14.513v6.911h-14.513z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M170.279 134.214h13.684v6.082h-13.684zM174.702 134.144v6.22"
        />
        <path fill="#D6D6D6" d="M160.189 133.799h5.529v6.911h-5.529z" />
        <path
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          d="M160.604 134.214h4.7v6.082h-4.7z"
        />
        <path
          d="M156.74 66.34a.518.518 0 01.498-.372l14.234.024c.345 0 .593.332.496.664l-2.575 8.777a.517.517 0 01-.498.373l-14.236-.025a.518.518 0 01-.496-.663l2.577-8.778z"
          fill="#fff"
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M157.368 67.268a.517.517 0 01.497-.373h2.843c.345 0 .594.332.497.663l-.733 2.508a.52.52 0 01-.498.373h-2.842a.518.518 0 01-.498-.664l.734-2.507zM162.178 67.268a.519.519 0 01.498-.373h2.842c.346 0 .594.332.497.663l-.733 2.508a.517.517 0 01-.497.373h-2.843a.518.518 0 01-.497-.664l.733-2.507zM166.979 67.268a.517.517 0 01.497-.373h2.84c.346 0 .594.332.497.663l-.733 2.508a.518.518 0 01-.497.373h-2.84a.518.518 0 01-.497-.664l.733-2.507zM156.049 71.71a.517.517 0 01.497-.372h2.84c.346 0 .594.332.497.663l-.733 2.508a.517.517 0 01-.497.373h-2.84a.518.518 0 01-.497-.664l.733-2.508zM160.86 71.71a.517.517 0 01.497-.372h2.84c.346 0 .594.332.497.663l-.733 2.508a.517.517 0 01-.497.373h-2.84a.518.518 0 01-.497-.664l.733-2.508zM165.657 71.71a.52.52 0 01.498-.372h2.842c.346 0 .595.332.498.663l-.734 2.508a.517.517 0 01-.497.373h-2.842a.518.518 0 01-.498-.664l.733-2.508z"
          fill={illustrationPrimaryColor}
        />
        <path
          d="M158.115 75.988h-1.382v2.764h1.382v-2.764zM167.1 75.988h-1.382v2.764h1.382v-2.764z"
          fill="#fff"
          stroke={illustrationPrimaryColor}
          strokeWidth={0.829}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M141.491 173.64a5.091 5.091 0 100-10.183 5.091 5.091 0 000 10.183z"
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.83}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={139.58} cy={117.501} r={14.284} fill="#fff" />
      <g filter="url(#prefix__filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.854 131.439l-7.881 7.882a3.319 3.319 0 00.008 4.703l.002.003a3.318 3.318 0 002.352.973c.847 0 1.702-.324 2.352-.974l7.88-7.882a3.288 3.288 0 00.974-2.352c0-.353-.055-.695-.162-1.017l-.078-.235 3.31-3.31.281.227a15.338 15.338 0 009.678 3.408c4.126 0 8.003-1.604 10.919-4.52 6.015-6.016 6.015-15.817 0-21.833-6.015-6.016-15.813-6.016-21.828 0-5.711 5.713-6.007 14.832-.877 20.888l.239.283-3.192 3.184-.253-.113a3.347 3.347 0 00-3.724.685zm9.369-23.352h.001a13.197 13.197 0 019.345-3.867c3.386 0 6.773 1.292 9.345 3.867 5.147 5.148 5.147 13.537 0 18.685a13.125 13.125 0 01-9.345 3.867 13.139 13.139 0 01-9.346-3.867c-5.147-5.148-5.147-13.537 0-18.685z"
          fill={illustrationPrimaryColor}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.469 109.086a8.326 8.326 0 016.833 3.545 8.173 8.173 0 011.488 4.716 8.213 8.213 0 01-2.784 6.167 2.128 2.128 0 00-.187 2.996c.772.88 2.109.964 2.985.188a12.467 12.467 0 004.216-9.351 12.43 12.43 0 00-2.26-7.161 12.551 12.551 0 00-10.291-5.346 12.551 12.551 0 00-10.291 5.346 12.423 12.423 0 00-2.26 7.161c0 3.723 1.635 7.065 4.216 9.351a2.108 2.108 0 002.985-.188 2.128 2.128 0 00-.187-2.996 8.213 8.213 0 01-2.784-6.167c0-1.755.548-3.378 1.487-4.716a8.327 8.327 0 016.834-3.545z"
        fill={`url(#${id})`}
      />
      <path
        opacity={0.8}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.26 108.305c-.076.328-.321.51-.686.51-.456 0-.745-.332-.745-.857 0-.521.292-.857.745-.857.359 0 .633.22.686.551h-.209a.471.471 0 00-.477-.36c-.323 0-.533.262-.533.666 0 .407.207.667.534.667.243 0 .417-.118.476-.32h.209zm-5.841 5.03c.351 0 .56-.174.56-.466 0-.221-.144-.38-.369-.408v-.01c.159-.028.285-.189.285-.362 0-.248-.191-.414-.474-.414h-.627v1.66h.625zm-.418-1.477h.373c.201 0 .313.091.313.255 0 .18-.133.278-.377.278h-.309v-.533zm0 1.294v-.583h.367c.264 0 .399.098.399.29 0 .192-.131.293-.379.293h-.387zm-.261 6.245h-.217l-.166-.475h-.658l-.166.475h-.216l.609-1.66h.204l.61 1.66zm-.708-1.408h-.007l-.265.757h.536l-.264-.757zm3.191 7.47h-.217l-.166-.475h-.658l-.166.475h-.216l.609-1.659h.204l.61 1.659zm-.708-1.407h-.007l-.265.756h.536l-.264-.756zm1.964.846h-.46v.485h-.186v-.485h-.46v-.186h.46v-.486h.186v.486h.46v.186zm9.4-17.77v1.66h.555c.492 0 .78-.307.78-.831 0-.522-.289-.829-.78-.829h-.555zm.206.187h.336c.375 0 .583.23.583.645 0 .411-.209.641-.583.641h-.336v-1.286zm5.324 5.833h.818v.187h-1.024v-1.66h1.024v.187h-.818v.532h.776v.184h-.776v.57zm1.41 6.249v-.719h.732v-.184h-.732v-.57h.798v-.187h-1.004v1.66h.206zm-2.408 5.218v.158c0 .448-.26.714-.701.714-.462 0-.757-.338-.757-.866 0-.519.294-.849.757-.849.357 0 .629.213.691.543h-.217a.47.47 0 00-.474-.352c-.34 0-.545.246-.545.657 0 .417.208.676.545.676.293 0 .495-.191.495-.469v-.03h-.466v-.182h.672z"
        fill="#333"
      />
      <path
        d="M138.039 120.481l.416-1.28h2.025l.416 1.28h1.271l-1.934-5.61h-1.528l-1.937 5.61h1.271zm.718-2.205l.69-2.123h.044l.69 2.123h-1.424z"
        fill="#50B849"
      />
      <path
        d="M134.491 59.115a10.145 10.145 0 0112.985 0M131.189 55.841a14.759 14.759 0 0119.515 0M137.747 62.398a5.532 5.532 0 016.409 0M140.947 65.985h.008"
        stroke={illustrationPrimaryColor}
        strokeWidth={2.213}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.655 54.31c10.855 0 19.655-8.8 19.655-19.655S197.51 15 186.655 15 167 23.8 167 34.655s8.8 19.655 19.655 19.655z"
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.875 27.2a1.25 1.25 0 010 1.768l-13.747 13.747a1.25 1.25 0 01-1.768 0l-6.248-6.248a1.25 1.25 0 011.767-1.768l5.365 5.365 12.864-12.863a1.25 1.25 0 011.767 0z"
        fill={illustrationPrimaryColor}
      />
      <defs>
        <linearGradient
          id={id}
          x1={152.101}
          y1={108.281}
          x2={125.286}
          y2={108.281}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.06} stopColor="#FF3700" />
          <stop offset={0.495} stopColor="#FFE600" stopOpacity={0.496} />
          <stop offset={0.912} stopColor="#219653" />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(136 65.276)"
            d="M0 0h41.467v84.118H0z"
          />
        </clipPath>
        <filter
          id="prefix__filter0_d"
          x={109.16}
          y={99.972}
          width={48.679}
          height={48.679}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={0.811} />
          <feGaussianBlur stdDeviation={1.42} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

const MemoSvgAccountCreated = React.memo(AccountCreated);
export default MemoSvgAccountCreated;
