import * as React from 'react';
import { theme } from 'twin.macro';

function SvgCheckEmail(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      width={275}
      height={194}
      viewBox="0 0 275 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M249.02 88C249.02 77.2 238.353 61.5 233.02 55C203.52 25.5 166.02 21 132.02 21C98.0201 21 26.0201 55 25.0201 93C24.0201 131 60.52 152.5 92.02 169C123.52 185.5 143.353 162 169.02 127C190.653 97.5 249.02 122.5 249.02 88Z"
        fill="#F5F5F5"
      />
      <path
        d="M232.352 101.841C232.352 101.841 244.435 106.948 245.873 116.737C247.311 126.527 236.765 139.948 201.528 134.197C166.29 128.446 157.901 122.213 157.901 122.213"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M56.8711 49.2052C56.8711 49.2052 42.5749 47.5674 32.5043 56.6752C22.4338 65.7831 25.0343 84.4739 46.0255 85.1928C67.0167 85.9117 94.6966 90.7063 100.991 113.954"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M228.682 87.7188L227.152 93.4438L230.397 95.5105L227.152 104.92L195 100.347L228.682 87.7188Z"
        fill="#F9B83B"
      />
      <path
        d="M228.682 87.7188L227.152 93.4438L230.397 95.5105L227.152 104.92L195 100.347L228.682 87.7188Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195 100.347L221.752 81L228.682 87.7188L195 100.347Z"
        fill="#FCDB9D"
      />
      <path
        d="M232.982 90.6736L195 100.347L242.559 99.6877L232.982 90.6736Z"
        fill="#FCDB9D"
      />
      <path
        d="M195 100.347L221.752 81L228.682 87.7188L195 100.347Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.982 90.6736L195 100.347L242.559 99.6877L232.982 90.6736Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3948 28.425L61.3208 35.61L57.2578 38.2043L61.3208 50.0062L101.642 44.2635L59.3948 28.425Z"
        fill="#F9B83B"
      />
      <path
        d="M59.3948 28.425L61.3208 35.61L57.2578 38.2043L61.3208 50.0062L101.642 44.2635L59.3948 28.425Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.643 44.2634L68.0926 20L59.395 28.4249L101.643 44.2634Z"
        fill="#FCDB9D"
      />
      <path
        d="M54.013 32.1362L101.643 44.2635L42 43.4369L54.013 32.1362Z"
        fill="#FCDB9D"
      />
      <path
        d="M101.643 44.2634L68.0926 20L59.395 28.4249L101.643 44.2634Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.013 32.1362L101.643 44.2635L42 43.4369L54.013 32.1362Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.406 81.2501C182.869 81.63 183.137 82.1972 183.137 82.7958V117.357C183.137 118.461 182.241 119.357 181.137 119.357H90.02C88.9154 119.357 88.02 118.461 88.02 117.357V82.7958C88.02 82.1972 88.2882 81.63 88.7508 81.2501L134.309 43.8417C135.047 43.236 136.11 43.236 136.848 43.8417L182.406 81.2501Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M182.406 81.2501C182.869 81.63 183.137 82.1972 183.137 82.7958V117.357C183.137 118.461 182.241 119.357 181.137 119.357H90.02C88.9154 119.357 88.02 118.461 88.02 117.357V82.7958C88.02 82.1972 88.2882 81.63 88.7508 81.2501L134.309 43.8417C135.047 43.236 136.11 43.236 136.848 43.8417L182.406 81.2501Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
      />
      <path
        d="M170.573 52.9326H99.3594C98.2548 52.9326 97.3594 53.828 97.3594 54.9326V114.744C97.3594 115.848 98.2548 116.744 99.3594 116.744H170.573C171.677 116.744 172.573 115.848 172.573 114.744V54.9326C172.573 53.828 171.677 52.9326 170.573 52.9326Z"
        fill="white"
      />
      <path
        d="M172.573 92.4384L135.579 117.724L97.3594 91.607V80.355L135.579 106.472L172.573 81.1864V92.4384Z"
        fill="#ADADAD"
      />
      <path
        d="M170.573 52.9326H99.3594C98.2548 52.9326 97.3594 53.828 97.3594 54.9326V114.744C97.3594 115.848 98.2548 116.744 99.3594 116.744H170.573C171.677 116.744 172.573 115.848 172.573 114.744V54.9326C172.573 53.828 171.677 52.9326 170.573 52.9326Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
      />
      <path
        d="M158.969 77.2861H111.558C110.844 77.2861 110.265 77.865 110.265 78.5792C110.265 79.2933 110.844 79.8722 111.558 79.8722H158.969C159.684 79.8722 160.262 79.2933 160.262 78.5792C160.262 77.865 159.684 77.2861 158.969 77.2861Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M158.969 84.1821H111.558C110.844 84.1821 110.265 84.761 110.265 85.4752C110.265 86.1893 110.844 86.7682 111.558 86.7682H158.969C159.684 86.7682 160.262 86.1893 160.262 85.4752C160.262 84.761 159.684 84.1821 158.969 84.1821Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M144.315 91.0784H111.558C110.844 91.0784 110.265 91.6573 110.265 92.3714C110.265 93.0855 110.844 93.6645 111.558 93.6645H144.315C145.029 93.6645 145.608 93.0855 145.608 92.3714C145.608 91.6573 145.029 91.0784 144.315 91.0784Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M168.021 54.7693H101.644C100.216 54.7693 99.0581 55.9271 99.0581 57.3554V60.8035C99.0581 62.2318 100.216 63.3896 101.644 63.3896H168.021C169.449 63.3896 170.607 62.2318 170.607 60.8035V57.3554C170.607 55.9271 169.449 54.7693 168.021 54.7693Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M183.137 145.549C183.137 146.654 182.241 147.549 181.137 147.549H90.02C88.9154 147.549 88.02 146.654 88.02 145.549V83.4941L135.578 116L183.137 83.4941V145.549Z"
        fill="white"
      />
      <path
        d="M183.137 145.549C183.137 146.654 182.241 147.549 181.137 147.549H90.02C88.9154 147.549 88.02 146.654 88.02 145.549V83.4941L135.578 116L183.137 83.4941V145.549Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
      />
      <path
        d="M181.841 146.636C182.277 146.993 182.024 147.699 181.461 147.699H89.6962C89.1329 147.699 88.8801 146.993 89.3155 146.636L134.309 109.691C135.047 109.085 136.11 109.085 136.848 109.691L181.841 146.636Z"
        fill="white"
      />
      <path
        d="M181.841 146.636C182.277 146.993 182.024 147.699 181.461 147.699H89.6962C89.1329 147.699 88.8801 146.993 89.3155 146.636L134.309 109.691C135.047 109.085 136.11 109.085 136.848 109.691L181.841 146.636Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
      />
      <path
        d="M108.54 144.528C108.54 133.034 99.2221 123.716 87.7275 123.716V147.869H108.274C108.449 146.781 108.54 145.665 108.54 144.528Z"
        fill="#ADADAD"
      />
      <path
        d="M80.8313 162.755C92.3258 162.755 101.644 153.437 101.644 141.942C101.644 130.448 92.3258 121.129 80.8313 121.129C69.3367 121.129 60.0186 130.448 60.0186 141.942C60.0186 153.437 69.3367 162.755 80.8313 162.755Z"
        fill={illustrationPrimaryColor}
      />
      <path
        d="M80.8313 162.755C92.3258 162.755 101.644 153.437 101.644 141.942C101.644 130.448 92.3258 121.129 80.8313 121.129C69.3367 121.129 60.0186 130.448 60.0186 141.942C60.0186 153.437 69.3367 162.755 80.8313 162.755Z"
        stroke={illustrationPrimaryColor}
        strokeWidth={0.8}
        strokeMiterlimit={10}
      />
      <path
        d="M82.7241 134.938H80.0171L76.645 137.073V139.627L79.7644 137.672H79.8442V148.56H82.7241V134.938Z"
        fill="white"
      />
    </svg>
  );
}

const MemoSvgCheckEmail = React.memo(SvgCheckEmail);
export default MemoSvgCheckEmail;
