import * as React from 'react';

function SvgStepperCurrent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={8}
        cy={8}
        r={6}
        fill="white"
        stroke="#F7A60A"
        strokeWidth={4}
      />
    </svg>
  );
}

const MemoSvgStepperCurrent = React.memo(SvgStepperCurrent);
export default MemoSvgStepperCurrent;
