import tw, { styled } from 'twin.macro';

interface ITabsComposition {
  Item: typeof Item;
}

type ItemProps = {
  active?: boolean;
  disabled?: boolean;
}

const Tabs: ITabsComposition & React.FC<{ className?: string }> = ({
  children,
  ...props
}) => (
  <Container {...props}>
    {children}
  </Container>
);

const Container = styled.div(() => [
  tw`flex justify-evenly w-full rounded-sm bg-gray-25`,
]);

const Item = styled.button<ItemProps>(({ active, disabled }) => [
  tw`w-full h-10 px-2 py-3 rounded-sm text-base leading-none`,
  tw`hover:bg-action-100 hover:border-action-100 hover:text-white focus:outline-none transition-colors cursor-pointer`,

  active && [
    tw`text-white font-bold bg-action-500 border border-action-500`,
    tw`hover:bg-action-400 hover:border-action-400 focus:bg-action-600 focus:border-action-600`,
  ],

  disabled && tw`text-gray-200 border-gray-200`,
]);

Tabs.Item = Item;

export default Tabs;
