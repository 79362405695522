import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgColdWater(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4a1 1 0 011-1h11a1 1 0 011 1v2a1 1 0 01-1 1h-4v3.905h1.752c4.047 0 7.248 3.295 7.248 7.248v1.578a1 1 0 01-1 1h-4.683a1 1 0 01-1-1v-1.578c0-.282-.283-.47-.565-.564H7.03v1.659c0 1.875-1.594 3.468-3.469 3.468-.28 0-.562-.281-.562-.562V6.218c0-.281.281-.562.562-.562 1.875 0 3.469 1.593 3.469 3.468v1.781H14V7h-4a1 1 0 01-1-1V4zm11.5 22.116c0-1.395 1.855-3.535 2.177-3.907.162-.279.484-.279.646 0 .403.372 2.177 2.512 2.177 3.907C25.5 27.698 24.371 29 23 29s-2.5-1.302-2.5-2.884z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1={-0.199}
          y1={3}
          x2={25.243}
          y2={28.624}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51BCD5" />
          <stop offset={1} stopColor="#AEE5EF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgColdWater = React.memo(SvgColdWater);
export default MemoSvgColdWater;
