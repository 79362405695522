import * as React from 'react';

function SvgTotal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM10.3548 7C9.83939 7 9.36859 7.29145 9.14023 7.75191C8.91186 8.21237 8.96551 8.76206 9.27862 9.17006L14.5202 16L9.27862 22.8299C8.96551 23.2379 8.91186 23.7876 9.14023 24.2481C9.36859 24.7085 9.83939 25 10.3548 25H21.6452C22.3934 25 23 24.3956 23 23.65V20.5C23 19.7544 22.3934 19.15 21.6452 19.15C20.8969 19.15 20.2903 19.7544 20.2903 20.5V22.3H13.0965L17.302 16.8201C17.6735 16.336 17.6735 15.664 17.302 15.1799L13.0965 9.7H20.2903V11.5C20.2903 12.2456 20.8969 12.85 21.6452 12.85C22.3934 12.85 23 12.2456 23 11.5V8.35C23 7.60442 22.3934 7 21.6452 7H10.3548Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-4}
          y1={0}
          x2={26.4}
          y2={32}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB574" />
          <stop offset={1} stopColor="#FFE0C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgTotal = React.memo(SvgTotal);
export default MemoSvgTotal;
