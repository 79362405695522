import * as React from 'react';

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H14C15.103 16 16 15.103 16 14V2C16 0.897 15.103 0 14 0Z"
        fill="white"
      />
      <path
        d="M13.5 8H11V6C11 5.448 11.448 5.5 12 5.5H13V3H11C9.343 3 8 4.343 8 6V8H6V10.5H8V16H11V10.5H12.5L13.5 8Z"
        fill="#3B5998"
      />
    </svg>
  );
}

const MemoSvgFacebook = React.memo(SvgFacebook);
export default MemoSvgFacebook;
