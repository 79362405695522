import * as React from 'react';

function SvgMeterList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 15.33 19.192 18.2389 16.4993 19.7966C16.0212 20.0731 15.8579 20.6848 16.1344 21.1629C16.4109 21.641 17.0227 21.8043 17.5007 21.5278C20.786 19.6273 23 16.0728 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 16.0728 3.21398 19.6273 6.49927 21.5278C6.97733 21.8043 7.58906 21.641 7.86561 21.1629C8.14215 20.6848 7.97879 20.0731 7.50073 19.7966C4.80801 18.2389 3 15.33 3 12ZM17.0905 8.28592C17.341 7.88086 17.2711 7.35536 16.9234 7.02992C16.5757 6.70448 16.0467 6.6694 15.6591 6.94607L10.4191 10.6861C9.96953 11.0069 9.86521 11.6314 10.1861 12.0809C10.5069 12.5305 11.1314 12.6348 11.5809 12.3139L12.7758 11.4611L12.1495 12.4741C11.859 12.9438 12.0043 13.5601 12.4741 13.8505C12.9438 14.141 13.5601 13.9956 13.8505 13.5259L17.0905 8.28592Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgMeterList = React.memo(SvgMeterList);
export default MemoSvgMeterList;
