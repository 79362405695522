import { PeriodType } from '../models/Period';

export const APP_LANGUAGES = (
  process.env.REACT_APP_LANGUAGES || 'en,da,nb'
).split(',');
export const APP_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;
export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const BRAND: AppBrand =
  (process.env.REACT_APP_BRAND as AppBrand) || 'TECHEM_DENMARK';
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
export const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;
export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const REPORT_APP_LINKS_ENABLED =
  process.env.REACT_APP_REPORT_FOOTER_ENABLED === 'true' ||
  process.env.REACT_APP_REPORT_APP_LINKS_ENABLED === 'true';
export const DEFAULT_PERIOD_TYPE: PeriodType =
  BRAND === 'PALLE_MORCH' ? 'last365Days' : 'last30Days';
export const CONSUMPTION_COMPARISON_PERCENTAGES_ENABLED =
  process.env.REACT_APP_CONSUMPTION_COMPARISON_PERCENTAGES_ENABLED === 'true';

export type AppBrand = 'TECHEM_DENMARK' | 'TECHEM_NORWAY' | 'PALLE_MORCH';
