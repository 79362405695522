import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useCallback, useContext, useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import {
  SELECTED_TENANCY_IDENTIFIER,
  UNUSED_ACTIVATION_CODE_IDENTIFIER,
} from '../helpers/auth';
import { selectedTenantId } from '../lib/apolloClient';

// WORKINGCODE = 'SHFWAJGKTCIC';

// Original code on graphqltester: JEGRJUXBCKCM

/*
MORE CODES: 
IPDENDMEKVYV
JETVYECPWNXZ
EQEFULOANAZR
*/

const ValidateActivationCodeQuery = loader(
  '../graphql/queries/ValidateActivationCode.graphql'
);

const AddTenancyMutation = loader('../graphql/mutations/addTenancy.graphql');

const UserQuery = loader('../graphql/queries/User.graphql');

type CodeAction =
  | 'codeInputChange'
  | 'validateCode'
  | 'validationError'
  | 'validationSuccess'
  | 'addTenancy'
  | 'tenancyAdded'
  | 'storeCode'
  | 'reset';

export type ActivationCodeState = {
  code: string;
  codeComplete: boolean;
  validatingCode: boolean;
  codeValidated: boolean;
  validationError: boolean;
  canAddTenancy: boolean;
  addingTenancy: boolean;
  tenancyAdded: boolean;
  activationCodeStored: boolean;
};

const initialActivationCodeState: ActivationCodeState = {
  code: '',
  codeComplete: false,
  validatingCode: false,
  codeValidated: false,
  validationError: false,
  canAddTenancy: false,
  addingTenancy: false,
  tenancyAdded: false,
  activationCodeStored: false,
};

const activationCodeReducer = (
  state,
  action: {
    type: CodeAction;
    payload?: any;
  }
) => {
  switch (action.type) {
    case 'codeInputChange': {
      const sanitizedCode = action.payload
        .replace(/[^A-Za-z]/g, '')
        .toUpperCase();
      const chunks = sanitizedCode.match(/.{1,4}/g);
      const newCode = (
        chunks?.length ? chunks.join('-') : sanitizedCode
      ).substring(0, 14);
      return {
        ...state,
        code: newCode,
        codeComplete: newCode.length === 14,
        codeValidated: false,
        validationError: false,
        canAddTenancy: false,
      };
    }
    case 'validateCode': {
      return { ...state, validatingCode: true, canAddTenancy: false };
    }
    case 'validationError': {
      return {
        ...state,
        validatingCode: false,
        codeValidated: false,
        validationError: true,
        canAddTenancy: false,
      };
    }
    case 'validationSuccess': {
      return {
        ...state,
        validatingCode: false,
        codeValidated: true,
        validationError: false,
        canAddTenancy:
          state.code.length !== 14 ||
          state.validationError ||
          state.validatingCode,
      };
    }
    case 'addTenancy': {
      return {
        ...state,
        addingTenancy: true,
      };
    }
    case 'tenancyAdded': {
      return {
        ...state,
        addingTenancy: false,
        tenancyAdded: true,
      };
    }
    case 'storeCode': {
      return {
        ...state,
        activationCodeStored: true,
      };
    }
    case 'reset': {
      return {
        ...initialActivationCodeState,
      };
    }
    default:
      return state;
  }
};

export const useActivationCodeReducer = (
  initialState: Partial<ActivationCodeState> = initialActivationCodeState
) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(activationCodeReducer, {
    ...initialActivationCodeState,
    ...initialState,
  });
  const { setUserTenancies } = useContext(AppContext);

  const [validateCode] = useLazyQuery(ValidateActivationCodeQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      dispatch({
        type:
          response?.validateActivationCode.ok === true
            ? 'validationSuccess'
            : 'validationError',
      });
    },
  });

  const [addTenancy] = useMutation(AddTenancyMutation, {
    variables: { activationCode: state.code.replaceAll('-', '') },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      fetchUserTenancies();
      const message = t('settings.tenancy_added');
      toast.success(message);

      if (
        localStorage.getItem(UNUSED_ACTIVATION_CODE_IDENTIFIER) ===
        state.code.replaceAll('-', '')
      ) {
        localStorage.removeItem(UNUSED_ACTIVATION_CODE_IDENTIFIER);
      }
    },
  });

  const [fetchUserTenancies] = useLazyQuery(UserQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setUserTenancies(res.allTenanciesForCurrentUser);
      if (!selectedTenantId()) {
        selectedTenantId(res.allTenanciesForCurrentUser[0]?.id);
        localStorage.setItem(
          SELECTED_TENANCY_IDENTIFIER,
          JSON.stringify(res.allTenanciesForCurrentUser[0]?.id)
        );
      }
      dispatch({ type: 'tenancyAdded' });
    },
  });

  const doValidate = useCallback(() => {
    dispatch({ type: 'validateCode' });
    validateCode({
      variables: { activationCode: state.code.replaceAll('-', '') },
    });
  }, [dispatch, validateCode, state.code]);

  useEffect(() => {
    if (
      state.codeComplete &&
      !state.validatingCode &&
      !state.validationError &&
      !state.codeValidated
    ) {
      doValidate();
    }
  }, [state, doValidate]);

  const dispatchInterceptor = useCallback(
    (action: { type: CodeAction; payload?: any }) => {
      // Side effects
      if (action.type === 'addTenancy') {
        addTenancy();
      }

      if (action.type === 'storeCode') {
        localStorage.setItem(
          UNUSED_ACTIVATION_CODE_IDENTIFIER,
          action.payload.replaceAll('-', '')
        );
      }

      // Dispatch the action
      dispatch(action);
    },
    [addTenancy]
  );

  return [state, dispatchInterceptor];
};
