import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useActivationCodeReducer } from '../reducers/activationCodeReducer';
import {
  Box,
  Button,
  Form,
  Icon,
  FormControlGroup,
  Modal,
  Spinner,
} from '../ui';

const AddTenancyModal: React.FC<{
  show: boolean;
  onClose: () => void;
  code?: string;
}> = ({ show, onClose, code = '' }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useActivationCodeReducer();

  useEffect(() => {
    if (state.tenancyAdded) {
      onClose();
      dispatch({ type: 'reset' });
    }
  }, [state.tenancyAdded, onClose, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'codeInputChange',
      payload: code,
    });
  }, [code, dispatch]);

  return (
    <Modal
      title={t('login.enter_activation_code')}
      subtitle={t('login.activation_code_consists_of_12_letters')}
      titleVariant="h1"
      show={show}
      onHide={onClose}
      maxWidth="484"
      variant="stretch"
    >
      <Modal.FormWrapper>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{t('login.activation_code')}</Form.Label>
              <FormControlGroup>
                <Form.Control
                  type="text"
                  placeholder="ABCD-EFGH-IJKL"
                  value={state.code}
                  disabled={state.validatingCode}
                  onChange={(e) =>
                    dispatch({
                      type: 'codeInputChange',
                      payload: e.target.value,
                    })
                  }
                />
                <FormControlGroup.Suffix>
                  <FormControlGroup.Text>
                    {state.codeValidated && (
                      <Icon name="check" className="text-green-600 pt-1" />
                    )}
                    {state.validationError && (
                      <Icon name="close" className="text-red-500 pt-1" />
                    )}
                    {state.validatingCode && <Spinner />}
                  </FormControlGroup.Text>
                </FormControlGroup.Suffix>
              </FormControlGroup>

              {state.validationError && (
                <Form.Error>{t('login.activation_code_invalid')}</Form.Error>
              )}
            </Form.Group>

            <Box className="xl:hidden">
              <Form.Group>
                <Button
                  disabled={!state.canAddTenancy}
                  onClick={() => dispatch({ type: 'addTenancy' })}
                  type="button"
                  variant="primary"
                  fullWidth
                >
                  {state.addingTenancy ? (
                    <Spinner mode="light" />
                  ) : (
                    t('global.confirm')
                  )}
                </Button>
              </Form.Group>
            </Box>
          </Form>
        </Modal.Body>
        <Box className="hidden xl:block">
          <Modal.Footer>
            <Button
              disabled={!state.canAddTenancy}
              onClick={() => dispatch({ type: 'addTenancy' })}
              type="button"
              variant="primary"
              fullWidth
            >
              {state.addingTenancy ? (
                <Spinner mode="light" />
              ) : (
                t('global.confirm')
              )}
            </Button>
          </Modal.Footer>
        </Box>
      </Modal.FormWrapper>
    </Modal>
  );
};

export default AddTenancyModal;
