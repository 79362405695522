import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

function SvgHumidity(props: React.SVGProps<SVGSVGElement>) {
  // Generate unique IDs to hinder style conflicts on multiple instances.
  const id = uuidv4();
  const id2 = uuidv4();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.903 7.658c-1.097 1.17-7.403 7.894-7.403 12.279C10.5 24.907 14.339 29 19 29s8.5-4.093 8.5-9.063c0-4.386-6.032-11.11-7.403-12.28-.549-.876-1.645-.876-2.194 0z"
        fill={`url(#${id})`}
      />
      <path
        d="M9.368 3.064a32.679 32.679 0 00-2.432 3.161c-.515.773-1.018 1.623-1.395 2.47-.372.84-.641 1.72-.641 2.538 0 3.41 2.45 6.367 5.6 6.367 3.15 0 5.6-2.957 5.6-6.367 0-.814-.256-1.69-.616-2.53-.363-.848-.85-1.699-1.357-2.473-.962-1.472-2.028-2.724-2.513-3.194-.255-.386-.655-.636-1.114-.636-.47 0-.878.262-1.132.664z"
        fill={`url(#${id2})`}
        stroke="#fff"
        strokeWidth={1.2}
      />
      <defs>
        <linearGradient
          id={id}
          x1={8.136}
          y1={7}
          x2={29.489}
          y2={25.785}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51BCD5" />
          <stop offset={1} stopColor="#AEE5EF" />
        </linearGradient>
        <linearGradient
          id={id2}
          x1={4.109}
          y1={3}
          x2={17.521}
          y2={13.907}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51BCD5" />
          <stop offset={1} stopColor="#AEE5EF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgHumidity = React.memo(SvgHumidity);
export default MemoSvgHumidity;
