import slugify from 'slugify';

type RouteRef = {
  route: string;
};

type RouteGetter = (...args) => RouteRef;

const paths: { [k: string]: RouteGetter } = {
  alarms: () => ({ route: `/alarms` }),
  alarms_alarm: (alarmId) => ({ route: `/alarms/${alarmId}` }),
  alarms_new: () => ({ route: `/alarms/new-alarm?step=1` }),
  consumption: () => ({ route: `/` }),
  consumption_type: (consumptionKind) => ({
    route: `/consumption/${consumptionKind}`,
  }),
  consumption_room: (consumptionKind, roomName) => ({
    route: `/consumption/${consumptionKind}/room/${slugify(roomName, {
      lower: true,
    })}`,
  }),
  consumption_meter: (consumptionKind, meterId) => ({
    route: `/consumption/${consumptionKind}/meter/${meterId}`,
  }),
  forgot_password: () => ({
    route: '/login/email/forgot-password',
  }),
  help: () => ({ route: `/help` }),
  indoor_climate: () => ({ route: `/dashboard/indoor-climate` }),
  login: () => ({ route: `/login` }),
  login_with_email: () => ({ route: '/login/email' }),
  meters: () => ({ route: `/meters` }),
  notifications: () => ({ route: `/notifications` }),
  notifications_notification: (notificationId) => ({
    route: `/notifications/${notificationId}`,
  }),
  profile: () => ({ route: `/profile` }),
  settings: () => ({ route: `/settings` }),
  sign_up: () => ({ route: '/signup' }),
  sign_up_with_email: () => ({ route: '/signup/email' }),
  statements: () => ({ route: `/statements` }),
};

export default paths;
