import { ButtonHTMLAttributes, ClassAttributes } from 'react';
import tw, { styled } from 'twin.macro';

import { IconMapping } from '../lib/icons';
import { Box, Icon } from '../ui';

export type IconProps = {
  icon?: keyof typeof IconMapping;
  iconClass?: string;
};

export type ButtonProps = {
  disabled?: boolean;
  fullWidth?: boolean;
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'apple'
    | 'facebook';
};

const Button: React.FC<
  ButtonProps &
    ButtonHTMLAttributes<HTMLButtonElement> &
    ClassAttributes<HTMLButtonElement> &
    IconProps
> = ({ icon, children, iconClass, ...props }) => (
  <Container {...props}>
    <Box className="flex items-center space-x-1.5">
      {icon && <Icon name={icon} size="4" className={iconClass} />}

      <Box>{children}</Box>
    </Box>
  </Container>
);

const Container = styled.button<ButtonProps>(
  ({ variant, fullWidth, disabled }) => [
    tw`flex items-center justify-center space-x-2`,
    tw`h-12 px-8 py-3 rounded-sm font-bold text-base leading-none`,
    tw`focus:outline-none transition-colors`,

    disabled && [tw`text-gray-200 border-gray-200`],

    variant === 'primary' && [
      tw`text-white bg-action-500 border border-action-500`,
      tw`hover:bg-action-400 hover:border-action-400 focus:bg-action-600 focus:border-action-600`,
    ],

    variant === 'primary' &&
      disabled && [
        tw`text-action-200 bg-action-100 border-action-100`,
        tw`hover:bg-action-100 hover:border-action-100`,
      ],

    variant === 'secondary' && [
      tw`text-gray-500 bg-white border border-gray-200`,
      tw`hover:border-gray-300 focus:border-gray-500`,
    ],

    variant === 'secondary' && disabled && [tw`text-gray-200 border-gray-200`],

    variant === 'tertiary' && [tw`text-gray-500 h-6 p-1 border-none`],

    variant === 'apple' && [
      tw`text-white bg-apple border-apple`,
      tw`hover:bg-apple hover:border-apple`,
    ],

    variant === 'facebook' && [
      tw`text-white bg-facebook border-facebook`,
      tw`hover:bg-facebook hover:border-facebook`,
    ],

    variant === 'danger' && [
      tw`text-red-500 bg-white border border-red-200`,
      tw`hover:border-red-300 focus:border-red-500`,
    ],

    variant === 'danger' &&
      disabled && [
        tw`text-gray-200 border-gray-200`,
        tw`hover:text-gray-200 hover:border-gray-200`,
      ],

    fullWidth && [tw`w-full`],
  ]
);

export default Button;
