import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  clearUserStorage,
  getAccessToken,
  isTokenExpired,
  LANG_IDENTIFIER,
  SELECTED_TENANCY_IDENTIFIER,
  startAuthenticateTimer,
  stopAuthenticateTimer,
} from '../helpers/auth';
import { selectedTenantId } from '../lib/apolloClient';
import { Tenancy } from '../models/Tenancy';
import { User } from '../models/User';
import { Spinner } from '../ui';

const UserQuery = loader('../graphql/queries/User.graphql');

export type Breadcrumb = {
  label: string;
  link?: string;
};

export type AppContextType = {
  isMenuActive: boolean;
  closeMenu: Function;
  toggleMenu: Function;
  isBackdropActive: boolean;
  setBackdropActive: Function;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  currentUser: User;
  setCurrentUser: (user: User) => void;
  userTenancies: Tenancy[];
  setUserTenancies: (tenancies: Tenancy[]) => void;
};

const getDefaultUserTenancy = (): number => {
  try {
    if (localStorage.getItem(SELECTED_TENANCY_IDENTIFIER)) {
      return JSON.parse(localStorage.getItem(SELECTED_TENANCY_IDENTIFIER));
    }
  } catch (e) {
    return null;
  }
};

export const AppContext = createContext<AppContextType>(null);

export const AppContextProvider = ({ children }) => {
  const [initialising, setInitialising] = useState<boolean>(
    getAccessToken() && !isTokenExpired()
  );
  const [isMenuActive, setMenuActive] = useState(false);
  const [isBackdropActive, setBackdropActive] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [userTenancies, setUserTenancies] = useState([]);

  const [getUserInfo] = useLazyQuery(UserQuery, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onError: () => {
      console.log('Could not fetch user info from ctx. Logging out.');
      setInitialising(false);
      clearUserStorage();
      setUserTenancies(null);
      setCurrentUser(null);
      selectedTenantId(null);
      stopAuthenticateTimer();
    },
    onCompleted: (res) => {
      localStorage.setItem(LANG_IDENTIFIER, res.me.language);
      setUserTenancies(res.allTenanciesForCurrentUser);
      setCurrentUser(res.me);
      selectedTenantId(
        getDefaultUserTenancy() || Number(res.allTenanciesForCurrentUser[0]?.id)
      );
      startAuthenticateTimer();
      setInitialising(false);
    },
  });

  useEffect(() => {
    if (getAccessToken() && !isTokenExpired()) {
      getUserInfo();
    } else {
      setInitialising(false);
    }
  }, [setInitialising, getUserInfo]);

  const toggleMenu = useCallback(() => {
    setMenuActive((isActive) => !isActive);
    setBackdropActive((isActive) => !isActive);
  }, [setMenuActive]);

  const closeMenu = useCallback(() => {
    setMenuActive(false);
    setBackdropActive(false);
  }, [setMenuActive, setBackdropActive]);

  const providerValues = useMemo<AppContextType>(
    () => ({
      isMenuActive,
      closeMenu,
      toggleMenu,
      isBackdropActive,
      setBackdropActive,
      breadcrumbs,
      setBreadcrumbs,
      currentUser,
      setCurrentUser,
      userTenancies,
      setUserTenancies,
    }),
    [
      isMenuActive,
      closeMenu,
      toggleMenu,
      isBackdropActive,
      setBackdropActive,
      breadcrumbs,
      setBreadcrumbs,
      currentUser,
      setCurrentUser,
      userTenancies,
      setUserTenancies,
    ]
  );

  if (initialising) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <AppContext.Provider value={providerValues}>{children}</AppContext.Provider>
  );
};
