import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Text } from '../ui';

export const CookieConsentModal = () => {
  const { t } = useTranslation();
  const [hasActed, setHasActed] = useState<boolean>(() =>
    document.cookie
      .split(';')
      .some((c: string) => c.trim().startsWith('cookieAccepted'))
  );

  if (hasActed) {
    return null;
  }

  const onAccept = () => {
    // @ts-ignore
    dataLayer.push({ event: 'cookieConsent', hasAccepted: true });
    storeActed(true);
  };

  const onReject = () => {
    // @ts-ignore
    dataLayer.push({ event: 'cookieConsent', hasAccepted: false });
    storeActed(false);
  };

  const storeActed = (accepted: boolean) => {
    const maxAge = 60 * 60 * 24 * 365;
    document.cookie = `cookieAccepted=${accepted}; SameSite=None; Secure;;max-age=${maxAge}`;
    setHasActed(true);
  };

  return (
    <div className="print:hidden fixed bottom-0 md:bottom-4 md:right-4 margin-x-auto bg-white rounded p-8 max-w-lg space-y-4 shadow-lg">
      <Text color="300">
        <Trans i18nKey="cookie_consent.line1">
          <a href="/cookie-policy" className="text-secondary-500 font-bold">
            Read more.
          </a>
        </Trans>
      </Text>
      <Text color="300">{t('cookie_consent.line2')}</Text>
      <div className="flex justify-between items-center pt-4">
        <Button variant="tertiary" className="flex-1" onClick={onReject}>
          {t('cookie_consent.reject')}
        </Button>
        <Button variant="primary" className="flex-1" onClick={onAccept}>
          {t('cookie_consent.accept')}
        </Button>
      </div>
    </div>
  );
};
