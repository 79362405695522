import * as React from 'react';

const SvgOk = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3.667C9.19 3.667 3.667 9.188 3.667 16c0 6.811 5.522 12.333 12.333 12.333 6.812 0 12.333-5.521 12.333-12.333S22.812 3.667 16 3.667ZM1.667 16C1.667 8.084 8.084 1.667 16 1.667S30.333 8.084 30.333 16 23.916 30.333 16 30.333 1.667 23.916 1.667 16Z"
      fill="#0AC85B"
    />
    <path
      d="M20.532 19a1 1 0 1 1 1.6 1.2c-.306.407-.852.977-1.629 1.542-1.305.95-2.815 1.525-4.505 1.525-1.689 0-3.2-.576-4.504-1.525-.778-.565-1.323-1.135-1.629-1.542a1 1 0 0 1 1.519-1.296l.156.188.128.144.084.089c.26.271.567.545.918.8.986.717 2.101 1.142 3.328 1.142 1.228 0 2.342-.425 3.329-1.142.35-.255.658-.529.918-.8l.155-.167.132-.158Z"
      fill="#0AC85B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 12a1.5 1.5 0 0 1 1.5-1.5h.013a1.5 1.5 0 0 1 0 3H12a1.5 1.5 0 0 1-1.5-1.5ZM18.5 12a1.5 1.5 0 0 1 1.5-1.5h.013a1.5 1.5 0 0 1 0 3H20a1.5 1.5 0 0 1-1.5-1.5Z"
      fill="#0AC85B"
    />
  </svg>
);

const MemoSvgOk = React.memo(SvgOk);
export default MemoSvgOk;
