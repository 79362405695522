import tw, { styled, css } from 'twin.macro';

import CheckSVG from '../../src/svgs/check.svg';

interface ISelectionListComposition {
  Button: typeof Button;
  List: typeof List;
  Item: typeof Item;
}

type ListProps = {
  visible?: boolean;
};

const SelectionList: ISelectionListComposition &
  React.FC<{ className?: string }> = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);

const Container = styled.div(() => [tw`relative`]);

const Button = styled.button(() => [
  tw`hidden xl:flex items-center h-9 px-2 py-2.5 space-x-1 text-sm rounded-sm border border-gray-200 cursor-pointer`,
]);

const List = styled.ul<ListProps>(({ visible }) => [
  tw`-mx-4 bg-white overflow-hidden`,
  tw`xl:hidden xl:absolute xl:left-4 xl:top-10 xl:z-10 xl:shadow`,
  tw`xl:w-full xl:max-w-sm`,
  tw`xl:rounded xl:border xl:border-gray-200`,

  css`
    min-width: 360px;
  `,

  visible && tw`xl:block`,
]);

const Item = styled.li<{ active?: boolean }>(({ active }) => [
  tw`relative flex items-center justify-between py-5 px-4 h-14 sm:h-16`,
  tw`xl:h-12 xl:py-2 xl:pr-10`,
  tw`cursor-pointer hover:bg-gray-25 transition`,

  css`
    line-height: 24px;
  `,

  `&:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      border-top: 1px solid #F5F5F5;
      width: calc(100% - 32px);
    }`,

  active && [
    tw`bg-gray-25`,

    css`
      background-image: url(${CheckSVG});
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: right 16px center;
    `,

    `&:after {
      border-top: 1px solid transparent;
    }`,
  ],
]);

SelectionList.Button = Button;
SelectionList.List = List;
SelectionList.Item = Item;

export default SelectionList;
