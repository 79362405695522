import * as React from 'react';
import { theme } from 'twin.macro';

function SvgCompare(props: React.SVGProps<SVGSVGElement>) {
  const illustrationPrimaryColor = theme('colors.secondary.500');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 343 260"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M300.1 225.2c34.98-23.02 24.28-51.1 22.45-62.88-14.74-57.2-38.7-101.76-80.05-125.82C201.15 12.44 98.26-.14 42.39 61.9.17 108.78 17.17 164.2 59.82 189.01c36.44 21.21 38.57 22.42 80.24 34.97 20.14 6.06 125.07 24.26 160.05 1.23z"
      />
      <path
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M283.75 239.08h0a6.08 6.08 0 007-4.9h.01l.1-.6v-.01a5.97 5.97 0 00-5.1-6.78l-205.9-32.23h0a6.08 6.08 0 00-7.01 4.9h0l-.1.61s0 0 0 0a5.97 5.97 0 005.09 6.79l205.9 32.22z"
      />
      <path
        fill={illustrationPrimaryColor}
        d="M183.29 226.39a3 3 0 00-4.75-.02l-10.72 13.79a3 3 0 002.37 4.84h21.31a3 3 0 002.38-4.83l-10.59-13.78z"
      />
      <rect
        width={48.48}
        height={48.48}
        fill="#fff"
        rx={4}
        transform="matrix(-1 0 0 1 116 143)"
      />
      <path
        fill="#D6D6D6"
        d="M67.52 159.4c9.05-.5 16.37-7.5 17.36-16.4H71.52a4 4 0 00-4 4v12.4z"
      />
      <rect
        width={47.28}
        height={47.28}
        x={-0.6}
        y={0.6}
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.4}
        transform="matrix(-1 0 0 1 114.8 143)"
      />
      <path
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M108.18 166.89a16.29 16.29 0 11-32.58 0 16.29 16.29 0 0132.58 0z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M101.5 169.66a10 10 0 01-19.2.08"
      />
      <path
        fill={illustrationPrimaryColor}
        d="M85.72 165.85a2.34 2.34 0 100-4.67 2.34 2.34 0 000 4.67zM97.38 165.85a2.34 2.34 0 100-4.67 2.34 2.34 0 000 4.67z"
      />
      <rect
        width={43.48}
        height={43.48}
        fill="#fff"
        rx={3.59}
        transform="matrix(-1 0 0 1 284 166)"
      />
      <path
        fill="#D6D6D6"
        d="M240.52 180.72A16.62 16.62 0 00256.1 166h-11.98a3.59 3.59 0 00-3.59 3.59v11.13z"
      />
      <rect
        width={42.28}
        height={42.28}
        x={-0.6}
        y={0.6}
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={2.99}
        transform="matrix(-1 0 0 1 282.8 166)"
      />
      <path
        fill="#FCDB9D"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M274.94 187.77a13.17 13.17 0 11-26.34 0 13.17 13.17 0 0126.34 0z"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        d="M256.2 194.72a9.99 9.99 0 0111.28-.1"
      />
      <path
        fill={illustrationPrimaryColor}
        d="M256.74 186.91a1.9 1.9 0 100-3.8 1.9 1.9 0 000 3.8zM266.26 186.91a1.9 1.9 0 100-3.8 1.9 1.9 0 000 3.8z"
      />
      <circle
        r={18.52}
        fill={illustrationPrimaryColor}
        transform="matrix(-1 0 0 1 62.48 136.53)"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M58.6 142h-4.9a1.3 1.3 0 01-1.35-1.27v-4.97c0-.7.6-1.27 1.34-1.27.74 0 1.34.57 1.34 1.27v1.9l4.41-4.18c.53-.5 1.37-.5 1.9 0l3.96 3.76 5.41-5.2a1.4 1.4 0 011.9 0c.52.5.52 1.3 0 1.8l-6.36 6.1c-.52.49-1.37.49-1.9 0l-3.96-3.77-3.46 3.29h1.67c.74 0 1.34.57 1.34 1.27 0 .7-.6 1.27-1.34 1.27z"
        clipRule="evenodd"
      />
      <circle
        r={15.53}
        fill={illustrationPrimaryColor}
        transform="matrix(-1 0 0 1 236.53 160.53)"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M232.94 156h-4.12a1.1 1.1 0 00-1.12 1.06v4.17c0 .59.5 1.06 1.12 1.06a1.1 1.1 0 001.12-1.06v-1.6l3.7 3.51c.44.42 1.15.42 1.59 0l3.32-3.15 4.53 4.35c.44.41 1.15.41 1.6 0 .43-.42.43-1.09 0-1.5l-5.34-5.1a1.17 1.17 0 00-1.59 0l-3.32 3.14-2.9-2.75h1.4a1.1 1.1 0 001.13-1.06c0-.6-.5-1.07-1.12-1.07z"
        clipRule="evenodd"
      />
      <path
        stroke={illustrationPrimaryColor}
        strokeDasharray="1 3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M85 243.5C79 233 75.5 226 74 211m9 2.5c1 10 3.5 16 6 22.5"
      />
      <path
        fill="#fff"
        d="M71 90.6c0-23.49 38.33-28.58 38.33-28.58 18.77-28.45 48.56-8.15 48.56-8.15s5.3-18.75 30.56-22.5c25.25-3.75 41.04 22.5 41.04 22.5C266.66 47.97 273 81.16 273 90.6c0 9.44-5.96 36.86-43.38 31.68 0 0-15.8 22.64-41.05 19.4-25.25-3.23-30.56-19.4-30.56-19.4s-29.78 17.6-48.55-7.11h-.06c-1.53.17-38.4-1.09-38.4-24.57z"
      />
      <rect width={40} height={40} x={159} y={73} fill="#D6D6D6" rx={3.94} />
      <rect
        width={38.8}
        height={38.8}
        x={153.6}
        y={67.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.4}
      />
      <path
        fill={illustrationPrimaryColor}
        stroke={illustrationPrimaryColor}
        strokeWidth={1.2}
        d="M167.51 73.05a.55.55 0 01.54-.45h12.66c.3 0 .55.25.55.55v2.3c0 .3-.25.55-.55.55H175.51V81.69h2.62a7.74 7.74 0 017.74 7.74v1.82c0 .3-.25.55-.55.55h-5.39a.54.54 0 01-.55-.55v-1.82c0-.38-.2-.67-.4-.85-.2-.18-.44-.3-.66-.37l-.1-.03h-14.18v2.51a3.45 3.45 0 01-3.38 3.39.16.16 0 01-.06-.06v-18.3l.03-.03a.13.13 0 01.03-.03 3.45 3.45 0 013.38 3.39v2.65H173.26V76.01H168.06a.55.55 0 01-.55-.55v-2.41zm14.24 23.19c.5-.74 1.03-1.38 1.28-1.66a12.23 12.23 0 012 2.94c.18.42.27.8.27 1.08 0 1.57-1.1 2.72-2.28 2.72-1.17 0-2.28-1.15-2.28-2.72 0-.28.1-.65.29-1.08.18-.42.44-.86.72-1.28z"
      />
      <rect width={40} height={40} x={101} y={64} fill="#D6D6D6" rx={3.94} />
      <rect
        width={40}
        height={40}
        x={95}
        y={58}
        fill={illustrationPrimaryColor}
        rx={4}
      />
      <path
        fill="#FCDB9D"
        d="M119.73 65L107 81.9l6.6-1.8-3.07 11.26 13.19-17.25-6.99 1.06 3-10.17z"
      />
      <rect
        width={39.37}
        height={39.37}
        x={212}
        y={54}
        fill="#D6D6D6"
        rx={3.94}
      />
      <rect
        width={38.17}
        height={38.17}
        x={206.6}
        y={48.6}
        fill="#fff"
        stroke={illustrationPrimaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.2}
        rx={3.34}
      />
      <path
        fill={illustrationPrimaryColor}
        fillRule="evenodd"
        d="M217.85 55.87c-1.22 0-2.16.93-2.16 2.13V75.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V58c0-1.2-.94-2.13-2.16-2.13zm5.19 0c-1.22 0-2.16.93-2.16 2.13V75.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V58c0-1.2-.94-2.13-2.16-2.13zm5.18 0c-1.22 0-2.16.93-2.16 2.13V75.8c0 1.2.94 2.13 2.16 2.13 1.15 0 2.3-.9 2.16-2.16V58c0-1.2-.94-2.13-2.16-2.13zm5.3 0c-1.23 0-2.17.93-2.17 2.13V75.8c0 1.2.94 2.13 2.16 2.13 1.22 0 2.16-.93 2.16-2.13V58c0-1.2-.94-2.13-2.16-2.13zm3.35 2.86c-.23 0-.44.2-.44.42v3.21c0 .23.2.43.44.43h.53c1.14 0 2.06-.9 2.06-2.03s-.92-2.03-2.06-2.03h-.53zm0 11.72c-.23 0-.44.2-.44.43v3.2c0 .23.2.43.44.43h.53c1.14 0 2.06-.9 2.06-2.03s-.92-2.03-2.06-2.03h-.53zm-24.53-11.72c-.23 0-.44.2-.44.42v3.21c0 .23.2.43.44.43h2.16c.23 0 .43-.2.43-.43v-3.2c0-.23-.2-.43-.43-.43h-2.16zm0 11.72c-.23 0-.44.2-.44.43v3.2c0 .23.2.43.44.43h2.16c.23 0 .43-.2.43-.42v-3.21c0-.23-.2-.43-.43-.43h-2.16z"
        clipRule="evenodd"
      />
    </svg>
  );
}

const MemoSvgCompare = React.memo(SvgCompare);
export default MemoSvgCompare;
