import tw, { styled } from 'twin.macro';
import { IllustrationMapping } from '../lib/illustrations';

import { Text } from './';

export type IllustrationProps = {
  title?: string;
  text?: string;
  illustration: keyof typeof IllustrationMapping;
  width?: number;
  height?: number;
  className?: string;
};

const Illustration: React.FC<IllustrationProps> = ({
  title,
  text,
  illustration,
  ...props
}) => {
  const Illustration = IllustrationMapping[illustration];

  return (
    <Container>
      <Illustration {...props} />

      {(title || text) && (
        <TextWrapper>
          <Text variant="h1">
            {title}
          </Text>
          <Text color="300">
            {text}
          </Text>
        </TextWrapper>
      )}
    </Container>
  );
};

const Container = styled.div(() => [
  tw`flex flex-col items-center`
]);

const TextWrapper = styled.div(() => [
  tw`text-center max-w-xs space-y-2 pt-4`
]);

export default Illustration;
