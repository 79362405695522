import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MeterKind } from '../models/Consumption';

const METER_KIND_FORMATS: {
  [k in MeterKind | string]: Partial<Intl.NumberFormatOptions>;
} = {
  coldwater: { minimumFractionDigits: 2 },
  cooling: { minimumFractionDigits: 2 },
  electricity: { minimumFractionDigits: 2 },
  energy: { maximumFractionDigits: 0 },
  heating: { maximumFractionDigits: 0 },
  hotwater: { minimumFractionDigits: 2 },
  humidity: { maximumFractionDigits: 0 },
  radiator: { maximumFractionDigits: 0 },
  temperature: { maximumFractionDigits: 0 },
};

export const useNumberFormat = () => {
  const { i18n } = useTranslation();

  const nf = useCallback(
    (value: number, config?: Intl.NumberFormatOptions) => {
      const locale = i18n.language === 'en' ? 'en-GB' : 'da-DK';
      const val = Intl.NumberFormat(locale, {
        maximumFractionDigits: 2,
        ...config,
      }).format(value);
      return val;
    },
    [i18n.language]
  );

  const formatByMeterKind = useCallback(
    (value, meterKind: MeterKind) =>
      nf(value, METER_KIND_FORMATS[meterKind] || {}),
    [nf]
  );

  return { nf, formatByMeterKind };
};
