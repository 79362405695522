import * as React from 'react';
import { SVGProps } from 'react';

const SvgSmile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3.667C9.19 3.667 3.667 9.188 3.667 16c0 6.811 5.522 12.333 12.333 12.333 6.812 0 12.333-5.521 12.333-12.333S22.812 3.667 16 3.667ZM1.667 16C1.667 8.084 8.084 1.667 16 1.667S30.333 8.084 30.333 16 23.916 30.333 16 30.333 1.667 23.916 1.667 16Z"
      fill="#067837"
    />
    <path
      d="M22.4 18a1 1 0 0 1 1 1c0 .776-.233 1.856-.939 2.944-1.162 1.792-3.232 2.856-6.286 2.856-3.04 0-5.16-1.05-6.433-2.815-.774-1.073-1.083-2.144-1.14-2.912a1 1 0 0 1 1.98-.261l.031.235.037.185a4.364 4.364 0 0 0 .714 1.583c.89 1.235 2.404 1.985 4.81 1.985 2.394 0 3.826-.736 4.609-1.944.27-.416.445-.867.542-1.317l.026-.126.033-.211.01-.084L21.4 19a1 1 0 0 1 1-1Z"
      fill="#067837"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 12a1.5 1.5 0 0 1 1.5-1.5h.015a1.5 1.5 0 0 1 0 3H12a1.5 1.5 0 0 1-1.5-1.5ZM18.5 12a1.5 1.5 0 0 1 1.5-1.5h.015a1.5 1.5 0 0 1 0 3H20a1.5 1.5 0 0 1-1.5-1.5Z"
      fill="#067837"
    />
  </svg>
);

const MemoSvgSmile = React.memo(SvgSmile);
export default MemoSvgSmile;
