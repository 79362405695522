import * as React from 'react';
import { SVGProps } from 'react';

const SvgMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3.667C9.188 3.667 3.666 9.188 3.666 16c0 6.811 5.522 12.333 12.334 12.333 6.811 0 12.333-5.521 12.333-12.333S22.811 3.667 16 3.667ZM1.666 16C1.666 8.084 8.084 1.667 16 1.667c7.916 0 14.333 6.417 14.333 14.333S23.916 30.333 16 30.333 1.666 23.916 1.666 16Z"
      fill="#F7A60A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.74 21.718a1 1 0 0 1 .539-1.308l.384.923-.384-.923h.002l.003-.002.009-.004.026-.01.088-.034c.073-.027.177-.064.31-.108.267-.086.651-.2 1.141-.31.98-.223 2.388-.442 4.139-.442 1.75 0 3.158.219 4.138.442.49.11.874.224 1.14.31a8.095 8.095 0 0 1 .399.142l.026.01.009.004.003.001h.001s.001.001-.367.886l.368-.885a1 1 0 0 1-.769 1.846l.385-.923-.385.923h.001l.002.002-.009-.004-.05-.02a6.03 6.03 0 0 0-.234-.08c-.212-.07-.537-.165-.963-.262a16.763 16.763 0 0 0-3.695-.392c-1.584 0-2.842.198-3.695.392a10.94 10.94 0 0 0-.964.262 5.918 5.918 0 0 0-.284.1l-.008.003h.001a1 1 0 0 1-1.307-.54ZM10.5 12a1.5 1.5 0 0 1 1.5-1.5h.016a1.5 1.5 0 0 1 0 3H12a1.5 1.5 0 0 1-1.5-1.5ZM18.5 12a1.5 1.5 0 0 1 1.5-1.5h.016a1.5 1.5 0 0 1 0 3H20a1.5 1.5 0 0 1-1.5-1.5Z"
      fill="#F7A60A"
    />
  </svg>
);

const MemoSvgMedium = React.memo(SvgMedium);
export default MemoSvgMedium;
