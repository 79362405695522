import tw, { styled } from 'twin.macro';

export type SvgWrapperProps = {
  size?: '3' | '4' | '5' | '6' | '8' | '10' | '12' | '14' | '16' | 'auto';
};

const SvgWrapper = styled.span<SvgWrapperProps>(({ size }) => [
  tw`inline-block w-6 h-6`,

  size === '3' && tw`w-3 h-3`,
  size === '4' && tw`w-4 h-4`,
  size === '5' && tw`w-5 h-5`,
  size === '6' && tw`w-6 h-6`,
  size === '8' && tw`w-8 h-8`,
  size === '10' && tw`w-10 h-10`,
  size === '12' && tw`w-12 h-12`,
  size === '14' && tw`w-14 h-14`,
  size === '16' && tw`w-16 h-16`,
  size === 'auto' && tw`w-auto h-auto`,
]);

export default SvgWrapper;
